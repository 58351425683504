import { atom, useRecoilValue } from 'recoil';
import firebase from '../settings/firebase';

export const firestoreAtom = atom({
  key: 'firestoreAtom',
  default: Promise.resolve(firebase.firestore)
});

export function useFirestore() {
  return useRecoilValue(firestoreAtom);
}
