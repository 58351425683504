import { Asset, Entry } from 'contentful';
import * as React from 'react';
import { style, classes } from 'typestyle';
import { EmbeddedAsset } from './EmbeddedAsset';

export interface Fields {
  image: Asset;
  url: string;
}

export interface ImageLinkProps {
  entry: Entry<Fields>;
  block?: boolean;
}

export function isImageLink(entry: Entry<unknown>): entry is Entry<Fields> {
  return entry.sys.contentType.sys.id === 'imageLink';
}

const cn = {
  blockLink: style({
    display: 'block'
  })
};

export function ImageLink({ entry, block = false }: ImageLinkProps) {
  return (
    <a
      href={entry.fields.url}
      target="_blank"
      rel="noopener"
      referrerPolicy="same-origin"
      className={classes(block && cn.blockLink)}
    >
      <EmbeddedAsset asset={entry.fields.image} block={block} />
    </a>
  );
}
