import { Button, Divider, Paper, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { coupons } from 'stripe';
import { classes, style } from 'typestyle';
import { getAuthUser } from '../ducks/auth';
import { requestWithAuth } from '../ducks/requestWithAuth';
import { endpoint } from '../env';
import { customerState } from '../recoil';
import { analytics } from '../utils/analytics';
import { Link } from '../utils/components';
import { messageOf } from '../utils/error';
import { getDefaultCard, getSubscription } from '../utils/stripeHelpers';
import { container, margin, marginR, marginV, padding } from '../utils/xlasses';
import { useConfirm } from './ConfirmManager';
import { ErrorBoundary } from './ErrorBoundary';
import { LoadingButton } from './LoadingButton';
import { LoadingPage } from './LoadingPage';
import { noticeAtom } from './NoticeManager';
import { PlanCancelDialog } from './PlanCancelDialog';
import { NeedSignIn } from './SignInDialog';

const cn = {
  enoughUsedForFree: style({
    backgroundColor: 'rgba(0,0,0,0.1)',
    padding: 8,
    paddingRight: 16,
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 600,
    marginBottom: 16
  })
};

export type EnoughUsedForFreeProps = {
  className?: string;
};

export const EnoughUsedForFree: React.ComponentType<
  EnoughUsedForFreeProps
> = props => (
  <div className={classes(cn.enoughUsedForFree, props.className)}>
    <img
      src={require('../resources/blue_penguin_knight.png')}
      alt="(^・◆・^)"
    />
    <span>HackforPlayは無料プランでも十分お使いいただけます</span>
  </div>
);

export function Plan() {
  return (
    <ErrorBoundary fallback={<NeedSignIn />}>
      <React.Suspense fallback={<LoadingPage />}>
        <AuthUserPlan />
      </React.Suspense>
    </ErrorBoundary>
  );
}

function AuthUserPlan() {
  const authUser = useSelector(getAuthUser);

  const customer = useRecoilValue(customerState);
  const sub = getSubscription(customer);
  const card = getDefaultCard(customer);

  const status = sub && sub.status;
  const free = !status || status === 'canceled';
  // キャンセル予約が実行される（解約される）とき
  const cancelAt =
    sub &&
    (status === 'active' || status === 'trialing') &&
    sub.cancel_at_period_end &&
    sub.current_period_end * 1000;
  // 次回の引き落とし日（このピリオドの終了日）
  const chargeAt =
    sub &&
    (status === 'active' || status === 'trialing') &&
    !sub.cancel_at_period_end &&
    sub.current_period_end * 1000;
  // トライアルが終了する日
  const endTrialAt =
    sub?.status === 'trialing' && sub?.trial_end && sub?.trial_end * 1000;

  const [loading, setLoading] = React.useState(false);

  // これから契約
  const addSubscription = useRecoilCallback(
    async ({ reset, set }) => {
      analytics.startSubscription();
      if (!authUser?.haveTrialed) {
        analytics.generateLead();
      }

      try {
        setLoading(true);
        await requestWithAuth(endpoint + '/subscriptions', 'POST');
        reset(customerState);
        set(noticeAtom, {
          severity: 'success',
          children: 'おめでとうございます！プランが変更されました 🎉'
        });
      } catch (error) {
        set(noticeAtom, {
          severity: 'error',
          children:
            '月額プランを開始できませんでした。右下のチャットからお問い合わせください'
        });
        window.Rollbar?.error(error);
        await window._slaask?.show();
        const snippet =
          '@teramotodaiki 月額プランを開始できませんでした。\n```\n' +
          messageOf(error) +
          '\n```'; // スニペットとしてエラーメッセージの全文を送る
        window._slaask?.sendMessageAsContact(snippet);
      } finally {
        setLoading(false);
      }
    },
    [authUser?.haveTrialed]
  );

  // キャンセルダイアログの表示
  const [opened, setOpened] = React.useState(false);
  const handleCloseDialog = React.useCallback(() => setOpened(false), []);
  // サブスクリプションのキャンセル予約
  const confirm = useConfirm();
  const cancelSubscription = useRecoilCallback(
    async ({ reset, set }, reason: string) => {
      setOpened(false);
      if (!sub) return;
      if (
        await confirm(
          '本当にプランを解約しますか？今まで使っていたアセットは使えなくなります。',
          'やめておく',
          'サブスクリプションの解約を予約する'
        )
      ) {
        return;
      }
      analytics.stopSubscription();

      try {
        setLoading(true);
        await requestWithAuth(endpoint + `/subscriptions/${sub.id}`, 'DELETE', {
          reason
        });
        reset(customerState);
        set(noticeAtom, {
          severity: 'success',
          children:
            'プランが変更されました。引き続きハックフォープレイをお楽しみください！'
        });
      } catch (error) {
        set(noticeAtom, {
          severity: 'error',
          children:
            '月額プランを解約できませんでした。右下のチャットからお問い合わせください'
        });
        window.Rollbar?.error(error);
        await window._slaask?.show();
        const snippet =
          '@teramotodaiki 月額プランを解約できませんでした。\n```\n' +
          messageOf(error) +
          '\n```'; // スニペットとしてエラーメッセージの全文を送る
        window._slaask?.sendMessageAsContact(snippet);
      } finally {
        setLoading(false);
      }
    },
    [sub]
  );

  // サブスクリプションのキャンセル予約を解除
  const reactivateSubscription = useRecoilCallback(
    async ({ reset, set }) => {
      if (!sub) return;
      try {
        setLoading(true);
        await requestWithAuth(endpoint + `/subscriptions/${sub.id}`, 'PUT');
        reset(customerState);
        set(noticeAtom, {
          severity: 'success',
          children:
            'プランが変更されました。戻ってきてくれて、ありがとうございます！'
        });
      } catch (error) {
        set(noticeAtom, {
          severity: 'error',
          children:
            '解約予約を解除できませんでした。右下のチャットからお問い合わせください'
        });
        window.Rollbar?.error(error);
        await window._slaask?.show();
        const snippet =
          '@teramotodaiki 解約予約を解除できませんでした。\n```\n' +
          messageOf(error) +
          '\n```'; // スニペットとしてエラーメッセージの全文を送る
        window._slaask?.sendMessageAsContact(snippet);
      } finally {
        setLoading(false);
      }
    },
    [sub]
  );

  const cardExistButCanceled = Boolean(sub && sub.cancel_at_period_end && card);

  if (!authUser) {
    return <NeedSignIn />;
  }

  return (
    <>
      <Paper
        elevation={2}
        className={classes(container.medium, margin.large, padding.medium)}
      >
        <Typography color="textSecondary" gutterBottom>
          現在のプラン
        </Typography>
        <Typography variant="h5" gutterBottom>
          {authUser?.enterprise
            ? '法人プラン'
            : free
            ? '無料プラン'
            : '月額プラン'}
          {cardExistButCanceled ? (
            <small>(キャンセル済み)</small>
          ) : status === 'trialing' ? (
            <small>(無料体験中)</small>
          ) : null}
        </Typography>
        {free || sub?.cancel_at_period_end ? null : (
          <div>
            <img src={require('../resources/thank_you.png')} alt="Thank you!" />
          </div>
        )}

        {authUser?.enterprise ? (
          <Typography variant="body1" color="textSecondary" gutterBottom>
            所属している法人でお支払いが有効になっています
          </Typography>
        ) : free ? (
          <Typography variant="body1" color="textSecondary" gutterBottom>
            月額プランに加入すると、より深くプログラミングを学べるようになります。
          </Typography>
        ) : null}

        {free && !authUser?.enterprise && !card ? (
          <div className={marginV.large}>
            <Alert severity="info">
              月額プランに変更するには、お支払い情報の登録が必要です
            </Alert>
            <Button
              variant="contained"
              color="primary"
              className={marginV.small}
              component={Link(`/payments`)}
            >
              お支払い情報を登録する
            </Button>
          </div>
        ) : null}

        {endTrialAt && !cardExistButCanceled ? (
          <Typography
            variant="body1"
            color="textSecondary"
            className={marginV.medium}
          >
            {moment(endTrialAt).format(
              'YYYY/MM/DD から自動で引き落としされます。いつでもキャンセル可能です'
            )}
          </Typography>
        ) : null}

        {chargeAt ? (
          <Typography
            variant="body1"
            color="textSecondary"
            className={marginV.medium}
          >
            {moment(chargeAt).format('次回のお支払いは YYYY/MM/DD です。')}
            {`金額は${sub?.plan?.amount || '-'}円(税込み)です。`}
          </Typography>
        ) : null}

        {cardExistButCanceled && cancelAt ? (
          <div className={marginV.medium}>
            {moment(cancelAt).format(
              'YYYY/MM/DD までの間、いつでも解約をキャンセルできます。解約時に料金は発生しません'
            )}
          </div>
        ) : null}

        {free && !authUser?.enterprise && !authUser?.haveTrialed ? (
          <Alert severity="success">
            {moment()
              .add(3, 'months')
              .format('今ご加入いただくと、YYYY/MM/DD まで無料になります。')}
          </Alert>
        ) : null}

        {free && authUser?.haveTrialed ? (
          <Alert severity="info">
            最初の１ヶ月分の料金（980円）が引き落とされ、以降は毎月自動的に引き落としとなります
          </Alert>
        ) : null}

        <Coupon coupon={sub?.discount?.coupon} />
        <Coupon coupon={customer?.discount?.coupon} />

        {!free && !card ? (
          <Alert severity="error">
            お支払い情報が削除されています。次回のお引き落とし日までに必ずご登録ください
          </Alert>
        ) : null}

        <div className={marginV.medium}>
          {cancelAt && card ? (
            <LoadingButton
              variant="outlined"
              color="primary"
              className={marginR.medium}
              onClick={reactivateSubscription}
              loading={loading}
            >
              やっぱり解約をキャンセルする
            </LoadingButton>
          ) : null}
          {free && !authUser?.enterprise ? (
            <LoadingButton
              variant="contained"
              color="primary"
              className={marginR.medium}
              onClick={addSubscription}
              disabled={!card}
              loading={loading}
            >
              月額プランにする
              {authUser?.haveTrialed
                ? null
                : '(初めての方に限り、３ヶ月間無料トライアル ✨)'}
            </LoadingButton>
          ) : null}
        </div>
      </Paper>
      {sub?.status && sub?.status !== 'canceled' ? (
        <div className={container.medium}>
          <Divider />
          <LoadingButton
            onClick={() => setOpened(true)}
            disabled={Boolean(cancelAt)}
            loading={loading}
          >
            月額プランを解約する{!cancelAt ? null : '(設定済み)'}
          </LoadingButton>
        </div>
      ) : null}
      <PlanCancelDialog
        open={opened}
        onResult={cancelSubscription}
        onClose={handleCloseDialog}
      />
    </>
  );
}

interface CouponProps {
  coupon?: coupons.ICoupon;
}

function Coupon({ coupon }: CouponProps) {
  return coupon ? (
    <Alert severity="success">
      <Typography variant="body1" gutterBottom>
        {coupon.name}が有効になっています
      </Typography>
      <Typography variant="subtitle1">
        表示価格より
        {coupon.amount_off ? `${coupon.amount_off}円` : ''}
        {coupon.percent_off ? `${coupon.percent_off}%` : ''}OFF
      </Typography>
    </Alert>
  ) : null;
}
