/**
 * 任意の Promise に時間制限をつける
 */
export function timeout<T>(
  promise: Promise<T>,
  time: number,
  timeoutError = new Error('Operation timeout')
) {
  return new Promise<T>((resolve, reject) => {
    const handle = setTimeout(() => {
      reject(timeoutError);
    }, time);

    promise
      .then(value => {
        clearTimeout(handle);
        resolve(value);
      })
      .catch(reject);
  });
}
