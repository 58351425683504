import { createGenerateClassName, jssPreset } from '@material-ui/styles';
import { create } from 'jss';
import * as React from 'react';
import { JssProvider } from 'react-jss';

const styleNode = document.createElement('style');
styleNode.id = 'insertion-point-jss';
if (!document.head) {
  throw new Error('document.head is not initialized');
}
document.head.insertBefore(styleNode, document.head.firstChild);

const generateClassName = createGenerateClassName();

// Configure JSS
const options = jssPreset();
options.insertionPoint = styleNode;
(options as any).generateClassName = generateClassName; // Warning: Failed prop type: The prop `jss.options.createGenerateClassName` is marked as required in `JssProvider`, but its value is `undefined`.
const jss = create(options);

export default function Provider(props: any) {
  return (
    <JssProvider generateId={generateClassName} jss={jss}>
      {props.children}
    </JssProvider>
  );
}
