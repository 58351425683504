import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { style } from 'typestyle';
import { actions } from '../ducks/actions';
import { Link } from '../utils/components';
import { Props } from '../utils/props';
import { container } from '../utils/xlasses';
import { DialogClose } from './DialogClose';

const cn = {
  container: style({
    marginTop: 48,
    marginBottom: 64,
    display: 'flex',
    justifyContent: 'center'
  }),
  icon: style({
    height: 48,
    margin: -2,
    marginRight: 8
  }),
  button: style({
    marginTop: 16,
    marginBottom: 16,
    padding: 0,
    paddingRight: 16,
    textTransform: 'initial',
    fontSize: '1.25rem'
  })
};

const SignInContent = ({}) => {
  const dispatch = useDispatch();
  const signInWithGoogle = React.useCallback(() => {
    dispatch(actions.auth.signInWithGoogle());
  }, []);

  return (
    <>
      <DialogContentText>
        Googleアカウントを持っていれば、ハックフォープレイにそのままログインできます。「Googleでログイン」を押してね。
        <b>ファミリーリンクアカウントではログインできません</b>
      </DialogContentText>
      <div className={cn.container}>
        <Button
          variant="contained"
          color="primary"
          className={cn.button}
          onClick={signInWithGoogle}
        >
          <img
            src={require('../resources/google.svg')}
            alt="G"
            className={cn.icon}
          />
          Googleでログイン
        </Button>
      </div>
      <DialogContentText>
        ログインすると、利用規約に同意したことになります
      </DialogContentText>
      <Button
        color="primary"
        component={Link('/pages/agreement/terms-of-use')}
        target="_blank"
      >
        利用規約
      </Button>
    </>
  );
};

export const NeedSignIn = () => {
  return (
    <div className={container.medium}>
      <Typography variant="h6" gutterBottom>
        このページはログインしないと見ることができません
      </Typography>
      <SignInContent />
    </div>
  );
};

export function SignInDialog(props: Props<typeof Dialog>) {
  const userInfo = useSelector(state => state.auth.userInfo);

  const close = React.useCallback(() => {
    props.onClose && props.onClose({} as any, 'escapeKeyDown');
  }, [props.onClose]);

  React.useEffect(() => {
    if (userInfo) {
      // ログインした
      close();
    }
  }, [userInfo]);

  return (
    <Dialog {...props}>
      <DialogTitle>ログインして、ステージを保存しよう</DialogTitle>
      <DialogContent>
        <SignInContent />
      </DialogContent>
      <DialogClose onClick={close} />
      <DialogActions>
        <Button component={Link('/teamLogin')} onClick={close}>
          チームログイン
        </Button>
      </DialogActions>
    </Dialog>
  );
}
