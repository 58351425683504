import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle
} from '@material-ui/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { unblock } from './IdeMenuBar';
import { WorkEnableComment } from './WorkEnableComment';
import { WorkListItem } from './WorkList';

export interface IdePublishDialogProps extends DialogProps {
  id: string;
}

export function IdePublishDialog({
  id,
  open,
  ...props
}: IdePublishDialogProps) {
  const history = useHistory();
  return React.useMemo(
    () => (
      <Dialog open={Boolean(open && id)} {...props}>
        <DialogTitle>公開しました！🎉</DialogTitle>
        <DialogContent>
          <WorkListItem
            id={id}
            showVisibility
            disableAction
            showDeleted
            getTimestamp={() => undefined}
          />
          <WorkEnableComment id={id} />
        </DialogContent>
        <DialogActions>
          <Button
            href="/series"
            onClick={event => {
              event.preventDefault();
              unblock();
              history.push('/series');
            }}
          >
            シリーズについか
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.onClose?.({}, 'escapeKeyDown')}
          >
            とじる
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [id, open]
  );
}
