export function twitterIntent(text: string, hashtag?: string) {
  text = encodeURIComponent(text);
  const width = 550;
  const height = 420;
  const left = Math.max(Math.round(window.screen.width / 2 - width / 2), 0);
  const top = Math.max(Math.round(window.screen.height / 2 - height / 2), 0);
  hashtag = hashtag ? `&hashtags=${encodeURIComponent(hashtag)}` : '';
  return () => {
    window.open(
      `https://twitter.com/intent/tweet?text=${text}${hashtag}`,
      'twitter',
      `scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=${width},height=${height},left=${left},top=${top}`
    );
  };
}

export function facebookIntent(href: string, hashtag: string | null = null) {
  if (hashtag && !hashtag.startsWith('#')) {
    hashtag = '#' + hashtag;
  }

  return () => {
    const FB = (window as any).FB;
    FB?.ui({
      method: 'share',
      href,
      hashtag
    });
  };
}
