export const regex =
  /(create\(|つくる\(|rule\.つくる\(|\S\.summon\(|\S\.しょうかんする\(|\S\.transform\(|\S\.へんしんする\(|\S\.おとす\(|\S\.message\(|\S\.メッセージする\(|\S\.is\(|\S\.は\(|\S\.skill = |\S\.スキル = )['"]([^'"]+)['"]/g;
const errorMessage = 'extractAssetNames allows only string at first argument';

export default function extractAssetNames(code = '') {
  if (typeof code !== 'string') throw new TypeError(errorMessage);
  const assetNames: string[] = [];
  let result;
  regex.lastIndex = 0; // 正規表現のマッチを初期化
  while ((result = regex.exec(code))) {
    const [, , name] = result;
    if (name && !assetNames.includes(name)) {
      assetNames.push(name);
    }
  }
  return assetNames;
}
