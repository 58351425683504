import { defaultPalette } from '../js/getCustomTheme';
import ImmutableFile from './ImmutableFile';

export interface Config {
  test: RegExp;
  multiple: boolean;
  defaultValue: any;
  defaultName: string;
  bundle?: (files: ImmutableFile[]) => (file: ImmutableFile) => any[];
}

const configs = new Map<string, Config>([
  [
    'palette',
    {
      test: /^\.palette$/i,
      multiple: false,
      defaultValue: defaultPalette,
      defaultName: '.palette'
    }
  ],
  [
    'env',
    {
      test: /^\.env$/i,
      multiple: false,
      defaultValue: {
        MODULE: [true, 'boolean', 'A flag to use module bundler']
      },
      defaultName: '.env'
    }
  ],
  [
    'babelrc',
    {
      test: /^\.babelrc$/i,
      multiple: false,
      defaultValue: {
        presets: ['es2015']
      },
      defaultName: '.babelrc'
    }
  ]
]);

export default configs;
