import {
  Button,
  Divider,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import { Cached, ExpandMore, MobileScreenShare } from '@material-ui/icons';
import { shuffle } from 'lodash-es';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { atomFamily } from 'recoil';
import { actions } from '../ducks/actions';
import { empty, processing } from '../ducks/helpers';
import { useHashCommentId } from '../hooks/useHashCommentId';
import { useRecoilEffect } from '../hooks/useRecoilEffect';
import { Link } from '../utils/components';
import { ErrorBoundary } from './ErrorBoundary';
import { LoadingPage } from './LoadingPage';
import { SeriesWorkItem } from './SeriesWorkItem';
import { ShareDialog } from './ShareDialog';
import { TruncateText } from './TrancateText';
import { UserNameLink } from './UserNameLink';
import { WorkEnableComment } from './WorkEnableComment';
import { WorkInfoComments } from './WorkInfoComments';

export interface WorkInfoPanelProps {
  id: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 312,
    maxWidth: 312,
    padding: theme.spacing() * 2,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'scroll'
  },
  title: {
    ...theme.typography.h6,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexShrink: 0
  },
  space: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  },
  otherWorksContainer: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    maxHeight: 264,
    minHeight: 264
  },
  button: {
    alignSelf: 'flex-start'
  },
  actions: {
    display: 'flex',
    marginTop: theme.spacing(),
    '&>*:not(:last-child)': {
      marginRight: theme.spacing()
    }
  }
}));

// work を Subscribe していない場合、変更が反映されないので
// ローカルでも変更を保持しておく
export const receiveCommentAtom = atomFamily({
  key: 'receiveCommentAtom',
  default: (workId: string) => false
});

export const WorkInfoPanel = React.forwardRef(function WorkInfoPanel(
  props: WorkInfoPanelProps,
  ref
) {
  const cn = useStyles();

  return (
    <Paper square className={cn.root} ref={ref}>
      <React.Suspense fallback={<LoadingPage />}>
        <WorkInfoPanelContent {...props} />
      </React.Suspense>
    </Paper>
  );
});

function WorkInfoPanelContent({ id }: WorkInfoPanelProps) {
  const work = useSelector(state => state.work.works[id])?.data;
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (id) {
      dispatch(actions.series.fetchByWorkId.started(id));
    }
  }, [id]);
  const series = useSelector(state => {
    const idState = id ? state.series.workIdToSeriesId[id] : undefined;
    if (idState?.isEmpty) return empty<ISeries>();
    const seriesId = idState?.data;
    const series = seriesId ? state.series.byId[seriesId] : undefined;
    return series || processing<ISeries>();
  });

  // series がなかった場合 user の works を取得する
  const ownerUid = work?.uid;
  const latestUserWorkId = useSelector(state =>
    ownerUid
      ? state.work.users[ownerUid]?.data?.find(
          id => state.work.works[id]?.data?.visibility === 'public'
        )
      : undefined
  );
  React.useEffect(() => {
    if (series.isEmpty && ownerUid && !latestUserWorkId) {
      dispatch(actions.work.fetchUsers.started({ uid: ownerUid }));
    }
  }, [series.isEmpty, ownerUid, latestUserWorkId]);

  // 「他のステージ」を表示する
  const recommendIds = useSelector(state => state.work.recommendIds);
  const recommendNum = 3;
  const waitForFetch = recommendIds.length < recommendNum;
  const [recommended, setRecommended] = React.useState<string[]>([]);
  React.useEffect(() => {
    if (waitForFetch) return;
    const randoms = shuffle(recommendIds).slice(0, recommendNum);
    setRecommended(randoms);
    dispatch(actions.work.removeRecommendIds(randoms));
  }, [id, waitForFetch]);
  // 候補が足りない場合、あたらしいステージを取得する
  const isFetching = useSelector(state => state.work.newers.isFetching);
  React.useEffect(() => {
    if (waitForFetch && !isFetching) {
      dispatch(actions.work.fetchNewerMore.started()); // TODO: ifNeeded パターンにする
    }
  }, [id]);

  const uid = useSelector(state => state.auth.userInfo?.uid);
  const isOwner = uid ? uid === ownerUid : false;

  const commentId = useHashCommentId(); // 指定されたコメントまでスクロールさせる
  const [showComment, setShowComment] = React.useState(!!commentId);

  // この作品がコメントを受け取る設定になっているかどうか
  useRecoilEffect(
    ({ set }) => {
      set(receiveCommentAtom(id), !!work?.receiveComment);
    },
    [work]
  );

  const history = useHistory();
  const handleOpen = React.useCallback(
    (id: string) => {
      history.push(`/works/${id}`);
    },
    [history]
  );

  const [openedShare, setOpenedShare] = React.useState(false);

  const cn = useStyles();

  return (
    <>
      <Typography variant="caption" color="textSecondary">
        {work?.visibility === 'public'
          ? '公開中'
          : work?.visibility === 'limited'
          ? '限定公開'
          : '非公開'}
        {work?.clearRate
          ? `・${Math.floor(work.clearRate * 100)}%の人がクリア`
          : work?.clearChecked
          ? ''
          : '・クリア未確認'}
      </Typography>
      <TruncateText
        className={cn.title}
        text={work?.title || ''}
        maxWidth={312}
        lineClamp={3}
      />
      <UserNameLink uid={work?.uid} icon creator className={cn.space} />
      <div className={cn.actions}>
        <Button
          startIcon={<Cached />}
          variant="outlined"
          color="secondary"
          className={cn.button}
          component={Link(`/works/${id}/edit`)}
          disableElevation
        >
          コードをみる
        </Button>
        <Button
          variant="outlined"
          startIcon={<MobileScreenShare />}
          onClick={() => setOpenedShare(true)}
        >
          スマホで開く
        </Button>
      </div>
      <Divider className={cn.space} />
      <div className={cn.otherWorksContainer}>
        <Typography>
          {series.data ? series.data.title : 'ほかのステージ'}
        </Typography>
        {series.data?.works.map((itemId, i) => (
          <SeriesWorkItem
            key={i}
            order={i + 1}
            id={itemId}
            selected={id === itemId}
            hideDeleted
            onClick={() => handleOpen(itemId)}
          />
        )) ||
          recommended
            .filter(itemId => itemId !== latestUserWorkId)
            .map(itemId => (
              <SeriesWorkItem
                key={itemId}
                id={itemId}
                selected={itemId === id}
                hideDeleted
                onClick={() => handleOpen(itemId)}
              />
            )) ||
          null}
      </div>
      <Divider className={cn.space} />
      <Typography gutterBottom>
        コメント ※ネタバレがあるかもしれません
      </Typography>
      {showComment ? (
        <ErrorBoundary>
          {isOwner ? <WorkEnableComment id={id} /> : null}
          <WorkInfoComments id={id} />
        </ErrorBoundary>
      ) : (
        <Button
          color="secondary"
          startIcon={<ExpandMore />}
          onClick={() => setShowComment(true)}
          className={cn.button}
        >
          すべてのコメントを読む
          {work?.commentsNum ? ` (${work.commentsNum}件)` : ''}
        </Button>
      )}
      <ShareDialog
        open={openedShare}
        workId={id}
        onClose={() => setOpenedShare(false)}
      />
    </>
  );
}
