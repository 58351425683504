import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { Theme, withTheme } from '@material-ui/core/styles';
import { Settings } from '@material-ui/icons';
import * as React from 'react';
import { SetterOrUpdater, useSetRecoilState } from 'recoil';
import { style } from 'typestyle';
import { actions } from '../../../../ducks/file';
import ImmutableFile from '../../File/ImmutableFile';
import {
  IPreferenceDialogData,
  preferenceDialogAtom
} from '../../FileDialog/PreferenceDialog';
import { useActionDispatcher } from '../../hooks/useActionDispatcher';
import Filename from './Filename';

export interface FileCardProps {
  theme: Theme;
  file: ImmutableFile;
  handleFileSelect: (file: ImmutableFile) => void;
  openFileDialog: SetterOrUpdater<IPreferenceDialogData | null>;
  isSelected?: boolean;

  // Injected by withFiles (for JSX)
  putFile: typeof actions.putFile;
  deleteFiles: typeof actions.deleteFiles;
}

const cn = {
  container: style({
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  })
};
const getCn = (props: FileCardProps) => ({
  root: style({
    marginTop: 4,
    marginRight: 8,
    transition: props.theme.transitions.create('all')
  }),
  card: style({
    boxSizing: 'border-box',
    height: 40,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: props.theme.spacing(2),
    borderTopRightRadius: props.isSelected ? 0 : 2,
    borderBottomRightRadius: props.isSelected ? 0 : 2,
    opacity: 1,
    transition: props.theme.transitions.create('all')
  })
});

export function FileCardWrapper(
  props: Omit<FileCardProps, 'putFile' | 'deleteFiles' | 'openFileDialog'>
) {
  const putFile = useActionDispatcher(actions.putFile);
  const deleteFiles = useActionDispatcher(actions.deleteFiles);
  const openFileDialog = useSetRecoilState(preferenceDialogAtom);

  return (
    <FileCard
      {...props}
      putFile={putFile}
      deleteFiles={deleteFiles}
      openFileDialog={openFileDialog}
    />
  );
}

class FileCard extends React.PureComponent<FileCardProps> {
  handleConfirmSettings = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    const { openFileDialog, file, deleteFiles, putFile } = this.props;
    openFileDialog({
      file: file,
      resolve: change => {
        if (change) {
          deleteFiles({ files: [file], isUserAction: true });
          putFile({ file: file.set(change), isUserAction: true });
        }
      }
    });
  };

  handleNameChange = (file: ImmutableFile, name: string) => {
    this.props.deleteFiles({ files: [file], isUserAction: true });
    this.props.putFile({ file: file.rename(name), isUserAction: true });
  };

  render() {
    const dcn = getCn(this.props);
    const { file, handleFileSelect } = this.props;

    return (
      <div className={dcn.root}>
        <Paper
          elevation={2}
          onClick={() => handleFileSelect(file)}
          className={dcn.card}
        >
          <div className={cn.container}>
            <Filename file={file} onChange={this.handleNameChange} />
          </div>
          <IconButton onClick={this.handleConfirmSettings}>
            <Settings />
          </IconButton>
        </Paper>
      </div>
    );
  }
}

export default withTheme(FileCardWrapper);
