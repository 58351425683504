import firebase from '../settings/firebase';

const auth = firebase.auth;
const callbacks = new Set<() => void>();

/**
 * @param callback ユーザーがサインアウトする時に実行されるコールバック
 */
export function onSignOut(callback: () => void) {
  callbacks.add(callback);
}

auth().onAuthStateChanged(user => {
  if (user) return;
  // 一度参照をコピーしてからループする
  const copy = new Set(callbacks);
  callbacks.clear();
  copy.forEach(callback => callback());
});
