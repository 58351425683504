import IconButton from '@material-ui/core/IconButton';
import { ArrowBack, Delete } from '@material-ui/icons';
import * as React from 'react';
import { classes, style } from 'typestyle';

export interface TrashBoxProps {
  showTrashes: boolean;
  onClick: () => void;
}

const cn = {
  icon: style({
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRadius: 2
  })
};

export default function TrashBox(props: TrashBoxProps) {
  const { showTrashes, onClick } = props;

  return (
    <div>
      <IconButton className={classes(cn.icon)} onClick={onClick}>
        {showTrashes ? <ArrowBack /> : <Delete />}
      </IconButton>
    </div>
  );
}
