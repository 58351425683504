import {
  Button,
  Grid,
  LinearProgress,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  Build,
  Edit,
  NavigateNext,
  Notifications,
  OndemandVideo
} from '@material-ui/icons';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selector, useRecoilValue } from 'recoil';
import { style } from 'typestyle';
import { actions } from '../ducks/actions';
import { getAuthUser } from '../ducks/auth';
import { compareTimestamps } from '../ducks/utils';
import {
  alreadyWatchVideo,
  getUnwatched,
  getVideoId,
  isFree
} from '../ducks/video';
import { firestoreAtom } from '../hooks/useFirestore';
import { authUidAtom, convertToNotification } from '../recoil';
import { Link as LinkUtil } from '../utils/components';
import { container, hover, objectFit } from '../utils/xlasses';
import { IconTypography } from './IconTypograhy';
import { LoadingButton } from './LoadingButton';
import { LoadingPage } from './LoadingPage';
import { MapListItem } from './MapList';
import { ItemCommentToWork, ItemStagePlay } from './NotificationItem';
import { UserIconLink } from './UserNameLink';
import { VideoPlayer, VideoThumbnail } from './VideoList';
import { WorkListItem } from './WorkList';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(),
    paddingBottom: 100
  },
  container: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing() * 6
  }
}));

/**
 * 「ステージをつくる」のトップページ
 * いずれは「つくる」カテゴリに内包されるが、
 * 今はまだそのカテゴリがない
 */
export function MakeTop() {
  const cn = useStyles();

  const isSignedOut = useSelector(
    state => state.auth.initialized && !state.auth.userInfo
  );
  const authUser = useSelector(state => state.auth.userInfo);

  return (
    <div className={cn.root}>
      <div className={container.large}>
        <div className={cn.container}>
          <Typography variant="h2" color="textSecondary" gutterBottom>
            あたらしくステージをつくろう
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <ImageLink
                to="/officials/make-rpg-2"
                imageSrc={require('../resources/make-rpg-2-cover.png')}
                imageAlt="ステージをつくる"
              >
                <IconTypography variant="h4" icon={Build}>
                  ステージをつくる
                </IconTypography>
              </ImageLink>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ImageLink
                to="/map-editor"
                imageSrc={require('../resources/map-editor-cover.png')}
                imageAlt="はいけいをかく"
              >
                <IconTypography variant="h4" icon={Edit}>
                  はいけいをかく
                </IconTypography>
              </ImageLink>
            </Grid>
          </Grid>
        </div>
        <div className={cn.container}>
          <Grid container spacing={2}>
            {authUser ? (
              <Grid item xs={6} sm={3}>
                <Item
                  heading={
                    <IconTypography
                      icon={Notifications}
                      variant="subtitle1"
                      color="textSecondary"
                    >
                      お知らせ
                    </IconTypography>
                  }
                  footer={isSignedOut ? undefined : 'ほかのお知らせも見る'}
                  footerLink="/notifications"
                >
                  <React.Suspense fallback={<LoadingPage />}>
                    <NotificationCard />
                  </React.Suspense>
                </Item>
              </Grid>
            ) : null}
            <Grid item xs={6} sm={3}>
              <Item
                heading={
                  <IconTypography
                    variant="subtitle1"
                    color="textSecondary"
                    icon={OndemandVideo}
                  >
                    おすすめの授業動画
                  </IconTypography>
                }
                footer="ほかの動画を見る"
                footerLink="/videos/season_1"
              >
                <SuggestedVideoItem />
              </Item>
            </Grid>
          </Grid>
        </div>
        {isSignedOut ? null : (
          <div className={cn.container}>
            <Typography variant="h2" color="textSecondary" gutterBottom>
              じぶんのステージ
              <MoreLink href="/authUser" />
            </Typography>
            <MyStageList />
          </div>
        )}
        {isSignedOut ? null : (
          <div className={cn.container}>
            <Typography variant="h2" color="textSecondary" gutterBottom>
              じぶんのはいけい
              <MoreLink href="/authUser/maps" />
            </Typography>
            <MyMapList />
          </div>
        )}
        <div className={cn.container}>
          <Typography variant="h2" color="textSecondary" gutterBottom>
            ゲーム作りのヒント
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
              <HintItem
                heading="【よくある質問】検索できるヒント集"
                href="https://helpfeel.com/hackforplay"
                thumbnailSrc={require('../resources/helpfeel.png')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <HintItem
                heading="【チートシート】ハックフォープレイでつかえる機能の一覧"
                href="https://docs.hackforplay.xyz/"
                thumbnailSrc={require('../resources/cheat-sheet.png')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <HintItem
                heading="【ステージをまとめる】シリーズをつくる"
                href="/series"
                thumbnailSrc={require('../resources/make-series.jpg')}
              />
            </Grid>
          </Grid>
        </div>
        <div className={cn.container}>
          <Button component={LinkUtil('/officials/make-rpg')} size="small">
            旧キットを開く
          </Button>
        </div>
      </div>
    </div>
  );
}

interface ImageLinkProps {
  imageSrc: string;
  imageAlt: string;
  to: string;
  children: React.ReactNode;
}

const useStylesImageLink = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    height: 160,
    width: '100%',
    cursor: 'pointer',
    '&:hover .image-link-shadow': {
      transform: 'scaleY(1.5)'
    },
    '&:hover .image-link-container': {
      transform: 'translateY(-10%)'
    }
  },
  img: {
    width: '100%',
    height: '100%'
  },
  container: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    color: theme.palette.common.white,
    padding: theme.spacing() * 1.5,
    transition: theme.transitions.create('transform')
  },
  shadow: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '50%',
    background: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5))',
    transformOrigin: 'bottom',
    transition: theme.transitions.create('transform')
  }
}));

function ImageLink(props: ImageLinkProps) {
  const cn = useStylesImageLink();

  return (
    <Link to={props.to} className={cn.root}>
      <img
        src={props.imageSrc}
        alt={props.imageAlt}
        className={classNames(cn.img, objectFit.cover)}
      />
      <div className={classNames(cn.shadow, 'image-link-shadow')}></div>
      <div className={classNames(cn.container, 'image-link-container')}>
        {props.children}
      </div>
    </Link>
  );
}

interface MoreLinkProps {
  href: string;
}

const buttonAlignItem = style({
  alignItems: 'initial'
});

function MoreLink(props: MoreLinkProps) {
  return (
    <Button
      color="secondary"
      component={LinkUtil(props.href)}
      className={buttonAlignItem}
      startIcon={<NavigateNext />}
    >
      すべてみる
    </Button>
  );
}

interface ItemProps {
  heading: React.ReactNode;
  children: React.ReactNode;
  onClick?: () => void;
  footer?: string;
  footerLink?: string;
}

const useStylesItem = makeStyles(theme => ({
  root: {},
  heading: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  card: {
    width: '100%',
    height: 280,
    overflow: 'hidden',
    backgroundColor: '#EDF0F3',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  container: {
    flex: 1,
    alignSelf: 'stretch'
  },
  footer: {
    padding: theme.spacing(),
    paddingTop: 0
  }
}));

function Item(props: ItemProps) {
  const cn = useStylesItem();

  return (
    <div className={cn.root}>
      {props.heading}
      <div className={cn.card}>
        <div className={cn.container}>{props.children || null}</div>
        {props.footer ? (
          <div className={cn.footer}>
            {props.footerLink ? (
              <Button color="secondary" component={LinkUtil(props.footerLink)}>
                {props.footer}
              </Button>
            ) : (
              <span>{props.footer}</span>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

const useStylesNotificationCard = makeStyles(theme => ({
  root: {
    padding: theme.spacing() * 2
  },
  icons: {
    marginBottom: theme.spacing(),
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

/**
 * /make で表示するお知らせ
 * ステージをプレイされたお知らせ or コメントのお知らせだけを表示
 */
export const makeTopNotification = selector({
  key: 'makeTopNotification',
  get: async ({ get }) => {
    const firestore = get(firestoreAtom);
    const uid = get(authUidAtom);
    if (!uid) {
      return undefined;
    }
    const qs = await firestore()
      .collection('users')
      .doc(uid)
      .collection('notifications')
      .where('content.type', 'in', ['stage-play', 'comment-work'])
      .orderBy('updatedAt', 'desc')
      .limit(1)
      .withConverter(convertToNotification)
      .get();
    const item = qs.docs[0]?.data()?.content;
    if (!item || (item.type !== 'stage-play' && item.type !== 'comment-work')) {
      return undefined;
    }
    return item;
  }
});

function NotificationCard() {
  const cn = useStylesNotificationCard();
  const content = useRecoilValue(makeTopNotification);

  if (!content) {
    return (
      <Typography variant="body1" color="textSecondary">
        お知らせはありません
      </Typography>
    );
  }
  if (content.type === 'stage-play') {
    return (
      <div className={cn.root}>
        <div className={cn.icons}>
          {content.players
            .slice(-6)
            .reverse()
            .map(id => (
              <UserIconLink key={id} uid={id} />
            ))}
        </div>
        <ItemStagePlay
          icon={false}
          players={content.players}
          workId={content.workId}
        />
      </div>
    );
  }
  if (content.type === 'comment-work') {
    return (
      <div className={cn.root}>
        <ItemCommentToWork commentId={content.commentId} content={content} />
      </div>
    );
  }

  return null;
}

const useStylesSuggestedVideoItem = makeStyles(theme => ({
  pointer: {
    cursor: 'pointer'
  },
  disabled: {
    filter: 'brightness(0.7)'
  },
  title: {
    padding: theme.spacing() * 2,
    paddingTop: theme.spacing(),
    paddingBottom: 0
  },
  action: {
    paddingLeft: theme.spacing()
  }
}));

function SuggestedVideoItem() {
  const cn = useStylesSuggestedVideoItem();

  const isSignedOut = useSelector(
    state => state.auth.initialized && !state.auth.userInfo
  );

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(actions.video.listIfNeeded('season_1'));
  }, []);

  const authUser = useSelector(getAuthUser);
  const canWatchVideo = authUser?.plans?.canWatchVideo; // 授業動画が視聴可能になるフラグ
  const restrictVideo = authUser?.plans?.restrictVideo; // 授業動画を１日１本にするフラグ

  const unwatched = useSelector(getUnwatched);
  const initialized = useSelector(state => state.auth.initialized);

  // 視聴中に unwatched が変更されるのを防ぐため一度 unwatched が決定したら変更しない
  const [nowShowing, setNowShowing] = React.useState(unwatched);
  React.useEffect(() => {
    if (nowShowing || !initialized || !unwatched) return;
    setNowShowing(unwatched);
  }, [nowShowing, unwatched, initialized]);
  const uri = nowShowing?.uri;
  const id = uri && getVideoId(uri);

  // 「もう見た」ボタン
  const [watchedManualy, setWatchedManualy] = React.useState(false);
  const watchManualy = React.useCallback(() => {
    if (!id) return;
    setWatchedManualy(true);
    dispatch(actions.video.watch.started(id));
  }, [id]);

  // 「もう見た」を元に戻せる UI を表示するかどうか
  const alreadyWatched = useSelector(alreadyWatchVideo);
  const idRef = React.useRef(id);
  idRef.current = id;
  const watchedAt = useSelector(state => {
    const id = idRef.current;
    return id && state.video.videoWatchedAt[id];
  });
  const showUnwatchButton = Boolean(watchedManualy && watchedAt);

  // 「もとにもどす」ボタン
  const unwatch = React.useCallback(() => {
    if (!id) return;
    dispatch(actions.video.unwatch.started(id));
    setWatchedManualy(false);
  }, [id]);

  const isNeedPayment = !canWatchVideo && nowShowing && !isFree(nowShowing);

  const isRestricted = restrictVideo && alreadyWatched && !watchedAt; // 動画の視聴が制限されているかどうか

  const [playing, setPlaying] = React.useState(false);
  const play = React.useCallback(() => setPlaying(true), []);
  const stop = React.useCallback(() => setPlaying(false), []);

  return (
    <div className={hover.growImageRoot}>
      {nowShowing === undefined ? (
        <LinearProgress />
      ) : nowShowing ? (
        <>
          <VideoThumbnail video={nowShowing} onPlay={play} />
          <div className={cn.title}>
            <Typography
              variant="body1"
              gutterBottom
              onClick={play}
              className={classNames(
                cn.pointer,
                (isRestricted || isNeedPayment) && cn.disabled
              )}
            >
              {nowShowing.name}
            </Typography>
          </div>
          <div className={cn.action}>
            {showUnwatchButton ? (
              <Button onClick={unwatch}>もとにもどす</Button>
            ) : !watchedAt && !isNeedPayment && !isSignedOut ? (
              <Tooltip arrow title="この動画を「視聴済み」にします">
                <LoadingButton
                  loading={Boolean(watchedManualy)}
                  onClick={watchManualy}
                >
                  視聴済みにする
                </LoadingButton>
              </Tooltip>
            ) : null}
          </div>
          <VideoPlayer playing={playing} video={nowShowing} onStop={stop} />
        </>
      ) : (
        <Typography variant="body1">おすすめはありません</Typography>
      )}
    </div>
  );
}

const useStylesMyStageList = makeStyles(theme => ({
  loading: {
    flex: 1
  }
}));

function MyStageList() {
  const cn = useStylesMyStageList();

  const id = useSelector(state => state.auth.userInfo?.uid);
  const users = useSelector(state => (id ? state.work.users[id] : undefined));
  const works = useSelector(state => state.work.works);
  const latestIds = React.useMemo(() => {
    const copy = [...(users?.data || [])];
    return copy.sort(compareTimestamps(id => works[id]?.data?.updatedAt));
  }, [users, works]);

  return (
    <Grid container spacing={2}>
      {latestIds
        ?.slice(0, 4)
        .map(id => (
          <WorkListItem
            key={id}
            id={id}
            getTimestamp={work => work.updatedAt}
          />
        )) || (
        <div className={cn.loading}>
          <LinearProgress />
        </div>
      )}
    </Grid>
  );
}

function MyMapList() {
  // updatedAt の順番に並べ直して新しい順に４つ取得する
  const myMapIds = useSelector(state => state.maps.myMapIds);
  const maps = useSelector(state => state.maps.maps);

  return React.useMemo(() => {
    const copy = [...myMapIds];
    copy.sort(compareTimestamps(id => maps[id]?.data?.updatedAt));

    return (
      <Grid container spacing={2}>
        {copy.slice(0, 4).map(id => (
          <MapListItem key={id} id={id} />
        ))}
      </Grid>
    );
  }, [myMapIds, maps]);
}

interface HintItemProps {
  thumbnailSrc: string;
  heading: string;
  href: string;
}

const useStylesHintItem = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 238,
    cursor: 'pointer',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing()
  },
  imgParent: {
    width: '100%',
    height: 135,
    marginBottom: theme.spacing()
  },
  img: {
    width: '100%',
    height: '100%'
  },
  blank: {
    flex: 1
  }
}));

function HintItem(props: HintItemProps) {
  const cn = useStylesHintItem();

  return (
    <a href={props.href} target="_blank" rel="noopener">
      <div className={classNames(cn.root, hover.growImageRoot)}>
        <div className={classNames(hover.growImageParent, cn.imgParent)}>
          <img
            src={props.thumbnailSrc}
            alt={props.heading}
            className={classNames(cn.img, objectFit.cover)}
          />
        </div>
        <Typography variant="subtitle2" color="textSecondary">
          {props.heading}
        </Typography>
        <div className={cn.blank}></div>
        <Typography variant="caption" color="textSecondary">
          ゲーム作りのヒント
        </Typography>
      </div>
    </a>
  );
}
