import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actions } from '../ducks/actions';
import { Feeles } from './Feeles';
import { cn } from './Work';

type Params = {
  id: string;
};

const nope = () => {};

export function WorkFromQR() {
  const { id } = useParams<Params>();
  const work = useSelector(state => state.work.works[id]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!id) return;
    dispatch(actions.work.fetch.started({ id, loadAfterFetch: true }));
    dispatch(actions.work.addView.started({ id })); // ステージのビューカウントを増やす
  }, [id]);

  if (!work || work.isProcessing) {
    return (
      <div className={cn.loading}>
        <CircularProgress size={60} />
      </div>
    );
  }

  if (work.isEmpty) {
    return <div>ステージが見つかりません</div>;
  }
  if (!work.data) return null;

  return <Feeles onMessage={nope} />;
}
