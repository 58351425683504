import { Button, Snackbar } from '@material-ui/core';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { atom, useRecoilValue } from 'recoil';
import { Link } from '../utils/components';

/**
 * NoticeManager を使って Snackbar+Alert を表示する
 */
export const noticeAtom = atom<AlertProps | undefined>({
  key: 'noticeAtom',
  default: undefined
});

export function NoticeManager() {
  // 新しい notice が登録されたら alert にセットする
  const [alert, setAlert] = React.useState<AlertProps>();
  const alertRedux = useSelector(state => state.notice.alert);
  const alertRecoil = useRecoilValue(noticeAtom);

  React.useEffect(() => {
    if (alertRedux) {
      setAlert(alertRedux);
    }
  }, [alertRedux]);
  React.useEffect(() => {
    if (alertRecoil) {
      setAlert(alertRecoil);
    }
  }, [alertRecoil]);

  const handleClose = React.useCallback((event: any, reason?: string) => {
    if (reason === 'clickaway') return; // 画面クリックでは閉じない
    setAlert(undefined);
  }, []);

  // closeText と role に、リンクの意味を持たせる
  const action =
    alert?.action ||
    (alert?.role !== undefined ? (
      <Button component={Link(alert.role)} onClick={handleClose}>
        {alert.closeText || '決定'}
      </Button>
    ) : undefined);

  return (
    <Snackbar
      open={Boolean(alert)}
      autoHideDuration={action ? null : 6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} {...alert} action={action} />
    </Snackbar>
  );
}
