import { useEffect } from 'react';
import {
  atom,
  DefaultValue,
  GetRecoilValue,
  RecoilState,
  selector,
  useRecoilCallback
} from 'recoil';
import { Observable, SubscriptionLike } from 'rxjs';

// 外部の Observable に next をため込んで
// useRecoilCallback で set する
const subscriptionMap = new Map<string, SubscriptionLike>();
type Next<T = unknown> = {
  atom: RecoilState<T>;
  value: T;
};
let setNext: <T>(next: Next<T>) => void = () => {};
const next$ = new Observable<Next<any>>(_next => {
  setNext = _next.next.bind(_next);
});

/**
 * Subscribe によって定義される ReadOnlySelector
 * observe() の戻り値として Observable<T> を渡す
 * observe の中で get() した State が変更されたら
 * 前回の Subscription を unsubscribe() するので
 * あまり get を多用しないようが良い
 */
export function selectorObservable<T>(options: {
  key: string;
  observe: (opts: { get: GetRecoilValue }) => Observable<T>;
}) {
  /**
   * 実際の値を保持する Atom
   * Atom が自分自身の value を書き換えるために
   * 外にある Observable を Component で受け取る
   * observe が同期的に値を返す場合、初回の値は
   * 外から set 出来ない (おそらく実行順序の問題)
   * そこで、初回のみ resolve() で値を設定する
   * Atom Effects がリリースされたら書き直す
   */
  const valueAtom = atom<T>({
    key: `${options.key}__value`,
    default: selector<T>({
      key: `${options.key}__subscriber`,
      get: opts => {
        // 現在の Subscription を閉じる・削除
        subscriptionMap.get(options.key)?.unsubscribe();
        subscriptionMap.delete(options.key);

        // 同期的に値を取り出せた場合は戻り値として返す
        // そうでない場合は Pending Promise を返す
        let defaultValue: T | DefaultValue = new DefaultValue();
        // 次の Subscription を取得・設定
        const observable = options.observe(opts); // observe() の依存関係を登録する
        const subscription = observable.subscribe(value => {
          defaultValue = value;
          setNext({
            atom: valueAtom,
            value
          });
        });
        subscriptionMap.set(options.key, subscription);
        return defaultValue instanceof DefaultValue
          ? new Promise(() => {})
          : defaultValue;
      }
    })
  });

  return selector<T>({
    key: options.key,
    get: ({ get }) => {
      const value = get(valueAtom);
      return value;
    }
  });
}

export function SelectorObservableProvider() {
  const setRecoilState = useRecoilCallback(({ set }, next: Next) => {
    set(next.atom, next.value);
  }, []);

  useEffect(() => {
    const subscription = next$.subscribe(next => {
      setRecoilState(next);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [setRecoilState]);

  return null;
}
