import { combineEpics } from 'redux-observable';
import { distinct, filter, map } from 'rxjs/operators';
import { endpoint } from '../env';
import firebase from '../settings/firebase';
import { isTruly } from '../utils/filterTruly';
import { actions } from './actions';
import { asyncEpic, SKIP } from './asyncEpic';
import { reducerWithImmer } from './reducer-with-immer';
import { StoreState } from './type';

const firestore = firebase.firestore;

export interface IVideoResponse {
  data: {
    uri: string; // e.g. '/videos/396389118'
    name: string; // タイトル
    description: null | string;
    duration: number;
    created_time: string; // e.g. '2020-03-09T07:32:05+00:00'
    release_time: string; // e.g. '2020-03-09T07:32:05+00:00'
    embed: {
      html: string; // HTML
    };
    pictures: {
      sizes: {
        width: number;
        height: number;
        link: string; // e.g. 'https://i.vimeocdn.com/video/863127513_640x360.jpg?r=pad'
      }[];
    };
  }[];
}

export type State = typeof initialState;

export const initialState = {
  videos: {} as { [series: string]: IVideoResponse },
  videoWatchedAt: {} as NonNullable<IUserDocument['videoWatchedAt']>,
  /**
   * signedIn から setAuthUser されるまでの間は true を返す
   * videoWatchedAt がセットされていないことを表す
   */
  isLoadingAuthUser: false
};

export default reducerWithImmer(initialState)
  .reset(actions.auth.signOut)
  .case(actions.auth.signedIn, draft => {
    draft.isLoadingAuthUser = true;
  })
  .case(actions.user.setAuthUser, (draft, payload) => {
    draft.videoWatchedAt = payload.videoWatchedAt || {};
    draft.isLoadingAuthUser = false;
  })
  .case(actions.video.list.done, (draft, payload) => {
    draft.videos[payload.params] = payload.result;
  })
  .toReducer();

export const epics = combineEpics(
  // コレクションの動画一覧を取得
  (action$, state$) =>
    action$.pipe(
      filter(actions.video.listIfNeeded.match),
      map(action => action.payload),
      distinct(),
      filter(series => !state$.value.video.videos[series]),
      map(series => actions.video.list.started(series))
    ),
  asyncEpic(actions.video.list, async (action, state) => {
    if (!action.payload) return { data: [] };
    const url = endpoint + `/videos?series=${action.payload}`;
    const response = await fetch(url);
    const json = await response.text();
    if (!response.ok) {
      throw new Error(json);
    }
    let result: IVideoResponse;
    try {
      result = JSON.parse(json) as IVideoResponse;
    } catch (error) {
      throw new Error(json);
    }
    return result;
  }),

  asyncEpic(actions.video.watch, async (action, state) => {
    const hashed = action.payload;
    const uid = state.auth.userInfo?.uid;
    if (!uid) return SKIP;
    // 既に観ている場合はスキップする
    const { videoWatchedAt } = state.video;
    if (videoWatchedAt[hashed]) return SKIP;
    // Firestore のマップを更新する
    await firestore()
      .collection('users')
      .doc(uid)
      .update({
        [`videoWatchedAt.${hashed}`]: firestore.FieldValue.serverTimestamp()
      });
  }),

  asyncEpic(actions.video.unwatch, async (action, state) => {
    const hashed = action.payload;
    const uid = state.auth.userInfo?.uid;
    if (!uid) return SKIP;
    // まだ観ていない場合はスキップする
    const { videoWatchedAt } = state.video;
    if (!videoWatchedAt[hashed]) return SKIP;
    // Firestore のマップを更新する
    await firestore()
      .collection('users')
      .doc(uid)
      .update({
        [`videoWatchedAt.${hashed}`]: firestore.FieldValue.delete()
      });
  })
);

/**
 * 今日既に動画を観た場合は true
 * まだ観ていないか、全ての動画を観終わった場合は false
 */
export function alreadyWatchVideo(state: StoreState) {
  const { videos, videoWatchedAt } = state.video;

  // 動画を全て見終わっているかどうかを確認
  const watchedAll = Object.values(videos).every(list =>
    list.data
      .map(v => getVideoId(v.uri))
      .filter(isTruly)
      .every(id => Boolean(videoWatchedAt[id]))
  );
  if (watchedAll) {
    // 今のところ上がっている動画を全て見終わっている場合は false にする
    return false;
  }
  // 最後に見た動画のタイムスタンプ（時差は一定であると仮定する）
  const latestWatchedAt =
    Object.values(videoWatchedAt)
      .filter(isTruly)
      .sort()
      .reverse()[0]
      ?.toMillis() || 0;
  // その日の 00:00:00 の Unix Epoc
  const beginOfToday = new Date();
  beginOfToday.setHours(0, 0, 0, 0);
  // 今日既に動画を観たか？
  return latestWatchedAt > beginOfToday.valueOf();
}

export function getVideoId(uri: string) {
  const pathes = uri.split('/');
  return pathes.length === 3 ? pathes[2] : undefined;
}

/**
 * まだ観ていない動画のうち最も上にあるものを取得する
 * 未取得の場合は undefined を返す
 * 全部見終わったら null を返す
 */
export function getUnwatched(state: StoreState) {
  const { videoWatchedAt, videos, isLoadingAuthUser } = state.video;
  if (Object.keys(videos).length === 0) {
    return undefined;
  }
  if (isLoadingAuthUser) {
    return undefined; // videoWatchedAt を取得中
  }

  for (const series of Object.values(videos)) {
    for (const item of series.data) {
      const id = getVideoId(item.uri);
      if (id && !videoWatchedAt[id]) {
        return item;
      }
    }
  }

  return null;
}

export function isFree(video: IVideoResponse['data'][number]) {
  const freeVideoList = [
    '/videos/396389118', // はじめてのゲームづくり／モンスターを入れよう！
    '/videos/396389125', // かいだんの使い方／次のマップに行こう！
    '/videos/396389139', // プレイヤーを強くする方法／ゲームクリアを作ろう！
    '/videos/456439454' // コピー&ペーストをつかってみよう
  ];
  return freeVideoList.includes(video.uri);
}
