import { Button, Grid, Paper, Typography } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { classes, style } from 'typestyle';
import { StoreState } from '../ducks';
import { actions } from '../ducks/actions';
import { container } from '../utils/xlasses';
import { LoadingButton } from './LoadingButton';
import { LoadingPage } from './LoadingPage';
import { SortedGrid } from './SortedGrid';

type Works = StoreState['work']['works'];
type Maps = StoreState['maps']['maps'];
type Item = NonNullable<(Works | Maps)[string]>;

type ItemID = {
  type: 'works' | 'maps';
  id: string;
};

export interface TrashListProps {}

const cn = {
  root: style({
    margin: 16,
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'visible',
    padding: 16,
    paddingBottom: 0
  }),
  item: style({
    display: 'flex',
    flexDirection: 'row',
    height: 96,
    alignItems: 'stretch',
    paddingBottom: 16
  }),
  img: style({
    width: 120,
    height: 80
  }),
  captions: style({
    flex: 1,
    paddingLeft: 8
  }),
  noItem: style({
    marginTop: 16
  }),
  moreButton: style({
    fontSize: 'large',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 16,
    paddingTop: 16,
    paddingRight: 32,
    paddingBottom: 16,
    paddingLeft: 32
  })
};

export function TrashList({}: TrashListProps) {
  const works = useSelector(state => state.work.works); // TODO: myDeletedWorkIds をストアに保持にする
  const mapIds = useSelector(state => state.maps.myDeletedMapIds);
  const uid = useSelector(state => state.auth.userInfo?.uid);
  const items = useMemo(() => {
    const workItems = uid ? getDeletedItems(works, uid) : [];
    const mapItems = mapIds.map(id => ({ type: 'maps', id } as const));
    return workItems.concat(mapItems);
  }, [works, mapIds, uid]);
  const hasMoreMaps = useSelector(state => state.maps.more);
  const dispatch = useDispatch();
  const trash = useSelector(state => state.work.trash);
  const isFetching = trash.isFetching || hasMoreMaps.isFetching;
  const mayHasMore = trash.mayHasMore || hasMoreMaps.mayHasMore;
  const handleMore = React.useCallback(() => {
    dispatch(actions.work.fetchTrash.started());
    dispatch(actions.map.fetchMore.started());
  }, []);

  return (
    <Paper elevation={2} className={classes(cn.root, container.medium)}>
      {items.length > 0 ? (
        <Grid container direction="column" spacing={2}>
          {items.map((itemId, i) => (
            <TrashListItem key={i} itemId={itemId} />
          ))}
        </Grid>
      ) : !isFetching ? (
        <LoadingPage />
      ) : (
        <>
          <Typography
            variant="h4"
            color="textSecondary"
            align="center"
            className={cn.noItem}
            gutterBottom
          >
            ゴミ箱は空です
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            align="center"
            gutterBottom
          >
            削除されたステージは３０日間ゴミ箱に保存されます
          </Typography>
        </>
      )}
      <LoadingButton
        variant="contained"
        color="primary"
        className={cn.moreButton}
        loading={isFetching}
        disabled={!mayHasMore}
        onClick={handleMore}
      >
        {mayHasMore ? 'もっと見る' : 'これで全部です'}
      </LoadingButton>
    </Paper>
  );
}

const AMonth = 30 * 24 * 60 * 60 * 1000;

/**
 * item が削除されていて、かつ１ヶ月以内に削除されている場合のみ表示する
 */
function isDeleted(item: Item) {
  const deletedAt = item.data?.deletedAt;
  const millis = deletedAt?.toMillis?.();
  return Boolean(deletedAt && (!millis || Date.now() - millis < AMonth));
}

function getDeletedItems(works: Works, uid: string): ItemID[] {
  const deletedItems = [] as ItemID[];

  for (const [id, statefull] of Object.entries(works)) {
    if (!statefull) continue; // type hint
    if (statefull.data?.uid !== uid) continue; // 自分のではない
    if (isDeleted(statefull)) {
      deletedItems.push({
        id,
        type: 'works'
      });
    }
  }

  return deletedItems;
}

interface TrashListItemProps {
  itemId: ItemID;
}

function TrashListItem({ itemId }: TrashListItemProps) {
  const data = useSelector(state =>
    itemId.type === 'maps'
      ? state.maps.maps[itemId.id]?.data
      : state.work.works[itemId.id]?.data
  );

  const createdAt = data?.createdAt?.toMillis?.();
  const created = createdAt ? moment(createdAt) : undefined;
  const deletedAt = data?.deletedAt?.toMillis?.();
  const deleted = deletedAt ? moment(deletedAt) : undefined;
  const dispatch = useDispatch();
  const handleRestore = React.useCallback(() => {
    if (itemId.type === 'works') {
      dispatch(actions.work.restore.started({ id: itemId.id }));
    } else {
      dispatch(actions.map.restore.started({ id: itemId.id }));
    }
  }, [itemId]);

  return (
    <SortedGrid item timestamp={data?.deletedAt} className={cn.item}>
      <img src={data?.thumbnailUrl} className={cn.img} alt="" />
      <div className={cn.captions}>
        <Typography variant="body1" color="textPrimary">
          {itemId.type === 'works'
            ? (data as IWork)?.title || 'タイトルなし'
            : 'はいけい'}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {created ? created.fromNow() + 'に作成' : '作成日不明'}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {deleted ? deleted.fromNow() + 'に削除' : ''}
        </Typography>
      </div>
      <div>
        <Button variant="outlined" onClick={handleRestore}>
          元に戻す
        </Button>
      </div>
    </SortedGrid>
  );
}
