/**
 * Redux action creators
 * Ducks の原則では Reducer ごとにファイルを分け、そこに Action Creator も書くことになっているが、
 * ・複数の Ducks に必要な共通の Action が多い
 * ・Ducks 間の参照のループ（循環参照）が起こる
 * 上記の理由から Action Creator だけを切り出すことにした
 * TODO: 全ての Action Creator で命名規則を統一し、ここで定義する
 */
import { IPackage } from '@hackforplay/assets';
import { SceneMap } from '@hackforplay/next';
import actionCreatorFactory from 'typescript-fsa';
import { ISkinNames } from './asset';
import { IVideoResponse } from './video';

const ac = actionCreatorFactory('website');

type UpdateProfile = {
  uid: string;
  blob?: Blob;
  src?: string;
  displayName: string;
  color: string;
};

export type PaginateResult<T> = {
  data: T[];
  timestamp?: firebase.default.firestore.Timestamp;
  mayHasMore?: boolean;
  /**
   * TODO: 必須パラメータにする
   */
  isFetching?: boolean;
  error?: Error;
};

export const actions = {
  asset: {
    loadAsset: ac.async<{ version: string }, IPackage, Error>(
      'asset/LOAD_ASSET'
    ),
    setCompatibleAsset: ac<ImmutableFile[]>('asset/SET_COMPATIBLE_ASSET'),
    fetchSkinNames: ac.async<void, ISkinNames>('asset/FETCH_SKIN_NAMES')
  },
  auth: {
    noticeError: ac<firebase.default.auth.Error>('auth/NOTICE_ERROR'),
    subscribeAuth: ac<void>('auth/SUBSCRIBE'),
    signInWithGoogle: ac<void>('auth/SIGN_IN_WITH_GOOGLE'),
    signOut: ac<void>('auth/SIGN_OUT'),
    signedIn: ac<firebase.default.UserInfo>('auth/SIGNED_IN'),
    signedOut: ac<void>('auth/SIGNED_OUT')
  },
  history: {
    /**
     * 自分がプレイしたステージを取得する（ペジネーション）
     */
    fetchMore: ac.async<void, PaginateResult<IHistory>>('history/FETCH_MORE')
  },
  make: {
    change: ac<{ id: string }>('make/CHANGE'),
    load: ac.async<
      {
        type: 'works' | 'officials';
        id: string;
        jsonUrl?: string; // offical の場合はセットする
        assetVersion?: string;
        savedCount?: number;
      },
      IFileSeed[],
      Error
    >('make/LOAD'),
    copy: ac.async<{ id: string }, void, Error>('make/COPY'),
    metadata: ac<IWorkDocumentUpdate>('make/METADATA'),
    thumbnail: ac<string | { dataUrl: string }>('make/THUMBNAIL'),
    /**
     * ユーザーが明示的にサムネイルを決定する
     */
    decideThumbnail: ac<{ dataUrl: string }>('make/DECIDE_THUMBNAIL'),
    updateThumbnail: ac.async<{ blob: Blob; workId: string }, { url: string }>(
      'make/UPDATE_THUMBNAIL'
    ),
    trash: ac('make/TRASH'),
    add: ac.async<
      {},
      {
        id: string;
        doc: IWork;
        savedCount: number;
        fileSize: number;
        timestamp: firebase.default.firestore.Timestamp;
      },
      Error
    >('make/ADD'),
    updateFiles: ac.async<
      { id: string },
      {
        savedCount: number;
        assetStoragePath: string;
        fileSize: number;
        timestamp: firebase.default.firestore.Timestamp;
      },
      Error
    >('make/UPDATE_FILES'),
    updateMetadata: ac.async<
      {},
      {
        metadata: IWorkDocumentUpdate;
        timestamp: firebase.default.firestore.Timestamp;
      },
      Error
    >('make/UDPATE_METADATA'),
    /**
     * エディタに関するユーザー設定を更新する
     * 即座に変更が反映されるようにする
     */
    updateEditor: ac.async<Partial<IUserDocument['editor']>, void>(
      'make/UPDATE_EDITOR'
    ),
    stopAutoSave: ac<boolean>('make/STOP_AUTO_SAVE'),
    /**
     * 現在の assetStoragePath を新しいバージョンとして保存する
     * params は Work ID
     * restorePayload がセットされている場合は、終了後に
     * restoreVersion を dispatch する
     */
    commitVersion: ac.async<
      {
        id: string;
        storagePath: string;
        fileSize: number;
      },
      ID<IWorkVersion>
    >('make/ADD_VERSION')
  },
  map: {
    setData: ac<{ id: string; data: SceneMap }>('map/SET_DATA'),
    load: ac.async<
      { id: string },
      { id: string; data: SceneMap; documentData: IMapDocument },
      Error
    >('map/LOAD'),
    createNew: ac.async<{ json: string; thumbnail: string }, DR>(
      'map/CREATE_NEW'
    ),
    copy: ac.async<{ id: string }, DR<IMapDocument>>('map/COPY'),
    update: ac.async<{ id: string; json: string; thumbnail: string }, {}>(
      'map/UPDATE'
    ),
    delete: ac.async<
      { id: string },
      { deletedAt: firebase.default.firestore.Timestamp }
    >('map/DELETE'),
    restore: ac.async<{ id: string }, void>('map/RESTORE'),
    /**
     * fetchMore または onSnapshot されたマップのドキュメントをストアに格納する
     * 自分のマップを格納することを前提として考えられている
     */
    setCollection: ac<QS<IMapDocument>>('map/SET_COLLECTION'),
    fetchData: ac.async<{ id: string; jsonUrl: string }, SceneMap, Error>(
      'map/FETCH_DATA'
    ),
    /**
     *自分のマップをさらに取得する
     */
    fetchMore: ac.async<void, QS<IMapDocument>, Error>('map/FETCH_MORE'),
    /**
     * 現在編集中のマップの情報をリセットする
     */
    reset: ac('map/RESET')
  },
  notification: {
    setUsers: ac<{
      uid: string;
      notifications: INotification[];
      mayHasMore?: boolean;
    }>('notification/SET_USERS'),
    fetchMore: ac.async<
      {},
      { uid: string; notifications: INotification[]; mayHasMore?: boolean }
    >('notification/FETCH_MORE'),
    /**
     * 全ての通知を一括して既読にする
     */
    markAsReadAll: ac.async<
      void,
      {
        updated: INotification[];
        readAt: firebase.default.firestore.Timestamp;
      }
    >('notification/MARK_AS_READ_ALL')
  },
  official: {
    fetchIfNeeded: ac<{ id: string; loadAfterFetch?: boolean }>(
      'official/FETCH_IF_NEEDED'
    ),
    fetch: ac.async<
      { id: string; loadAfterFetch?: boolean },
      IOfficialWork,
      Error
    >('official/FETCH')
  },
  series: {
    fetchByWorkId: ac.async<string, { workId: string; data?: ISeries }>(
      'series/FETCH_BY_WORK_ID'
    ),
    listMine: ac.async<void, PaginateResult<ISeries>>('series/LIST_MINE'),
    create: ac.async<Partial<ISeries>, ISeries>('series/CREATE'),
    update: ac.async<{ id: string; data: Partial<ISeries> }, void>(
      'series/UPDATE'
    ),
    delete: ac.async<{ id: string; callback: () => void }, void>(
      'series/DELETE'
    ),
    set: ac<{ workId?: string; data?: ISeries }>('series/SET')
  },
  team: {
    /**
     * member の currentPage を更新する要求をキューに入れる
     * ここには uri だけを指定し、Epic 内部でメタデータを付与する
     * 実際には一定時間 throttle される
     */
    currentPage: ac<string>('team/CURRENT_PAGE'),
    /**
     * member の currentPage を更新するトリガー
     */
    updateCurrentPage: ac.async<void, void>('team/UPDATE_CURRENT_PAGE')
  },
  user: {
    load: ac<string>('user/LOAD'),
    set: ac<{ uid: string; user?: IUserDocument }>('user/SET'),
    /**
     * 認証ユーザーのユーザーをセットする
     * user/SET も呼ばれる
     */
    setAuthUser: ac<IUserDocument>('user/SET_AUTH_USER'),
    update: ac.async<{ uid: string; data: IUserDocumentUpdate }, {}>(
      'user/UPDATE'
    ),
    /**
     * プロフィールの画像と色と名前をいっぺんに更新する
     */
    updateProfile: ac.async<UpdateProfile, void>('user/UPDATE_PROFILE'),
    updateIcon: ac.async<{ uid: string; blob: Blob }, { url: string }>(
      'user/UPDATE_ICON'
    ),
    fetchIfNeeded: ac<{ uid: string }>('user/FETCH_IF_NEEDED'),
    fetch: ac.async<{ uid: string }, DS>('user/FETCH')
  },
  video: {
    listIfNeeded: ac<string>('video/LIST_IF_NEEDED'),
    list: ac.async<string, IVideoResponse>('video/LIST'),
    /**
     * 新しく動画を見たらタイムスタンプを更新する
     */
    watch: ac.async<string, void>('video/WATCH'),
    /**
     * 動画を視聴したタイムスタンプを取り消す（見てないことにする）
     */
    unwatch: ac.async<string, void>('video/UNWATCH')
  },
  work: {
    /**
     * ステージが未取得であれば取得する。params は Work ID
     */
    fetchIfNeeded: ac<string>('work/FETCH_IF_NEEDED'),
    /**
     * ステージを取得する。official には使われない
     */
    fetch: ac.async<
      { id: string; loadAfterFetch?: boolean },
      { work?: IWork; userInfo?: firebase.default.UserInfo },
      Error
    >('work/FETCH'),
    addView: ac.async<{ id: string }, { id: string }>('work/ADD_VIEW'),
    updateView: ac.async<
      { id: string; name: string; value: string },
      { labels: { [key: string]: string } }
    >('work/UPDATE_VIEW'),
    fetchTrendings: ac.async<void, { docs: IWork[] }, Error>(
      'work/FETCH_TRENDINGS'
    ),
    fetchUsers: ac.async<{ uid: string }, PaginateResult<IWork>>(
      'work/USERS_LOAD'
    ),
    fetchNewerMore: ac.async<void, PaginateResult<IWork>, Error>(
      'work/FETCH_NEWER_MORE'
    ),
    subscribeNew: ac.async<void, IWork>('work/SUBSCRIBE_NEW'),
    fetchTrash: ac.async<void, PaginateResult<IWork>, Error>(
      'works/FETCH_TRASH'
    ),
    delete: ac.async<
      { id: string },
      { deletedAt: firebase.default.firestore.Timestamp },
      Error
    >('work/DELETE'),
    restore: ac.async<{ id: string }, void>('work/RESTORE'),
    search: ac.async<{ query: string }, string[], Error>('work/SEARCH'),
    /**
     * 自分のステージを変更したときにマイページに加える
     */
    insertUsersWorks: ac<{ uid: string; works: QS<IWork> }>(
      'work/INSERT_USERS_WORKS'
    ),
    /**
     * 「他のステージ」として一度表示したのものを取り除く
     */
    removeRecommendIds: ac<string[]>('REMOVE_RECOMMEND_IDS')
  }
};
