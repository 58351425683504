import moment from 'moment';

/**
 * 日付を表すオブジェクトを渡すと、それを現在からの差分で表す
 * @param date
 */
export function fromNow(date?: TS) {
  if (!date) {
    return '';
  }
  if (typeof date !== 'string') {
    if ('toDate' in date) {
      date = date.toDate();
    }
    if ('toISOString' in date) {
      date = date.toISOString();
    }
  }
  return moment(date, 'YYYY-MM-DD hh:mm:ss')
    .add(moment().utcOffset(), 'm')
    .fromNow();
}
