import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  Tab,
  Tabs,
  Theme,
  Typography,
  withTheme
} from '@material-ui/core';
import { Check, Close, OpenInNew } from '@material-ui/icons';
import { debounce } from 'lodash-es';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { style } from 'typestyle';
import { LoadingButton } from '../../../../components/LoadingButton';
import { applyOrder } from '../../../../components/SortedGrid';
import { StoreState } from '../../../../ducks';
import { actions } from '../../../../ducks/actions';
import { actions as fileActions } from '../../../../ducks/file';
import { IMapTemplate } from '../../../../ducks/maps';
import ImmutableFile from '../../File/ImmutableFile';
import { useActionDispatcher } from '../../hooks/useActionDispatcher';
import { useSetLocation } from '../../hooks/useSetLocation';
import { Localization } from '../../localization';
import Dropdown from './Dropdown';

export interface MapSelectorProps {
  theme: Theme;
  show: boolean;
  selected: string;
  setLocation: (location?: string) => void;
  localization: Localization;
  // Injected by withFiles (for JSX)
  files: ImmutableFile[];
  putFiles: typeof fileActions.putFiles;
  // Injected by withOwnMaps (for JSX)
  withOwnMaps: { maps: StoreState['maps'] };
  fetchMore: typeof actions.map.fetchMore.started;
  // Injected by withAuthUser (for JSX)
  withAuthUser: { auth: StoreState['auth'] };
}

interface State {
  /**
   * どのマップの背景を変更するか. ボタンが押されたときに Set して, open: Boolean の役割も果たす
   */
  selected: string | null;
  file: null;
  config: {
    templateApi?: string;
    files: { [name: string]: string };
  };
  tab: number;
  /**
   * オフィシャルテンプレート
   */
  templates: IOfficialTemplate[];
}

interface IOfficialTemplate {
  title: string;
  image: string;
  template: string;
  author: {
    name: string;
    url: string;
  };
  createdBy: string;
}

function isOfficialTemplate(
  item: IOfficialTemplate | IMapTemplate
): item is IOfficialTemplate {
  return 'createdBy' in item;
}

const configFileName = 'maps.json';
const definitionDir = 'maps/';
const mapNames = Array.from({ length: 100 }).map((_, i) => `map${i + 1}`);

const cn = {
  button: style({
    alignSelf: 'center'
  }),
  dialogClasses: {
    paper: style({
      padding: 4
    })
  },
  title: style({
    display: 'flex',
    alignItems: 'center'
  }),
  blank: style({
    flex: 1
  }),
  tileClasses: {
    root: style({
      height: 'auto'
    }),
    tile: style({
      paddingBottom: '66.6%', // responsive embed (3:2)
      height: 0
    })
  },
  img: style({
    top: 0,
    left: 0,
    transform: 'none',
    height: 'auto', // GridListTile の自動補正を無効化する
    width: '100%'
  }),
  span: style({
    alignSelf: 'center'
  }),
  confirm: style({
    marginRight: 16
  }),
  buttonWrapper: style({
    margin: 16,
    textAlign: 'center'
  }),
  gridList: style({
    height: 420
  })
};

const getCn = ({ theme }: MapSelectorProps) => ({
  close: style({
    position: 'absolute',
    top: theme.spacing(),
    right: theme.spacing()
  }),
  header: style({
    marginLeft: theme.spacing()
  })
});

export default withTheme(MapSelectorWrapper);

function MapSelectorWrapper(
  props: Omit<
    MapSelectorProps,
    | 'withAuthUser'
    | 'withOwnMaps'
    | 'fetchMore'
    | 'putFiles'
    | 'files'
    | 'setLocation'
  >
) {
  const isOwner = useSelector(state => state.make.isOwner);
  const auth = useSelector(state => state.auth);
  const withAuthUser = { auth };
  const maps = useSelector(state => state.maps);
  const withOwnMaps = { maps };
  const fetchMore = useActionDispatcher(actions.map.fetchMore.started);
  const putFiles = useActionDispatcher(fileActions.putFiles);
  let files = useSelector(state => state.file.files);
  files = files.filter(file => file.name === configFileName);
  const setLocation = useSetLocation();

  return isOwner ? (
    <MapSelector
      {...props}
      withAuthUser={withAuthUser}
      withOwnMaps={withOwnMaps}
      fetchMore={fetchMore}
      putFiles={putFiles}
      files={files}
      setLocation={setLocation}
    />
  ) : null;
}

class MapSelector extends React.Component<MapSelectorProps, State> {
  static defaultProps = {
    selected: ''
  };

  state: State = {
    selected: null, // どのマップの背景を変更するか. ボタンが押されたときに Set して, open: Boolean の役割も果たす
    file: null,
    config: {
      files: {}
    },
    tab: 0,
    templates: [] // オフィシャルテンプレート
  };

  static getDerivedStateFromProps(props: MapSelectorProps, state: State) {
    const [file] = props.files; // configFile
    if (state.file === file) return null;
    let config = {};
    try {
      config = JSON.parse(file.text);
    } catch (error) {
      console.error(error);
    }
    return {
      file,
      config
    };
  }

  componentDidMount() {
    const templateApi = this.state.config && this.state.config.templateApi;
    if (templateApi) {
      getTemplateMaps(templateApi).then(templates => {
        this.setState({ templates });
      });
    }
  }

  handleClick = async (template: string) => {
    const { selected } = this.state;
    await new Promise<void>(resolve => {
      this.setState({ selected: null }, resolve); // とりあえず閉じる
    });

    if (!selected) return;

    const fileName = definitionDir + selected + '.json';

    const definitionFile = new ImmutableFile({
      type: 'application/json',
      name: fileName,
      text: template
    });

    const config = {
      ...this.state.config,
      files: {
        ...(this.state.config.files || {}),
        [selected]: definitionFile.name
      }
    };
    const configFile = new ImmutableFile({
      type: 'application/json',
      name: configFileName,
      text: JSON.stringify(config, null, 4)
    });
    this.props.putFiles({
      files: [definitionFile, configFile],
      isUserAction: true
    });
    this.props.setLocation(); // リロード
  };

  handleToggle = () =>
    this.setState(state => ({
      selected: state.selected ? null : this.props.selected // 開いたときは「今いるマップ」
    }));

  handleSelectDropdown = (selected: string) => {
    this.setState({ selected });
  };

  handleSelectTab = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ tab: value });
  };

  render() {
    const { selected, localization, withOwnMaps, withAuthUser } = this.props;
    const { file, templates, tab } = this.state;
    if (!selected) return null;
    if (!file) return <span className={cn.span}>{selected}</span>;

    const dcn = getCn(this.props);

    const isSignedIn = Boolean(withAuthUser.auth.userInfo);

    const fetchMore = debounce(() => this.props.fetchMore(), 1000);

    return (
      <>
        {this.props.show ? (
          <Button
            variant="contained"
            className={cn.button}
            onClick={this.handleToggle}
          >
            {localization.monitorCard.mapSelector(selected)}
          </Button>
        ) : null}
        <Dialog
          maxWidth="md"
          fullWidth
          open={Boolean(this.state.selected) && this.props.show}
          classes={cn.dialogClasses}
          onClose={this.handleToggle}
        >
          <DialogTitle disableTypography className={cn.title}>
            <Dropdown
              items={mapNames}
              selected={this.state.selected || ''}
              onSelectChanged={this.handleSelectDropdown}
              localization={localization}
            />
            <Typography variant="h6" className={dcn.header}>
              {localization.monitorCard.mapSelectorTitle}
            </Typography>
            <div className={cn.blank} />
            <IconButton onClick={this.handleToggle}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Tabs value={tab} onChange={this.handleSelectTab}>
              <Tab label={localization.monitorCard.officials} />
              <Tab label={localization.monitorCard.yours} />
            </Tabs>
            {tab === 0 ? (
              <GridList cellHeight="auto" cols={3} className={cn.gridList}>
                {templates.map((item, i) => (
                  <MapSelectorItem
                    key={i} // 公式マップは path がない
                    src={item.image}
                    title={item.title}
                    createdBy={item.createdBy}
                    localization={localization}
                    onSelect={() => this.handleClick(item.template)}
                  />
                ))}
              </GridList>
            ) : null}
            {tab === 1 ? (
              <>
                <GridList cellHeight="auto" cols={3} className={cn.gridList}>
                  {withOwnMaps.maps.myMapIds.map(id => (
                    <MyMapSelectorItem
                      key={id}
                      id={id}
                      onSelect={this.handleClick}
                      localization={localization}
                    />
                  ))}
                  <div className={cn.buttonWrapper} style={{ width: '100%' }}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      loading={withOwnMaps.maps.more.isFetching}
                      disabled={
                        !isSignedIn || !withOwnMaps.maps.more.mayHasMore
                      }
                      onClick={fetchMore}
                    >
                      {withOwnMaps.maps.more.mayHasMore
                        ? 'もっと読み込む'
                        : 'これで全部です'}
                    </LoadingButton>
                  </div>
                </GridList>
              </>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              disabled={!isSignedIn}
              href="/map-editor"
              target="_blank"
              referrerPolicy="same-origin"
              endIcon={<OpenInNew />}
            >
              マップを作る
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

interface MyMapSelectorItemProps {
  id: string;
  onSelect: (template: string) => void;
  localization: Localization;
  /**
   * GridList の子要素に inject される style プロパティを受け取る
   * これがないとグリッドのスタイルが崩れる
   */
  style?: React.CSSProperties;
}

function MyMapSelectorItem(props: MyMapSelectorItemProps) {
  const item = useSelector(state => state.maps.maps[props.id]?.data);
  const sceneMap = useSelector(state => state.maps.sceneMaps[props.id]?.data);

  const [waiting, setWating] = React.useState(false);
  const dispatch = useDispatch();
  const handleSelect = React.useCallback(() => {
    setWating(true);
    if (!sceneMap) {
      // マップテンプレートの読み込みが終了していないので、今からダウンロードする
      const jsonUrl = item?.jsonUrl;
      if (!jsonUrl) {
        throw new Error('MapSelector: jsonUrl is undefined.'); // type hint
      }
      dispatch(actions.map.fetchData.started({ id: props.id, jsonUrl }));
    }
  }, [sceneMap]);

  React.useEffect(() => {
    if (waiting && sceneMap) {
      const template = JSON.stringify(sceneMap);
      props.onSelect(template);
      setWating(false);
    }
  }, [waiting, sceneMap]);

  return item ? (
    <MapSelectorItem
      src={item.thumbnailUrl || `/api/maps/${props.id}/thumbnail`}
      onOpen={() => {
        window.open(`/maps/${props.id}`, 'target:blank');
      }}
      onSelect={handleSelect}
      localization={props.localization}
      style={props.style}
      timestamp={item.updatedAt}
    />
  ) : null;
}

async function getTemplateMaps(url: string): Promise<IOfficialTemplate[]> {
  const response = await fetch(
    process.env.NODE_ENV === 'production'
      ? url
      : 'https://www.hackforplay.xyz' + url // ローカルサーバを立てなくてもいいように
  );
  return await response.json();
}

interface MapSelectorItemProps {
  timestamp?: firebase.default.firestore.Timestamp | null;
  loading?: boolean;
  src: string;
  title?: string;
  createdBy?: string;
  onOpen?: () => void;
  onSelect: () => void;
  localization: Localization;
  /**
   * GridList の子要素に inject される style プロパティを受け取る
   * これがないとグリッドのスタイルが崩れる
   */
  style?: React.CSSProperties;
}

function MapSelectorItem(props: MapSelectorItemProps) {
  const { localization } = props;

  const style = React.useMemo(
    () => applyOrder(props.style, props.timestamp),
    [props.style, props.timestamp]
  );

  return (
    <GridListTile classes={cn.tileClasses} style={style}>
      <img src={props.src} alt="" className={cn.img} />
      <GridListTileBar
        title={props.title}
        subtitle={props.createdBy ? <span>by: {props.createdBy}</span> : null}
        actionIcon={
          <>
            {props.onOpen ? (
              <Button
                variant="contained"
                color="default"
                className={cn.confirm}
                onClick={props.onOpen}
                endIcon={<OpenInNew />}
              >
                {localization.monitorCard.open}
              </Button>
            ) : null}
            <LoadingButton
              loading={Boolean(props.loading)}
              variant="contained"
              color="primary"
              className={cn.confirm}
              onClick={props.onSelect}
              endIcon={<Check />}
            >
              {localization.monitorCard.confirm}
            </LoadingButton>
          </>
        }
      />
    </GridListTile>
  );
}
