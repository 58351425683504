import MarkdownIt from 'markdown-it';
import React, { useMemo, useState } from 'react';

export interface Message {
  role: 'user' | 'assistant';
  content: string;
}

interface ChatMessageProps {
  message: Message;
}

export function ChatMessage({ message }: ChatMessageProps) {
  const [md] = useState(() => new MarkdownIt());

  const innerHTML = useMemo(() => {
    if (message.role === 'user') {
      return { __html: message.content };
    }
    const html = md.render(message.content);

    return { __html: html };
  }, [message.content]);

  return (
    <div
      className="chat-message"
      style={{
        backgroundColor: message.role === 'user' ? '#feeac1' : '#f0f0f0',
        padding: 8,
        borderRadius:
          message.role === 'user' ? '8px 8px 0 8px' : '8px 8px 8px 0',
        alignSelf: message.role === 'user' ? 'flex-end' : 'flex-start',
        cursor: 'initial',
        lineBreak: 'anywhere',
        whiteSpace: 'pre-wrap'
      }}
      onPointerDown={e => e.stopPropagation()}
      dangerouslySetInnerHTML={innerHTML}
    />
  );
}
