import {
  AnyValue,
  Definition,
  FunctionValue,
  ObjectValue,
  PrimitiveValue
} from '@hackforplay/common/src/definition';
import { Pos } from 'codemirror';
import { debounce } from 'lodash-es';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { style } from 'typestyle';
import { useGlobalsHint } from '../../hooks/useGlobalsHint';
import { useHint } from '../../hooks/useHint';

/**
 * ウィジェットを作るために必要な情報
 */
interface WidgetBase {
  age: number;
  instance: CodeMirror.Editor;
  pos: CodeMirror.Position;
  text: string;
  hint: CodeMirror.AsyncHintFunction;
}

interface Widget {
  age: number;
  element: Element;
}

export interface DropdownProviderProps {
  codemirror?: CodeMirror.Editor;
}

export function DropdownProvider(props: DropdownProviderProps) {
  const widgetsRef = React.useRef<Widget[]>([]); // ウィジェットと世代をセットで保持する
  const definition = useSelector(state => state.file.definition);
  const hint = useHint();
  const globalsHint = useGlobalsHint();

  React.useEffect(() => {
    let currentAge = 0; // 処理中に onChange が fire されたことを知るための数値
    let lineCount = 0; // 行数が変わったら全ての Widget がズレるので一度全て消す必要がある。それを検知するための数値
    const onChange = debounce((instance?: CodeMirror.Editor) => {
      const age = ++currentAge; // 前回までの処理をキャンセルさせる
      const generator = makeWidgetGenerator(
        age,
        instance,
        definition,
        hint,
        globalsHint
      );
      const widgets = [] as WidgetBase[];
      // 行を追加 or 消すと、全ての Widget がズレるので、描画更新されるまで見栄えが悪い→一度全て消す
      if (lineCount !== instance?.lineCount()) {
        widgetsRef.current.forEach(e =>
          e.element.parentElement?.removeChild(e.element)
        );
        widgetsRef.current = [];
        lineCount = instance?.lineCount() || 0;
      }
      (function step() {
        window.requestIdleCallback(
          deadline => {
            if (age !== currentAge) {
              return; // キャンセルされた
            }
            const limit = 1000; // Widget の上限
            for (let _ = 0; _ < 100; _++) {
              const { value, done } = generator.next();
              value && widgets.push(value);
              if (done || deadline.didTimeout || widgets.length >= limit) {
                // 描画処理は重いので少しずつ進める. 一気に消すとチラつくので消しながら作る
                const index = widgetsRef.current.findIndex(
                  w => w.age !== currentAge
                );
                const head = index >= 0 ? widgetsRef.current[index] : undefined;
                if (head) {
                  // Widget の世代が古いので消す（上から順番に消していく）
                  head.element.parentElement?.removeChild(head.element);
                  widgetsRef.current.splice(index, 1);
                }
                // キューから一つ取り出してウィジェットを作成
                const next = widgets.shift();
                if (next) {
                  widgetsRef.current.push(createWidget(next)); // Widget を作成
                }
                if (!head && !next) {
                  return; // キューが空になった
                }
              }
              if (deadline.timeRemaining() < 5) {
                break; // 猶予がなくなったので処理を一時中断
              }
            }
            step(); // 次の処理をキューイング
          },
          { timeout: 5000 }
        );
      })();
    }, 100);

    requestAnimationFrame(() => onChange(props.codemirror)); // 今の時点で１度実行する
    props.codemirror?.on('change', onChange);
    props.codemirror?.on('swapDoc', onChange);
    return () => {
      props.codemirror?.off('change', onChange);
      props.codemirror?.off('swapDoc', onChange);
    };
  }, [props.codemirror, definition]);

  return null;
}

const cn = {
  widget: style({
    color: 'transparent',
    borderBottom: '1px solid #3f51b5',
    transform: 'translate(0px, -18px)',
    cursor: 'pointer',
    zIndex: 10
  })
};

function createWidget({ age, instance, text, pos, hint }: WidgetBase) {
  const element = document.createElement('div');
  element.textContent = text;
  element.className = cn.widget;
  element.addEventListener(
    'click',
    () => {
      instance.setCursor(pos);
      instance.showHint({ hint });
    },
    { passive: true }
  );
  instance.addWidget(pos, element, false);
  return { age, element };
}

enum GlobalVarState {
  None,
  /**
   * へんすう がみつかった
   */
  Identifier,
  /**
   * へんすう[ がみつかった
   */
  Bracket
}

/**
 * １つずつ Widget を作って投げる関数
 * 協調的マルチスレッドのためにジェネレータで実装
 */
function* makeWidgetGenerator(
  age: number,
  instance?: CodeMirror.Editor,
  definition?: Definition,
  hint?: CodeMirror.AsyncHintFunction,
  globalsHint?: CodeMirror.AsyncHintFunction
) {
  if (!instance || !definition || !hint || !globalsHint) {
    return; // 初期化が済んでいない
  }
  const end = instance.lineCount();
  const globals = Object.values(definition.globals || {}).filter(isObjectValue);
  const globalsVarNames = ['globals']; // 'globals という名前のグローバル変数がある
  if (definition.globals?.globals?.name) {
    globalsVarNames.push(definition.globals.globals.name); // ['globals, 'へんすう'] という配列が得られるはず
  }
  // むき.ひだり などを Dropdown にする
  let props: (PrimitiveValue | FunctionValue)[] = []; // 次に来たら Dropdown にする候補 (a)
  let globalVarState = GlobalVarState.None; // へんすう['なまえ'] の 'なまえ' を Dropdown にする (b)
  for (let line = 0; line < end; line++) {
    const tokens = instance.getLineTokens(line);
    // 次に来たら Dropdown にする候補 (a)
    for (const token of tokens) {
      if (!token.type) continue; // 識別子以外はスキップ
      // 2. 候補にヒットしたら Widget を作る. functions は含まない
      const hit = props.find(
        p => p.type === 'primitive' && p.name === token.string
      );
      if (hit) {
        yield {
          age,
          instance,
          pos: new Pos(line, token.start),
          text: token.string,
          hint
        };
      }

      // 1. グローバル変数が見つかったら次の候補をセット
      props =
        token.type === 'variable'
          ? Object.values(
              globals.find(obj => obj.name === token.string)?.properties || {}
            )
          : [];
    }
    // へんすう['なまえ'] の 'なまえ' を Dropdown にする (b)
    for (const token of tokens) {
      if (!token.string?.trim()) continue; // 空白はスキップ
      // 3. '[' の次（文字列が見つかったら Dropdown にする）
      if (globalVarState === GlobalVarState.Bracket) {
        if (token.type === 'string') {
          yield {
            age,
            instance,
            pos: new Pos(line, token.start),
            text: token.string,
            hint: globalsHint
          };
        }
        globalVarState = GlobalVarState.None;
      }
      // 2. '[' が見つかったら GlobalVarState.Bracket へ
      if (globalVarState === GlobalVarState.Identifier) {
        if (token.string === '[') {
          globalVarState = GlobalVarState.Bracket;
          continue;
        }
        globalVarState = GlobalVarState.None;
      }
      // 1. globalsVarNames が見つかったら GlobalVarState.Identifier へ
      if (globalVarState === GlobalVarState.None) {
        globalVarState =
          token.type === 'variable' && globalsVarNames.includes(token.string)
            ? GlobalVarState.Identifier
            : GlobalVarState.None;
        continue;
      }
    }
    yield; // 行ごとに処理を分割
  }
}

function isObjectValue(value: AnyValue): value is ObjectValue {
  return value.type === 'object';
}
