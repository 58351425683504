import React, { ReactNode } from 'react';

/**
 * 現在の言語設定を取得する。TODO: 言語変更と紐づける
 * @returns 言語コード
 */
function useLanguage() {
  const lang = navigator.language.split('-')[0];
  return lang;
}

interface LangComponentProps {
  children: ReactNode;
}

/** 日本語の時だけ表示されるComponent */
export function JA(props: LangComponentProps) {
  const lang = useLanguage();
  if (lang !== 'ja') return null;
  return <>{props.children}</>;
}

/** 日本語以外の時に表示されるComponent */
export function EN(props: LangComponentProps) {
  const lang = useLanguage();
  if (lang === 'ja') return null;
  return <>{props.children}</>;
}

interface InternationalizeProps {
  ja: ReactNode;
  en: ReactNode;
}

/** ワンライナーで書ける言語別表示Component */
export function I(props: InternationalizeProps) {
  const lang = useLanguage();
  return <>{lang === 'ja' ? props.ja : props.en}</>;
}
