import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { actions } from '../ducks/actions';

/**
 * /api/currentPage を更新する Provider
 *
 */
export function CurrentPageProvider() {
  const { pathname: uri } = useLocation();
  const dispatch = useDispatch();

  // pathname が変化したら currentPage を更新する
  React.useEffect(() => {
    dispatch(actions.team.currentPage(uri));
  }, [uri]);

  return null;
}
