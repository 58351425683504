import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  GridProps,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography
} from '@material-ui/core';
import { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import { MoreVert } from '@material-ui/icons';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { classes, style } from 'typestyle';
import { actions as _actions } from '../ducks/actions';
import { isInitialized, Statefull } from '../ducks/helpers';
import theme from '../settings/theme';
import { Link as makeLink } from '../utils/components';
import { fromNow } from '../utils/fromNow';
import { notFoundImage } from '../utils/urls';
import { hover, loadAnimation, objectFit } from '../utils/xlasses';
import { SortedGrid } from './SortedGrid';
import { UserNameLink } from './UserNameLink';

export const cn = {
  root: style({
    padding: theme.spacing(6)
  }),
  headline: style({
    marginBottom: theme.spacing(4)
  }),
  button: style({
    fontSize: 'large',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4)
  }),
  card: style({
    textAlign: 'left',
    overflow: 'visible',
    height: 260
  }),
  thumbnail: style({
    width: 240
  }),
  card_private: style({
    filter: `brightness(90%)`
  }),
  noTitle: style({
    fontStyle: 'italic'
  }),
  more: style({
    width: '100%',
    position: 'relative',
    textAlign: 'center',
    $nest: {
      '&::before': {
        display: 'block',
        whiteSpace: 'pre',
        content: '""',
        position: 'relative',
        marginTop: -16,
        width: '100%',
        height: 16,
        background: 'linear-gradient(to bottom, transparent, white)'
      }
    }
  }),
  cardMedia: {
    media: classes(
      style({
        minHeight: 160,
        maxHeight: 160
      }),
      loadAnimation.glimmering,
      objectFit.cover
    )
  },
  remove: style({
    color: 'red'
  })
};

const cardHeaderCn = {
  root: style({
    maxWidth: '100%'
  }),
  content: style({
    maxWidth: '100%'
  }),
  title: style({
    maxHeight: 48,
    overflow: 'hidden'
  })
};

export type OwnProps = {
  id: string;
  /**
   * TODO: PaginateResult<string> に変更する
   */
  workIds: Statefull<string[]>;
  title: React.ReactNode;
  more: boolean;
  moreLink?: string;
  onClickMore?: () => void;
  canFetchMore?: boolean;
  showVisibility: boolean;
  className?: string;
  getTimestamp: WorkListItemProps['getTimestamp'];
};

export function WorkList({
  id,
  workIds,
  title,
  more,
  moreLink,
  onClickMore,
  canFetchMore = false,
  showVisibility,
  className,
  getTimestamp
}: OwnProps) {
  if (isInitialized(workIds)) {
    return null;
  }

  return (
    <Paper elevation={2} id={id} className={classes(cn.root, className)}>
      {typeof title === 'string' ? (
        <Typography variant="h5" className={cn.headline}>
          {title}
        </Typography>
      ) : (
        title
      )}
      <Collapse collapsedHeight="284px" in={more || false}>
        <Grid container justify="center" spacing={1}>
          {workIds.data ? (
            workIds.data.map(id => (
              <WorkListItem
                key={id}
                id={id}
                showVisibility={showVisibility}
                getTimestamp={getTimestamp}
              />
            ))
          ) : (
            <Grid item>
              {workIds.isProcessing ? <CircularProgress /> : null}
              {workIds.isInvalid ? (
                <Typography variant="h5">
                  {`エラーが発生しました`}
                  <span role="img" aria-label="Confused">
                    {`😕`}
                  </span>
                  {workIds.error ? workIds.error.message : ''}
                </Typography>
              ) : null}
              {workIds.isEmpty ? (
                <Typography variant="h5">
                  {`ステージが見つかりませんでした`}
                  <span role="img" aria-label="Confused">
                    {`😕`}
                  </span>
                </Typography>
              ) : null}
            </Grid>
          )}
        </Grid>
      </Collapse>
      <div className={cn.more}>
        {moreLink ? (
          <Button
            variant="contained"
            color="primary"
            className={cn.button}
            component={makeLink(moreLink)}
            onClick={onClickMore}
          >
            もっと見る
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={cn.button}
            onClick={onClickMore}
            disabled={!canFetchMore}
          >
            もっと見る
          </Button>
        )}
      </div>
    </Paper>
  );
}

type OnClick = NonNullable<ButtonBaseProps['onClick']>;

export interface WorkListItemProps extends GridProps {
  id: string;
  showVisibility?: boolean;
  disableAction?: boolean;
  /**
   * 削除されたステージであっても表示する
   */
  showDeleted?: boolean;
  getTimestamp: (
    work: IWork
  ) => firebase.default.firestore.Timestamp | null | undefined;
}

export function WorkListItem({
  id,
  showVisibility,
  disableAction,
  showDeleted,
  getTimestamp,
  ...props
}: WorkListItemProps) {
  const statefull = useSelector(state => state.work.works[id]);
  const item = statefull?.data;
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(_actions.work.fetchIfNeeded(id));
  }, [id]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();

  const userInfo = useSelector(state => state.auth.userInfo);

  const handleOpen = React.useCallback<OnClick>(event => {
    // event.stopPropagation(); // 遷移しないように
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback(() => {
    setAnchorEl(undefined);
  }, []);

  const handleCopy = React.useCallback(() => {
    dispatch(_actions.make.copy.started({ id }));
    handleClose();
  }, [id]);

  const handleRemove = React.useCallback(() => {
    dispatch(_actions.work.delete.started({ id }));
    handleClose();
  }, [id]);

  if (!showDeleted && item?.deletedAt) {
    return null; // 削除されている
  }

  return (
    <SortedGrid
      item
      timestamp={item ? getTimestamp(item) : undefined}
      {...props}
    >
      {item ? (
        <>
          <Card
            elevation={0}
            className={classes(
              cn.card,
              cn.thumbnail,
              hover.growImageRoot,
              item.visibility === 'private' && cn.card_private
            )}
          >
            <Link to={`/works/${id}`} className={hover.growImageParent}>
              <CardMedia
                component="img"
                title={item.title}
                src={item.thumbnailUrl || notFoundImage}
                classes={cn.cardMedia}
              />
            </Link>
            <CardHeader
              action={
                userInfo && userInfo.uid === item.uid ? (
                  <IconButton disabled={disableAction} onClick={handleOpen}>
                    <MoreVert />
                  </IconButton>
                ) : null
              }
              title={
                <Link to={`/works/${id}`}>
                  <Typography
                    variant="body2"
                    className={classes(!item.title && cn.noTitle)}
                  >
                    {item.title + ''}
                  </Typography>
                </Link>
              }
              subheader={<UserNameLink uid={item.uid} />}
              classes={cardHeaderCn}
            />
            <CardContent>
              <Typography variant="caption">
                {getMetadata(item, showVisibility)}
              </Typography>
            </CardContent>
          </Card>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleCopy}>コピーする</MenuItem>
            <Divider />
            <ListItem
              button
              disabled={item.visibility === 'public'}
              className={classes(item.visibility !== 'public' && cn.remove)}
              onClick={handleRemove}
            >
              <ListItemText
                primary="ゴミばこにすてる"
                secondary={
                  item.visibility === 'public'
                    ? '公開中の作品はすてられません'
                    : undefined
                }
              />
            </ListItem>
          </Menu>
        </>
      ) : statefull?.isProcessing ? (
        <Card elevation={0} className={classes(cn.card, cn.thumbnail)}>
          <LinearProgress />
        </Card>
      ) : null}
    </SortedGrid>
  );
}

function getMetadata(item: IWork, showVisibility?: boolean) {
  const segments: string[] = [];
  if (item.publishedAt) {
    segments.push(fromNow(item.publishedAt));
    segments.push(
      `クリア${
        item.clearRate
          ? Math.floor(item.clearRate * 100)
          : item.clearChecked
          ? 100
          : 0
      }%`
    );
  }
  if (showVisibility) {
    if (item.visibility === 'limited') {
      segments.push('限定公開');
    }
    if (item.visibility === 'private') {
      segments.push('非公開');
    }
  }
  if (item.receiveComment) {
    segments.push('コメントOK');
  }
  return segments.join('・');
}
