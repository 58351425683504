import { Button, Paper, Zoom } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import { Theme, withTheme } from '@material-ui/core/styles';
import { Restore } from '@material-ui/icons';
import * as React from 'react';
import { style } from 'typestyle';
import { Localization } from '../../localization';
import { ErrorFollowAI } from './ErrorFollowAI';

export interface ErrorPaneProps {
  theme: Theme;
  error?: Error;
  localization: Localization;
  onRestore: () => void;
  canRestore: boolean;
  code: string;
}

interface State {
  show: boolean;
  expanded: boolean;
}

const cn = {
  dialogRoot: style({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1001
  }),
  error: style({
    margin: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderStyle: 'double',
    borderColor: red['500'],
    backgroundColor: red['50'],
    overflow: 'hidden'
  }),
  heading: style({
    color: 'rgba(255, 0, 0, .5)'
  }),
  close: style({
    alignSelf: 'flex-end'
  }),
  blank: style({
    flex: '0 0 1rem'
  }),

  messageText: style({
    margin: 8,
    color: red['500'],
    fontFamily: 'Consolas, "Liberation Mono", Menlo, Courier, monospace'
  })
};

class ErrorPane extends React.PureComponent<ErrorPaneProps, State> {
  state = {
    show: false,
    expanded: false
  };

  componentDidUpdate(prevProps: ErrorPaneProps) {
    if (prevProps.error !== this.props.error) {
      this.setState({
        show: !!this.props.error
      });
    }
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleRestore = () => {
    this.setState({ show: false }, () => {
      this.props.onRestore();
    });
  };

  renderAsDialog() {
    const { localization, canRestore, error } = this.props;

    return (
      <div className={cn.dialogRoot}>
        <Paper elevation={2} key="error" className={cn.error}>
          <Button
            variant="text"
            color="primary"
            className={cn.close}
            onClick={this.handleClose}
          >
            {localization.common.close}
          </Button>
          <h2 className={cn.heading}>{localization.editorCard.error}</h2>
          <i>{localization.editorCard.errorFollow}</i>
          <div className={cn.blank} />
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleRestore}
            disabled={!canRestore}
            startIcon={<Restore />}
          >
            {localization.editorCard.restore}
          </Button>
          <div className={cn.blank} />
        </Paper>
      </div>
    );
  }

  render() {
    const { show } = this.state;

    return (
      <div className="error-pane">
        {this.props.error ? (
          <ErrorFollowAI error={this.props.error} code={this.props.code} />
        ) : null}
        <Zoom in={show}>{this.renderAsDialog()}</Zoom>
      </div>
    );
  }
}

export default withTheme(ErrorPane);
