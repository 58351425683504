import { classes } from 'typestyle';
import * as React from 'react';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

/** いい感じに水平スクロールできるボタンがつく */
export function ScrollContainer(props: React.HTMLProps<HTMLDivElement>) {
  const { children, className, ...rest } = props;

  const scrollerRef = React.useRef<HTMLDivElement>(null);
  const makeScroll = (direction: number) => () => {
    if (!scrollerRef.current) return;
    const left = scrollerRef.current.clientWidth * direction * 0.5; // 半分ずつスクロールする
    scrollerRef.current.scrollBy({ behavior: 'smooth', left });
  };

  const [reach, setReach] = React.useState<'left' | '' | 'right'>('left'); // 最大までスクロールしたか
  React.useEffect(() => {
    const timerId = window.setInterval(() => {
      if (!scrollerRef.current) return;
      const { scrollLeft, scrollWidth, clientWidth } = scrollerRef.current;
      if (scrollLeft <= 0) {
        setReach('left');
      } else if (scrollLeft >= scrollWidth - clientWidth) {
        setReach('right');
      } else {
        setReach('');
      }
    }, 500);
    return () => window.clearInterval(timerId);
  }, []);

  return (
    <div className={classes(className, 'scroll-container')} {...rest}>
      <button
        className={classes('scroll-button left', reach === 'left' && 'hidden')}
        onClick={makeScroll(-1)}
      >
        <KeyboardArrowLeft />
      </button>
      <div className="scroll-box" ref={scrollerRef}>
        {children}
      </div>
      <button
        className={classes(
          'scroll-button right',
          reach === 'right' && 'hidden'
        )}
        onClick={makeScroll(1)}
      >
        <KeyboardArrowRight />
      </button>
    </div>
  );
}
