import { AlertProps } from '@material-ui/lab/Alert';
import { combineEpics } from 'redux-observable';
import { actions } from './actions';
import { reducerWithImmer } from './reducer-with-immer';

const initialState = {
  isSignedIn: false,
  alert: undefined as AlertProps | undefined
};

export type State = typeof initialState;

export default reducerWithImmer(initialState)
  .case(actions.auth.signedIn, draft => {
    const messages = [
      'ログインしました。おかえりなさい！',
      'ログインしました。Hello World! ;)',
      'ログイン完了、これよりオペレーションを開始します！',
      'ログインできたよ。今日は何してあそぶ？',
      '今日もログインしてえらい！ハナマルをあげます'
    ];
    draft.alert = {
      severity: 'success',
      children: messages[(Math.random() * messages.length) >> 0]
    };
    draft.isSignedIn = true;
  })
  .case(actions.auth.signOut, draft => {
    draft.isSignedIn = false;
  })
  .case(actions.auth.noticeError, (draft, payload) => {
    draft.alert = {
      severity: 'error',
      children: `ログインエラーです: ${payload.message} (${payload.code})`
    };
    window.Rollbar?.error(payload); // TODO: コネクションエラーはユーザーに通知だけして Rollbar に送らない
  })
  .case(actions.video.list.failed, (draft, payload) => {
    draft.alert = {
      severity: 'error',
      children: '動画の読み込みに失敗しました。再読み込みしてください'
    };
    window.Rollbar?.error(payload.error);
  })
  .case(actions.official.fetch.done, (draft, payload) => {
    if (payload.params.loadAfterFetch && payload.result.replayable) {
      draft.alert = draft.isSignedIn
        ? {
            severity: 'info',
            children: 'ログインしているので、じどうで保存されます'
          }
        : {
            severity: 'warning',
            children: 'ログインしていないので、保存されません'
          };
    }
  })
  .case(actions.make.load.failed, (draft, payload) => {
    draft.alert = {
      severity: 'error',
      children: 'ステージをうまく読み込めませんでした。再読み込みしてください'
    };
    window.Rollbar?.error(payload.error);
  })
  .case(actions.make.updateEditor.done, draft => {
    draft.alert = {
      severity: 'success',
      children: 'ユーザー設定を変更しました'
    };
  })
  .case(actions.make.copy.started, draft => {
    draft.alert = {
      severity: 'info',
      children: `コピーしています…`
    };
  })
  .case(actions.make.copy.done, draft => {
    draft.alert = {
      severity: 'success',
      children: `コピーできました！`
    };
  })
  .case(actions.make.copy.failed, (draft, payload) => {
    draft.alert = {
      severity: 'error',
      children: `コピーに失敗しました`
    };
    window.Rollbar?.error(payload.error);
  })
  .case(actions.map.copy.started, draft => {
    draft.alert = {
      severity: 'info',
      children: `コピーしています…`
    };
  })
  .case(actions.map.copy.done, draft => {
    draft.alert = {
      severity: 'success',
      children: `コピーできました！`
    };
  })
  .case(actions.map.copy.failed, (draft, payload) => {
    draft.alert = {
      severity: 'error',
      children: `コピーに失敗しました`
    };
    window.Rollbar?.error(payload.error);
  })
  .case(actions.make.add.failed, (draft, payload) => {
    const { error } = payload;
    draft.alert = {
      severity: 'error',
      children: `ステージの作成に失敗しました`
    };
    console.error(error); // debug. TODO: ステージの保存が上手くいかないバグが治ったら削除する
    window.Rollbar?.error(error);
  })
  .case(actions.make.updateFiles.failed, (draft, payload) => {
    const { error } = payload;
    draft.alert = {
      severity: 'error',
      children: `ステージの保存に失敗しました`
    };
    console.error(error); // debug. TODO: ステージの保存が上手くいかないバグが治ったら削除する
    window.Rollbar?.error(error);
  })
  .case(actions.work.delete.done, draft => {
    draft.alert = {
      severity: 'success',
      children: 'ステージをゴミ箱に入れました'
    };
  })
  .case(actions.asset.fetchSkinNames.failed, (draft, payload) => {
    draft.alert = {
      severity: 'error',
      children: 'スキンを読み込めませんでした'
    };
    window.Rollbar?.error(payload.error);
  })
  .case(actions.user.updateProfile.done, draft => {
    draft.alert = {
      severity: 'success',
      children: 'プロフィールが更新されました'
    };
  })
  .case(actions.user.updateProfile.failed, (draft, payload) => {
    draft.alert = {
      severity: 'error',
      children: 'プロフィールの更新に失敗しました'
    };
    window.Rollbar?.error(payload.error);
  })
  .case(actions.series.create.failed, (draft, payload) => {
    draft.alert = {
      severity: 'error',
      children: 'シリーズの作成に失敗しました'
    };
    window.Rollbar?.error(payload.error);
  })
  .case(actions.series.delete.failed, (draft, payload) => {
    draft.alert = {
      severity: 'error',
      children: 'シリーズの削除に失敗しました'
    };
    window.Rollbar?.error(payload.error);
  })
  .case(actions.map.load.failed, (draft, payload) => {
    draft.alert = {
      severity: 'error',
      children: 'マップを開けませんでした'
    };
    window.Rollbar?.error(payload.error);
  })
  .case(actions.map.createNew.failed, (draft, payload) => {
    draft.alert = {
      severity: 'error',
      children: 'マップを作成できませんでした'
    };
    window.Rollbar?.error(payload.error);
  })
  .case(actions.map.update.failed, (draft, payload) => {
    draft.alert = {
      severity: 'error',
      children: 'マップを保存できませんでした'
    };
    window.Rollbar?.error(payload.error);
  })
  .case(actions.map.delete.failed, (draft, payload) => {
    draft.alert = {
      severity: 'error',
      children: 'マップを削除できませんでした'
    };
    window.Rollbar?.error(payload.error);
  })
  .case(actions.map.restore.failed, (draft, payload) => {
    draft.alert = {
      severity: 'error',
      children: 'マップをゴミ箱から元に戻せませんでした'
    };
    window.Rollbar?.error(payload.error);
  })
  .case(actions.map.fetchData.failed, (draft, payload) => {
    draft.alert = {
      severity: 'error',
      children: 'マップの中身を取得できませんでした'
    };
    window.Rollbar?.error(payload.error);
  })
  .case(actions.map.fetchMore.failed, (draft, payload) => {
    draft.alert = {
      severity: 'error',
      children: 'マップを取得できませんでした'
    };
    window.Rollbar?.error(payload.error);
  })
  .toReducer();

export const epics = combineEpics();
