import { Grid, GridProps } from '@material-ui/core';
import * as React from 'react';
import { useUpdated } from '../hooks/useUpdated';
import { parseTimestamp } from '../utils/parseTimestamp';

interface SortedGridProps extends GridProps {
  timestamp?: firebase.default.firestore.Timestamp | null;
  /**
   * 昇順に並べたい場合は true にする
   */
  asc?: boolean;
}

/**
 * timestamp 順にソートされた Grid item
 * order を使って CSS でソートする
 * order は 32bit 符号あり整数として解釈されるため、ミリ秒単位の精度は表現できない
 * そのため秒単位で同一のタイムスタンプは正しい順序が保証されない
 */
export function SortedGrid({
  asc,
  style,
  timestamp,
  ...props
}: SortedGridProps) {
  const [actualStyle, setActualStyle] = React.useState(() =>
    applyOrder(style, timestamp, asc)
  );
  useUpdated(() => {
    setActualStyle(applyOrder(style, timestamp, asc));
  }, [style, timestamp, asc]);

  if (process.env.NODE_ENV !== 'production') {
    if (!props.item) {
      throw new Error('SortedGrid only to use grid item.');
    }
  }

  return <Grid {...props} style={actualStyle} />;
}

export function applyOrder(
  style?: React.CSSProperties,
  timestamp?: TS,
  asc?: boolean
): React.CSSProperties {
  const millis = parseTimestamp(timestamp, NaN);
  const seconds = Math.floor(millis / 1000);
  const order = !Number.isNaN(seconds)
    ? (asc ? 1 : -1) * seconds // order は昇順なので、降順に見せたい場合は -1 を掛ける
    : 2147483647; // タイムスタンプが不正な値なので、一番後ろに並べる

  return {
    order,
    ...style
  };
}
