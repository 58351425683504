import { PaginateResult } from './actions';

export type Statefull<T> = {
  isAvailable: boolean;
  isProcessing: boolean;
  isEmpty: boolean;
  isInvalid: boolean;
  error?: Error;
  data?: T;
};

export function fromPaginate(
  paginate?: PaginateResult<string>
): Statefull<string[]> {
  return paginate?.data.length
    ? has(paginate.data)
    : paginate?.isFetching
    ? processing()
    : paginate?.error
    ? invalid(paginate.error)
    : empty();
}

export function initialized<T>(): Statefull<T> {
  return {
    isAvailable: false,
    isProcessing: false,
    isEmpty: false,
    isInvalid: false
  };
}

export function processing<T>(): Statefull<T> {
  return {
    isAvailable: false,
    isProcessing: true,
    isEmpty: false,
    isInvalid: false
  };
}

export function empty<T>(): Statefull<T> {
  return {
    isAvailable: false,
    isProcessing: false,
    isEmpty: true,
    isInvalid: false
  };
}

export function invalid<T>(error: Error): Statefull<T> {
  return {
    isAvailable: false,
    isProcessing: false,
    isEmpty: false,
    isInvalid: true,
    error
  };
}

export function has<T>(data: T): Statefull<T> {
  return {
    isAvailable: true,
    isProcessing: false,
    isEmpty: false,
    isInvalid: false,
    data
  };
}

// has() の上位互換。引数に undefined を与えた場合 empty() のように振る舞う
export const from = <T>(data: T | undefined): Statefull<T> =>
  data
    ? {
        isAvailable: true,
        isProcessing: false,
        isEmpty: false,
        isInvalid: false,
        data
      }
    : {
        isAvailable: false,
        isProcessing: false,
        isEmpty: true,
        isInvalid: false
      };

const emptyQueue = new WeakSet();

export function isFetchNeeded<T>(data: Statefull<T>): boolean {
  if (data.isAvailable || data.isProcessing || data.isInvalid) {
    // すでに取得済みか、クエリを実行中か、エラーで終了している
    return false;
  }
  if (emptyQueue.has(data)) {
    // 前回の実行から時間がたっていない
    return false;
  }
  // 実行したことをキューに入れて、しばらく差し止める
  emptyQueue.add(data);
  setTimeout(() => {
    emptyQueue.delete(data);
  }, 5000);
  return true;
}

/**
 * そのアイテムが初期化されたばかりかどうか
 * @param {Object} data
 * @returns {Boolean} 初期化されたばかりであれば true
 */
export function isInitialized<T>(data: Statefull<T>): boolean {
  return (
    !data.isAvailable && !data.isEmpty && !data.isProcessing && !data.isInvalid
  );
}
