import { Theme, withTheme } from '@material-ui/core/styles';
import { uniqueId } from 'lodash-es';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { style } from 'typestyle';
import { clearCheckingAtom } from '.';
import { actions } from '../../../../ducks/file';
import ImmutableFile from '../../File/ImmutableFile';
import normalize from '../../File/normalize';
import { useActionDispatcher } from '../../hooks/useActionDispatcher';
import { useSetLocation } from '../../hooks/useSetLocation';
import { Localization } from '../../localization';
import cloneError from '../../utils/cloneError';
import {
  SpeechGrammarList,
  SpeechRecognition
} from '../../utils/SpeechRecognition';
import { detectTags } from './detectTags';
import { Screen } from './Screen';

export interface MonitorProps {
  theme: Theme;
  href: string;
  localization: Localization;
  setLocation: (location?: string) => void;
  globalEvent: any;
  clearChecking: boolean;
  // Injected by withFiles (for JSX)
  files: ImmutableFile[];
  putFile: typeof actions.putFile;
}

interface State {
  error: Error | null;
  port: MessagePort | null;
}

const ConnectionTimeout = 1000;

export const iframeOrigin = 'https://sandbox.hackforplay.xyz';

const getCn = (props: MonitorProps) => ({
  root: style({
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    boxSizing: 'border-box',
    opacity: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    zIndex: props.clearChecking ? 1201 : 300,
    position: 'relative',
    transition: props.theme.transitions.create('all')
  })
});

export function MonitorWrapper(
  props: Omit<
    MonitorProps,
    'files' | 'putFile' | 'clearChecking' | 'setLocation'
  >
) {
  let files = useSelector(state => state.file.files);
  files = files.filter(file => !file.isTrashed);
  const putFile = useActionDispatcher(actions.putFile);
  const clearChecking = useRecoilValue(clearCheckingAtom);
  const setLocation = useSetLocation();

  return (
    <Monitor
      {...props}
      files={files}
      putFile={putFile}
      clearChecking={clearChecking}
      setLocation={setLocation}
    />
  );
}

export default withTheme(MonitorWrapper);

export class Monitor extends React.PureComponent<MonitorProps> {
  state: State = {
    error: null,
    port: null
  };
  private _emit: any;

  onMessage = (event: MessageEvent) => {
    const [port1] = event.ports || [];
    if (!port1) return;
    // オリジンチェック
    if (![iframeOrigin, 'http://localhost:1234'].includes(event.origin)) {
      throw new Error(`Message from ${event.origin} is rejected.`);
    }

    port1.addEventListener('message', this.onPortMessage, {
      passive: true
    });
    port1.start();
    // Close previours connection
    if (this.state.port) {
      this.state.port.removeEventListener('message', this.onPortMessage);
      this.state.port.close();
    }
    this.setState({ port: port1 });

    // Detect entry point script files and send codes
    const htmlFile = this.props.files.find(
      file => file.name === normalize(this.props.href)
    );
    if (!htmlFile) {
      return this.setState({ error: new Error('index.html is not found') });
    }
    const entryPoints = detectTags(htmlFile.text, this.props.files);

    Promise.all(entryPoints).then(value => {
      port1.postMessage({
        query: 'entry',
        value
      });
    });
  };

  onPortMessage = (event: MessageEvent) => {
    const { port } = this.state;
    const reply = (params: any) => {
      params = { id: event.data.id, ...(params || {}) };
      port && port.postMessage(params);
    };

    const { type, data } = event;
    const name = data.query ? `message.${data.query}` : 'message';
    this.props.globalEvent.emit(name, { type, data, reply });
  };

  componentDidMount() {
    if (window.ipcRenderer) {
      this._emit = window.ipcRenderer.emit; // あとで戻せるようオリジナルを保持
      const self = this;
      window.ipcRenderer.emit = (...args: any) => {
        // ipcRenderer.emit をオーバーライドし, 全ての postMessage で送る
        if (self.state && self.state.port) {
          self.state.port.postMessage({
            query: 'ipcRenderer.emit',
            value: JSON.parse(JSON.stringify(args))
          });
        }
        this._emit.apply(this, args);
      };
    }

    // feeles.github.io/sample/#/path/to/index.html
    window.addEventListener('hashchange', this.handleHashChanged);
    if (/^#\//.test(location.hash)) {
      this.handleHashChanged();
    } else {
      // default href で起動
      this.props.setLocation();
    }

    // connect to iframe
    window.addEventListener('message', this.onMessage, { passive: true });

    const { globalEvent } = this.props;
    const on = globalEvent.on.bind(globalEvent);
    on('postMessage', this.handlePostMessage);
    on('message.fetch', this.handleFetch);
    on('message.resolve', this.handleResolve);
    on('message.fetchDataURL', this.handleFetchDataURL);
    on('message.fetchText', this.handleFetchText);
    on('message.fetchArrayBuffer', this.handleFetchArrayBuffer);
    on('message.saveAs', this.handleSaveAs);
    on('message.replace', this.handleReplace);
    on('message.error', this.handleError);
    on('message.ipcRenderer.*', this.handleIpcRenderer);
    on('message.api.SpeechRecognition', this.handleSpeechRecognition);
    on('message.openWindow', this.handleOpenWindow);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.handleHashChanged);
    if (window.ipcRenderer) {
      // オリジナルの参照を戻す. Monitor が複数 mount されることはない(はず)
      window.ipcRenderer.emit = this._emit;
    }

    // stop connecting to iframe
    window.removeEventListener('message', this.onMessage);
  }

  handlePostMessage = (value: any) => {
    // emitAsync('postMessage', value)
    const { port } = this.state;
    if (!port) return;
    // reply を receive するための id
    value = { id: uniqueId(), ...value };
    return new Promise(resolve => {
      // catch reply message (once)
      const task = (event: MessageEvent) => {
        if (!event.data || event.data.id !== value.id) return;
        if (port) port.removeEventListener('message', task);
        resolve(event.data);
      };
      port.addEventListener('message', task);
      // post message to frame
      port.postMessage(value);
    });
  };

  handleFetch = async ({ data, reply }: any) => {
    console.warn(
      `feeles.fetch is now deprecated because it cause CORS error. Use feeles.fetchText instead. file: ${data.value}`
    );
    const filePath = normalize(data.value);
    try {
      const file = this.props.files.find(file => file.name === filePath);
      if (file) {
        reply({ value: file.blob });
      } else if (data.value.indexOf('http') === 0) {
        const response = await fetch(data.value);
        const blob = await response.blob();
        reply({ value: blob });
      } else {
        reply({ error: true });
      }
    } catch (error) {
      reply(cloneError(error));
    }
  };

  handleResolve = async ({ data, reply }: any) => {
    const moduleId = normalize(data.value);
    const indexjs = normalize(data.value, 'index.js');
    if (!moduleId && !indexjs) {
      throw new Error(`Invalid module id: ${data.value}`);
    }
    const file =
      this.props.files.find(file => file.moduleName === moduleId) ||
      this.props.files.find(file => file.moduleName === indexjs);
    if (file) {
      try {
        reply({ value: await file.transpiled });
      } catch (error) {
        reply(cloneError(error));
        this.setState({ error });
      }
    } else if (data.value.indexOf('http') === 0) {
      try {
        const response = await fetch(data.value);
        if (!response.ok) {
          return reply({ error: { message: await response.text() } });
        }
        const text = await response.text();
        reply({ value: text });
      } catch (error) {
        reply(cloneError(error));
      }
    } else {
      reply({
        error: {
          message: `Resolve failed because file not found: ${moduleId} or ${indexjs}`
        }
      });
    }
  };

  handleFetchDataURL = async ({ data, reply }: any) => {
    if (data.value.indexOf('http') === 0) {
      try {
        const response = await fetch(data.value);
        const blob = await response.blob();
        const fileReader = new FileReader();
        fileReader.onload = () => {
          reply({ value: fileReader.result });
        };
        fileReader.readAsDataURL(blob);
      } catch (error) {
        reply(cloneError(error));
      }
    } else {
      const filePath = normalize(data.value);
      const file = this.props.files.find(file => file.name === filePath);
      if (file) {
        reply({
          value: await file.toDataURL()
        });
        return;
      }
      reply({ error: true });
    }
  };

  handleFetchText = async ({ data, reply }: any) => {
    if (data.value.indexOf('http') === 0) {
      try {
        const response = await fetch(data.value);
        if (response.ok) {
          const text = await response.text();
          reply({ value: text });
        } else {
          reply({ error: true });
        }
      } catch (error) {
        reply(cloneError(error));
      }
    } else {
      const filePath = normalize(data.value);
      const file = this.props.files.find(file => file.name === filePath);
      if (file) {
        reply({ value: file.text });
        return;
      }
      reply({ error: true });
    }
  };

  handleFetchArrayBuffer = async ({ data, reply }: any) => {
    if (data.value.indexOf('http') === 0) {
      try {
        const response = await fetch(data.value);
        if (response.ok) {
          const buffer = await response.arrayBuffer();
          reply({ value: buffer });
        } else {
          reply({ error: true });
        }
      } catch (error) {
        reply(cloneError(error));
      }
    } else {
      const filePath = normalize(data.value);
      const file = this.props.files.find(file => file.name === filePath);
      if (file && file.blob) {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          reply({ value: fileReader.result });
        };
        fileReader.readAsArrayBuffer(file.blob);
        return;
      }
      reply({ error: true });
    }
  };

  handleSaveAs = async ({ data, reply }: any) => {
    const [blob, name] = data.value;
    blob.name = name;
    const file = await ImmutableFile.fromFile(blob);
    this.props.putFile({ file, isUserAction: true });
    reply();
  };

  handleReplace = ({ data }: any) => {
    location.hash = data.value.replace(/^\/*/, '/');
  };

  handleError = ({ data }: any) => {
    if (!this.state.error) {
      const error: any = new Error(data.value.message || '');
      for (const key of Object.keys(data.value)) {
        error[key] = data.value[key];
      }
      this.setState({
        error
      });
    }
  };

  handleIpcRenderer = ({ data }: any) => {
    if (window.ipcRenderer) {
      window.ipcRenderer.sendToHost(...Object.values(data.value));
    } else {
      console.warn('window.ipcRenderer is not defined');
    }
  };

  handleOpenWindow = ({ data: { value } }: any) => {
    // value.url が相対パスかどうかを調べる
    const a = document.createElement('a');
    a.href = value.url;
    if (a.host === location.host) {
      window.open(value.url, value.target, value.features);
    } else {
      throw new Error(`Cannot open ${value.url}`);
    }
  };

  handleSpeechRecognition = ({ data, reply }: any) => {
    const recognition: any = new SpeechRecognition();
    for (const prop of [
      'lang',
      'continuous',
      'interimResults',
      'maxAlternatives',
      'serviceURI'
    ]) {
      if (data.value[prop] !== undefined) {
        recognition[prop] = data.value[prop];
      }
    }
    if (Array.isArray(data.value.grammars)) {
      recognition.grammars = new SpeechGrammarList();
      for (const { src, weight } of data.value.grammars) {
        recognition.grammars.addFromString(src, weight);
      }
    }
    recognition.onresult = (event: any) => {
      const results = [];
      for (const items of event.results) {
        const result: any = [];
        result.isFinal = items.isFinal;
        for (const { confidence, transcript } of items) {
          result.push({ confidence, transcript });
        }
        results.push(result);
      }
      reply({
        type: 'result',
        event: {
          results
        }
      });
    };
    recognition.onerror = (event: any) => {
      reply({
        type: 'error',
        event: {
          error: cloneError(event.error)
        }
      });
    };
    [
      'audioend',
      'audiostart',
      'end',
      'nomatch',
      'soundend',
      'soundstart',
      'speechend',
      'speechstart',
      'start'
    ].forEach(type => {
      recognition[`on${type}`] = () => {
        reply({ type });
      };
    });
    recognition.start();
  };

  handleHashChanged = () => {
    if (/^#\//.test(location.hash)) {
      const href = location.hash.substr(2);
      this.props.setLocation(href);
    }
  };

  render() {
    const dcn = getCn(this.props);
    const { error } = this.state;

    return (
      <div className={dcn.root}>
        <Screen
          error={error}
          localization={this.props.localization}
          setLocation={this.props.setLocation}
        />
      </div>
    );
  }
}

function notFound() {
  const text = `<!DOCTYPE html>
<html>
    <head>
        <meta charset="utf-8">
        <title>404 Not Found</title>
    </head>
    <body style="background-color: white;">
      File Not Found
    </body>
</html>`;
  return new ImmutableFile({
    name: '404.html',
    type: 'text/html',
    text
  });
}
