import { combineEpics } from 'redux-observable';
import { NEVER, Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import firebase from '../settings/firebase';
import { actions } from './actions';
import { reducerWithImmer } from './reducer-with-immer';
import { StoreState } from './type';
import { ofAction } from './typescript-fsa-redux-observable';

const auth = firebase.auth;

// 最終的な Root Reducere の中で、ここで管理している State が格納される名前
export const storeName = 'auth';

export type State = {
  initialized: boolean;
  userInfo?: firebase.UserInfo;
};

const initialState: State = {
  initialized: false
};

// Root Reducer

export default reducerWithImmer(initialState)
  .case(actions.auth.signedIn, (draft, payload) => {
    draft.initialized = true;
    draft.userInfo = payload;
  })
  .case(actions.auth.signedOut, draft => {
    draft.initialized = true;
  })
  .reset(actions.auth.signOut)
  .toReducer();

export const epics = combineEpics(
  action$ =>
    action$.pipe(
      ofAction(actions.auth.signInWithGoogle),
      tap(() => signInWithGoogle()),
      mergeMap(() => NEVER) // 同じ Action が何度も呼ばれる？のを防ぐ
    ),

  action$ =>
    action$.pipe(
      ofAction(actions.auth.signOut),
      tap(() => firebase.auth().signOut()),
      mergeMap(() => NEVER) // 同じ Action が何度も呼ばれる？のを防ぐ
    ),

  (action$, state$) =>
    action$.pipe(
      ofAction(actions.auth.signedIn),
      mergeMap(action =>
        new Observable<DS>(observer =>
          firebase
            .firestore()
            .collection('users')
            .doc(action.payload.uid)
            .onSnapshot(observer)
        ).pipe(
          map(snapshot => {
            if (snapshot) {
              const user = snapshot.data() as IUserDocument;
              if (user) {
                // ユーザー情報をストアに格納
                const { uid } = action.payload;
                return actions.user.set({ uid, user });
              }
            }
            // 存在しない UID
            const { uid } = action.payload;
            return actions.user.set({ uid });
          })
        )
      )
    )
);

async function signInWithGoogle() {
  const provider = new auth.GoogleAuthProvider();
  auth().useDeviceLanguage();

  // アカウントが１つしかなくても必ず選択画面を表示させる
  provider.setCustomParameters({
    prompt: 'select_account'
  });

  if (window.innerWidth > 600) {
    // 画面が大きければポップアップ
    await auth().signInWithPopup(provider);
  } else {
    // 画面が小さければリダイレクト
    auth().signInWithRedirect(provider);
  }
}

export function isAuthUser(state: StoreState, uid: string) {
  const {
    auth: { userInfo }
  } = state;
  return userInfo && userInfo.uid === uid;
}

/**
 * 認証ユーザーのユーザー情報を取得する
 * @param store 現在の Redux Store の State スナップショット
 */
export function getAuthUser(store: StoreState) {
  const uid = store.auth.userInfo?.uid;
  return uid ? store.user.byUid[uid]?.data : undefined;
}

export function getState(store: StoreState): State {
  return store[storeName];
}
