import { ExpandLess, ExpandMore } from '@material-ui/icons';
import MarkdownIt from 'markdown-it';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { canUseErrorFollowAI } from '../../../../ducks/user';
import { analytics } from '../../../../utils/analytics';

const md = new MarkdownIt();

interface ErrorFollowAIProps {
  code: string;
  error: Error;
}

async function fetchFollowMessage(
  code: string,
  error: string,
  callback: (text: string) => void
) {
  analytics.explainError();

  const endpoint = process.env.REACT_APP_CLOUDRUN_ENDPOINT;
  const url = `${endpoint}/openAI?`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      action: 'follow_error_message',
      error,
      code
    })
  });
  const reader = response.body?.getReader();
  if (!reader) return;

  let decoder = new TextDecoder();
  while (true) {
    const { done, value } = await reader.read();
    if (done) break;
    if (!value) continue;
    const text = decoder.decode(value);
    callback(text);
  }
}

/** コードとエラーメッセージをもとに、GPTにフォローさせる */
export function ErrorFollowAI(props: ErrorFollowAIProps) {
  const isEnabled = useSelector(canUseErrorFollowAI);

  const [expanded, setExpanded] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const error = formatError(props.error);
  const code = props.code;
  const [generated, setGenerated] = useState('');
  const html = md.render(generated);

  useEffect(() => {
    if (!error) return;
    setExpanded(true);
    if (!isEnabled) return;

    let cancelled = false;

    fetchFollowMessage(code, error, chunk => {
      if (cancelled) return;
      setGenerated(t => t + chunk);
    });
    return () => {
      cancelled = true;
    };
  }, [error, isEnabled]);

  // 下にはりつくスクロール
  useEffect(() => {
    const element = scrollRef.current;
    element?.scrollBy(0, 1000);
  }, [generated]);

  return (
    <div className="error-follow-ai" ref={scrollRef}>
      <div className="menu-bar">
        <span>エラーが発生しました</span>
        <span className="blank"></span>
        <button onClick={() => setExpanded(b => !b)}>
          {expanded ? <ExpandMore /> : <ExpandLess />}
          {expanded ? 'とじる' : 'ひらく'}
        </button>
      </div>
      {expanded ? (
        <>
          <pre className="error-message">{error}</pre>
          <div
            className="ai-generated-message"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </>
      ) : null}
    </div>
  );
}

function formatError(error: Error) {
  // エラーメッセージから理由に関する部分を取り除く
  //   SyntaxErrorの理由は人間にとって非直観的なため
  // e.g.
  // /game.js: 'Const declarations' require an initialization value. (6:13)
  // ↓
  // /game.js:(6:13)
  const messageWithoutReason = error.message.replace(/([^:]+)\:[^(\n]*/, '$1 ');

  return error.name + ': ' + messageWithoutReason;
}
