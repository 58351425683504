import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { classes, style } from 'typestyle';
import { actions } from '../ducks/actions';
import theme from '../settings/theme';
import { DialogClose } from './DialogClose';
import { cn as workListCn } from './WorkList';

const cn = {
  root: style({
    backgroundColor: 'red',
    maxWidth: '100vw'
  }),
  content: style({
    maxWidth: '80vw'
  }),
  wrapper: style({
    display: 'flex',
    flexWrap: 'wrap'
  }),
  item: style({
    height: 160
  }),
  border: style({
    margin: 2,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: 'transparent',
    $nest: {
      '&:hover': {
        borderColor: theme.palette.primary.main
      }
    }
  }),
  img: style({
    height: '100%'
  })
};

export interface ThumbnailDialogProps {
  open: boolean;
  src?: string;
  onClose: () => void;
}

export function ThumbnailDialog(props: ThumbnailDialogProps) {
  const dispatch = useDispatch();
  const thumbnails = useSelector(state => state.make.thumbnails);

  const handleSetThumbnail = React.useCallback(
    (dataUrl: string) => () => {
      props.onClose();
      dispatch(actions.make.decideThumbnail({ dataUrl }));
    },
    [props.onClose]
  );

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md" fullWidth>
      <DialogTitle>カバー画像をセットしよう</DialogTitle>
      <DialogContent className={cn.content}>
        <div className={cn.wrapper}>
          {thumbnails.length > 0 ? (
            thumbnails.map((src, i) => (
              <div
                key={i}
                className={classes(cn.border)}
                onClick={handleSetThumbnail(src)}
              >
                <div className={classes(workListCn.thumbnail, cn.item)}>
                  <img src={src} alt="サムネイル" className={cn.img} />
                </div>
              </div>
            ))
          ) : (
            <div>
              <Typography variant="h2" color="textSecondary" align="center">
                画像がありません 😟
              </Typography>
              <Typography variant="body1" color="textSecondary" align="center">
                自動的に撮影されるため、ゲームをプレイすると画像が表示されます
              </Typography>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogContent>
        <Typography variant="subtitle1">現在のカバー画像</Typography>
        {props.src ? (
          // 現在のサムネイル
          <div>
            <div className={classes(workListCn.thumbnail, cn.item)}>
              <img src={props.src} alt="今のサムネイル" />
            </div>
          </div>
        ) : (
          <Typography variant="body1" color="textSecondary">
            まだ設定されていません
          </Typography>
        )}
      </DialogContent>
      <DialogClose onClick={props.onClose} />
    </Dialog>
  );
}
