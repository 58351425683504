import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import type { TransitionProps } from '@material-ui/core/transitions/transition';
import * as React from 'react';
import { Subject } from 'rxjs';
import { style } from 'typestyle';

export interface IConfirm {
  title: string;
  ok: string;
  cancel: string;
  resolve: (ok: boolean) => void;
}

/**
 * 現在表示している、あるいは保留されている内容
 */
const confirm$ = new Subject<IConfirm>();

/**
 * ユーザーに簡単な確認を求めるためのダイアログを表示する
 * OK なら Promise<true>, そうでなければ Promise<false> を返す
 */
export function useConfirm() {
  return React.useCallback((title: string, ok: string, cancel: string) => {
    return new Promise<boolean>(resolve => {
      confirm$.next({
        title,
        ok,
        cancel,
        resolve
      });
    });
  }, []);
}

const cn = {
  pre: style({
    whiteSpace: 'pre-wrap',
    maxHeight: '50vh',
    overflowY: 'scroll'
  })
};

/**
 * ユーザーに確認を求めるダイアログ
 * このコンポーネントはアプリのなかで１つだけ存在する
 */
export function ConfirmManager() {
  // 現在表示すべき内容
  const [opened, setOpened] = React.useState(false);
  const [showing, setShowing] = React.useState<IConfirm>();
  const close = React.useCallback(() => setOpened(false), []);

  // 新しい内容が追加されたら更新する
  const stackRef = React.useRef<(IConfirm | undefined)[]>([]);
  const update = React.useCallback(() => {
    // スタックの最も上にある１件を表示する。なければダイアログを閉じる
    const next = stackRef.current[0];
    setShowing(next);
    setOpened(Boolean(next));
  }, []);
  React.useEffect(() => {
    const subscription = confirm$.subscribe(
      value => {
        stackRef.current.push(value);
        update();
      },
      error => {
        console.error(error);
        close();
      },
      close
    );
    return () => subscription.unsubscribe();
  }, []);

  // 決定またはキャンセル
  const ok = React.useCallback(() => {
    stackRef.current[0]?.resolve(true); // Promise.resolve(true)
    stackRef.current.shift(); // 今表示している内容を取り除く
    close(); // 一旦ダイアログを閉じて、もし次があれば表示
  }, []);
  const cancel = React.useCallback(() => {
    stackRef.current[0]?.resolve(false); // Promise.resolve(false)
    stackRef.current.shift(); // 今表示している内容を取り除く
    close(); // 一旦ダイアログを閉じて、もし次があれば表示
  }, []);

  // ダイアログを閉じた時に次の質問があれば改めて表示
  const { current: transition } = React.useRef<TransitionProps>({
    onExited: update
  });

  return (
    <Dialog open={opened} TransitionProps={transition}>
      <DialogTitle className={cn.pre}>
        {showing?.title || 'Confirm'}
      </DialogTitle>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={ok}>
          {showing?.ok || 'OK'}
        </Button>
        <Button variant="text" onClick={cancel}>
          {showing?.cancel || 'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
