import Alert from '@material-ui/lab/Alert';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Fields } from '../pages/Fes';
import { getContentfulClient } from '../pages/getContentfulClient';
import { LoadingPage } from './LoadingPage';

/**
 * 直近のフェスの情報を取得して History API でfリダイレクトする
 */
export function Fes() {
  const history = useHistory();
  const [errors, setErrors] = React.useState<Error[]>([]);
  React.useEffect(() => {
    getContentfulClient()
      .getEntries<Fields>({
        content_type: 'fes',
        'fields.test[ne]': true,
        order: '-sys.createdAt',
        limit: 1
      })
      .then(entries => {
        if (entries.errors) {
          return setErrors(entries.errors);
        }
        const [entry] = entries.items;
        if (!entry) {
          throw 'Cannot load entries';
        }
        history.replace(`/pages/fes/${entry.fields.slug}`);
      })
      .catch(error => {
        setErrors([error]);
      });
  }, []);

  return (
    <>
      {errors.length === 0 ? (
        <LoadingPage />
      ) : (
        errors.map((e, i) => (
          <Alert severity="error" key={i}>
            {e.message}
          </Alert>
        ))
      )}
    </>
  );
}
