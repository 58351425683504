import deepEqual from 'deep-equal';
import { pick } from 'lodash-es';
import { combineEpics } from 'redux-observable';
import { from, interval, NEVER, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap
} from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { endpoint } from '../env';
import firebase from '../settings/firebase';
import { analytics } from '../utils/analytics';
import { filterTruly } from '../utils/filterTruly';
import { idToPath, pathToId } from '../utils/id';
import { timeout } from '../utils/timeout';
import { actions } from './actions';
import { api } from './api';
import { asyncEpic, SKIP } from './asyncEpic';
import { getAuthUser } from './auth';
import { copyFile } from './copyFile';
import { reducerWithImmer } from './reducer-with-immer';
import { requestWithAuth } from './requestWithAuth';
import { StoreState } from './type';
import { ofAction } from './typescript-fsa-redux-observable';
import { eachUser, productionOnly, toBlob } from './utils';

const firestore = firebase.firestore;

// 最終的な Root Reducere の中で、ここで管理している State が格納される名前
export const storeName = 'make';

const metadataKeys: (keyof IWorkDocumentUpdate)[] = [
  'title',
  'description',
  'author',
  'assetStoragePath',
  'thumbnailUrl',
  'disableAutoUpdateThumbnail',
  'visibility',
  'clearChecked'
];

const defaultPrivacy = 'limited'; // ステージ作成直後は限定公開

export type State = {
  /**
   * @deprecated
   * 編集中の id は Component に保持し、
   * Action の payload に id を含めるようにする
   */
  path?: string;
  uid?: string; // 作者の UID
  isOwner: boolean; // ステージの所有者かどうか
  notFound: boolean; // ステージが見つからない
  isLoading: boolean; // ステージを読み込み中
  isFirstSave: boolean; // そのステージにとって初回セーブ時かどうか
  savedCount: number; // 最後に保存できた changedByUser カウンタ
  isUpdating: boolean; // 保存中
  changed: boolean; // 起動時から一度でもファイルが変更されたか
  error: null | Error;
  metadata: IWorkDocumentUpdate; // ローカルで変更された可能性のある metadata
  savedMetadata: IWorkDocumentUpdate; // Firestore に同期された metadata
  thumbnails: Array<string>;
  assetVersion?: string; // キットによって異なるアセットのバージョン
  restrained: boolean; // 所有者以外の改造が許可されていない
  stopAutoSave: boolean; // オートセーブを切るフラグ
  /**
   * @deprecated
   * Slaask を表示するかどうか. いずれ削除する
   * "Be hidden on specific pages" の設定を使う
   */
  slaask?: boolean;
  editor: NonNullable<IUserDocument['editor']>; // ユーザーのエディタ設定
  lastSaved?: firebase.firestore.Timestamp; // 最後に Firestore に保存された時刻
  /**
   * 次にバージョンに追加される情報
   * バージョンの数を減らすため、しばらく保持される
   */
  pendingVersion?: {
    id: string;
    storagePath: string;
    fileSize: number;
  };
};

export const epics = combineEpics(
  // 新しいステージを読み込んで, file を変更できるようにする
  action$ =>
    action$.pipe(
      ofAction(actions.official.fetch.done),
      filter(action => Boolean(action.payload.params.loadAfterFetch)),
      map(action =>
        actions.make.load.started({
          assetVersion: action.payload.result.assetVersion,
          type: 'officials',
          id: action.payload.params.id,
          jsonUrl: action.payload.result.workJsonUrl,
          savedCount: 0
        })
      )
    ),
  action$ =>
    action$.pipe(
      ofAction(actions.work.fetch.done),
      filter(action => Boolean(action.payload.params.loadAfterFetch)),
      map(action => {
        const workData = action.payload.result.work;
        return actions.make.load.started({
          assetVersion: workData?.assetVersion,
          type: 'works',
          id: action.payload.params.id,
          savedCount: workData?.savedCount
        });
      })
    ),
  asyncEpic(actions.make.load, async (action, state) => {
    /**
     * TODO: officials と works で分ける
     */
    let jsonUrl = action.payload.jsonUrl;
    const { id, type } = action.payload;
    if (!jsonUrl) {
      const workData = state.work.works[id]?.data;
      if (!workData) {
        throw new Error(`Document "works/${id}" is not set.`);
      }
      if (!workData.assetStoragePath) {
        throw new Error(`assetStoragePath of "works/${id}" is not set.`);
      }
      // 1. Google Cloud Storage API から直接ダウンロードを試みる
      try {
        const { bucket, fullPath } = firebase
          .storage()
          .ref(workData.assetStoragePath);
        jsonUrl = `https://storage.googleapis.com/${bucket}/${fullPath}`;
        const response = await fetch(jsonUrl);
        const result = await response.json();
        return result; // 成功したらそのまま返して良い
      } catch (error) {
        console.warn(error); // 失敗時は別の方法を用いる
      }
      // 2. Firebase Storage API で Download URL を取得する
      jsonUrl = await firebase
        .storage()
        .ref(workData.assetStoragePath)
        .getDownloadURL();
    }
    if (typeof jsonUrl !== 'string') {
      throw new Error(`Cannot download asset of document "${type}/${id}"`);
    }
    const response = await fetch(jsonUrl);
    const result = await response.json();
    return result;
  }),

  // ファイルの変更を検知して, CHANGE を dispatch する
  (action$, state$) =>
    state$.pipe(
      distinctUntilChanged(
        (a, b) => a.file.changedByUser === b.file.changedByUser
      ),
      map(state => state.make.path),
      filterTruly,
      mergeMap(path => of(actions.make.change({ id: pathToId(path) })))
    ),

  // 1000ms おきに変更を確認して update を走らせる
  (action$, state$) =>
    interval(1000).pipe(
      filter(
        () =>
          canSave(state$.value) &&
          !state$.value.make.stopAutoSave && // 自動セーブが有効になっているか？
          navigator.onLine &&
          !state$.value.make.isUpdating // race condition が発生しないようにする
      ),
      map(() => state$.value.make.path),
      filterTruly,
      map(path => {
        return path.startsWith('officials')
          ? actions.make.add.started({})
          : actions.make.updateFiles.started({ id: pathToId(path) });
      })
    ),

  // 新しく work を作成する
  asyncEpic(actions.make.add, async (action, state) => {
    const { userInfo } = state.auth;
    if (!userInfo) return SKIP;
    analytics.createWork();

    const { assetVersion, metadata } = state.make;
    const { files, changedByUser } = state.file;

    const userDoc = state.user.byUid[userInfo.uid];
    const assetStoragePath = createAssetStoragePath(userInfo.uid); // assetStoragePath は必ず毎回変更する

    const composedFiles = await Promise.all(files.map(f => f.compose()));
    const json = JSON.stringify(composedFiles);
    const file = await new Blob([json], { type: 'application/json' });
    await firebase.storage().ref(assetStoragePath).put(file);

    const doc: IWorkDocument = {
      author: userDoc?.data?.displayName || '_',
      assetVersion,
      savedCount: changedByUser,
      title: metadata.title || '', // 作成前にタイトルをつけることができる
      description: '',
      uid: userInfo.uid,
      visibility: defaultPrivacy,
      viewsNum: 0,
      favsNum: 0,
      clearRate: 0,
      assetStoragePath,
      createdAt: firestore.Timestamp.now(),
      updatedAt: firestore.Timestamp.now()
    };
    if (doc.assetVersion === undefined) {
      delete doc.assetVersion; // fix: https://bit.ly/3iXbaCH
    }
    const ref = await timeout(firestore().collection('works').add(doc), 10000);

    return {
      id: ref.id,
      doc: { ...doc, id: ref.id }, // TODO: id を params で与える
      savedCount: changedByUser,
      fileSize: file.size,
      timestamp: doc.createdAt
    };
  }),

  // 今開いている自分の work を更新する
  asyncEpic(actions.make.updateFiles, async (action, state) => {
    const { userInfo } = state.auth;
    if (!userInfo) return SKIP;
    if (idToPath('works', action.payload.id) !== state.make.path) return SKIP;
    const { path } = state.make;
    if (!path) return SKIP; // type hint

    const { files, changedByUser } = state.file;
    const workId = path.split('/')[1];
    const assetStoragePath = createAssetStoragePath(userInfo.uid, workId);

    const file = await Promise.all(files.map(f => f.compose())).then(
      composedFiles => {
        // プロジェクトを JSON に書き出し
        const json = JSON.stringify(composedFiles);
        return new Blob([json], { type: 'application/json' });
      }
    );
    await firebase.storage().ref(assetStoragePath).put(file);
    const updatedAt = firestore.Timestamp.now();
    await timeout(
      firebase.firestore().doc(path).update({
        // TODO: path ではなく id で指定する
        assetStoragePath,
        savedCount: changedByUser,
        updatedAt
      }),
      100000
    );

    return {
      savedCount: changedByUser,
      assetStoragePath,
      fileSize: file.size,
      timestamp: updatedAt
    };
  }),

  // Version を追加する
  // 1. ステージが作られた時に Version を追加する
  action$ =>
    action$.pipe(
      filter(actions.make.add.done.match),
      map(
        ({
          payload: {
            result: { id, fileSize, doc }
          }
        }) =>
          actions.make.commitVersion.started({
            id,
            fileSize,
            storagePath: doc.assetStoragePath
          })
      )
    ),
  // 2. ステージが更新された時に Version を追加する
  (action$, state$) =>
    state$.pipe(
      map(state => state.make.pendingVersion),
      distinctUntilChanged(), // 最後に保存されてから、
      debounceTime(10 * 1000), // 10sec の間、保存されなければ、
      filterTruly, // (リセットを可能にするためのチェック)
      map(payload => actions.make.commitVersion.started(payload)) // 新たなバージョンとして保存する
    ),
  // Work.Version ドキュメントを追加
  asyncEpic(actions.make.commitVersion, async (action, state) => {
    const { id, storagePath, fileSize } = action.payload;

    const data: IWorkVersion = {
      createdAt: firebase.firestore.Timestamp.now(),
      storagePath,
      fileSize
    };

    // metadata にあるサムネイルは古いかも知れないため
    // 最後に自動撮影されたサムネイルをアップロードする
    // (public になっている場合は更新されない)
    const [dataUrl] = state.make.thumbnails;
    if (dataUrl) {
      const blob = toBlob(dataUrl);
      const result = await requestWithAuth(
        endpoint + '/uploadImage',
        'POST',
        blob
      );
      data.thumbnailUrl = result?.url;
    }

    const ref = await firestore()
      .collection('works')
      .doc(id)
      .collection('versions')
      .withConverter(convertToWorkVersion)
      .add(data);

    return { id: ref.id, data };
  }),

  // assetStoragePath, thumbnailUrl 以外のフィールドを更新する
  (action$, state$) =>
    interval(1000).pipe(
      filter(
        () =>
          state$.value.make.isOwner &&
          !state$.value.make.stopAutoSave &&
          navigator.onLine &&
          state$.value.file.initialized && // https://bit.ly/3dDxy1B
          !state$.value.make.isUpdating // race condition が発生しないようにする
      ),
      map(() => state$.value.make.path),
      filterTruly,
      filter(
        () =>
          !deepEqual(
            state$.value.make.metadata,
            state$.value.make.savedMetadata
          )
      ),
      map(path =>
        path.startsWith('officials')
          ? actions.make.add.started({})
          : actions.make.updateMetadata.started({})
      )
    ),
  eachUser((userInfo, action$, state$) =>
    action$.pipe(
      ofAction(actions.make.updateMetadata.started),
      mergeMap(action => {
        const { path, metadata } = state$.value.make;
        const params = action.payload;
        if (!path) return NEVER; // type hint

        const timestamp = firestore.Timestamp.now();
        const updateData: firebase.firestore.UpdateData = {
          ...metadata,
          updatedAt: timestamp
        };

        // 公開された時, publishedAt がなければ現在のタイムスタンプにする
        if (metadata.visibility === 'public') {
          const work = state$.value.work.works[pathToId(path)];
          const hasPublished = Boolean(
            work && work.data && work.data.publishedAt
          );
          if (!hasPublished) {
            updateData.publishedAt = timestamp;
            analytics.publishWork();
          }
        }

        return from(firebase.firestore().doc(path).update(updateData)).pipe(
          map(() =>
            actions.make.updateMetadata.done({
              params,
              result: { metadata, timestamp }
            })
          ),
          catchError(error =>
            of(actions.make.updateMetadata.failed({ params, error }))
          )
        );
      })
    )
  ),

  // Auto update thumbnail of current work
  eachUser((userInfo, action$, store$) =>
    action$.pipe(
      productionOnly,
      ofAction(actions.make.thumbnail),
      map(action => {
        const { path, isOwner } = store$.value.make;
        const [collection, workId] = path ? path.split('/') : ['', ''];
        const dataUrl =
          typeof action.payload === 'string'
            ? action.payload
            : action.payload.dataUrl;
        return { dataUrl, collection, workId, isOwner };
      }),
      filter(
        action =>
          action.collection === 'works' &&
          Boolean(action.workId) &&
          action.isOwner &&
          // この dataUrl が、今ローカルにあるすべての thumbnails の中で
          // 最も情報量が大きかった時にだけ、自動更新を行う
          // => 真っ暗な画面や追加したばかりのマップになるのを防ぐ
          action.dataUrl.length >=
            Math.max(...store$.value.make.thumbnails.map(s => s.length))
      ),
      filter(() => !store$.value.make.metadata.disableAutoUpdateThumbnail),
      filter(() => navigator.onLine),
      map(action =>
        actions.make.updateThumbnail.started({
          blob: toBlob(action.dataUrl),
          workId: action.workId
        })
      )
    )
  ),
  api(
    actions.make.updateThumbnail,
    endpoint + '/uploadImage',
    'POST',
    payload => payload.blob
  ),
  eachUser((userInfo, action$) =>
    action$.pipe(
      ofAction(actions.make.updateThumbnail.done),
      mergeMap(({ payload }) => {
        const { url } = payload.result;
        if (typeof url === 'string') {
          firestore().collection('works').doc(payload.params.workId).update({
            thumbnailUrl: url
          });
        }
        // TODO: 例外処理
        return NEVER;
      })
    )
  ),

  (action$, store$) =>
    action$.pipe(
      ofAction(actions.make.decideThumbnail),
      map(action => {
        const { path } = store$.value.make;
        const workId = path ? path.split('/')[1] : '';
        return { ...action, workId };
      }),
      filter(action => Boolean(action.workId)),
      map(action =>
        actions.make.updateThumbnail.started({
          blob: toBlob(action.payload.dataUrl),
          workId: action.workId
        })
      )
    ),

  // ステージをコピーする
  asyncEpic(actions.make.copy, async (action, state) => {
    const uid = state.auth.userInfo?.uid;
    if (!uid) {
      return SKIP;
    }
    // 現在の work の状態を保持
    const { id } = action.payload;
    const workData = state.work.works[id]?.data;
    if (!workData) {
      throw new Error(`Work "${id}" is not found`);
    }
    const origin = workData?.assetStoragePath;
    if (!origin) {
      throw new Error(`assetStoragePath of "${id}" is not set`);
    }
    // 新しい work を追加
    const assetStoragePath = `users/${uid}/projects/${uuid()}.json`;
    await copyFile(origin, assetStoragePath); // コピーが終わるまで作らない
    const ref = await firestore()
      .collection('works')
      .add({
        ...pick(workData, [
          'uid',
          'assetVersion',
          'thumbnailUrl',
          'author',
          'clearChecked'
        ]),
        visibility: defaultPrivacy,
        title: 'Copy: ' + workData.title,
        assetStoragePath,
        favsNum: 0,
        viewsNum: 0,
        savedCount: 0,
        createdAt: firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.FieldValue.serverTimestamp()
      });

    // 過去 100 件までのバージョン履歴も複製する
    const versions = await firestore()
      .collection('works')
      .doc(id)
      .collection('versions')
      .orderBy('createdAt', 'desc')
      .limit(100)
      .get();
    const batch = firestore().batch();
    for (const snapshot of versions.docs) {
      batch.set(ref.collection('versions').doc(snapshot.id), snapshot.data()); // rollbackId の整合性を保つために id も同一でなければならない
    }
    await batch.commit();
  }),

  // エディタ設定を更新する
  asyncEpic(actions.make.updateEditor, async (action, state) => {
    const authUser = getAuthUser(state);
    if (!authUser) return SKIP;
    // 現時点のエディタ設定は reducer で既にセットされている
    await firestore()
      .collection('users')
      .doc(authUser.uid)
      .update({ editor: state.make.editor });
  })
);

const initialState: State = {
  isOwner: false, // ステージの所有者かどうか
  notFound: false, // ステージが見つからない
  isLoading: false, // ステージを読み込み中
  isFirstSave: false, // そのステージにとって初回セーブ時(ドキュメントを作成する必要がある)かどうか
  savedCount: 0,
  isUpdating: false,
  changed: false,
  error: null,
  metadata: {},
  savedMetadata: {},
  thumbnails: [],
  restrained: false,
  stopAutoSave: false,
  editor: {}
};

// Root Reducer
export default reducerWithImmer(initialState)
  .case(actions.official.fetch.started, (draft, params) => {
    if (!params.loadAfterFetch) return;
    draft.path = idToPath('officials', params.id);
    draft.isLoading = true;
  })
  .case(actions.official.fetch.done, (draft, { params, result }) => {
    if (!params.loadAfterFetch) return;
    if (draft.path !== idToPath('officials', params.id)) return;
    // これから load される official work の情報をセット
    draft.path = idToPath('officials', params.id);
    draft.isOwner = result.replayable;
    draft.savedCount = 0;
  })
  .case(actions.official.fetch.failed, (draft, { params, error }) => {
    if (!params.loadAfterFetch) return;
    if (draft.path !== idToPath('officials', params.id)) return;
    draft.isLoading = false;
    draft.notFound = true;
    draft.error = error;
  })
  .case(actions.work.fetch.started, (draft, params) => {
    if (!params.loadAfterFetch) return;
    draft.path = idToPath('works', params.id);
    draft.isLoading = true;
  })
  .case(actions.work.fetch.done, (draft, { params, result }) => {
    if (!params.loadAfterFetch) return;
    if (draft.path !== idToPath('works', params.id)) return;
    // これから load される work の情報をセット
    const { work, userInfo } = result;
    if (work) {
      draft.isOwner = Boolean(userInfo && userInfo.uid === work.uid);
      draft.uid = work.uid;
      draft.metadata = pick(work, metadataKeys);
      draft.savedMetadata = draft.metadata;
      draft.restrained = Boolean(work.restrained);
      draft.savedCount = work.savedCount || 0;
      if (work.updatedAt) {
        draft.lastSaved = work.updatedAt;
      }
    } else {
      draft.notFound = true;
    }
  })
  .case(actions.work.fetch.failed, (draft, { params, error }) => {
    if (!params.loadAfterFetch) return;
    if (draft.path !== idToPath('works', params.id)) return;
    draft.isLoading = false;
    draft.notFound = true;
    draft.error = error;
  })
  .case(actions.make.load.started, (draft, payload) => {
    draft.assetVersion = payload.assetVersion;
  })
  .case(actions.make.load.done, (draft, { params }) => {
    if (draft.path !== idToPath(params.type, params.id)) return; // 別の load が始まっていた
    draft.isLoading = false;
  })
  .case(actions.make.load.failed, (draft, { error }) => {
    draft.isLoading = false;
    draft.error = error;
  })
  .case(actions.make.change, draft => {
    draft.changed = true;
  })
  .case(actions.make.add.started, draft => {
    draft.isUpdating = true;
  })
  .case(actions.make.add.done, (draft, { result }) => {
    draft.isUpdating = false;
    draft.path = `works/${result.id}`;
    draft.isFirstSave = true;
    draft.savedCount = result.savedCount;
    draft.metadata = pick(result.doc, metadataKeys);
    draft.savedMetadata = draft.metadata;
    draft.lastSaved = result.timestamp;
  })
  .case(actions.make.add.failed, (draft, { error }) => {
    draft.isUpdating = false;
    draft.error = error;
  })
  .case(actions.make.updateFiles.started, draft => {
    draft.isUpdating = true;
  })
  .case(actions.make.updateFiles.done, (draft, { params, result }) => {
    draft.isUpdating = false;
    draft.savedCount = result.savedCount;
    draft.lastSaved = result.timestamp;
    draft.metadata.assetStoragePath = result.assetStoragePath;

    // 新たなバージョンとして保存できるよう保持しておく
    draft.pendingVersion = {
      id: params.id,
      fileSize: result.fileSize,
      storagePath: result.assetStoragePath
    };
  })
  .case(actions.make.updateFiles.failed, (draft, { error }) => {
    draft.isUpdating = false;
    draft.error = error;
  })
  .case(actions.make.metadata, (draft, metadata) => {
    Object.assign(draft.metadata, metadata);
  })
  .case(actions.make.updateMetadata.started, draft => {
    draft.isUpdating = true;
  })
  .case(actions.make.updateMetadata.done, (draft, { result }) => {
    draft.isUpdating = false;
    draft.savedMetadata = result.metadata;
    draft.lastSaved = result.timestamp;
  })
  .case(actions.make.updateMetadata.failed, (draft, { error }) => {
    draft.isUpdating = false;
    draft.error = error;
  })
  .case(actions.make.thumbnail, (draft, payload) => {
    draft.thumbnails.splice(4);
    draft.thumbnails.unshift(
      typeof payload === 'string' ? payload : payload.dataUrl
    );
  })
  .case(actions.make.decideThumbnail, draft => {
    draft.metadata.disableAutoUpdateThumbnail = true;
  })
  .case(actions.make.updateThumbnail.done, (draft, payload) => {
    draft.metadata.thumbnailUrl = payload.result.url;
  })
  .case(actions.make.stopAutoSave, (draft, payload) => {
    draft.stopAutoSave = payload;
  })
  .case(actions.auth.signedIn, (draft, userInfo) => {
    // ユーザー作成ステージであれば、サインイン時に isOwner を更新する
    // そうでない（公式ステージ）であれば、replayable フラグによって設定されているので、更新しない
    if (draft.path?.startsWith('works')) {
      draft.isOwner = Boolean(userInfo.uid === draft.uid);
    }
  })
  .case(actions.make.updateEditor.started, (draft, payload) => {
    Object.assign(draft.editor, payload || {});
  })
  .case(actions.make.commitVersion.started, draft => {
    draft.pendingVersion = undefined;
  })
  .case(actions.user.setAuthUser, (draft, payload) => {
    Object.assign(draft.editor, payload.editor || {});
  })
  .case(actions.auth.signOut, draft => {
    draft.isOwner = false;
  })
  .reset(actions.make.trash)
  .toReducer();

export function canSave(state: StoreState) {
  const {
    make: { path, savedCount, isOwner },
    file: { initialized, changedByUser }
  } = state;

  return (
    !!path && // 保存すべきステージがある
    savedCount !== changedByUser && // 未セーブの状態がある
    isOwner && // このステージの所有者である
    initialized // ファイルが存在する
  );
}

export function canPublish(state: StoreState) {
  const {
    make: { path, isUpdating, isOwner, metadata }
  } = state;

  return !!path && !isUpdating && isOwner && !!metadata.assetStoragePath;
}

export function canRemove(state: StoreState) {
  const {
    make: { path, isOwner }
  } = state;
  return !!path && !path.startsWith('officials') && isOwner;
}

/**
 * ハッシュするのをやめて, 一意な id を , 区切りで連結した文字列を返す
 * @param {ImmutableFile[]} files
 */
export function hashFiles(files: ImmutableFile[]) {
  const hash = files
    .map(f => f.id)
    .sort()
    .join(',');

  return hash;
}

export function getState(store: StoreState): State {
  return store[storeName];
}

export const convertToWorkVersion: firebase.firestore.FirestoreDataConverter<IWorkVersion> =
  {
    fromFirestore: snapshot => snapshot.data() as IWorkVersion,
    toFirestore: (data: IWorkVersion) => data
  };

/**
 * work の assetStoragePath を生成する
 * 更新時のキャッシュを回避するために、毎回ユニークなファイル名を指定する
 * 本当は workId は必要ないのだが、無駄なファイルを消せるように情報を残しておく
 * 初回は workId がないので、その場合は空文字を与えても良いことにする
 */
export function createAssetStoragePath(uid: string, workId = '') {
  if (workId.includes('/')) {
    console.error('createAssetStoragePath: workId cannot include "/"');
    workId.replace(/\//g, '');
  }
  return `users/${uid}/projects/_work_${workId}_${uuid()}.json`;
}
