import { last } from 'lodash-es';
import moment from 'moment';
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import { distinct, filter, first, map, mergeMap, tap } from 'rxjs/operators';
import firebase from '../settings/firebase';
import { idToPath, pathToId } from '../utils/id';
import { merge } from '../utils/merge';
import { actions, PaginateResult } from './actions';
import { jsonApi } from './api';
import { asyncEpic, SKIP } from './asyncEpic';
import * as helpers from './helpers';
import { Statefull } from './helpers';
import { reducerWithImmer } from './reducer-with-immer';
import { eachUser } from './utils';

const firestore = firebase.firestore;

// 最終的な Root Reducere の中で、ここで管理している State が格納される名前
export const storeName = 'work';

export const defaultTitle = ''; // タイトルをつけずに公開した場合の名前
const pageSize = 12;

const endpoint = process.env.REACT_APP_API_ENDPOINT;
if (!endpoint) {
  throw new Error('REACT_APP_API_ENDPOINT is not set');
}

export interface SearchResult {
  version: number;
  hits: {
    total: number;
    hits: {
      _id: string;
      _source?: {
        visibility?: string;
      };
    }[];
  };
}

export type WorkItemType = Statefull<IWork>;

export type State = {
  trendingIds: Statefull<string[]>;
  /**
   * キーは Work ID
   * officials はここには含まれない
   */
  works: KVS<Statefull<IWork>>;
  search: {
    query: string;
    loading: boolean;
    /**
     * 検索結果 (Work ID の配列)
     */
    result?: string[];
    errorMessage?: string;
  };
  currentView?: {
    path: string;
    id: string;
    labels: {
      [key: string]: string;
    };
  };
  newers: PaginateResult<string>;
  trash: PaginateResult<string>;
  /**
   * 作成したユーザーごとのステージの ID の配列
   */
  users: KVS<PaginateResult<string>>;
  /**
   * 「他のステージ」に表示するステージ ID
   */
  recommendIds: string[];
};

const initialState: State = {
  trendingIds: helpers.initialized(),
  works: {},
  search: {
    query: '',
    loading: false
  },
  newers: {
    data: [],
    mayHasMore: true,
    isFetching: false
  },
  recommendIds: [],
  trash: {
    data: [],
    mayHasMore: true,
    isFetching: false
  },
  users: {}
};

// Root Reducer
export default reducerWithImmer(initialState)
  .case(actions.work.fetch.started, (draft, params) => {
    draft.works[params.id] = helpers.processing();
  })
  .case(actions.work.fetch.done, (draft, { params, result }) => {
    draft.works[params.id] = helpers.from(result.work);
  })
  .case(actions.work.fetch.failed, (draft, { params, error }) => {
    draft.works[params.id] = helpers.invalid(error);
  })
  .case(actions.work.fetchTrendings.started, (draft, params) => {
    draft.trendingIds = helpers.processing();
  })
  .case(actions.work.fetchTrendings.done, (draft, { result }) => {
    const { docs } = result;
    draft.trendingIds =
      docs.length > 0
        ? helpers.has(docs.map(item => item.id))
        : helpers.empty();
    for (const item of docs) {
      draft.works[item.id] = helpers.from(item);
    }
  })
  .case(actions.work.fetchTrendings.failed, (draft, { params, error }) => {
    draft.trendingIds = helpers.invalid(error);
  })
  .case(actions.work.fetchNewerMore.started, draft => {
    draft.newers.isFetching = true;
  })
  .case(actions.work.fetchNewerMore.done, (draft, { result }) => {
    const { data } = result;
    for (const item of data) {
      draft.works[item.id] = helpers.from(item);
    }
    draft.newers.isFetching = false;
    if (result.timestamp) {
      draft.newers.timestamp = result.timestamp;
    }
    if (result.mayHasMore !== undefined) {
      draft.newers.mayHasMore = result.mayHasMore;
    }
    merge(
      draft.newers.data,
      data.map(item => item.id)
    );

    for (const item of data) {
      if (!draft.recommendIds.includes(item.id)) {
        draft.recommendIds.push(item.id);
      }
    }
  })
  .case(actions.work.fetchNewerMore.failed, (draft, payload) => {
    draft.newers.isFetching = false;
    draft.newers.error = payload.error;
  })
  .case(actions.work.subscribeNew.done, (draft, { result }) => {
    merge(draft.newers.data, [result.id]);
    draft.works[result.id] = helpers.from(result);
  })
  .case(actions.work.fetchUsers.started, (draft, { uid }) => {
    const more = draft.users[uid] || {
      data: [],
      mayHasMore: true,
      isFetching: false
    };
    more.isFetching = true;
    draft.users[uid] = more;
  })
  .case(actions.work.fetchUsers.done, (draft, { params: { uid }, result }) => {
    const { data } = result;
    const more = draft.users[uid] || {
      isFetching: false,
      mayHasMore: true,
      data: []
    };
    more.isFetching = false;
    if (result.mayHasMore !== undefined) {
      more.mayHasMore = result.mayHasMore;
    }
    if (result.timestamp) {
      more.timestamp = result.timestamp;
    }
    merge(
      more.data,
      result.data.map(item => item.id)
    );
    for (const item of data) {
      draft.works[item.id] = helpers.from(item);
    }
  })
  .case(actions.work.fetchUsers.failed, (draft, { params: { uid }, error }) => {
    const more = draft.users[uid];
    if (more) {
      more.isFetching = false;
    }
  })
  .case(actions.work.fetchTrash.started, (draft, payload) => {
    draft.trash.isFetching = true;
  })
  .case(actions.work.fetchTrash.done, (draft, { result }) => {
    draft.trash.isFetching = false;
    if (result.mayHasMore !== undefined) {
      draft.trash.mayHasMore = result.mayHasMore;
    }
    if (result.timestamp) {
      draft.trash.timestamp = result.timestamp;
    }
    merge(
      draft.trash.data,
      result.data.map(item => item.id)
    );
    for (const item of result.data) {
      draft.works[item.id] = helpers.from(item);
    }
  })
  .case(actions.work.fetchTrash.failed, (draft, payload) => {
    draft.trash.isFetching = false;
    draft.trash.error = payload.error;
  })
  .case(actions.work.search.started, (draft, params) => {
    draft.search.query = params.query;
    draft.search.loading = true;
    draft.search.errorMessage = undefined;
  })
  .case(actions.work.search.done, (draft, { params, result }) => {
    // まだそのクエリが残っているか？
    if (draft.search.query === params.query) {
      draft.search.result = result;
      draft.search.loading = false;
    }
  })
  .case(actions.work.search.failed, (draft, { params, error }) => {
    // まだそのクエリが残っているか？
    if (draft.search.query === params.query) {
      draft.search.errorMessage = error.message;
      draft.search.loading = false;
    }
  })
  .case(actions.work.delete.done, (draft, { params, result }) => {
    const docData = draft.works[params.id]?.data;
    if (docData) {
      docData.deletedAt = result.deletedAt;
      draft.trash.data.push(docData.id);
    }
  })
  .case(actions.work.restore.done, (draft, { params }) => {
    const docData = draft.works[params.id]?.data;
    if (docData) {
      docData.deletedAt = null;
      // 配列から取り除く
      draft.trash.data = draft.trash.data.filter(id => id !== docData?.id);
    }
  })
  .case(actions.work.addView.done, (draft, { params, result }) => {
    draft.currentView = {
      path: idToPath('works', params.id),
      id: result.id,
      labels: {}
    };
  })
  .case(actions.work.updateView.done, (draft, { params, result }) => {
    if (!draft.currentView) return;
    if (draft.currentView.path !== idToPath('works', params.id)) return; // 途中でステージが変わった
    Object.assign(draft.currentView.labels, result.labels);
  })
  .case(actions.make.add.done, (draft, { result }) => {
    const { id, doc } = result;
    draft.works[id] = helpers.from(doc);
  })
  .case(actions.make.trash, draft => {
    draft.currentView = undefined;
  })
  .case(actions.work.insertUsersWorks, (draft, { uid, works }) => {
    const users = draft.users[uid] || {
      isFetching: false,
      mayHasMore: true,
      data: []
    };
    merge(
      users.data,
      works.docs.map(doc => doc.id)
    );
    for (const snapshot of works.docs) {
      draft.works[snapshot.id] = helpers.from(snapshot.data());
    }
  })
  .case(actions.work.removeRecommendIds, (draft, payload) => {
    for (const id of payload) {
      const index = draft.recommendIds.indexOf(id);
      if (index >= 0) {
        draft.recommendIds.splice(index, 1);
      }
    }
  })
  .toReducer();

export const epics = combineEpics(
  // subscribe own works
  eachUser(userInfo => {
    let unsubscribe = () => {};
    return new Observable<QS<IWork>>(observer => {
      unsubscribe = firebase
        .firestore()
        .collection('works')
        .where('uid', '==', userInfo.uid)
        .orderBy('updatedAt', 'desc')
        .limit(1)
        .withConverter(convertToWork)
        .onSnapshot(observer);
    }).pipe(
      map(querySnapshot =>
        actions.work.insertUsersWorks({
          uid: userInfo.uid,
          works: querySnapshot
        })
      ),
      tap({ complete: () => unsubscribe() })
    );
  }),

  // ログイン後に自分のステージを一度取得させる
  (action$, state$) =>
    action$.pipe(
      filter(actions.auth.signedIn.match),
      map(action => action.payload.uid),
      distinct(),
      filter(uid => !state$.value.work.users[uid]?.isFetching), // 取得中でない
      map(uid => actions.work.fetchUsers.started({ uid }))
    ),

  // fetch work
  asyncEpic(actions.work.fetch, async (action, state, state$) => {
    const { id } = action.payload;
    const snapshot = await firebase
      .firestore()
      .collection('works')
      .doc(id)
      .withConverter(convertToWork)
      .get();
    return {
      work: snapshot.data(),
      userInfo: state$.value.auth.userInfo
    };
  }),

  // 未取得であればステージを取得する
  (action$, state$) =>
    action$.pipe(
      filter(actions.work.fetchIfNeeded.match),
      map(action => action.payload),
      filter(id => !state$.value.work.works[id]),
      map(id => actions.work.fetch.started({ id }))
    ),

  // search works
  asyncEpic(actions.work.search, async (action, state) => {
    const { query } = state.work.search;
    const response = await fetch(`${endpoint}/search?q=${query}`);
    const json = await response.text();
    if (!response.ok) {
      throw new Error('検索中にサーバーエラーが発生しました');
    }
    const result = JSON.parse(json) as SearchResult;

    // 検索結果に限定公開のステージが入るバグ. サーバ側でもフィルタする
    const published = result.hits.hits.filter(
      item => item._source?.visibility === 'public'
    );
    return published.map(item => item._id); // id だけを抽出
  }),

  // remove work
  asyncEpic(actions.work.delete, async (action, state) => {
    const { id } = action.payload;
    const docData = state.work.works[id]?.data;
    if (!docData) return SKIP; // 取得出来ていない
    if (docData.deletedAt) return SKIP; // 削除されている

    const deletedAt = firestore.Timestamp.now();

    await firestore()
      .collection('works')
      .doc(id)
      .update({ deletedAt, updatedAt: deletedAt });

    return { deletedAt };
  }),

  asyncEpic(actions.work.restore, async (action, state) => {
    const { id } = action.payload;
    const docData = state.work.works[id]?.data;
    if (!docData?.deletedAt) return SKIP; // 削除されていない
    await firestore().collection('works').doc(id).update({ deletedAt: null });
  }),

  // ステージの views コレクションにドキュメントを追加
  // works の histories にも自動で追加される
  asyncEpic(actions.work.addView, async (action, state) => {
    const uid = state.auth.userInfo?.uid;
    if (!uid) {
      return SKIP; // ログインする必要がある
    }
    const { id } = action.payload;
    const ref = await firebase
      .firestore()
      .collection('works')
      .doc(id)
      .collection('views')
      .add({
        uid,
        labels: {},
        createdAt: firestore.Timestamp.now()
      });
    return { id: ref.id };
  }),

  // "gameclear" フラグをセットする
  asyncEpic(actions.work.updateView, async (action, state) => {
    if (!state.work.currentView) {
      return SKIP; // add されていない
    }
    const { id, path } = state.work.currentView;

    await firebase
      .firestore()
      .collection('works')
      .doc(pathToId(path))
      .collection('views')
      .doc(id)
      .update({
        [`labels.${action.payload.name}`]: action.payload.value,
        updatedAt: firestore.Timestamp.now()
      });
    return {
      labels: {
        [action.payload.name]: action.payload.value
      }
    };
  }),

  // fetch newer
  action$ =>
    action$.pipe(
      first(),
      map(() => actions.work.fetchNewerMore.started())
    ),
  asyncEpic(actions.work.fetchNewerMore, async (action, state) => {
    let query = firestore()
      .collection('works')
      .where('visibility', '==', 'public')
      .orderBy('publishedAt', 'desc')
      .withConverter(convertToWork)
      .limit(pageSize);
    if (state.work.newers.timestamp) {
      query = query.startAfter(state.work.newers.timestamp);
    }
    const qs = await query.get();
    const mayHasMore = qs.size === pageSize;
    const works = qs.docs.map(snapshot => snapshot.data());
    const timestamp = last(works)?.publishedAt || undefined;
    return { data: works, mayHasMore, timestamp };
  }),

  // 最新のステージを subscribe する. snapshot のコネクション増加を抑えるため、サインインユーザーのみ
  action$ =>
    action$.pipe(
      filter(actions.auth.signedIn.match),
      first(),
      mergeMap(
        () =>
          new Observable(subscriber =>
            firestore()
              .collection('works')
              .where('visibility', '==', 'public')
              .orderBy('publishedAt', 'desc')
              .limit(1)
              .withConverter(convertToWork)
              .onSnapshot({
                next(snapshot) {
                  const item = snapshot.docs[0];
                  if (!item?.exists) return; // スキップ
                  const result = item.data();
                  subscriber.next(actions.work.subscribeNew.done({ result }));
                },
                error(error) {
                  subscriber.next(actions.work.subscribeNew.failed({ error }));
                }
              })
          )
      )
    ),

  // fetch trends
  jsonApi(
    actions.work.fetchTrendings,
    () => endpoint + '/trendingWorks?page=1'
  ),
  // Fetch works by specified users
  asyncEpic(actions.work.fetchUsers, async (action, state) => {
    // 自分かどうかまだ分からない場合は空の結果を返す
    if (!state.auth.initialized) {
      return { data: [] };
    }

    const more = state.work.users[action.payload.uid];

    let query = firebase
      .firestore()
      .collection('works')
      .where('uid', '==', action.payload.uid)
      .limit(pageSize)
      .withConverter(convertToWork);

    const userInfo = state.auth.userInfo;
    const isMine = userInfo?.uid === action.payload.uid;
    if (isMine) {
      // 自分のステージ
      query = query.orderBy('updatedAt', 'desc');
    } else {
      // 他人のステージ
      query = query
        .where('visibility', '==', 'public')
        .orderBy('publishedAt', 'desc');
    }
    if (more?.timestamp) {
      query = query.startAfter(more.timestamp);
    }

    const qs = await query.get();
    const mayHasMore = qs.size === pageSize;
    const works = qs.docs.map(snapshot => snapshot.data());
    const timestamp =
      (isMine ? last(works)?.updatedAt : last(works)?.publishedAt) || undefined;
    return { data: works, mayHasMore, timestamp };
  }),

  // ゴミ箱にあるステージを取得する（過去１ヶ月間だけ）
  asyncEpic(actions.work.fetchTrash, async (draft, state) => {
    const uid = state.auth.userInfo?.uid;
    if (!uid) return SKIP;
    const aMonthAgo = moment().subtract(1, 'month').toDate();
    let query = firestore()
      .collection('works')
      .where('uid', '==', uid)
      .where('deletedAt', '>', aMonthAgo)
      .orderBy('deletedAt', 'desc')
      .limit(pageSize)
      .withConverter(convertToWork);
    if (state.work.trash.timestamp) {
      query = query.startAfter(state.work.trash.timestamp);
    }
    const qs = await query.get();
    const mayHasMore = qs.size === pageSize;
    const works = qs.docs.map(snapshot => snapshot.data());
    const timestamp = last(works)?.deletedAt || undefined;
    return { data: works, mayHasMore, timestamp };
  })
);

const convertToWork: firebase.firestore.FirestoreDataConverter<IWork> = {
  fromFirestore: snapshot => {
    return {
      ...snapshot.data(),
      id: snapshot.id
    } as IWork;
  },
  toFirestore: ({ id, ...data }: IWork) => data
};
