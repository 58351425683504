import { Definition } from '@hackforplay/common/src/definition';
import { Pos } from 'codemirror';
import * as React from 'react';
import { useSelector } from 'react-redux';

/**
 * hint (入力補完) を行う関数を得るための React Hook
 */
export function useHint() {
  // @hackforplay/common から取得したシノニム定義
  const definition = useSelector(state => state.file.definition);
  const suggestionsRef = React.useRef<Suggestion[]>([]);
  suggestionsRef.current = React.useMemo(
    () => getSuggestions(definition),
    [definition]
  );

  const handleRef = React.useRef(0);
  return React.useMemo(() => {
    // 協調的マルチスレッドによって UI が固まるのを防ぐ
    const hint: CodeMirror.AsyncHintFunction = (cm, callback) => {
      window.cancelIdleCallback(handleRef.current);
      handleRef.current = window.requestIdleCallback(
        () => {
          getHints(cm, suggestionsRef.current, callback);
        },
        { timeout: 1000 }
      );
    };
    hint.async = true;

    return hint;
  }, []);
}

type Var =
  | {
      name: string;
      next: Var;
    }
  | null
  | undefined;

const notIdentifier = [
  'atom',
  'string',
  'comment',
  'number',
  'keyword',
  'def',
  'operator'
];

// https://github.com/codemirror/CodeMirror/blob/f9c0e370ec4ddace08bb799965b3f46f9536a553/addon/hint/javascript-hint.js#L97
// を元にしているが、 import export を消して await let を追加している
const javascriptKeywords = (
  'break case catch class const continue debugger default delete do else extends false finally for function ' +
  'if in instanceof new null return super switch this throw true try typeof var void while with yield ' +
  'await let'
)
  .split(' ')
  .map<Suggestion>(text => ({ text }));

function getHints(
  cm: CodeMirror.Editor,
  suggestions: Suggestion[],
  callback: (hints: CodeMirror.Hints) => void
) {
  const cursor = cm.getCursor();
  const token = cm.getTokenAt(cursor);
  const result: CodeMirror.Hints = {
    from: cursor,
    to: cursor,
    list: []
  };

  // 識別子以外は何もしない
  if (notIdentifier.includes(token.type || '')) return;

  // どの function の中にもない場合は this から始まる suggestions を取り除く
  const noScope = token.state?.localVars === undefined;
  if (noScope) {
    suggestions = suggestions.filter(s => !s.text.startsWith('this'));
  }

  // カーソルのある部分までにある変数の参照式を作る e.g. "this.attac"
  let expression = '';
  if (isVar(token)) {
    // トークンの途中にカーソルがある場合、string をカーソル位置までにする
    const length = cursor.ch - token.start;
    expression = token.string.substr(0, length);
  }

  // Token を前に進めて、変数の参照式を得る e.g. "this.vector.x"
  const prevCh = token.start + (token.string === '.' ? 0 : -1); // "." の分減らす
  let head = cm.getTokenAt(new Pos(cursor.line, prevCh)); // 置き換えるトークンの先頭。この変数を１つずつ前に戻す
  // l は無限ループ防止のためのリミッター
  for (let l = 0; isVar(head) && l < 10; l++) {
    expression = head.string + '.' + expression; // プロパティ名を "." で連結
    const left = cm.getTokenAt(new Pos(cursor.line, head.start));
    if (left?.string !== '.') {
      break; // head の左に "." がなければ終了
    }
    const prev = new Pos(cursor.line, head.start - 1); // "." の分減らす
    head = cm.getTokenAt(prev); // head を１つ前に進める
  }
  // expression の前に await キーワードがあるか調べる
  const hasAwait = head.string === 'await'; // スペースが…

  // expression の後に () があるか調べる
  let tail = token; // 置き換えるトークンの末尾
  // ちょうど . のすぐ後にカーソルがある場合、次のトークンまで置き換えの対象に含める（expression には含めない）
  if (token.string === '.') {
    const next = cm.getTokenAt(new Pos(cursor.line, token.end + 1)); // １つ次のトークン
    tail = next.type === 'property' ? next : tail; // プロパティが続くなら、そこまで置き換えの対象とする
  }
  const next = cm.getTokenAt(new Pos(cursor.line, tail.end + 1));
  const isCallee = next.string === '(';

  // variable の場合は localVars の可能性を追加する
  for (let local: Var = token.state?.localVars; local; local = local.next) {
    suggestions = suggestions.concat({ text: local.name });
  }

  // 昇順にソートする
  suggestions = suggestions
    .filter(s => s.text)
    .sort((a, b) => (a.text > b.text ? 1 : -1));

  // キーワードを追加する
  suggestions = suggestions.concat(javascriptKeywords);

  let previous = suggestions[1]; // 重複を除去するために１つ前の値を保持する（初期値の [1] はスキップされない）
  for (const item of suggestions) {
    if (previous === item) continue; // １つ前と同じなのでスキップ
    previous = item;
    // これから入力されるであろう文字列をリストに追加
    if (item.text.startsWith(expression)) {
      result.list.push({
        text: [
          item.await && !hasAwait ? 'await ' : '',
          item.text,
          item.callee && !isCallee ? '()' : ''
        ].join(''),
        displayText: item.text,
        from: new Pos(cursor.line, cursor.ch - expression.length),
        to: new Pos(cursor.line, tail.end)
      });
    }
  }

  callback(result);
}

/**
 * そのトークンが変数名であれば true, そうでなければ false を返す
 * @param token トークン
 */
function isVar(token: CodeMirror.Token) {
  return (
    token.type === 'variable' ||
    token.type === 'variable-2' ||
    token.type === 'property' ||
    (token.type === 'keyword' && token.string === 'this')
  );
}

interface Suggestion {
  /**
   * displayText で、少なくとも挿入されるコード
   */
  text: string;
  /**
   * この suggest が関数コールであることを表す
   * () が後ろになければ付け足す
   */
  callee?: boolean;
  /**
   * この suggest の関数コールの前に await キーワードが
   * なければ付け足すべきであることを表す
   */
  await?: boolean;
}

/**
 * サジェスト可能な文字列の一覧を作る
 * @param definition @hackforplay/common のシノニム定義
 */
function getSuggestions(definition?: Definition) {
  if (!definition) return [];
  const suggestions: Suggestion[] = [{ text: 'this' }];
  for (const global of Object.values(definition.globals)) {
    // グローバルプロパティ自体を書き出す
    suggestions.push({
      text: global.name,
      callee: global.type === 'function',
      await: global.type === 'function' && global.await
    });
    // 子プロパティを書き出す
    const def =
      global.type === 'object'
        ? global.properties
        : global.type === 'instance'
        ? definition.classes[global.class]?.properties
        : undefined;
    for (const value of Object.values(def || {})) {
      suggestions.push({
        text: `${global.name}.${value.name}`,
        callee: value.type === 'function',
        await: value.type === 'function' && value.await
      });
    }
  }
  // this のプロパティを書き出す
  const className =
    definition.this?.type === 'instance' && definition.this.class;
  const classDef = className ? definition.classes[className] : undefined;
  if (classDef) {
    for (const item of Object.values(classDef.properties)) {
      suggestions.push({
        text: `this.${item.name}`,
        callee: item.type === 'function',
        await: item.type === 'function' && item.await
      });
    }
  }
  return suggestions;
}
