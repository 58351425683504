import { combineEpics } from 'redux-observable';
import { filter, map } from 'rxjs/operators';
import { actions } from './actions';
import { jsonApi } from './api';
import { from, invalid, processing, Statefull } from './helpers';
import { reducerWithImmer } from './reducer-with-immer';

// 最終的な Root Reducere の中で、ここで管理している State が格納される名前
export const storeName = 'officialWork';

export type State = {
  /**
   * ID をキーとした公式ステージのマップ
   */
  officialWorks: KVS<Statefull<IOfficialWork>>;
};

const initialState: State = {
  officialWorks: {}
};

// Root Reducer
export default reducerWithImmer(initialState)
  .case(actions.official.fetch.started, (draft, params) => {
    draft.officialWorks[params.id] = processing();
  })
  .case(actions.official.fetch.done, (draft, { params, result }) => {
    draft.officialWorks[params.id] = from(result);
  })
  .case(actions.official.fetch.failed, (draft, { params, error }) => {
    draft.officialWorks[params.id] = invalid(error);
  })
  .toReducer();

export const epics = combineEpics(
  (action$, state$) =>
    action$.pipe(
      filter(actions.official.fetchIfNeeded.match),
      map(action => {
        // 取得済みのデータがあれば fetch をスキップする
        const data =
          state$.value.officialWork.officialWorks[action.payload.id]?.data;
        return data
          ? actions.official.fetch.done({
              params: action.payload,
              result: data
            })
          : actions.official.fetch.started(action.payload);
      })
    ),

  jsonApi(
    actions.official.fetch,
    params => process.env.REACT_APP_API_ENDPOINT + '/officials/' + params.id
  )
);
