import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { ArrowDropDown, ArrowRight, Description } from '@material-ui/icons';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { style } from 'typestyle';
import { Tab, tabsAtom } from '.';
import { Localization } from '../../localization';

export interface SelectTabProps {
  className: string;
  localization: Localization;
  tabs: any[];
  filePath: string;
  globalEvent: any;
}

interface State {
  anchorEl: HTMLElement | null;
}

const cn = {
  blank: style({
    flex: '1 1 auto'
  }),
  menuSize: {
    paper: style({
      width: 200
    })
  },
  menuItem: style({
    paddingTop: 4,
    paddingBottom: 4
  }),
  icon: style({
    width: 24,
    height: 24,
    marginRight: 4
  })
};

const Icon = (props: { iconUrl?: string }) =>
  props.iconUrl ? (
    <img src={props.iconUrl} alt="" className={cn.icon} />
  ) : (
    <Description />
  );

export default function SelectTabWrapper(props: Omit<SelectTabProps, 'tabs'>) {
  const tabs = useRecoilValue(tabsAtom);

  return <SelectTab {...props} tabs={tabs} />;
}

class SelectTab extends React.Component<SelectTabProps, State> {
  static propTypes = {};

  state = {
    anchorEl: null
  };

  componentDidUpdate(prevProps: SelectTabProps) {
    if (prevProps.filePath !== this.props.filePath) {
      // ファイルが選択されたので Popout は閉じる
      this.handleClose();
    }
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    // 現在選択中のタブの情報を filePath (ファイル名) から調べる. tabs の中にはない(nullになる)こともある
    const selected = Tab.find(this.props.tabs, this.props.filePath);

    return (
      <>
        <Button
          variant="text"
          onClick={this.handleClick}
          className={this.props.className}
          endIcon={<ArrowDropDown />}
        >
          {selected ? <Icon iconUrl={selected.iconUrl} /> : null}
        </Button>
        <Menu
          id="file-select-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          classes={cn.menuSize}
          onClose={this.handleClose}
        >
          <NestedMenus
            tabs={this.props.tabs}
            localization={this.props.localization}
            filePath={this.props.filePath}
            globalEvent={this.props.globalEvent}
          />
        </Menu>
      </>
    );
  }
}

interface NestedMenusProps {
  tabs: any[];
  localization: Localization;
  filePath: string;
  globalEvent: any;
}

interface NestedMenusState {
  anchorEl: HTMLElement | null;
  selectedIndex: number;
}

class NestedMenus extends React.Component<NestedMenusProps, NestedMenusState> {
  state = {
    anchorEl: null,
    selectedIndex: -1
  };

  handleMenuItemClick = (filePath: string) => {
    this.props.globalEvent.emit('message.editor', {
      data: { value: filePath }
    });
    this.handleClose();
  };

  handleOpen = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedIndex: index
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      selectedIndex: -1
    });
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <>
        {this.props.tabs.map((tab, index) =>
          tab.filePath ? (
            <MenuItem
              key={index}
              selected={tab.filePath === this.props.filePath}
              className={cn.menuItem}
              onClick={() => this.handleMenuItemClick(tab.filePath)}
            >
              <Icon iconUrl={tab.iconUrl} />
              <Typography variant="body2">{tab.label}</Typography>
            </MenuItem>
          ) : tab.tabs ? (
            <React.Fragment key={index}>
              <MenuItem
                selected={index === this.state.selectedIndex}
                className={cn.menuItem}
                onClick={event => this.handleOpen(event, index)}
              >
                <Icon iconUrl={tab.iconUrl} />
                <Typography variant="body2">{tab.label}</Typography>
                <div className={cn.blank} />
                <ArrowRight />
              </MenuItem>
              <Menu
                id={`file-select-menu-${tab.label}`}
                classes={cn.menuSize}
                open={index === this.state.selectedIndex}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'top'
                }}
              >
                <NestedMenus
                  tabs={tab.tabs}
                  localization={this.props.localization}
                  filePath={this.props.filePath}
                  globalEvent={this.props.globalEvent}
                />
              </Menu>
            </React.Fragment>
          ) : null
        )}
      </>
    );
  }
}
