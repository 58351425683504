import { Document } from '@contentful/rich-text-types';
import { Grid } from '@material-ui/core';
import { Entry } from 'contentful';
import * as React from 'react';
import { renderEntry } from './renderers';

type N = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;

export interface Fields {
  body: Document;
  xs: N;
  sm: N;
  md: N;
  lg: N;
}

export interface GridItemProps {
  entry: Entry<Fields>;
  block?: boolean;
}

export function isGridItem(entry: Entry<unknown>): entry is Entry<Fields> {
  return entry.sys.contentType.sys.id === 'gridItem';
}

export function GridItem({ entry, block = false }: GridItemProps) {
  const { body, xs, sm, md, lg } = entry.fields;
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
      {renderEntry(body)}
    </Grid>
  );
}
