import {
  AppBar,
  IconButton,
  Slide,
  Toolbar,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Close, Menu } from '@material-ui/icons';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { media, style } from 'typestyle';
import { OnMessage } from '../components/Feeles';
import { actions } from '../ducks/actions';
import { useGetter } from '../hooks/useGetter';
import { isIdeSidebarOpenedAtom } from '../ide/src/jsx/Main';
import { pathToId } from '../utils/id';
import { ErrorBoundary } from './ErrorBoundary';
import { Feeles } from './Feeles';
import { IdeMenuBar, unblock } from './IdeMenuBar';
import { WorkInfoPanel } from './WorkInfoPanel';

export type WorkUrlParams = {
  [0]: 'officials' | 'works';
  id: string;
  action?: '' | 'edit';
};

export const cn = {
  root: style({
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  }),
  // 実質的なフルスクリーンモード
  header: style(
    media(
      {
        orientation: 'landscape',
        maxHeight: 479
      },
      {
        display: 'none',
        opacity: 11 // media query が違うのに 同じ className を出力している
      }
    )
  ),
  toolbar: style({
    paddingRight: 20
  }),
  iconButton: style({
    marginLeft: 4,
    marginRight: 4
  }),
  loading: style({
    flex: 1,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  feelesWrapper: style({
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'relative',
    overflow: 'hidden'
  })
};

export type LocState =
  | {
      /**
       * 初回保存時のみ, path を取得出来たらリダイレクトする
       */
      redirectBecauseFirstSave?: boolean;
    }
  | undefined;

export function Work() {
  const params = useParams<WorkUrlParams>();
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.auth.userInfo);
  const actualPath = useSelector(state => state.make.path); // 'works/:id' 'officials/:id' have opened
  const getActualPath = useGetter(actualPath);
  const restrained = useSelector(state => state.make.restrained);

  // 初回保存時のみ URL を変更する. その場合は fetch も trash もしない
  const redirectBecauseFirstSaveRef = React.useRef(false);
  const isFirstSave = useSelector(state => state.make.isFirstSave);
  React.useEffect(() => {
    if (isFirstSave && actualPath) {
      unblock(); // 警告を出さない
      redirectBecauseFirstSaveRef.current = true;
      history.replace(`/${actualPath}/edit`); // TODO: actualPath を使わずに実装する
    }
  }, [isFirstSave, actualPath]);

  // 開いているステージが変更されたら, 新しい work をロードする
  React.useEffect(() => {
    if (!redirectBecauseFirstSaveRef.current) {
      // 新しい work をストアに load する
      if (params[0] === 'officials') {
        dispatch(
          actions.official.fetch.started({
            id: params.id,
            loadAfterFetch: true
          })
        );
      } else {
        dispatch(
          actions.work.fetch.started({
            id: params.id,
            loadAfterFetch: true
          })
        );
      }
    }
    redirectBecauseFirstSaveRef.current = false;

    return () => {
      if (!redirectBecauseFirstSaveRef.current) {
        // 古い work を TRASH する
        dispatch(actions.make.trash());
      }
    };
  }, [params[0], params.id]);

  // ステージのビューカウントを増やす
  React.useEffect(() => {
    if (!userInfo) return; // ログインが必要
    if (!actualPath) return; // ステージの fetch が終わってから送る
    if (actualPath.startsWith('works/')) {
      // 公式ステージは除く
      dispatch(actions.work.addView.started({ id: pathToId(actualPath) }));
    }
  }, [userInfo, actualPath]);

  // メタデータを取得する
  const isOwner = useSelector(state => state.make.isOwner);
  const getIsOwner = useGetter(isOwner);

  const [sidebarOpened, setSidebarOpened] = useRecoilState(
    isIdeSidebarOpenedAtom
  );

  const makeChanged = useSelector(state => state.make.changed);
  const getMakeChanged = useGetter(makeChanged);
  const changedByUser = useSelector(state => state.file.changedByUser);
  const history = useHistory();
  // Feeles で実行している iframe から message を受け取った
  const handleMessage: OnMessage = React.useCallback(
    event => {
      const { labelName, labelValue, href } = event.data.value;
      if (labelName) {
        // path に対して実行 (ただし改変されていない場合に限る)
        const actualPath = getActualPath();
        if (!getMakeChanged() && actualPath) {
          // labels に新たなラベルを追加
          // e.g. { 'gameclear': 'gameclear' }
          dispatch(
            actions.work.updateView.started({
              id: pathToId(actualPath),
              name: labelName,
              value: labelValue
            })
          );
        }
        if (getIsOwner()) {
          // クリアチェックが成功したことを保持する
          dispatch(actions.make.metadata({ clearChecked: true }));
        }
      }
      if (href) {
        // origin が同じか検証
        const a = document.createElement('a');
        a.href = href;
        if (a.origin !== window.location.origin) {
          console.error(`Cannot open ${href} because different origin`);
          return;
        }
        // react-router で遷移
        unblock(); // 警告を出さない
        history.push(href);
      }
    },
    [changedByUser]
  );

  const metadata = useSelector(state => state.make.metadata);

  const notFound = useSelector(state => state.make.notFound); // ステージが見つからない

  const theme = useTheme();
  const isLaptop = useMediaQuery(theme.breakpoints.up('md'));

  if (notFound) {
    return <div>ステージが見つかりませんでした</div>;
  }

  const { visibility } = metadata;
  if (visibility === 'private' && !isOwner) {
    return null;
  }

  const editing =
    (params[0] === 'works' && params.action === 'edit') ||
    params[0] === 'officials';

  const workPanelOpened =
    params.action !== 'edit' && !(restrained && !isOwner) && isLaptop;

  return (
    <div className={cn.root}>
      {editing || params[0] === 'officials' ? (
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={cn.header}
        >
          <Toolbar disableGutters className={cn.toolbar}>
            <IconButton
              onClick={() => setSidebarOpened(curr => !curr)}
              className={cn.iconButton}
            >
              {sidebarOpened ? <Close /> : <Menu />}
            </IconButton>
            {editing ? <IdeMenuBar id={params.id} /> : null}
          </Toolbar>
        </AppBar>
      ) : null}

      <div className={cn.feelesWrapper}>
        <ErrorBoundary>
          <Feeles onMessage={handleMessage} />
        </ErrorBoundary>
        {params[0] === 'works' ? (
          <Slide in={workPanelOpened} direction="left" unmountOnExit>
            <WorkInfoPanel id={params.id} />
          </Slide>
        ) : null}
      </div>
    </div>
  );
}
