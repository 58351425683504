import { TransformOptions } from '@babel/core';
import ImmutableFile from './ImmutableFile';
import validateType from './validateType';

type Resolve = (result: string) => void;
type Reject = (error: Error) => void;
type Request = { id: string; code: string; options: TransformOptions };
type Response = { id: string; code: string; error?: Error };

const worker = new Worker('/babel-worker.js');

worker.onmessage = event => {
  // Result of transpiling
  const { id, code, error } = event.data as Response;
  if (error) {
    // トランスパイルを試みたが, Syntax Error が見つかった
    const reject = rejects.get(id);
    if (!reject) {
      // エラーを伝える関数が登録されていない
      console.error(error);
      throw new Error(`rejects[${id}] is not registered`);
    }
    const babelError = new Error(error.message);
    babelError.name = 'SyntaxError';
    babelError.stack = error.stack;
    reject(babelError);
    return;
  }

  const resolve = resolves.get(id);
  if (!resolve) {
    // 結果を伝える関数が登録されていない
    throw new Error(`resolves[${id}] is not registered`);
  }

  resolve(code); // 結果を返す
  resolves.delete(id);
  rejects.delete(id);
};
worker.onerror = event => {
  console.error(event);
  // Babel Error ではなく, 致命的なエラーが発生した
  worker && worker.terminate();
  resolves.clear();
  rejects.clear();
  cache.clear();
  console.warn('Babel Worker was terminated.');
  throw new Error(event.message);
};

const defaultOptions: TransformOptions = {
  presets: [
    [
      '@babel/preset-env',
      {
        targets: { browsers: ['last 2 versions'] },
        useBuiltIns: false
      }
    ]
  ],
  plugins: [
    [
      '@babel/plugin-transform-runtime',
      {
        corejs: false,
        helpers: true,
        regenerator: true,
        useESModules: false
      }
    ]
  ]
};

const resolves = new Map<string, Resolve>();
const rejects = new Map<string, Reject>();
const cache = new Map<string, Promise<string>>();

export default function babelFile(file: ImmutableFile) {
  if (!validateType('javascript', file.type)) {
    return Promise.resolve(''); // トランスパイル対象ではない
  }
  if (file.text.length > 100000) {
    return Promise.resolve(file.text); // 長すぎるのでスキップ
  }
  const exist = cache.get(file.id);
  if (exist) return exist; // すでにトランスパイルが始まっている

  const promise = new Promise((resolve: Resolve, reject: Reject) => {
    // 後からイベントハンドラでコールできるように関数を保持する
    resolves.set(file.id, resolve);
    rejects.set(file.id, reject);
  });
  // 同じファイルが複数呼ばれた時のために promise をキャッシュする
  cache.set(file.id, promise);
  // トランスパイル開始
  worker.postMessage({
    id: file.id,
    code: file.text,
    options: {
      ...defaultOptions,
      filename: file.name
    }
  } as Request);
  return promise;
}
