/**
 * 試験的に Recoil で状態管理をしてみる
 * Redux と（なるべく）、分離できて、かつ ReadOnly に扱える状態に限る
 */
import { atom, atomFamily } from 'recoil';
import { Observable, of } from 'rxjs';
import { customers } from 'stripe';
import { requestWithAuth } from '../ducks/requestWithAuth';
import { endpoint } from '../env';
import { firestoreAtom } from '../hooks/useFirestore';
import firebase from '../settings/firebase';
import { atomRewind, atomRewindFamily } from './atomRewind';
import { onSignOut } from './onSignOut';
import { selectorObservable } from './selectorObservable';

const firestore = firebase.firestore;

/**
 * ログインしているユーザーの UID
 */
export const authUidAtom = atom<string | undefined>({
  key: 'authUidAtom',
  default: undefined
});

/**
 * ログインしているユーザーの users.secrets['parentalControl']
 * ログアウト後は速やかにデータを破棄する
 */
export const parentalControlState = selectorObservable({
  key: 'parentalControlState',
  observe: ({ get }) => {
    const uid = get(authUidAtom);
    if (!uid) return of(undefined);

    return new Observable<IParentalControl | undefined>(subscriber => {
      const unsubscribe = firestore()
        .collection('users')
        .doc(uid)
        .collection('secrets')
        .doc('parentalControl')
        .withConverter(convertToParentalControl)
        .onSnapshot(
          snapshot => {
            const data = snapshot.data();
            subscriber.next(data?.value);
          },
          error => {
            console.error(error); // TODO: エラーハンドリング
          }
        );
      onSignOut(unsubscribe);
    });
  }
});

/**
 * Stripe の顧客情報を取得する Atom Rewind https://bit.ly/2S8cszg
 */
export const customerState = atomRewind({
  key: 'customerState',
  default: async ({ get }) => {
    const uid = get(authUidAtom);
    if (!uid) {
      return undefined;
    }
    const result = await requestWithAuth(endpoint + '/customers');
    return result as customers.ICustomer;
  }
});

/**
 * ステージに対するコメントのうち parentId を持たないコメントの ID の配列
 * null は未取得を表す
 */
export const workCommentIdsAtom = atomFamily<string[] | null, string>({
  key: 'workCommentsAtom',
  default: null
});

/**
 * ステージに対するコメントのうち parentId を含むコメントの ID の配列
 * parentId を param とする
 */
export const workReplyIdsAtom = atomFamily<string[], string>({
  key: 'workReplyIdsAtom',
  default: []
});

/**
 * 自分が今作っているステージのバージョン情報の ID を配列で格納する
 */
export const workVersionIdsAtom = atomFamily<string[], string>({
  key: 'workVersionIds',
  default: []
});

/**
 * ステージのバージョンオブジェクトを格納する
 */
export const versionAtom = atomFamily<IWorkVersion | undefined, string>({
  key: 'versionAtom',
  default: undefined
});

/**
 * ステージに対するコメントを保持する
 * 未取得の commentId が与えられた場合は新たに取得を試みるが Subscribe はしない
 */
export const commentAtom = atomRewindFamily<IComment, string>({
  key: 'commentAtom',
  default:
    (commentId: string) =>
    async ({ get }) => {
      const firestore = get(firestoreAtom);
      const snapshot = await firestore()
        .collection('comments')
        .withConverter(convertToComment)
        .doc(commentId)
        .get();
      const data = snapshot.data();
      if (!data) {
        throw new Error(`comments/${commentId} doesn't exists`);
      }
      return data;
    }
});

export const notificationIdsAtom = atom<string[]>({
  key: 'notificationIdsAtom',
  default: []
});

export const notificationAtom = atomFamily<
  INotificationDocument | undefined,
  string
>({
  key: 'notificationAtom',
  default: notificationId => undefined
});

export interface IUserSecrets<T> {
  value: T;
  updatedAt: firebase.firestore.Timestamp;
}

export interface IParentalControl {
  email: string;
  enablePublish?: boolean;
  enableReceive?: boolean;
  enableSend?: boolean;
}

export const convertToComment: firebase.firestore.FirestoreDataConverter<IComment> =
  {
    toFirestore(comment: IComment) {
      return comment;
    },
    fromFirestore(snapshot) {
      return snapshot.data() as IComment;
    }
  };

const convertToParentalControl: firebase.firestore.FirestoreDataConverter<
  IUserSecrets<IParentalControl>
> = {
  toFirestore(parentalControl: IUserSecrets<IParentalControl>) {
    return parentalControl;
  },
  fromFirestore(snapshot) {
    return snapshot.data() as IUserSecrets<IParentalControl>;
  }
};

export const convertToNotification: firebase.firestore.FirestoreDataConverter<INotificationDocument> =
  {
    fromFirestore: snapshot => snapshot.data() as INotificationDocument,
    toFirestore: (data: INotificationDocument) => data
  };
