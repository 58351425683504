import { useRecoilCallback } from 'recoil';
import { hrefAtom } from '../atoms';
import { iframeRefAtom } from '../Cards/MonitorCard/Screen';

/**
 * MonitorCard の href を変更してゲームを再読み込みする
 */
export function useSetLocation() {
  return useRecoilCallback(({ getLoadable, set }, href?: string) => {
    set(hrefAtom, curr => {
      const loadable = getLoadable(iframeRefAtom);
      const iframe =
        loadable.state === 'hasValue' ? loadable.contents.current : undefined;
      if (iframe) {
        iframe.src = iframe.src; // reload
      }
      return href ?? curr;
    });
  }, []);
}
