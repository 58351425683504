import { selector, useRecoilValue } from 'recoil';
import firebase from '../settings/firebase';

// 初回のfetchAndActivateが終わるまでhookを待つためのSelector
const remoteConfigAtom = selector({
  key: 'remoteConfigAtom',
  get: async () => {
    const remoteConfig = firebase.remoteConfig();
    remoteConfig.defaultConfig = {
      unsafe_keywords: ''
    };
    try {
      await remoteConfig.fetchAndActivate(); // 標準でキャッシュされる
    } catch (error) {
      console.error(error);
    }
    return remoteConfig;
  }
});

export function useRemoteConfig(key: string) {
  const remoteConfig = useRecoilValue(remoteConfigAtom);
  const value = remoteConfig.getValue(key);
  return value;
}
