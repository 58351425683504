import { Button, Card } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { includes } from 'lodash-es';
import * as React from 'react';
import { atom, useRecoilValue } from 'recoil';
import { style } from 'typestyle';
import { actions } from '../../../../ducks/file';
import {
  cardPropsAtom,
  cardVisibilityAtom,
  globalEventAtom,
  localizationAtom
} from '../../atoms';
import ImmutableFile from '../../File/ImmutableFile';
import { AddDialog } from '../../FileDialog';
import { PreferenceDialog } from '../../FileDialog/PreferenceDialog';
import { useActionDispatcher } from '../../hooks/useActionDispatcher';
import CardFloatingBar from '../CardFloatingBar';
import { IHierarchy } from './getHierarchy';
import { Root } from './Root';
import SearchBar from './SearchBar';

const cn = {
  button: style({
    margin: 16,
    alignSelf: 'flex-end'
  })
};

/**
 * HierarchyCard に表示するカードをフィルタするための関数
 */
export const hierarchyCardFilterAtom = atom<(file: ImmutableFile) => boolean>({
  key: 'hierarchyCardFilterAtom',
  default: () => false
});

export function HierarchyCard() {
  const putFiles = useActionDispatcher(actions.putFiles);
  const localization = useRecoilValue(localizationAtom);
  const globalEvent = useRecoilValue(globalEventAtom);
  const order = useRecoilValue(cardPropsAtom)?.HierarchyCard.order;
  const visible = useRecoilValue(cardVisibilityAtom).HierarchyCard;

  const [isAddDialogOpened, setIsAddDialogOpened] = React.useState(false);

  const [openedPaths, setOpenedPaths] = React.useState(['']);
  const isDirOpened = <T, S>(dir: IHierarchy, passed: T, failed: S) => {
    return includes(openedPaths, dir.path) ? passed : failed;
  };
  const handleFileSelect = (file: ImmutableFile) => {
    globalEvent.emit('message.editor', {
      data: {
        value: file.name
      }
    });
  };

  const handleDirToggle = (dir: IHierarchy) => {
    setOpenedPaths(curr =>
      includes(curr, dir.path)
        ? curr.filter(path => path !== dir.path)
        : curr.concat(dir.path)
    );
  };
  const handleNativeDrop = (files: FileList) => {
    Promise.all(Array.from(files).map(ImmutableFile.fromFile)).then(files => {
      putFiles({ files, isUserAction: true });
    });
  };

  return (
    <div
      id="HierarchyCard"
      style={{
        position: 'relative',
        width: 0,
        order,
        boxSizing: 'border-box',
        maxWidth: '100%',
        direction: 'ltr',
        flex: '0 0 auto',
        flexBasis: visible ? '50%' : 0,
        padding: visible ? '16px 0 16px 20px' : 0,
        overflow: visible ? 'initial' : 'hidden',
        display: 'flex'
      }}
    >
      <Card
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          position: 'relative',
          overflow: 'visible' // position: sticky のために必要
        }}
      >
        <CardFloatingBar>{localization.hierarchyCard.title}</CardFloatingBar>
        <SearchBar onOpen={handleNativeDrop} localization={localization} />
        <Root
          isDirOpened={isDirOpened}
          handleFileSelect={handleFileSelect}
          handleDirToggle={handleDirToggle}
        />
        <Button
          variant="contained"
          size="small"
          className={cn.button}
          onClick={() => setIsAddDialogOpened(true)}
        >
          <Add />
        </Button>
      </Card>
      <AddDialog
        localization={localization}
        open={isAddDialogOpened}
        onClose={() => setIsAddDialogOpened(false)}
      />
      <PreferenceDialog />
      <div id="HierarchyCard-BottomAnchor" />
    </div>
  );
}
