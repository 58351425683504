import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { shuffle } from 'lodash-es';
import * as React from 'react';

const placeholders = shuffle([
  '月額料金が高い',
  '無料版で十分だから',
  '使い方がよく分からない',
  'サービスに問題がある'
]);

const useStyles = makeStyles(theme => ({
  label: {
    display: 'block'
  }
}));

export interface PlanCancelDialogProps {
  open: boolean;
  onResult(reason: string): void;
  onClose(): void;
}

export function PlanCancelDialog(props: PlanCancelDialogProps) {
  const cn = useStyles();

  const [selected, setSelected] = React.useState<string[]>([]);
  const setter = (reason: string) => (e: any, checked: boolean) => {
    const next = checked
      ? selected.concat(reason)
      : selected.filter(s => s !== reason);
    setSelected(next);
  };

  const [option, setOption] = React.useState('');

  // 間違えてボタンを押さないよう、少しの間だけ disabled にする
  const [disabled, setDisabled] = React.useState(true);
  React.useEffect(() => {
    setDisabled(true);
    const handler = window.setTimeout(() => setDisabled(false), 1000);
    return () => window.clearTimeout(handler);
  }, [props.open]);

  return (
    <Dialog open={props.open} maxWidth="lg" onClose={props.onClose}>
      <DialogTitle>ご期待に答えることができず、申し訳ありません。</DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textSecondary">
          もしよろしければ、解約する理由をお聞かせいただけませんか？
        </Typography>
        <Typography variant="body2" color="textSecondary">
          今後のサービス向上に役立てさせていただきます。外部には漏らしません。
        </Typography>
      </DialogContent>
      <DialogContent>
        {placeholders.map((text, i) => (
          <FormControlLabel
            key={i}
            control={
              <Checkbox
                checked={selected.includes(text)}
                onChange={setter(text)}
              />
            }
            label={text}
            className={cn.label}
          />
        ))}
      </DialogContent>
      <DialogContent>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          ご意見をお寄せください
        </Typography>
        <TextField
          multiline
          fullWidth
          rows={3}
          variant="outlined"
          value={option}
          onChange={e => setOption(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={() =>
            props.onResult(Array.from(selected).concat(option).join(', '))
          }
        >
          決定
        </Button>
        <Button variant="outlined" onClick={() => props.onClose()}>
          とじる
        </Button>
      </DialogActions>
    </Dialog>
  );
}
