import { CircularProgress, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { classes, style } from 'typestyle';
import { actions } from '../ducks/actions';
import { notFoundImage } from '../utils/urls';
import { CircleIcon } from './CircleIcon';

export type UserNameLinkProps = {
  uid?: string;
  icon?: boolean;
  className?: string;
  /**
   * WorkInfoPanel で使う少し大きめの表示
   */
  creator?: boolean;
};

const cn = {
  container: style({
    display: 'flex',
    alignItems: 'center'
  }),
  authorName: style({
    color: grey[500],
    $nest: {
      '&:hover': {
        color: grey[900]
      }
    },
    maxWidth: 176,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }),
  noAuthorName: style({
    color: grey[500],
    fontStyle: 'italic'
  }),
  icon: style({
    marginRight: 4
  })
};

export function UserNameLink({
  uid = '',
  icon = false,
  className,
  creator = false
}: UserNameLinkProps) {
  const user = useSelector(store => (uid ? store.user.byUid[uid] : undefined));

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (uid) {
      dispatch(actions.user.fetchIfNeeded({ uid }));
    }
  }, [uid]);

  if (!uid) {
    return null; // 特別なケース
  }

  if (!user || user.isProcessing) {
    return <CircularProgress size={16} />;
  }
  const userData = user.data;
  if (!userData) {
    return (
      <Typography variant="body1" className={cn.noAuthorName}>
        ユーザーの取得に失敗しました
      </Typography>
    );
  }

  return (
    <Link to={`/users/${uid}`} className={classes(cn.container, className)}>
      {icon ? (
        <CircleIcon
          size={creator ? 40 : 24}
          src={userData.iconUrl || notFoundImage}
          className={cn.icon}
        />
      ) : null}
      <div>
        {creator ? (
          <Typography variant="caption" color="textSecondary">
            クリエイター
          </Typography>
        ) : null}
        <Typography variant="body1" className={cn.authorName}>
          {userData.displayName || '_'}
        </Typography>
      </div>
    </Link>
  );
}

export type UserIconLinkProps = {
  uid?: string;
  size?: number;
};

export function UserIconLink({ uid, size }: UserIconLinkProps) {
  const user = useSelector(store => (uid ? store.user.byUid[uid] : undefined));

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (uid) {
      dispatch(actions.user.fetchIfNeeded({ uid }));
    }
  }, [uid]);

  if (!uid) {
    return null; // 特別なケース
  }

  if (!user || user.isProcessing) {
    return <CircularProgress size={size ?? 24} />;
  }
  const userData = user.data;
  if (!userData) {
    return <CircleIcon size={size ?? 32} src={notFoundImage} />;
  }

  return (
    <Link to={`/users/${uid}`}>
      <CircleIcon
        size={size ?? 32}
        src={userData.iconUrl || notFoundImage}
        inline
      />
    </Link>
  );
}
