import { IPackage, IOutput } from '@hackforplay/assets';
import { safeLoad } from 'js-yaml';
import { flatten, flattenDeep } from './array';

const tryParseYAML = (text: string) => {
  try {
    return safeLoad(text);
  } catch (e) {
    console.error(e);
    return {};
  }
};

function isString(value: unknown): value is string {
  return typeof value === 'string';
}

/**
 * 旧アセット定義ファイル(YAML)を新仕様に変換する
 */
export function convertAsset(definitionFileTexts: string[] = []): IPackage {
  const configs = definitionFileTexts.map(tryParseYAML);
  const scopeNames: string[] = flatten(configs.map(def => Object.keys(def)));
  const scopes = scopeNames.filter(isString).map(name => ({
    name,
    defaultActiveCategory: -1
  }));
  const nullable = (value: any) => (value ? value + '' : null);
  const converter: (scope: string) => (config: Config) => IOutput =
    (scope = '') =>
    config => ({
      name: config.label || '',
      description: config.description || '',
      category: -1,
      iconUrl: config.image || '',
      insertCode: nullable(config.code),
      moduleCode: null,
      plan: 'free',
      variations: null,
      production: true,
      scopes: [scopeNames.indexOf(scope)],
      filePath: nullable(config.filePath) // 既にあるファイルのパス
    });
  const buttons = flattenDeep(
    configs.map(object =>
      Object.keys(object)
        .filter(isString)
        .map(scope => (object as any)[scope].map(converter(scope)))
    )
  );

  return {
    version: '',
    categories: [],
    scopes: scopes,
    module: {},
    buttons
  };
}

interface Config {
  label?: string | null;
  description?: string | null;
  image?: string | null;
  code?: string | null;
  filePath?: string | null;
}
