import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { important } from 'csx';
import { pathToRegexp } from 'path-to-regexp';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { style } from 'typestyle';
import { contents, ContentType } from '../settings/contents';
import theme from '../settings/theme';
import { container } from '../utils/xlasses';

const cn = {
  alignMiddle: style({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: important(32)
  }),
  responsive: style({
    position: 'relative',
    paddingBottom: '56.25%',
    height: 0,
    overflow: 'hidden',
    maxWidth: '100%',
    $nest: {
      '& iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }
    }
  }),
  root: style({
    boxSizing: 'border-box',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }),
  item: style({
    cursor: 'pointer',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    $nest: {
      '& img': {
        width: '100%',
        paddingRight: theme.spacing(2)
      },
      '& button': {
        marginRight: theme.spacing(2)
      }
    }
  })
};

export function Contents() {
  const location = useLocation();

  // 現在表示している URL にふさわしいデータソースを取得する
  const source = contents.find(item => {
    const re = pathToRegexp(item.path);
    return re.test(location.pathname);
  });

  if (!source) {
    return null;
  }

  const children = [];

  for (const item of source.items) {
    children.push(
      item.type === 'youtube' ? (
        <YouTubeContent key={item.title} {...item} />
      ) : item.type === 'stage' ? (
        <StageContent key={item.title} {...item} />
      ) : null,
      <Divider key={item.title + '-divider'} />
    );
  }
  children.pop(); // Remove <Devidier /> at last

  return (
    <div className={container.large}>
      <Paper elevation={2} className={cn.root}>
        {children}
      </Paper>
    </div>
  );
}

function StageContent(props: ContentType) {
  const history = useHistory();
  const open = (href: string) => {
    const a = document.createElement('a');
    a.href = href;
    if (a.host === location.host) {
      history.push(a.pathname);
    } else {
      window.open(href, 'target:blank');
    }
  };
  const noLink = (href?: string) => (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!href) return;
    open(href);
  };

  return (
    <Grid
      container
      spacing={2}
      className={cn.item}
      onClick={() => {
        if (props.url) {
          open(props.url);
        }
      }}
    >
      <Grid item xs={6}>
        <img src={props.image} alt="" />
      </Grid>
      <Grid item xs={6} className={cn.alignMiddle}>
        <div />
        <div>
          <Typography variant="h6" align="left" gutterBottom>
            {props.title}
          </Typography>
          <Typography variant="caption" align="left">
            {props.description}
          </Typography>
        </div>
        <div>
          {props.buttons.map((item, i) => (
            <Button href="" key={i} {...item} onClick={noLink(item.href)}>
              {item.children}
            </Button>
          ))}
        </div>
      </Grid>
    </Grid>
  );
}

function YouTubeContent(props: ContentType) {
  return (
    <Grid container spacing={2} className={cn.item}>
      <Grid item xs={6}>
        <div className={cn.responsive}>
          <iframe
            title={props.title}
            src={props.url}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </Grid>
      <Grid item xs={6} className={cn.alignMiddle}>
        <div />
        <div>
          <Typography variant="h6" align="left">
            {props.title}
          </Typography>
          <Typography variant="caption" align="left">
            {props.description}
          </Typography>
        </div>
        <div>
          {props.buttons.map((item, i) => (
            <Button href="" {...item} key={i}>
              {item.children}
            </Button>
          ))}
        </div>
      </Grid>
    </Grid>
  );
}
