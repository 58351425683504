import { Button } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import { Entry } from 'contentful';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { style } from 'typestyle';

export interface Fields {
  title: string;
  url: string;
  uidKey?: string;
}

export interface FormLinkProps {
  entry: Entry<Fields>;
  block?: boolean;
}

export function isFormLink(entry: Entry<unknown>): entry is Entry<Fields> {
  return entry.sys.contentType.sys.id === 'formLink';
}

const cn = {
  blockLink: style({
    display: 'block'
  })
};

export function FormLink({ entry, block = false, ...props }: FormLinkProps) {
  const uid = useSelector(state => state.auth.userInfo?.uid);
  const url = React.useMemo(() => {
    const { uidKey, url } = entry.fields;
    if (!uidKey) return url;
    if (!uid) return '';
    const urlObj = new URL(url);
    if (!urlObj.searchParams) {
      return urlObj.href + `?${uidKey}=${uid}`; // https://bit.ly/2Xvhfyd
    }
    urlObj.searchParams.set(uidKey, uid);
    return urlObj.href;
  }, [entry, uid]);

  return (
    <Button
      variant="contained"
      color="secondary"
      href={url}
      target="_blank"
      rel="noopener"
      className={block ? cn.blockLink : undefined}
      endIcon={<OpenInNew />}
    >
      {entry.fields.title}
    </Button>
  );
}
