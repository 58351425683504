import { EventEmitter2 } from 'eventemitter2';
import { atom } from 'recoil';
import { CardName, CardProps } from './Cards/defaultState';
import getCustomTheme from './js/getCustomTheme';
import getLocalization from './localization';

/**
 * IDE だけの material-UI theme (互換性保持のため)
 * TODO: website 全体で一致させる
 */
export const muiThemeAtom = atom({
  key: 'muiThemeAtom',
  default: getCustomTheme({})
});

/**
 * 多言語化のための文字列オブジェクト
 * TODO: website 全体で一致させる
 */
export const localizationAtom = atom({
  key: 'localizationAtom',
  default: getLocalization([
    ...new URLSearchParams(location.search).getAll('lang'), // ?lang=ll_CC
    ...(navigator.languages || navigator.language) // browser settings
  ])
});

/**
 * feeles/card.json をパースしたもの
 */
export const cardPropsAtom = atom<CardProps | null>({
  key: 'cardPropsAtom',
  default: null
});

/**
 * それぞれのカードが表示中かどうかを表すフラグ
 */
export const cardVisibilityAtom = atom<{ [key in CardName]?: boolean }>({
  key: 'cardVisibilityAtom',
  default: {}
});

/**
 * iframe に表示させる HTML のファイル名 (URL ではない)
 */
export const hrefAtom = atom({
  key: 'hrefAtom',
  default: 'index.html'
});

/**
 * エディタカードを広くするモードのフラグ。集中執筆モード的なやつ
 */
export const isEditorCardExpandedAtom = atom({
  key: 'isEditorCardExpandedAtom',
  default: false
});

/**
 * 全てのメニューを表示するモード
 */
export const showAllAtom = atom({
  key: 'showAllAtom',
  default: false
});

/**
 * カード横断的にイベントを伝えるトリガー
 * card =(emit)=> globalEvent =(on)=> card
 */
export const globalEventAtom = atom({
  key: 'globalEventAtom',
  default: new EventEmitter2({ wildcard: true })
});
