import { Button, Snackbar } from '@material-ui/core';
import * as React from 'react';
import { style } from 'typestyle';
import { Localization } from '../../localization';
import fixDangerousChange from '../../utils/fixDangerousChange';

export interface FixAndMessageProps {
  localization: Localization;
  codemirror?: CodeMirror.Editor;
}

interface State {
  fixedMessage: string;
}

const cn = {
  button: style({
    marginRight: 8
  })
};

let stopFixing = false;

export default class FixAndMessage extends React.Component<
  FixAndMessageProps,
  State
> {
  state = {
    fixedMessage: ''
  };

  componentDidUpdate(prevProps: FixAndMessageProps) {
    if (this.props.codemirror !== prevProps.codemirror) {
      if (prevProps.codemirror) {
        prevProps.codemirror.off('beforeChange', this.handleBeforeChange);
      }
      if (this.props.codemirror) {
        this.props.codemirror.on('beforeChange', this.handleBeforeChange);
      }
    }
  }

  handleBeforeChange = (
    cm: CodeMirror.Editor,
    change: CodeMirror.EditorChangeCancellable
  ) => {
    if (stopFixing) return;
    const { localization } = this.props;
    const result = fixDangerousChange(cm, change);
    const fixedMessage = result.hankaku
      ? localization.editorCard.hankaku
      : result.lineBreakInString
      ? localization.editorCard.lineBreakInString
      : null;
    if (fixedMessage) {
      this.setState({ fixedMessage });
    }
  };

  handleStopFixing = () => {
    if (stopFixing) return;
    stopFixing = true;
    this.setState({
      fixedMessage: ''
    });
  };

  handleMessageClose = (event: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      fixedMessage: ''
    });
  };

  render() {
    const { localization } = this.props;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={Boolean(this.state.fixedMessage)}
        message={this.state.fixedMessage}
        autoHideDuration={600000}
        onClose={this.handleMessageClose}
        action={
          <>
            <Button
              color="primary"
              variant="contained"
              className={cn.button}
              onClick={this.handleMessageClose}
            >
              {localization.editorCard.ok}
            </Button>
            <Button
              color="primary"
              className={cn.button}
              onClick={this.handleStopFixing}
            >
              {localization.editorCard.stopAction}
            </Button>
          </>
        }
      />
    );
  }
}
