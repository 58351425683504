import { State } from '../../../../ducks/asset';

export default function findAssetButton(
  asset: State['assetPackage'],
  name: string
) {
  return findRecursive(asset.buttons, name);
}

type Button = State['assetPackage']['buttons'][number];

function findRecursive(
  assetButtons: Button[] | null,
  name: string
): Button | null {
  if (!Array.isArray(assetButtons)) return null;
  for (const item of assetButtons) {
    if (item.name === name) return item;
    const result = findRecursive(item.variations, name);
    if (result) return result;
  }
  return null;
}
