/**
 * ある配列に対して、そこに含まれていない要素をすべて push する
 * つまり concat -> uniq
 * @param source 破壊的に変更される配列
 * @param added 追加したいデータ
 */
export function merge<T>(source: T[], ...added: T[][]) {
  for (const array of added) {
    for (const item of array) {
      if (!source.includes(item)) {
        source.push(item);
      }
    }
  }
}
