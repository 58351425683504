import { IOutput } from '@hackforplay/assets';
import { Grid, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Popover from '@material-ui/core/Popover';
import { Theme, withTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import { Add } from '@material-ui/icons';
import { url } from 'csx';
import { debounce } from 'lodash-es';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { classes, style } from 'typestyle';
import { StoreState } from '../../../../ducks';
import { canAuthUserUseAsset } from '../../../../ducks/user';
import { Localization } from '../../localization';
import Dropdown from '../MonitorCard/Dropdown';
import { pathToInstall } from './AssetPane';
import { InsertAsset, OpenFile } from './type';

export interface AssetButtonProps extends Partial<IOutput> {
  /**
   * name だけは必須
   */
  name: string;
  /**
   * 最初から存在するファイルにアクセス => filePath
   * 追加してからアクセス => asset.assetPackage.module[name]
   */
  filePath?: string;
  theme: Theme;
  insertAsset: InsertAsset;
  openFile: OpenFile;
  localization: Localization;
  globalEvent: any;
  isAvailableScope: (scopeName: string) => void;
  defaultScope: string;
  scopePrefix?: string;
  // Injected by withAsset
  canUseAssetButton: (asset: any) => boolean;
  asset: StoreState['asset'];
}

interface State {
  anchorEl: HTMLElement | null;
  backgroundStyle: React.CSSProperties;
  /**
   * 選択中の variation
   */
  selectedIndex: number;
  /**
   *  スコープ Dropdown の選択情報
   */
  selectedScope: string;
}

const iconSize = 48;

const cn = {
  mainButton: style({
    width: 80,
    height: 80,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  }),
  mainIcon: style({
    flex: 1,
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat'
  }),
  popoverClasses: {
    paper: style({
      padding: 8,
      maxWidth: 360
    })
  },
  label: style({
    fontSize: 10,
    fontWeight: 600,
    wordBreak: 'break-word'
  }),
  payIcon: style({
    position: 'absolute',
    paddingRight: 24,
    paddingBottom: 24
  }),
  buttonContainer: style({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: 8,
    marginLeft: 0,
    $nest: {
      '&>*': {
        marginLeft: 8
      }
    }
  }),
  titleWrapper: style({
    height: '1.5rem',
    marginBottom: 8
  }),
  thumbnail: style({
    verticalAlign: 'text-bottom',
    height: '100%',
    paddingLeft: '1rem'
  })
};
const getCn = ({ theme }: AssetButtonProps) => ({
  root: style({
    position: 'relative',
    margin: '8px 8px 0px 8px'
  }),
  button: style({
    color: theme.palette.getContrastText(theme.palette.text.primary),
    transition: theme.transitions.create('background-color'),
    backgroundColor: fade(theme.palette.grey[600], 0.6),
    $nest: {
      '&:hover': {
        backgroundColor: theme.palette.grey[600]
      },
      '&:disabled': {
        color: theme.palette.text.disabled
      }
    }
  }),
  variation: style({
    maxWidth: iconSize,
    height: iconSize,
    boxSizing: 'content-box',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: 'transparent',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(),
    backgroundColor: theme.palette.grey[200],
    cursor: 'pointer',
    $nest: {
      '&>img': {
        maxWidth: iconSize,
        height: iconSize
      },
      '&:hover': {
        backgroundColor: theme.palette.grey[400]
      }
    }
  }),
  variationPaid: style({
    borderColor: '#D9B166'
  }),
  selected: style({
    borderColor: '#4A90E2',
    backgroundColor: theme.palette.grey[400]
  })
});

export function AssetButtonWrapper(
  props: Omit<AssetButtonProps, 'asset' | 'canUseAssetButton'>
) {
  const asset = useSelector(state => state.asset);
  const isPaid = useSelector(canAuthUserUseAsset);
  const canUseAssetButton = (item: IOutput) => isPaid || item.plan === 'free';

  return (
    <AssetButton
      {...props}
      asset={asset}
      canUseAssetButton={canUseAssetButton}
    />
  );
}

class AssetButton extends React.PureComponent<AssetButtonProps, State> {
  static defaultProps = {
    name: '',
    description: ''
  };

  state = {
    anchorEl: null,
    backgroundStyle: {},
    selectedIndex: 0, // 選択中の variation
    selectedScope: '' // スコープ Dropdown の選択情報
  };

  get selected() {
    const {
      name,
      description,
      iconUrl,
      insertCode,
      variations,
      scopes,
      thumbnail
    } = this.props;

    return variations
      ? variations[this.state.selectedIndex]
      : {
          name,
          description,
          iconUrl,
          insertCode,
          scopes,
          thumbnail
        };
  }

  get filePathToOpen() {
    // 最初から存在するファイルにアクセス => filePath
    // 追加してからアクセス => asset.assetPackage.module[name]
    const { filePath, asset, name } = this.props;
    return (
      filePath ||
      (asset.assetPackage.module[name]
        ? `${pathToInstall}/${name}.js`
        : undefined)
    );
  }

  scopeIndexToName = (index = 0) => {
    const scope =
      this.props.asset.assetPackage.scopes &&
      this.props.asset.assetPackage.scopes[index];
    return scope && scope.name;
  };

  handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  static getDerivedStateFromProps(props: AssetButtonProps, state: State) {
    let nextState = null;
    if (props.iconUrl) {
      const backgroundImage = url(props.iconUrl);
      if (
        backgroundImage &&
        backgroundImage !== state.backgroundStyle.backgroundImage
      ) {
        nextState = {
          backgroundStyle: { backgroundImage }
        };
      }
    }
    if (props.defaultScope) {
      nextState = {
        ...(nextState || {}),
        selectedScope: props.defaultScope
      };
    }
    return nextState;
  }

  handleInsertAssetSelected = debounce(
    () => {
      this.handleClose();
      const insertCode = this.selected.insertCode;
      if (this.state.selectedScope && insertCode) {
        this.props.insertAsset({
          insertCode,
          scopeName: this.state.selectedScope
        });
      }
    },
    5000,
    { leading: true, trailing: false }
  );

  handleOpenFile = debounce(
    () => {
      this.handleClose();
      this.props.openFile({
        name: this.props.name,
        filePath: this.filePathToOpen,
        iconUrl: this.props.iconUrl
      });
    },
    5000,
    { leading: true, trailing: false }
  );

  showPromotionDialog = () => {};

  render() {
    const dcn = getCn(this.props);
    const { localization, variations, canUseAssetButton, scopePrefix } =
      this.props;
    const { selectedIndex } = this.state;
    const selected = this.selected; // Popover の中は選択中のバリエーションに切り替わる

    return (
      <>
        <div className={dcn.root}>
          <ButtonBase
            focusRipple
            className={classes(dcn.button, cn.mainButton)}
            onClick={this.handleOpen}
          >
            <div className={cn.mainIcon} style={this.state.backgroundStyle} />
            <span className={cn.label}>{this.props.name}</span>
          </ButtonBase>
        </div>
        <Popover
          open={Boolean(this.state.anchorEl)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          anchorEl={this.state.anchorEl}
          classes={cn.popoverClasses}
          onClose={this.handleClose}
        >
          <div className={cn.titleWrapper}>
            <Typography variant="h5" display="inline" gutterBottom>
              {selected.name}
            </Typography>
            {selected.thumbnail ? (
              <img src={selected.thumbnail} className={cn.thumbnail} />
            ) : null}
          </div>
          <Typography variant="body1" gutterBottom>
            {selected.description}
          </Typography>
          {variations && (
            <Grid container>
              {variations.map((child, i) => (
                <Grid
                  key={i}
                  item
                  xs={2}
                  className={classes(
                    dcn.variation,
                    child.plan === 'paid' && dcn.variationPaid,
                    selectedIndex === i && dcn.selected
                  )}
                  onClick={
                    canUseAssetButton(child)
                      ? () => this.setState({ selectedIndex: i })
                      : this.showPromotionDialog
                  }
                >
                  {canUseAssetButton(child) ? (
                    <img src={child.iconUrl} alt={child.name} />
                  ) : (
                    <Tooltip title={localization.editorCard.needPayment}>
                      <img src={child.iconUrl} alt={child.name} />
                    </Tooltip>
                  )}
                </Grid>
              ))}
            </Grid>
          )}
          <div className={cn.buttonContainer}>
            {selected.scopes ? (
              <Dropdown
                prefix={scopePrefix}
                items={selected.scopes
                  .map(this.scopeIndexToName)
                  .filter(this.props.isAvailableScope)}
                selected={this.state.selectedScope}
                onSelectChanged={selectedScope =>
                  this.setState({ selectedScope })
                }
                localization={localization}
              />
            ) : null}
            <Button
              variant="contained"
              color="primary"
              disabled={!selected.insertCode || !this.state.selectedScope}
              onClick={this.handleInsertAssetSelected}
              startIcon={<Add />}
            >
              {localization.editorCard.insert}
            </Button>
          </div>
        </Popover>
      </>
    );
  }
}

export default withTheme(AssetButtonWrapper);
