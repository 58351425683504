import Button, { ButtonProps } from '@material-ui/core/Button';
import * as React from 'react';
import { style } from 'typestyle';

const cn = {
  root: style({
    marginRight: 20
  })
};

export function Confirm(props: ButtonProps) {
  return (
    <Button
      variant="contained"
      color="primary"
      className={cn.root}
      {...props}
    />
  );
}

export function Abort(props: ButtonProps) {
  return (
    <Button variant="text" className={cn.root} {...props}>
      {props.children || 'Cancel'}
    </Button>
  );
}
