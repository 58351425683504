import { IPackage } from '@hackforplay/assets';
import { makeRe } from 'minimatch';
import { combineEpics } from 'redux-observable';
import { distinctUntilChanged, filter, first, map } from 'rxjs/operators';
import { convertAsset } from '../utils/convertAsset';
import { filterTruly } from '../utils/filterTruly';
import { skinNames } from '../utils/urls';
import { actions, actions as _actions } from './actions';
import { jsonApi } from './api';
import { asyncEpic } from './asyncEpic';
import { reducerWithImmer } from './reducer-with-immer';
import { ofAction } from './typescript-fsa-redux-observable';

export interface ISkinNames {
  [name: string]: {
    en: string;
    ja: string;
    paid?: boolean;
  };
}

export type State = {
  isCompatibleMode: boolean;
  assetPackage: IPackage;
  version?: string;
  /**
   * スキンの名前の一覧
   */
  skinNames?: ISkinNames;
};

export const initialState: State = {
  isCompatibleMode: false,
  assetPackage: {
    version: '',
    categories: [],
    scopes: [],
    module: {},
    buttons: []
  }
};

const compatibleAssetRegExp = makeRe('feeles/*.asset.yml');

export const epics = combineEpics(
  // Load assets
  (action$, state$) =>
    action$.pipe(
      ofAction(_actions.make.load.started),
      map(action => action.payload.assetVersion),
      filterTruly,
      filter(version => version !== state$.value.asset.version),
      map(version => actions.asset.loadAsset.started({ version }))
    ),
  jsonApi(
    actions.asset.loadAsset,
    payload => `https://assets.hackforplay.xyz/${payload.version}`
  ),
  // Convert asset from files
  (action$, state$) =>
    state$.pipe(
      filter(state => state.asset.isCompatibleMode),
      map(state => state.file.files),
      distinctUntilChanged(),
      map(files => actions.asset.setCompatibleAsset(files))
    ),
  // Load skin names
  action$ =>
    action$.pipe(
      first(),
      map(() => actions.asset.fetchSkinNames.started())
    ),
  asyncEpic(actions.asset.fetchSkinNames, async () => {
    const response = await fetch(skinNames);
    return await response.json();
  })
);

export default reducerWithImmer(initialState)
  .case(actions.asset.loadAsset.started, (draft, payload) => {
    if (draft.version !== payload.version) {
      draft.assetPackage = initialState.assetPackage;
      draft.version = payload.version;
    }
  })
  .case(actions.asset.loadAsset.done, (draft, { result }) => {
    draft.assetPackage = result;
  })
  .case(_actions.make.load.started, (draft, payload) => {
    // asset が与えられなかった => compatible mode でアセットを生成する
    if (!payload.assetVersion) {
      // assetVersion が与えられた場合は epics で値をチェックしたいので, 変更しない
      // 与えられなかった場合だけ互換モードに設定する
      draft.isCompatibleMode = true;
      draft.version = undefined;
    }
  })
  .case(actions.asset.setCompatibleAsset, (draft, payload) => {
    if (!draft.isCompatibleMode) return;
    // 旧アセット定義を変換して使用する
    const configs = payload
      .filter(file => compatibleAssetRegExp.test(file.name))
      .map(file => file.text + '');
    const compatibleAsset = convertAsset(configs);
    draft.assetPackage = compatibleAsset;
  })
  .case(_actions.make.trash, draft => {
    draft.isCompatibleMode = false;
  })
  .case(actions.asset.fetchSkinNames.done, (draft, payload) => {
    draft.skinNames = payload.result;
  })
  .toReducer();
