import { grey, red } from '@material-ui/core/colors';
import * as React from 'react';
import { style } from 'typestyle';
import { Localization } from '../../localization';

export interface ErrorMessageProps {
  error: Error | null;
  setLocation: (location?: string) => void;
  localization: Localization;
}

const cn = {
  root: style({
    position: 'absolute',
    width: '100%',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: red['500'],
    zIndex: 3000
  }),
  pre: style({
    width: '100%',
    maxHeight: '8rem',
    color: 'white',
    overflow: 'scroll',
    fontFamily: 'Consolas, "Liberation Mono", Menlo, Courier, monospace'
  }),
  button: style({
    background: grey[500],
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 2,
    margin: 4
  })
};

export default class ErrorMessage extends React.PureComponent<ErrorMessageProps> {
  static defaultProps = {
    error: null
  };

  state = {
    open: false
  };

  get message() {
    return this.props.error ? this.props.error.message : '';
  }

  componentDidUpdate(prevProps: ErrorMessageProps) {
    if (prevProps.error !== this.props.error) {
      this.setState({
        open: this.props.error !== null
      });
    }
  }

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    return this.state.open ? (
      <div className={cn.root}>
        <div
          style={{ cursor: 'pointer', alignSelf: 'flex-end' }}
          onClick={this.handleClose}
        >
          <svg fill="white" style={{ height: 24 }} viewBox="0 0 24 24">
            <path
              d={
                'M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'
              }
            />
          </svg>
        </div>
        <pre className={cn.pre}>{this.message}</pre>
        <div>
          <button
            onClick={() => this.props.setLocation()}
            className={cn.button}
          >
            <span>{this.props.localization.monitorCard.reload}</span>
            <svg fill="white" style={{ height: 24 }} viewBox="0 0 24 24">
              <path
                d={`M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z`}
              />
            </svg>
          </button>
        </div>
      </div>
    ) : null;
  }
}
