import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { useFirestore } from '../hooks/useFirestore';
import { useRecoilEffect } from '../hooks/useRecoilEffect';
import {
  commentAtom,
  convertToComment,
  workCommentIdsAtom,
  workReplyIdsAtom
} from '../recoil';
import { LoadingPage } from './LoadingPage';
import { WorkCommentForm } from './WorkCommentForm';
import { WorkCommentItem } from './WorkCommentItem';

export interface WorkInfoCommentsProps {
  id: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing() * 12
  }
}));

export function WorkInfoComments(props: WorkInfoCommentsProps) {
  // ステージに対するコメントを取得する
  const comments = useRecoilValue(workCommentIdsAtom(props.id));
  const [error, setError] = React.useState<Error>();
  if (error) {
    throw error;
  }
  const firestore = useFirestore();
  useRecoilEffect(
    ({ set }) => {
      // 直近のコメント100件を Subscribe
      return firestore()
        .collection('comments')
        .where('workId', '==', props.id)
        .orderBy('createdAt', 'desc')
        .limit(100)
        .withConverter(convertToComment)
        .onSnapshot({
          next: snapshot => {
            const commentIds = [] as string[];
            const replyIds = new Map<string, string[]>();
            snapshot.docChanges().forEach(change => {
              set(commentAtom(change.doc.id), change.doc.data());
            });
            snapshot.forEach(qs => {
              const parentId = qs.data().parentId;
              if (parentId) {
                const curr = replyIds.get(parentId) || [];
                curr.unshift(qs.id); // スレッドの中は順番が逆になる
                replyIds.set(parentId, curr);
              } else {
                commentIds.push(qs.id);
              }
            });
            set(workCommentIdsAtom(props.id), commentIds);
            replyIds.forEach((ids, key) => {
              set(workReplyIdsAtom(key), ids);
            });
          },
          error: setError
        });
    },
    [props.id]
  );

  const cn = useStyles();

  return React.useMemo(() => {
    return (
      <div className={cn.root}>
        <WorkCommentForm workId={props.id} />
        {error ? (
          <Typography variant="body1" color="textSecondary">
            コメントを取得できませんでした
          </Typography>
        ) : comments ? (
          comments.length > 0 ? (
            comments.map(commentId => (
              <WorkCommentItem
                key={commentId}
                commentId={commentId}
                parentId={commentId}
                workId={props.id}
              />
            ))
          ) : (
            <Typography color="textSecondary">
              コメントはまだありません
            </Typography>
          )
        ) : (
          <LoadingPage />
        )}
      </div>
    );
  }, [comments, error]);
}
