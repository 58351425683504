import { Button, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';
import { convertToWorkVersion } from '../ducks/make';
import { useFirestore } from '../hooks/useFirestore';
import { versionAtom } from '../recoil';
import { Link } from '../utils/components';
import { LoadingPage } from './LoadingPage';
import { noticeAtom } from './NoticeManager';
import { messageOf } from '../utils/error';

type Params = {
  versionId: string;
  workId: string;
};

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing() * 4,
    '&>*': {
      marginTop: theme.spacing() * 6
    }
  }
}));

/**
 *
 */
export function WorkRollback() {
  const { versionId, workId } = useParams<Params>();
  const [error, setError] = React.useState<string>();

  const firestore = useFirestore();
  const history = useHistory();
  const rollback = useRecoilCallback(async ({ getPromise, set }) => {
    try {
      const version = await getPromise(versionAtom(versionId));
      if (!version) {
        setError('対象のバージョンが見つかりませんでした');
        return;
      }
      // このバージョンの時の assetStoragePath に上書きする
      const workRef = firestore().collection('works').doc(workId);
      await workRef.update({
        assetStoragePath: version.storagePath,
        updatedAt: firestore.FieldValue.serverTimestamp()
      });
      // 新しいバージョンアイテムを追加する
      const newItem: IWorkVersion = {
        createdAt: firestore.Timestamp.now(),
        fileSize: version.fileSize,
        storagePath: version.storagePath,
        rollbackId: versionId
      };
      if (version.thumbnailUrl) {
        newItem.thumbnailUrl = version.thumbnailUrl;
      }
      await workRef
        .collection('versions')
        .withConverter(convertToWorkVersion)
        .add(newItem);
      // Snackbar を出す
      set(noticeAtom, {
        severity: 'success',
        children: 'ロールバックに成功しました 🎉'
      });
      // ステージに戻る
      history.replace(`/works/${workId}/edit`);
    } catch (error) {
      setError(messageOf(error) || 'エラー');
    }
  }, []);
  React.useEffect(() => {
    rollback();
  }, []);

  const cn = useStyles();

  if (error) {
    return (
      <div className={cn.root}>
        <Typography variant="h2" color="textSecondary">
          ロールバックに失敗しました
        </Typography>
        <Typography variant="body1" align="center">
          {error}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link(`/works/${workId}/edit`)}
        >
          ステージに戻る
        </Button>
      </div>
    );
  }

  return (
    <div className={cn.root}>
      <Typography variant="h2">ロールバックしています</Typography>
      <LoadingPage />
    </div>
  );
}
