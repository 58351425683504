import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { Theme, withTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { ArrowDropDown, PlayCircleOutline, Refresh } from '@material-ui/icons';
import { debounce } from 'lodash-es';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { style } from 'typestyle';
import ImmutableFile from '../../File/ImmutableFile';
import { Localization } from '../../localization';

export interface PlayMenuProps {
  theme: Theme;
  runApp: (location?: string) => void;
  href: string;
  localization: Localization;
  localChange: boolean;
  // Injected by withFiles (for JSX)
  files: ImmutableFile[];
}

interface State {
  open: boolean;
  anchorEl: null | HTMLElement;
  entries: { title: string; href: string }[];
  hasMultiEntry: boolean;
  hasPlayed: boolean;
}

const cn = {
  currentSecondaryText: style({
    marginLeft: 8,
    fontSize: '.8rem',
    opacity: 0.6
  }),
  menu: style({
    maxHeight: 300
  }),
  href: style({
    marginLeft: 8,
    fontSize: '.8rem',
    opacity: 0.6
  })
};
const getCn = (props: PlayMenuProps) => ({
  dropDown: style({
    marginLeft: props.theme.spacing(),
    minWidth: 0,
    paddingLeft: 0,
    paddingRight: 0
  }),
  current: style({
    marginTop: -8,
    marginBottom: -8,
    backgroundColor: fade(props.theme.palette.primary.main, 0.1)
  }),
  button: style({
    marginRight: props.theme.spacing()
  })
});

export function PlayMenuWrapper(
  props: Omit<PlayMenuProps, 'files' | 'localChange'>
) {
  let files = useSelector(state => state.file.files);
  files = files.filter(file => !file.isTrashed); // ゴミ箱にあるファイルを除外
  files = files.filter(file => file.name.endsWith('.html')); // watch の対象でないファイルを除外
  const localChange = useSelector(state => state.file.localChange);

  return <PlayMenu {...props} files={files} localChange={localChange} />;
}

export default withTheme(PlayMenuWrapper);

class PlayMenu extends React.PureComponent<PlayMenuProps, State> {
  state = {
    open: false,
    anchorEl: null,
    // [...{ title, href(name) }]
    entries: [],
    hasMultiEntry: false,
    /**
     * 一度でもプレイボタンを押したか？
     */
    hasPlayed: false
  } as State;

  static getDerivedStateFromProps(props: PlayMenuProps, state: State) {
    const hasMultiEntry = props.files.length > 1;
    if (state.hasMultiEntry !== hasMultiEntry) {
      return { hasMultiEntry };
    }
    return null;
  }

  handleShowPopover = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const parser = new DOMParser();
    const entries = this.props.files
      .map((file: any) => {
        const doc = parser.parseFromString(file.text, 'text/html');
        const titleNode = doc.querySelector('title');
        const title = titleNode && titleNode.textContent;
        return {
          title: title || file.name,
          href: file.name
        };
      })
      .sort((a, b) => (a.title > b.title ? 1 : -1));

    this.setState({
      open: true,
      anchorEl: event.currentTarget,
      entries
    });
  };

  handlePlay = debounce(
    () => {
      this.props.runApp();
      this.setState({ hasPlayed: true });
    },
    5000,
    {
      leading: true,
      trailing: false
    }
  );

  handleItemTouchTap = debounce(
    (event, menuItem) => {
      this.props.runApp(menuItem.props.value);
      this.setState({
        open: false
      });
    },
    5000,
    {
      leading: true,
      trailing: false
    }
  );

  handleRequestClose = () => {
    this.setState({
      open: false
    });
  };

  renderMenu = (entry: any) => {
    return (
      <MenuItem key={entry.href} value={entry.href}>
        <span>{entry.title}</span>
        <span className={cn.href}>{entry.href}</span>
      </MenuItem>
    );
  };

  render() {
    const dcn = getCn(this.props);
    const { localization } = this.props;

    const current = this.state.entries.find(
      item => item.href === this.props.href
    );

    return (
      <>
        <Tooltip
          title={localization.editorCard.playAfterEdit}
          open={!this.state.hasPlayed && this.props.localChange}
          arrow
        >
          <Button
            variant="contained"
            color={this.props.localChange ? 'primary' : 'default'}
            className={dcn.button}
            onClick={this.handlePlay}
            startIcon={<PlayCircleOutline />}
          >
            {localization.editorCard.play}
          </Button>
        </Tooltip>
        {this.state.hasMultiEntry ? (
          <Button
            variant="text"
            color="primary"
            className={dcn.dropDown}
            onClick={this.handleShowPopover}
          >
            <ArrowDropDown />
          </Button>
        ) : null}
        <Popover
          className={cn.menu}
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.handleRequestClose}
        >
          {current && [
            <MenuItem key="current" value={current.href}>
              <ListItemIcon>
                <Refresh />
              </ListItemIcon>
              <ListItemText
                inset
                primary={current.title}
                secondary={
                  <span className={cn.currentSecondaryText}>Ctrl + Space</span>
                }
              />
              {current.title}
            </MenuItem>,
            <Divider key="divider" />
          ]}
          {this.state.entries.map(this.renderMenu)}
        </Popover>
      </>
    );
  }
}
