import { Typography } from '@material-ui/core';
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField';
import * as React from 'react';
import { useDebouncedCallback } from '../hooks/useDebouncedCallback';

export type EditableTextFieldProps = Omit<StandardTextFieldProps, 'value'> & {
  readonly?: boolean;
  defaultValue?: string;
  onEdit: (value: string) => void;
};

type OnChange = NonNullable<StandardTextFieldProps['onChange']>;

export const EditableTextField = React.forwardRef<any, EditableTextFieldProps>(
  (props, ref) => {
    const { readonly, onEdit, defaultValue, id, className, ...restProps } =
      props;
    const [value, setValue] = React.useState(defaultValue);
    const prevId = React.useRef<string>(); // id が変化したことを検知するための変数
    React.useEffect(() => {
      if (value === undefined && defaultValue) {
        setValue(defaultValue); // defaultValue が undefined だった時だけ value を更新する
        // onEdit(defaultValue); // 必要ないと思ったのでコメントアウトした
      } else if (prevId.current !== id) {
        setValue(defaultValue); // id が変わったときに defaultValue を更新する
      }
      prevId.current = id;
    }, [defaultValue, id]);

    const onEditRef = React.useRef(onEdit);
    onEditRef.current = onEdit;
    const [debouncedEdit, handleEdit] = useDebouncedCallback(
      (value: string) => {
        if (readonly) return;
        onEditRef.current(value);
      },
      [readonly],
      500
    );

    const onChange = React.useCallback<OnChange>(
      event => {
        if (readonly) return;
        setValue(event.target.value);
        props.onChange && props.onChange(event);
        debouncedEdit(event.target.value);
      },
      [readonly]
    );
    const onBlur = React.useCallback(
      event => {
        if (readonly) return;
        props.onBlur && props.onBlur(event);
        value && handleEdit(value);
        debouncedEdit.cancel();
      },
      [value, readonly]
    );

    return readonly ? (
      <Typography variant="h6" className={className} ref={ref}>
        {value}
      </Typography>
    ) : (
      <TextField
        {...restProps}
        ref={ref}
        id={id}
        className={className}
        onChange={onChange}
        value={value || ''}
        onBlur={onBlur}
      />
    );
  }
);
