import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { ArrowForward, Close } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { Asearch, MatchMode } from 'asearch.ts';
import { debounce } from 'lodash-es';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Subject } from 'rxjs';
import { classes, style } from 'typestyle';
import { canAuthUserUseAsset } from '../../../../ducks/user';
import { useDebouncedCallback } from '../../../../hooks/useDebouncedCallback';
import { skinIcons } from '../../../../utils/urls';
import { objectFit } from '../../../../utils/xlasses';
import { Localization } from '../../localization';

const regex = /(costume\(|みためをかえる\()['"]([^'"]+)['"]/g;

interface Selection {
  start: CodeMirror.Position;
  end: CodeMirror.Position;
  text: string;
}

/**
 * 現在選択されている部分
 * CodeMirror Widget と React Component の橋渡しをする
 */
const selection$ = new Subject<Selection | undefined>();

export interface SkinSelectorProviderProps {
  codemirror?: CodeMirror.Editor;
  localization: Localization;
  onChange: (replace: string) => void;
}

const useStyles = makeStyles(theme => ({
  searchText: {
    marginBottom: theme.spacing()
  },
  scroller: {
    height: '50vh', // 絞り込み検索で高さがガタガタしないよう固定にする
    overflow: 'scroll',
    paddingTop: 24,
    paddingBottom: 80
  },
  item: {
    textAlign: 'center',
    cursor: 'pointer',
    width: '14em',
    transition: theme.transitions.create('background-color', {
      duration: 100
    }),
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey.A100
    }
  },
  selected: {
    backgroundColor: theme.palette.grey[200],
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
    textDecoration: 'line-through'
  },
  previews: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 24
    }
  },
  closer: {
    position: 'absolute',
    right: 0,
    top: 0
  }
}));

/**
 * アセットの名前を簡単に書き換える UI を提供する
 */
export function SkinSelectorProvider({
  codemirror,
  localization,
  onChange
}: SkinSelectorProviderProps) {
  const cn = useStyles();
  const [selection, setSelection] = React.useState<Selection>();
  const showDialog = Boolean(selection);

  const widgetsRef = React.useRef<Element[]>([]);
  React.useEffect(() => {
    if (!codemirror) return;

    const render = debounce(
      () => {
        widgetsRef.current.forEach(node => node.parentNode?.removeChild(node));
        widgetsRef.current = renderWidgets(codemirror);
      },
      500,
      { leading: true }
    );
    render();
    codemirror.on('update', render);
    const closer = () => {
      // コードが変わったら Selection がズレてしまうので閉じる
      setSelection(undefined);
    };
    codemirror.on('beforeChange', closer);
    return () => {
      render.cancel();
      codemirror.off('update', render);
      codemirror.off('beforeChange', closer);
    };
  }, [codemirror]);

  React.useEffect(() => {
    const sub = selection$.subscribe(setSelection);
    return () => {
      sub.unsubscribe();
    };
  }, []);

  const close = React.useCallback(() => {
    selection$.next(undefined);
    setSearchText(''); // 絞り込み検索はリセットする
  }, []);

  const [selected, setSelected] = React.useState<string>();
  const replaceToSelected = React.useCallback(() => {
    close();
    if (!selected || !selection) return;
    codemirror?.replaceRange(selected, selection.start, selection.end);
    onChange(selected);
  }, [codemirror, selection, selected, onChange]);

  const [searchText, setSearchText] = React.useState(''); // 絞り込み検索
  const [setSearchTextDebounced] = useDebouncedCallback(setSearchText, [], 100); // 検索はやや重いので debounce する

  const skinNames = useSelector(state => state.asset.skinNames);
  const canUseAsset = useSelector(canAuthUserUseAsset);
  const buttons = React.useMemo(() => {
    if (!skinNames) return null;
    const asearch = new Asearch(searchText, MatchMode.Include);
    const ambig = Math.max(1, Math.min(2, searchText.length - 2)); // 4 文字以上入力したら曖昧度を 2 にする
    return Object.values(skinNames)
      .filter(
        names =>
          asearch.match(names.ja, ambig) || asearch.match(names.en, ambig)
      ) // 絞り込み検索
      .sort((a, b) => (a.ja > b.ja ? 1 : -1)) // ja でソートする
      .map((names, i) => (
        <Grid
          key={i}
          item
          className={classes(
            cn.item,
            selected === names.en && cn.selected,
            selected === names.ja && cn.selected,
            !canUseAsset && names.paid && cn.disabled
          )}
          onClick={() =>
            setSelected(selected === names.ja ? undefined : names.ja)
          }
        >
          <Preview skinName={names.ja} />
        </Grid>
      ));
  }, [skinNames, selected, canUseAsset, searchText, showDialog]);

  // scroller の横幅を固定する
  const scrollerRef = React.useRef<HTMLDivElement>(null);
  const [minWidth, setMinWidth] = React.useState(0);
  React.useEffect(() => {
    // 横幅を取得して minWidth にセットする => 絞り込みをして件数が減っても、横幅が小さくならない
    const width = scrollerRef.current?.getBoundingClientRect().width || 0;
    setMinWidth(current => Math.max(current, width));
  }, [buttons]);

  return (
    <Dialog open={showDialog} maxWidth="md" onClose={close}>
      <IconButton className={cn.closer} onClick={close}>
        <Close />
      </IconButton>
      <DialogTitle>{localization.editorCard.replaceToOtherSkin}</DialogTitle>
      <DialogContent>
        {canUseAsset ? null : (
          <Alert
            severity="warning"
            action={
              <Button
                variant="outlined"
                color="primary"
                target="_blank"
                href="/promotion"
              >
                {localization.editorCard.seeMore}
              </Button>
            }
          >
            {localization.editorCard.youCanNotUsePaidAssets}
          </Alert>
        )}
      </DialogContent>
      <DialogContent>
        <TextField
          name="searchText"
          variant="outlined"
          size="small"
          label="Search"
          defaultValue="" // debounce の影響を受けないよう value は与えない
          onChange={event => setSearchTextDebounced(event.target.value)}
          className={cn.searchText}
        />
        <Grid
          container
          alignItems="flex-start"
          className={cn.scroller}
          ref={scrollerRef}
          style={{ minWidth }}
        >
          {buttons}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <div className={cn.previews}>
          <Preview
            skinName={selection?.text}
            label={localization.editorCard.currentSkin}
          />
          <ArrowForward />
          <Preview
            skinName={selected}
            label={localization.editorCard.selectedSkin}
          />
        </div>
        <Button
          disabled={!selected}
          variant="contained"
          color="primary"
          onClick={replaceToSelected}
        >
          {localization.editorCard.replace}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface PreviewProps {
  label?: string;
  skinName?: string;
}

const useStylesPreview = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  img: {
    height: 64,
    width: 64,
    marginRight: 4
  },
  name: {
    fontSize: '0.75rem'
  }
}));

function Preview({ skinName, label }: PreviewProps) {
  const cn = useStylesPreview();

  return (
    <div>
      {label ? (
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
      ) : null}
      <div className={cn.wrapper}>
        <img
          src={skinName ? `${skinIcons}/${skinName}.png` : ''}
          className={classes(cn.img, objectFit.contain)}
          alt={skinName}
        />
        <Typography variant="body2" className={cn.name}>
          {skinName}
        </Typography>
      </div>
    </div>
  );
}

const cn = {
  widget: style({
    color: 'transparent',
    borderBottom: '1px solid #a11',
    transform: 'translate(0px, -18px)',
    cursor: 'pointer',
    zIndex: 10
  })
};

function renderWidgets(editor: CodeMirror.Editor) {
  const codes = editor.getValue().split('\n');
  const widgets: Element[] = [];

  for (let line = 0; line < codes.length; line++) {
    const code = codes[line];
    regex.lastIndex = 0; // 正規表現のマッチを初期化
    for (let index = 0; index < 100; index++) {
      const result = regex.exec(code);
      if (!result) break;
      const [_, _prefix, text] = result; // 3番目にアセット名が来る
      const start: CodeMirror.Position = {
        line,
        ch: result.index + _prefix.length + 1 // ' の分足す
      };
      const end: CodeMirror.Position = {
        line,
        ch: start.ch + text.length
      };
      const element = document.createElement('div');
      element.textContent = text;
      element.className = cn.widget;
      element.addEventListener(
        'click',
        () => {
          selection$.next({
            text,
            start,
            end
          });
        },
        { passive: true }
      );
      editor.addWidget(start, element, false);
      widgets.push(element);
    }
  }
  return widgets;
}
