export default {
  accept: ['en', 'en-us'],
  native: 'English',
  ll_CC: 'en_US',

  menu: {
    clone: 'Save and Load',
    aboutFeeles: 'About Feeles',
    you: 'You',
    version: 'Version',
    showAllUrls: 'Show all URLs'
  },
  cloneDialog: {
    saveTitle: 'Save',
    saveHeader: 'Save to the browser',
    loadTitle: 'Load',
    loadHeader: 'Load from the browser',
    overwriteSave: 'Overwrite Save',
    saveInNew: 'Save in New Slot',
    remove: 'Remove',
    openOnThisTab: 'Open on This Tab',
    openInNewTab: 'Open in New Tab',
    created: 'Creation date and time',
    updated: 'Update date and time',
    size: 'Data size',
    project: 'Export project as JSON',
    save: 'Save',
    cancel: 'Cancel',
    saveHTML: 'Save Only HTML',
    saveLibrary: 'Save Only Library',
    saveAll: 'Save All',
    failedToSave: 'Failed to save this app.',
    failedToRemove: 'Failed to remove this app.',
    failedToOpenTab: 'Failed to open an app because new tab is blocked!!',
    failedToRename:
      'It is not possible to create two projects with the same title.',
    titleIsRequired: 'Project title is required to open via URL.',
    autoSaved: 'It is automatically saved ;)',
    setTitle: 'Please set title'
  },
  saveDialog: {
    title:
      'You need to download it manually, because Your browser does not support HTML5!',
    description: (filename: string) =>
      `Right click on the above link, click "download with alias", and save it with the name "${filename}"`,
    cancel: 'Cancel'
  },
  aboutDialog: {
    title: '',
    coreVersion: 'Core Version',
    changeVersion: 'Change Version',
    change: 'Change'
  },
  addDialog: {
    title: '',
    fileName: 'File name',
    add: 'Add',
    cancel: 'Cancel'
  },
  launchDialog: {
    title: '',
    description:
      'Some saved data are found. If you want to load it, please click "Load this data"',
    openProject: 'Open this project',
    startNew: 'Start'
  },
  shotCard: {
    title: '',
    shoot: 'After rewriting,',
    button: 'send the code',
    restore: 'Restore'
  },
  hierarchyCard: {
    title: '',
    emptyTrashBox: 'Empty trash'
  },
  common: {
    clickToSelect: 'Click to select',
    close: 'Close',
    tapTwiceQuickly: 'Tap twice quickly to edit',
    cannotBeUndone: 'This operation can not be undone',
    editFile: 'Edit File',
    conflict:
      'A file with the same name already exists. Do you want to overwrite it?',
    or: 'or',
    wantToOpen: (name: string) => `Do you want to open ${name}?`
  },
  editorCard: {
    title: '',
    replaceToOtherAsset: 'Replace to other asset',
    currentAsset: 'Current asset',
    selectedAsset: 'Selected asset',
    replace: 'Replace',
    replaceNotice:
      'It will be another character not only in appearance but also in different behavior',
    youCanNotUsePaidAssets:
      'Some assets are restricted in the free version. If you start monthly subscription, you can use everything',
    seeMore: 'See more',
    replaceToOtherSkin: 'Replace to other skin',
    currentSkin: 'Current skin',
    selectedSkin: 'Selected skin',
    playAfterEdit: 'Click play button after editing!',
    insertTo: (name: string) => `Insert to ${name}`,
    insertToStage: `Insert to stage`,
    undo: 'Undo',
    play: 'Play',
    notice: 'This tab has not saved. Are you sure?',
    insert: 'Insert into stage',
    onceMoreInsert: 'Once more insert',
    edit: (name: string) => `Hack ${name}`,
    stopEditing: (name: string) => `Close ${name}`,
    error: '😇💭 Oops, a little typo...',
    errorFollow: 'Everyone mistakes',
    restore: 'Restore before mistake',
    copy: 'Copy',
    copied: 'Copied!',
    cut: 'Cut',
    paste: 'Paste',
    sendCode: 'Send code',
    deleteLine: 'Delete a line',
    copyLine: 'Copy a line',
    pasteLine: 'Paste',
    clickHere: '⭐️ Click here',
    selectedScope: ', these can be used',
    hankaku: `Converted to Hankaku charactor`,
    lineBreakInString: `Line break cannot be used in string. Use \\n instead`,
    ok: `OK`,
    needPayment: `To use this, you need to sign in and subscribe montly plan`,
    noAssetAt: (name: string) => `There are no "${name}" at this file.`,
    noAssetDescription:
      'To open other file (see detail of the charactor), please click icons of the charactor',
    stopAction: 'Stop this function'
  },
  envCard: {
    title: '',
    remove: 'Remove'
  },
  monitorCard: {
    title: '',
    mapSelector: (name: string) => `Change platform of ${name}`,
    mapSelectorTitle: `Select platform`,
    officials: 'Officials',
    yours: 'Yours',
    reload: 'Reload',
    open: 'Open',
    confirm: 'Confirm'
  },
  customizeCard: {
    title: '',
    detail: 'Click here for details',
    editor: 'Tabs and line wrapping ...etc',
    style: 'Color of text and background ...etc'
  },
  mediaCard: {
    title: ''
  }
};
