import { ActionsObservable } from 'redux-observable';
import { from } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { AnyAction, AsyncActionCreators } from 'typescript-fsa';
import { actions } from './actions';
import { requestWithAuth } from './requestWithAuth';

type Body = Record<string, string> | Blob;

export const api =
  <Params, Result, Error = {}>(
    asyncActionCreators: AsyncActionCreators<Params, Result, Error>,
    endpoint: string | ((params: Params) => string),
    method = 'GET',
    body?: (params: Params) => Body
  ) =>
  (action$: ActionsObservable<AnyAction>) =>
    action$.pipe(
      filter(asyncActionCreators.started.match),
      mergeMap(action =>
        from(
          requestWithAuth(
            typeof endpoint === 'string' ? endpoint : endpoint(action.payload),
            method,
            body && body(action.payload)
          )
            .then(result =>
              asyncActionCreators.done({ params: action.payload, result })
            )
            .catch(error =>
              asyncActionCreators.failed({ params: action.payload, error })
            )
        ).pipe(takeUntil(action$.pipe(filter(actions.auth.signOut.match))))
      )
    );

export const jsonApi =
  <Params, Result>(
    asyncActionCreators: AsyncActionCreators<Params, Result, Error>,
    endpoint: (params: Params) => string
  ) =>
  (action$: ActionsObservable<AnyAction>) =>
    action$.pipe(
      filter(asyncActionCreators.started.match),
      mergeMap(action =>
        from(
          fetch(endpoint(action.payload))
            .then(response => response.text())
            .then(json => JSON.parse(json))
            .then(result =>
              asyncActionCreators.done({ params: action.payload, result })
            )
            .catch(error =>
              asyncActionCreators.failed({ params: action.payload, error })
            )
        )
      )
    );
