import { Container, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect, Route } from 'react-router-dom';
import { style } from 'typestyle';
import { canUseExplanation } from '../../ducks/user';
import { Idea } from './Idea';
import { Story } from './Story';

const cn = {
  container: style({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 32,
    padding: 128
  })
};

export function GenerativeAI() {
  const isEnabled = useSelector(canUseExplanation);
  if (!isEnabled)
    return (
      <Container className={cn.container}>
        <Typography variant="h4">
          この機能は現在有料プラン限定機能です🙇‍♂️
        </Typography>
        <Link to="/plans">プランを確認する</Link>
      </Container>
    );

  return (
    <>
      <Route path="/generative-ai" exact>
        <Redirect to="/generative-ai/story" />
      </Route>
      <Route path="/generative-ai/story" component={Story} />
      <Route path="/generative-ai/idea" component={Idea} />
    </>
  );
}
