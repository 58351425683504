import moment from 'moment';
import 'moment/locale/ja';
import 'normalize.css';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, Middleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import { Action } from 'typescript-fsa';
import { App } from './components/App';
import { ErrorBoundary } from './components/ErrorBoundary';
import reducer, { rootEpic, StoreState } from './ducks';
import './styles/index.scss';
import { isOldBrowser } from './isOldBrowser';
import MaterialUIJssProvider from './MaterialUIJssProvider';
import './settings/firebase';

require('object-fit-images')();

type A = Action<any>;

// IE では Rollbar を無効にする
if (isOldBrowser) {
  window.Rollbar?.configure({ enabled: false });
}

moment.locale(navigator.language);

const epicMiddleware = createEpicMiddleware<A, A, StoreState>();

const middleware: Middleware[] = [epicMiddleware];
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
}

const enhancer = composeWithDevTools(applyMiddleware(...middleware));

/**
 * Singleton application state made by redux
 */
const store = createStore(reducer, enhancer);

// 開発中に store にアクセスするための変数
(window as any).__redux_store__ = store;

if (!isOldBrowser) {
  epicMiddleware.run(rootEpic);

  const RootApp = () => {
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <MaterialUIJssProvider>
            <App />
          </MaterialUIJssProvider>
        </Provider>
      </ErrorBoundary>
    );
  };

  const app = document.getElementById('app');

  if (!app) {
    throw new Error('app container is not found');
  }

  ReactDOM.render(<RootApp />, app);
}
