import { Theme, withTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { style } from 'typestyle';

export interface CardFloatingBarProps {
  padding?: number;
  theme: Theme;
}

const getCn = (props: CardFloatingBarProps) => ({
  root: style({
    display: 'flex',
    alignItems: 'stretch',
    padding:
      props.padding !== undefined ? props.padding : props.theme.spacing(),
    width: '100%',
    boxSizing: 'border-box',
    zIndex: 900,
    position: ['-webkit-sticky', 'sticky'],
    top: 0,
    backgroundColor: props.theme.palette.background.paper
  })
});

class CardFloatingBar extends React.PureComponent<CardFloatingBarProps> {
  render() {
    const dcn = getCn(this.props);
    return <div className={dcn.root}>{this.props.children}</div>;
  }
}

export default withTheme(CardFloatingBar);
