import {
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { WorkListItem } from './WorkList';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4),
    padding: theme.spacing(6)
  },
  headline: {
    marginBottom: theme.spacing(4)
  }
}));

export function SearchList() {
  const cn = useStyles();
  const search = useSelector(state => state.work.search);

  return (
    <Paper elevation={2} id="search" className={cn.root}>
      <Typography variant="h5" className={cn.headline}>
        「{search.query}」の検索結果
      </Typography>
      <Grid container justify="center" spacing={1}>
        {search.errorMessage ? (
          <Grid item>
            <Typography variant="h5">
              {`エラーが発生しました`}
              <span role="img" aria-label="Confused">
                {`😕`}
              </span>
              {search.errorMessage}
            </Typography>
          </Grid>
        ) : search.loading ? (
          <Grid item>
            <CircularProgress />
          </Grid>
        ) : (
          search.result?.map(id => (
            <WorkListItem
              key={id}
              id={id}
              showVisibility
              className="search-result"
              getTimestamp={() => undefined} // 検索結果の一致順に並べる
            />
          )) || null
        )}
      </Grid>
    </Paper>
  );
}
