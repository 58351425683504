import React from 'react';
import { useSelector } from 'react-redux';

import { canUseChatWithAI } from '../../ducks/user';
import { ChatContainer } from './ChatContainer';

export function ExperimentalAvatarChat() {
  const isEnabled = useSelector(canUseChatWithAI);

  return <>{isEnabled ? <ChatContainer /> : null}</>;
}
