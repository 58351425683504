import {
  Button,
  MenuItem,
  Paper,
  Popover,
  PopoverPosition,
  Typography,
  useTheme
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { ArrowDownward } from '@material-ui/icons';
import { every } from 'lodash-es';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { style } from 'typestyle';
import { WorkUrlParams } from '../../../../components/Work';
import {
  cardPropsAtom,
  cardVisibilityAtom,
  globalEventAtom,
  hrefAtom,
  isEditorCardExpandedAtom,
  localizationAtom
} from '../../atoms';
import { useGlobalEvent } from '../../hooks/useGlobalEvent';
import CardFloatingBar from '../CardFloatingBar';
import MapSelector from './MapSelector';
import Monitor from './Monitor';
import ResolveProgress from './ResolveProgress';

export const clearCheckingAtom = atom({
  key: 'clearCheckingAtom',
  default: false
});

const cn = {
  root: style({
    minHeight: 300,
    position: 'relative',
    width: 0,
    boxSizing: 'border-box',
    maxWidth: '100%'
  }),
  blank: style({
    flex: 1
  }),
  parent: style({
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'visible' // position: sticky のために必要
  }),
  hideSlaask: style({
    display: 'none !important'
  }),
  backdrop: style({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 1200,
    cursor: 'pointer'
  }),
  paper: style({
    position: 'absolute',
    width: '100%',
    zIndex: 1201,
    display: 'flex',
    alignItems: 'center',
    padding: 6,
    paddingLeft: 10,
    boxSizing: 'border-box',
    transform: 'translateY(-12px)'
  }),
  paperIcon: style({
    marginRight: 8
  })
};

export function MonitorCard() {
  const theme = useTheme();
  const globalEvent = useRecoilValue(globalEventAtom);
  const isExpandingEditorCard = useRecoilValue(isEditorCardExpandedAtom);
  const href = useRecoilValue(hrefAtom);
  const localization = useRecoilValue(localizationAtom);
  const cardVisibility = useRecoilValue(cardVisibilityAtom);
  const visible = cardVisibility.MonitorCard;
  const order = useRecoilValue(cardPropsAtom)?.MonitorCard.order;

  const [statusLabel, setStatusLabel] = React.useState('');
  useGlobalEvent(
    'message.statusLabel',
    event => {
      const value = event.data?.value;
      if (typeof value !== 'string') {
        throw new TypeError(`Cannot make statusLabel ${value}`);
      }
      setStatusLabel(value);
    },
    []
  );

  const params = useParams<WorkUrlParams>();
  const isPlaying = params[0] === 'works' && params.action !== 'edit'; // 作る画面ではなく遊ぶ画面

  // hide slaask button when monitor only
  const restrained = useSelector(state => state.make.restrained);
  const isOwner = useSelector(state => state.make.isOwner);
  const isMonitorOnly =
    isPlaying ||
    (cardVisibility.MonitorCard &&
      every(cardVisibility, (value, key) => key === 'MonitorCard' || !value)) ||
    (restrained && !isOwner);
  React.useEffect(() => {
    if (isMonitorOnly) {
      for (const node of Array.from(
        document.querySelectorAll('.slaask-component')
      )) {
        node.classList.add(cn.hideSlaask);
      }
    } else {
      for (const node of Array.from(document.querySelectorAll(cn.hideSlaask))) {
        node.classList.remove(cn.hideSlaask);
      }
    }
  }, [isMonitorOnly]);

  React.useEffect(
    () => () => {
      for (const node of Array.from(document.querySelectorAll(cn.hideSlaask))) {
        node.classList.remove(cn.hideSlaask);
      }
    },
    []
  );

  return (
    <div
      id="MonitorCard"
      className={cn.root}
      style={{
        flexBasis: !visible
          ? 0
          : isPlaying
          ? '100%'
          : isMonitorOnly
          ? '100%'
          : isExpandingEditorCard
          ? '25%'
          : '50%',
        order,
        padding: !visible ? 0 : isMonitorOnly ? 0 : 16,
        overflow: visible ? 'initial' : 'hidden'
      }}
    >
      <div
        className={cn.parent}
        style={{
          boxShadow: theme.shadows[1]
        }}
      >
        {isMonitorOnly || isPlaying ? null : (
          <CardFloatingBar>
            <span>{localization.monitorCard.title}</span>
            <div className={cn.blank} />
            <MapSelector
              show={!isMonitorOnly}
              selected={statusLabel}
              localization={localization}
            />
            <IconButton disabled>
              <ResolveProgress size={24} globalEvent={globalEvent} />
            </IconButton>
          </CardFloatingBar>
        )}
        <ClearCheckBackdrop />
        <Monitor
          href={href}
          localization={localization}
          globalEvent={globalEvent}
        />
      </div>
      <div id={`MonitorCard-BottomAnchor`} />
    </div>
  );
}

function ClearCheckBackdrop() {
  const [clearChecking, setClearChecking] = useRecoilState(clearCheckingAtom);
  const [menuPosition, setMenuPosition] = React.useState<PopoverPosition>();
  React.useEffect(() => {
    if (!clearChecking) {
      setMenuPosition(undefined);
    }
  }, [clearChecking]);

  return clearChecking ? (
    <>
      <Paper elevation={2} className={cn.paper}>
        <ArrowDownward className={cn.paperIcon} />
        <Typography variant="body1">
          ステージがクリアできるか、チェックしよう！
        </Typography>
        <div className={cn.blank}></div>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setClearChecking(false)}
        >
          やめる
        </Button>
      </Paper>
      <div
        className={cn.backdrop}
        onClick={event =>
          setMenuPosition({ left: event.clientX, top: event.clientY })
        }
      ></div>
      <Popover
        open={Boolean(menuPosition)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
        onClose={() => setMenuPosition(undefined)}
      >
        <MenuItem onClick={() => setClearChecking(false)}>
          クリアチェックをやめる
        </MenuItem>
      </Popover>
    </>
  ) : null;
}
