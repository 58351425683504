import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import { actions } from '../ducks/actions';
import { authUidAtom } from '../recoil';
import firebase from '../settings/firebase';

const auth = firebase.auth;

export function SubscribeAuth() {
  const dispatch = useDispatch();
  const setAuthUid = useSetRecoilState(authUidAtom); // Recoil にもデータを保持する

  React.useEffect(() => {
    let unsubscribe = () => {};
    Promise.resolve()
      // ブラウザ ウィンドウを閉じたり React Native でアクティビティが破棄されたりした場合でも、状態が維持されることを示します。この状態をクリアするには、明示的なログアウトが必要です
      .then(() => auth().setPersistence(auth.Auth.Persistence.LOCAL))
      // firebase.auth().signInWithRedirect(provider); されて戻ってきた
      .then(() => auth().getRedirectResult())
      // Auth の状態を購読する
      .then(() => {
        unsubscribe = auth().onAuthStateChanged(
          user => {
            if (user) {
              dispatch(actions.auth.signedIn(user));
              connectExternalService(user);
            } else {
              dispatch(actions.auth.signedOut());
            }
            setAuthUid(user?.uid);
          },
          err => {
            dispatch(actions.auth.noticeError(err));
          }
        );
      })
      .catch((err: firebase.auth.Error) => {
        dispatch(actions.auth.noticeError(err));
      });

    return () => {
      unsubscribe();
    };
  }, []);

  return null;
}

function connectExternalService(user: firebase.User) {
  if (process.env.NODE_ENV === 'production') {
    window.gtag?.('event', 'login', {
      method:
        user.providerId === auth.GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD
          ? 'Google'
          : user.providerId, // ← こっちでいいのでは？
      non_interaction: true
    });
  }
}
