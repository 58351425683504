import { combineEpics } from 'redux-observable';
import { NEVER } from 'rxjs';
import {
  distinct,
  distinctUntilChanged,
  filter,
  map,
  mergeMap
} from 'rxjs/operators';
import { endpoint } from '../env';
import firebase from '../settings/firebase';
import { filterTruly } from '../utils/filterTruly';
import { actions } from './actions';
import { api } from './api';
import { asyncEpic, SKIP } from './asyncEpic';
import { getAuthUser } from './auth';
import * as helpers from './helpers';
import { Statefull } from './helpers';
import { reducerWithImmer } from './reducer-with-immer';
import { requestWithAuth } from './requestWithAuth';
import { StoreState } from './type';
import { ofAction } from './typescript-fsa-redux-observable';
import { eachUser } from './utils';

const firestore = firebase.firestore;

// 最終的な Root Reducere の中で、ここで管理している State が格納される名前
export const storeName = 'user';

export type UserType = Statefull<IUserDocument>;

export type State = {
  byUid: {
    [key: string]: UserType | undefined;
  };
};

const initialState: State = {
  byUid: {}
};

// Root Reducer
export default reducerWithImmer(initialState)
  .case(actions.user.load, (draft, payload) => {
    draft.byUid[payload] = helpers.processing();
  })
  .case(actions.user.set, (draft, payload) => {
    draft.byUid[payload.uid] = helpers.from(payload.user);
  })
  .case(actions.user.update.started, (draft, payload) => {
    draft.byUid[payload.uid] = helpers.processing();
  })
  .case(actions.user.fetch.started, (draft, payload) => {
    draft.byUid[payload.uid] = helpers.processing();
  })
  .case(actions.user.updateIcon.done, (draft, { params, result }) => {
    const user = draft.byUid[params.uid];
    user && user.data && (user.data.iconUrl = result.url);
  })
  .toReducer();

export const epics = combineEpics(
  // ユーザーのプロフィールをいっぺんに更新する
  asyncEpic(actions.user.updateProfile, async action => {
    const { blob, src, color, displayName, uid } = action.payload;
    const api =
      endpoint +
      `/uploadImage?type=icon${
        src ? `&url=${encodeURIComponent(src)}` : ''
      }&color=${encodeURIComponent(color)}`;
    // アイコン画像をアップロードする
    const result = await requestWithAuth<{ url: string }>(api, 'POST', blob);
    // プロフィールを更新する
    await firebase.firestore().collection('users').doc(uid).set(
      {
        displayName,
        iconUrl: result.url,
        profile: { color }
      },
      { merge: true }
    );
  }),

  // update user
  asyncEpic(actions.user.update, async (action, state) => {
    const { userInfo } = state.auth;
    if (!userInfo) return SKIP;

    await firebase
      .firestore()
      .collection('users')
      .doc(userInfo.uid)
      .update(action.payload.data);

    return {};
  }),

  api(
    actions.user.updateIcon,
    '/api/uploadImage',
    'POST',
    payload => payload.blob
  ),
  eachUser((userInfo, action$) =>
    action$.pipe(
      ofAction(actions.user.updateIcon.done),
      mergeMap(({ payload }) => {
        const { url } = payload.result;
        if (typeof url === 'string') {
          firestore().collection('users').doc(payload.params.uid).update({
            iconUrl: url
          });
        }
        // TODO: 例外処理
        return NEVER;
      })
    )
  ),

  // fetch user data
  (action$, state$) =>
    action$.pipe(
      filter(actions.user.fetchIfNeeded.match),
      map(action => action.payload.uid),
      distinct(),
      filter(uid => !state$.value.user.byUid[uid]),
      map(uid => actions.user.fetch.started({ uid }))
    ),
  asyncEpic(actions.user.fetch, async action => {
    const result = await firebase
      .firestore()
      .collection('users')
      .doc(action.payload.uid)
      .get();

    return result;
  }),

  action$ =>
    action$.pipe(
      ofAction(actions.user.fetch.done),
      map(({ payload }) =>
        actions.user.set({
          uid: payload.params.uid,
          user: payload.result.data() as IUserDocument | undefined
        })
      )
    ),

  // 認証ユーザーのユーザーをセットする
  (action$, state$) =>
    state$.pipe(
      map(getAuthUser),
      filterTruly,
      distinctUntilChanged(),
      map(authUser => actions.user.setAuthUser(authUser))
    )
);

// Helpers

export function getUserByUid(store: StoreState, uid: string): UserType {
  return getState(store).byUid[uid] || helpers.initialized();
}

export function getState(store: StoreState): State {
  return store[storeName];
}

/**
 * 現在のログインユーザーが、有料アセットを使うことができるかどうかを取得する
 * 互換性を維持するため plans がない場合は isPaid を使う
 */
export function canAuthUserUseAsset(store: StoreState) {
  const authUser = getAuthUser(store);
  const plans = authUser?.plans;
  return Boolean(plans !== undefined ? plans.canUseAsset : authUser?.isPaid);
}

/**
 * 現在のログインユーザーが、生成AI機能(AI解説、ストーリー生成など)を使うことができるかどうかを取得する
 */
export function canUseExplanation(store: StoreState) {
  // const authUser = getAuthUser(store);
  // const plans = authUser?.plans;
  // return plans?.canUseExplanation || false;
  return true; // 期間限定で全ユーザーに解説機能を提供するため、常に true を返す
}

/**
 * 現在のログインユーザーが、エラー解説機能を使うことができるかどうかを取得する
 */
export function canUseErrorFollowAI(store: StoreState) {
  const authUser = getAuthUser(store);
  const plans = authUser?.plans;
  return plans?.canUseExplanation || false; // ひとまず同じフラグを使う
}

/**
 * 現在のログインユーザーが、AIチャットを使うことができるかどうかを取得する
 */
export function canUseChatWithAI(store: StoreState) {
  if (
    process.env.NODE_ENV === 'development' &&
    location.hostname.endsWith('ngrok-free.app')
  ) {
    return true; // ngrok での開発時は常に有効
  }
  const authUser = getAuthUser(store);
  const plans = authUser?.plans;
  return plans?.canChatWithAI || false;
}
