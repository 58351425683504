import { Asset, Entry } from 'contentful';
import * as React from 'react';
import { classes, style } from 'typestyle';

export interface EmbeddedAssetProps {
  asset: Asset;
  block?: boolean;
}

export function isEmbeddedAsset(asset: Asset | Entry<unknown>): asset is Asset {
  return asset.sys.type === 'Asset';
}

const cn = {
  img: style({
    maxWidth: '100%'
  }),
  blockImg: style({
    display: 'block'
  })
};

export function EmbeddedAsset({ asset, block = false }: EmbeddedAssetProps) {
  const {
    file: { contentType, url },
    title
  } = asset.fields;

  if (contentType.startsWith('image/')) {
    return (
      <img
        src={url}
        alt={title}
        className={classes(cn.img, block && cn.blockImg)}
      />
    );
  }
  console.warn(asset);
  return null;
}
