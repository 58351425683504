import { IOutput } from '@hackforplay/assets';
import { Button, IconButton, Popover, Tooltip } from '@material-ui/core';
import { Theme, withTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  Add,
  Close,
  ColorLens,
  ColorLensOutlined,
  Home,
  MoreHoriz
} from '@material-ui/icons';
import { Pos } from 'codemirror';
import {
  debounce,
  differenceWith,
  flatten,
  forEach,
  includes,
  intersection,
  uniq,
  uniqBy,
  without
} from 'lodash-es';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { classes, style } from 'typestyle';
import { StoreState } from '../../../../ducks';
import { actions } from '../../../../ducks/file';
import { canAuthUserUseAsset } from '../../../../ducks/user';
import ImmutableFile from '../../File/ImmutableFile';
import { Localization } from '../../localization';
import { assetRegExp } from '../../utils/keywords';
import replaceExistConsts from '../../utils/replaceExistConsts';
import AssetButton from './AssetButton';
import AssetLink, { assetLinkWidth } from './AssetLink';
import { AssetSelectorProvider } from './AssetSelectorProvider';
import extractAssetNames from './extractAssetNames';
import { SkinSelectorProvider } from './SkinSelectorProvider';
import {
  ExecuteCallbackParams,
  InsertAssetPayload,
  OpenFilePayload
} from './type';

export interface AssetPaneProps {
  label: string;
  codemirror: CodeMirror.Editor;
  theme: Theme;
  runApp: (location?: string) => void;
  localization: Localization;
  globalEvent: any;
  filePath: string;
  filePathToBack: string;
  isExpandingEditorCard: boolean;
  saveFileIfNeeded: () => void;
  // Injected by withAsset
  canUseAssetButton: (item: IOutput) => boolean;
  asset: StoreState['asset'];
  // Injected by withFiles (for JSX)
  files: ImmutableFile[];
  putFiles: typeof actions.putFiles;
}

interface State {
  show: boolean;
  activeCategoryIndex: number;
  scopeIndexes: number[];
  defaultScope: string;
  assetNamesOfLinks: string[];
  /**
   * 全ての色を同時に表示するモード
   */
  showAllVariations: boolean;
  /**
   * 全てのスコープを表示するモード. scopeIndexes を考慮しない
   */
  showAllScopes: boolean;
  /**
   * リンクで移動する前の label を保持する (Home='')
   */
  previousLabel: string;
  linkPopoverTarget: null | Element;
  /**
   * インストール中のモジュール名
   */
  installingFileNames: string[] | null;
  /**
   * インストール後の挙動
   */
  callback: ExecuteCallbackParams;
}

const paneHeight = 80; // %
export const pathToInstall = 'modules'; // 後方互換性のために変更しない (TODO: feelesrc で上書きできるように)
const pathToAutoload = 'autoload.js'; // 後方互換性のために変更しない (TODO: feelesrc で上書きできるように)

const cn = {
  in: style({
    top: `${100 - paneHeight}vh`
  }),
  out: style({
    top: '100vh'
  }),
  label: style({
    flex: '0 0 100%',
    color: 'rgb(255,255,255)',
    textAlign: 'center',
    marginTop: 16,
    fontWeight: 600
  }),
  wrapper: style({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  }),
  assetLinkContainer: style({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap'
  }),
  assetLinkButton: style({
    margin: 4,
    marginRight: 0,
    height: assetLinkWidth,
    width: assetLinkWidth,
    minWidth: assetLinkWidth // ButtonBase の minWidth を打ち消す
  }),
  blank: style({
    flex: '1 1 auto'
  }),
  popoverClasses: {
    paper: style({
      padding: '16px 8px 8px 4px'
    })
  },
  moreButton: style({
    padding: 6,
    margin: 6
  }),
  noAssetButton: style({
    color: 'rgb(255,255,255)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '1.25rem',
    fontWeight: 600,
    $nest: {
      '&>*': {
        marginBottom: 16
      }
    }
  })
};
const getCn = ({ theme }: AssetPaneProps) => ({
  root: style({
    position: 'fixed',
    width: '100%',
    height: `${paneHeight}vh`,
    padding: theme.spacing(),
    boxSizing: 'border-box',
    zIndex: theme.zIndex.modal - 1,
    left: 0,
    transition: theme.transitions.create('top'),
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: fade(theme.palette.text.primary, 0.75)
  }),
  scroller: style({
    flex: 1,
    overflowX: 'auto',
    overflowY: 'scroll',
    boxSizing: 'border-box',
    paddingBottom: 24,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  }),
  scopeWrapper: style({
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    fontWeight: 600
  }),
  scope: style({
    padding: theme.spacing(),
    marginRight: theme.spacing(),
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius
  }),
  closer: style({
    position: 'absolute',
    top: theme.spacing(),
    right: theme.spacing(),
    color: theme.palette.common.white
  }),
  toggle: style({
    position: 'absolute',
    bottom: theme.spacing(),
    right: theme.spacing(),
    color: theme.palette.common.white
  }),
  categoryWrapper: style({
    display: 'flex',
    flexWrap: 'nowrap',
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4)
  }),
  category: style({
    flex: 1,
    fontWeight: 600,
    cursor: 'pointer',
    color: theme.palette.grey[600],
    borderBottom: `4px solid ${theme.palette.grey[600]}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    $nest: {
      '&>img': {
        maxWidth: 48,
        height: 48
      }
    }
  }),
  active: style({
    color: theme.palette.common.white,
    borderBottomColor: theme.palette.common.white
  }),
  previousLinkButton: style({
    borderColor: theme.palette.primary.main
  }),
  addButton: style({
    marginRight: theme.spacing()
  })
});

export function AssetPaneWrapper(
  props: Omit<
    AssetPaneProps,
    'asset' | 'canUseAssetButton' | 'files' | 'putFiles'
  >
) {
  const asset = useSelector(state => state.asset);
  const isPaid = useSelector(canAuthUserUseAsset);
  const canUseAssetButton = (item: IOutput) => isPaid || item.plan === 'free';
  let files = useSelector(state => state.file.files);
  files = files.filter(file => !file.isTrashed); // ゴミ箱にあるファイルを除外
  files = files.filter(file => file.name.endsWith('.js')); // watch の対象でないファイルを除外
  const dispatch = useDispatch();
  const putFiles: any = React.useCallback((payload: any) => {
    dispatch(actions.putFiles(payload));
  }, []);

  return (
    <AssetPane
      {...props}
      asset={asset}
      canUseAssetButton={canUseAssetButton}
      files={files}
      putFiles={putFiles}
    />
  );
}

class AssetPane extends React.PureComponent<AssetPaneProps, State> {
  state: State = {
    show: false,
    activeCategoryIndex: -1,
    scopeIndexes: [],
    defaultScope: '',
    assetNamesOfLinks: [],
    showAllVariations: false, // 全ての色を同時に表示するモード
    showAllScopes: false, // 全てのスコープを表示するモード. scopeIndexes を考慮しない
    previousLabel: '', // リンクで移動する前の label を保持する (Home='')
    linkPopoverTarget: null,
    // オートインストール
    installingFileNames: null, // インストール中のモジュール名
    callback: {
      // インストール後の挙動
      type: ''
    }
  };

  _widgets = new Map();
  _pendingInstallModule: [string[], ExecuteCallbackParams][] = [];

  componentDidMount() {
    const cm = this.props.codemirror;
    cm.on('change', this.handleUpdateWidget);
    cm.on('swapDoc', this.handleUpdateWidget);
    cm.on('update', this.handleRenderWidget);
    cm.on('beforeChange', this.handleIndexReplacement);
    cm.on('change', this.handleIndentLine);
    cm.on('swapDoc', this.updateAssetLink);
    cm.on('change', this.updateAssetLink);

    this.handleUpdateWidget(cm);
    this.handleRenderWidget(cm);
    this.updateAssetLink(cm);
    this.props.globalEvent.on('message.install', this.handleInstallMessage);
    this.installAssetsFromCodeMirror(cm);
  }

  componentWillUnmount() {
    this.props.globalEvent.off('message.install', this.handleInstallMessage);
  }

  componentDidUpdate(prevProps: AssetPaneProps) {
    const { files, label } = this.props;
    const { installingFileNames, callback } = this.state;
    // オートインストールの完了待ち
    if (installingFileNames && prevProps.files !== this.props.files) {
      const all = files.map(file => file.name);
      if (installingFileNames.every(filePath => includes(all, filePath))) {
        // 全てのファイルが含まれているので, 待ち状態を初期化してコールバックを実行
        this.setState(
          {
            installingFileNames: null,
            callback: { type: '' }
          },
          () => this.executeCallback(callback)
        );
      }
    }
    // previousLabel: ラベルが変わったとき, 前回の label を保持
    if (label !== prevProps.label) {
      const wasInHome = prevProps.filePath === prevProps.filePathToBack;
      this.setState({
        previousLabel: wasInHome ? '' : prevProps.label
      });
    }
    // アセットのダウンロードが完了したら直ちにリンクを表示する
    if (this.props.asset !== prevProps.asset) {
      this.updateAssetLink(this.props.codemirror);
    }
  }

  insertAsset = ({ insertCode, scopeName }: InsertAssetPayload) => {
    const line = this.getLineFromScopeName(scopeName);
    if (typeof line !== 'number') return;
    const cm = this.props.codemirror;
    // バーが常に上に来るよう, 下に挿入
    const pos = new Pos(line + 1, 0);
    const end = new Pos(pos.line + insertCode.split('\n').length, 0);
    insertCode = '\n' + insertCode;
    cm.replaceRange(insertCode, pos, pos, 'asset');
    // スクロール
    cm.scrollIntoView(
      {
        from: pos,
        to: end
      },
      10
    );
    // カーソル (挿入直後に undo したときスクロールが上に戻るのを防ぐ)
    cm.focus();
    cm.setCursor(new Pos(end.line - 1, 0));
    // Pane をとじる
    this.handleClose();
    // 新しいアセットが更新された可能性が高いので, アセットのリンクを更新
    this.updateAssetLink(cm);
    // 実行
    this.props.runApp();
  };

  updateAssetLink = debounce(cm => {
    // ソースコードに含まれるキーワードを抜き出して Scrapbox 風のリンクを表示する
    const { assetPackage } = this.props.asset;
    const assetNamesOfLinks = extractAssetNames(cm.getValue()).filter(
      assetName => Boolean(assetPackage.module[assetName])
    );
    this.setState({ assetNamesOfLinks });
  }, 100);

  _availableScopes: string[] = [];
  isAvailableScope = (scopeName: string) =>
    includes(this._availableScopes, scopeName);
  isAvailableScopeIndex = (scopeIndex: number) =>
    this.props.asset.assetPackage.scopes[scopeIndex] &&
    this.isAvailableScope(
      this.props.asset.assetPackage.scopes[scopeIndex].name
    );

  handleUpdateWidget = (cm: CodeMirror.Editor) => {
    this._widgets.clear();
    this._availableScopes = []; // init
    const lines = cm.getValue().split('\n');
    for (let line = 0; line < lines.length; line++) {
      this.updateWidget(cm, line, lines[line]);
    }
  };

  updateWidget = (cm: CodeMirror.Editor, line: number, text: string) => {
    const { asset } = this.props;
    // Syntax: /*+ モンスター アイテム */
    const tokens = assetRegExp.exec(text);
    if (tokens) {
      const [, _prefix, _left, _label, _right] = tokens.map(t =>
        t.replace(/\t/g, '    ')
      );
      const prefix = document.createElement('span');
      prefix.textContent = _prefix;
      prefix.classList.add('Feeles-asset-blank');
      const left = document.createElement('span');
      left.textContent = _left;
      left.classList.add('Feeles-asset-blank');
      const label = document.createElement('span');
      label.textContent = _label;
      const right = document.createElement('span');
      right.textContent = _right;
      right.classList.add('Feeles-asset-blank');
      const button = document.createElement('span');
      button.classList.add('Feeles-asset-button');
      button.onclick = event => {
        const scopeIndexes: number[] = [];
        let activeCategoryIndex = -1;
        let defaultScope = '';
        // バーに書かれた文字列の中に scope.name があれば選択
        forEach(asset.assetPackage.scopes, (scope, index: number) => {
          if (includes(_label, scope.name)) {
            scopeIndexes.push(index);
            // 先頭のスコープで, 初期表示カテゴリと初期スコープを決める
            if (activeCategoryIndex < 0) {
              activeCategoryIndex = scope.defaultActiveCategory;
              defaultScope = scope.name;
            }
          }
        });
        this.setState({
          show: true,
          scopeIndexes,
          showAllScopes: false,
          defaultScope,
          activeCategoryIndex
        });
        event.stopPropagation();
      };
      button.appendChild(left);
      button.appendChild(label);
      button.appendChild(right);
      const parent = document.createElement('div');
      parent.classList.add('Feeles-widget', 'Feeles-asset');
      parent.appendChild(prefix);
      parent.appendChild(button);
      this._widgets.set(line, parent);
      const availableScopes = _label.split(' ').filter(s => s);
      this._availableScopes.unshift(...availableScopes);
    }
  };

  handleRenderWidget = (cm: CodeMirror.Editor) => {
    // remove old widgets
    for (const widget of [
      ...Array.from(document.querySelectorAll('.Feeles-asset'))
    ]) {
      if (widget.parentNode) {
        widget.parentNode.removeChild(widget);
      }
    }
    // render new widgets
    this._widgets.forEach((element, i) => {
      // fold されていないかを確認
      const lineHandle = cm.getLineHandle(i);
      if (lineHandle.height > 0) {
        cm.addWidget(new Pos(i, 0), element, false);
      }
    });
  };

  handleIndexReplacement = (
    cm: CodeMirror.Editor,
    change: CodeMirror.EditorChangeCancellable
  ) => {
    if (!includes(['asset', 'paste'], change.origin)) return;

    const code = cm.getValue('\n');
    const sourceText = change.text.join('\n');
    const replacedText = replaceExistConsts(code, sourceText);
    if (sourceText !== replacedText && change.update) {
      change.update(change.from, change.to, replacedText.split('\n'));
    }
  };

  handleIndentLine = (
    cm: CodeMirror.Editor,
    change: CodeMirror.EditorChangeLinkedList
  ) => {
    if (!includes(['asset', 'paste'], change.origin)) return;
    const { from } = change;
    const to = new Pos(from.line + change.text.length, 0);
    // インデント
    for (let line = from.line; line < to.line; line++) {
      cm.indentLine(line);
    }
  };

  handleClose = () => {
    this.setState({
      show: false
    });
  };

  openFile = async ({ name, filePath, label, iconUrl }: OpenFilePayload) => {
    filePath = filePath || (name && `${pathToInstall}/${name}.js`);
    if (!filePath) return;
    try {
      await this.props.saveFileIfNeeded(); // リンクで移動する前に変更を保存する
      // 保存に失敗した場合は、ファイルを開かない
      this.props.globalEvent.emit('message.editor', {
        data: {
          value: filePath,
          options: {
            showBackButton: Boolean(label), // アセットのコードを閉じて以前のファイルに戻るボタンを表示する
            label, // ↑そのボタンを、この名前で「${label}の改造をおわる」と表示
            iconUrl
          }
        }
      });
    } catch (error) {
      console.error(error);
    }

    this.handleClose(); // Pane をとじる
  };

  /**
   * アセットに含まれるモジュールをプロジェクトにコピーする
   */
  installAssetModules = (
    assetNames: string[],
    callback: ExecuteCallbackParams
  ) => {
    if (this.state.installingFileNames) {
      // すでに別のモジュールをインストール中
      this._pendingInstallModule.push([assetNames, callback]); // 前回コールされた installModule の終了を待ってから実行する
      return;
    }
    const { asset, files } = this.props;

    // module が存在するかどうか
    for (const assetName of assetNames) {
      const mod = asset.assetPackage.module[assetName];
      if (!mod) throw new Error(`${assetName} is not exist in asset.module`); // TODO: 例外処理
    }

    const toPath = (name: string) => `${pathToInstall}/${name}.js`; // この名前で rule.this を決めているので, 命名規則は変えない

    const notInstalled = differenceWith(
      assetNames,
      files,
      (assetName, file) => toPath(assetName) === file.name
    );
    if (notInstalled.length === 0) {
      // すでに全部インストールされている
      this.executeCallback(callback);
      return;
    }

    // まずコールバックを設定してからファイルをコピー
    const installingFileNames = notInstalled.map(toPath);
    this.setState({ installingFileNames, callback }, () => {
      const autoload = this.props.files.find(
        file => file.name === pathToAutoload
      );
      if (!autoload) throw new Error(`${pathToAutoload} is not found`);

      // autoload.js の更新
      let text = autoload.text;
      if (text && text.substr(-1) !== '\n') text += '\n';
      for (const assetName of notInstalled) {
        text += `import '${pathToInstall}/${assetName}'\n`;
      }
      // ファイルのコピー
      this.props.putFiles({
        files: [
          new ImmutableFile({
            type: autoload.type,
            name: autoload.name,
            text
          }),
          ...notInstalled.map(
            assetName =>
              new ImmutableFile({
                type: 'text/javascript',
                name: toPath(assetName),
                text: asset.assetPackage.module[assetName].code
              })
          )
        ],
        isUserAction: false
      });
    });
  };

  /**
   * AssetSelectorProvider からアセットをインストールするための関数
   */
  installAssetFromSelectorProvider = (assetName: string) => {
    const dependencies = this.getDependencies(assetName);
    this.installAssetModules(dependencies, { type: 'runApp' });
  };

  /**
   * そのアセットを動作させるためにインストールする必要があるアセット(自分自身を含む)を列挙する
   * 重複を除くために, 同じ配列 _ignoreList に要素を追加していく (動的計画法, 深さ優先探索)
   */
  getDependencies = (name: string, _foundList: string[] = []) => {
    const mod = this.props.asset.assetPackage.module[name];
    if (!mod) return _foundList;
    _foundList.push(name);
    for (const dependency of extractAssetNames(mod.code)) {
      if (!includes(_foundList, dependency)) {
        // 新しいアセットが見つかったら, 再帰的に依存アセットを探す
        this.getDependencies(dependency, _foundList);
      }
    }
    return _foundList;
  };

  getDependenciesFromCode = (code: string, _foundList = []) => {
    for (const dependency of extractAssetNames(code)) {
      if (!includes(_foundList, dependency)) {
        // 新しいアセットが見つかったら, 再帰的に依存アセットを探す
        this.getDependencies(dependency, _foundList);
      }
    }
    return _foundList;
  };

  executeCallback = (params: ExecuteCallbackParams) => {
    if (params.type === 'insertAsset') this.insertAsset(params.payload);
    if (params.type === 'openFile') this.openFile(params.payload);
    if (params.type === 'runApp') this.props.runApp();
    // pending 状態のインストールを実行
    const next = this._pendingInstallModule.shift();
    if (next) {
      this.installAssetModules.apply(this, next);
    }
  };

  _pendingAssetsToInstall: string[] = [];
  handleInstallMessage = (event: any) => {
    const {
      data: { value: name }
    } = event;
    const { asset } = this.props;
    // module が存在するなら先に install
    const mod = asset.assetPackage.module[name];
    if (mod) {
      this._pendingAssetsToInstall.push(name); // ここでは pending list に入れるだけ
      this.installAssetsFromMessage(); // この関数でインストールする
    } else {
      // そもそもアセットの名前を間違えているかも知れない
    }
  };

  installAssetsFromMessage = debounce(
    (() => {
      // install message を一定時間 debounce して, 一度にインストール
      const { length } = this._pendingAssetsToInstall;
      if (length <= 0) return; // ない

      const assetNames = uniq(
        flatten(
          this._pendingAssetsToInstall
            .splice(0, length) // pending list から削除
            .map(assetName => this.getDependencies(assetName)) // 依存アセットもここで同時にインストール
        )
      );
      // インストール後に runApp
      this.installAssetModules(assetNames, {
        type: 'runApp'
      });
    }).bind(this),
    16
  );

  /**
   * エディタ起動直後に必要なモジュールをインストールする
   */
  installAssetsFromCodeMirror = (cm: CodeMirror.Editor) => {
    if (!cm) return;
    const code = cm.getValue();
    if (!code) return;
    const assetNames = this.getDependenciesFromCode(code);
    this.installAssetModules(assetNames, {
      type: ''
    });
  };

  /**
   * Widget が今何行目にあるのかは分からないため,
   * 与えられた scopeName を頼りにソースコードから探す
   * @returns scopeName が含まれる行番号
   */
  getLineFromScopeName(scopeName: string) {
    const lines = this.props.codemirror.getValue().split('\n');
    for (let line = 0; line < lines.length; line++) {
      const tokens = assetRegExp.exec(lines[line]);
      if (tokens) {
        const [, , , _label] = tokens;
        if (includes(_label, scopeName)) {
          return line;
        }
      }
    }
  }

  handleInsertAsset = ({ insertCode, scopeName }: InsertAssetPayload) => {
    // 依存アセットもここで同時にインストール
    const dependencies = this.getDependenciesFromCode(insertCode);
    // インストール後に insertAsset
    this.installAssetModules(dependencies, {
      type: 'insertAsset',
      payload: { insertCode, scopeName }
    });
  };

  handleOpenFile = ({ name, filePath, iconUrl }: OpenFilePayload) => {
    const { asset } = this.props;
    if (filePath) {
      // インストール済みなので開く
      this.openFile({ name, filePath, label: name, iconUrl });
      return;
    }
    // module が存在するなら先に install
    if (name && asset.assetPackage.module[name]) {
      // インストール後に openFile
      this.installAssetModules([name], {
        type: 'openFile',
        payload: { name, filePath, label: name, iconUrl }
      });
    } else {
      // 開けるかどうか試す
      this.openFile({ name, filePath, label: name, iconUrl });
    }
  };

  handleBackButtonClick = () => {
    this.openFile({
      filePath: this.props.filePathToBack
    });
  };

  handleMoreButtonClick = debounce(
    () => {
      this.setState({
        show: true,
        showAllScopes: true,
        defaultScope: '',
        activeCategoryIndex: 0
      });
    },
    5000,
    {
      leading: true,
      trailing: false
    }
  );

  handleToggle = () => {
    this.setState(prevState => ({
      showAllVariations: !prevState.showAllVariations
    }));
  };

  handleOpenLinkPopover = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    this.setState({
      linkPopoverTarget: event.currentTarget
    });
  };

  handleCloseLinkPopover = () => {
    if (this.state.linkPopoverTarget === null) {
      return;
    }
    this.setState({
      linkPopoverTarget: null
    });
  };

  renderAssetButtons(assetButtons: IOutput[]) {
    assetButtons = uniqBy(assetButtons, 'name'); // key を name にするので被らないようにする
    const keyPrefix = this._availableScopes.join(' ') + ' '; // bugfix: 置けるスコープが変わったら, AssetButton の state をリフレッシュする
    const { showAllVariations } = this.state;
    const scopePrefix =
      this.props.filePath === this.props.filePathToBack
        ? undefined
        : this.props.label;

    if (showAllVariations) {
      // 全てのバリエーションを並べる
      return assetButtons.map((assetButton: IOutput) =>
        assetButton.variations ? (
          <React.Fragment key={assetButton.name + '-container'}>
            {this.renderAssetButtons(
              assetButton.variations.filter(this.props.canUseAssetButton)
            )}
          </React.Fragment>
        ) : (
          <AssetButton
            key={keyPrefix + assetButton.name}
            name={assetButton.name}
            description={assetButton.description}
            iconUrl={assetButton.iconUrl}
            insertCode={assetButton.insertCode}
            scopes={assetButton.scopes}
            insertAsset={this.handleInsertAsset}
            openFile={this.handleOpenFile}
            localization={this.props.localization}
            globalEvent={this.props.globalEvent}
            isAvailableScope={this.isAvailableScope}
            defaultScope={this.state.defaultScope}
            scopePrefix={scopePrefix}
          />
        )
      );
    } else {
      // クリックでバリエーションを展開する
      return assetButtons.map(assetButton => (
        <AssetButton
          key={keyPrefix + assetButton.name}
          name={assetButton.name}
          description={assetButton.description}
          iconUrl={assetButton.iconUrl}
          insertCode={assetButton.insertCode}
          variations={assetButton.variations}
          scopes={assetButton.scopes}
          insertAsset={this.handleInsertAsset}
          openFile={this.handleOpenFile}
          localization={this.props.localization}
          globalEvent={this.props.globalEvent}
          isAvailableScope={this.isAvailableScope}
          defaultScope={this.state.defaultScope}
          scopePrefix={scopePrefix}
        />
      ));
    }
  }

  render() {
    const dcn = getCn(this.props);
    const { localization, filePath, filePathToBack } = this.props;
    const {
      linkPopoverTarget,
      show,
      activeCategoryIndex,
      scopeIndexes,
      showAllScopes,
      assetNamesOfLinks,
      previousLabel
    } = this.state;
    const { scopes, categories, buttons } = this.props.asset.assetPackage;

    const showingScopes = scopes.filter((_, i) => includes(scopeIndexes, i));

    const _showingButtons = buttons.filter(
      b => b.category === activeCategoryIndex
    );
    const showingButtons = showAllScopes
      ? _showingButtons.filter(
          b => b.scopes === null || b.scopes.some(this.isAvailableScopeIndex)
        ) // スコープによらず, 今置くことのできる全てのアセットボタンを表示する
      : _showingButtons.filter(
          b => b.scopes === null || intersection(b.scopes, scopeIndexes).length
        ); // スコープが被っているものに絞る (scopes === null || は互換性保持のため)

    const showBackButton = filePath !== filePathToBack;

    const activeCategoryName =
      (categories[activeCategoryIndex] &&
        categories[activeCategoryIndex].name) ||
      '';

    // アセットリンクの表示限界個数を求める
    const calcMaxLength = (width: number) => {
      if (width === undefined) return 0;
      const moreButtonWidth = 36 + 6 * 2;
      const num =
        Math.floor(
          (width - moreButtonWidth) / assetLinkWidth // 全体 width / リンク１個分の width
        ) - 1; // 直前のアセットへのリンクの分を引く
      return Math.max(0, num);
    };

    return (
      <>
        {/* Scrapbox 風のアセットのリンク */}
        <div className={cn.assetLinkContainer}>
          {showBackButton ? (
            <Tooltip
              title={this.props.localization.editorCard.stopEditing(
                this.props.label
              )}
            >
              <Button
                variant="contained"
                color="primary"
                className={cn.assetLinkButton}
                onClick={this.handleBackButtonClick}
              >
                <Home fontSize="small" />
              </Button>
            </Tooltip>
          ) : null}
          {previousLabel ? (
            <AssetLink
              key={previousLabel}
              name={previousLabel}
              showPopover={false}
              className={classes(cn.assetLinkButton, dcn.previousLinkButton)}
              openFile={this.handleOpenFile}
              insertAsset={this.handleInsertAsset}
              onClose={this.handleCloseLinkPopover}
              isAvailableScope={this.isAvailableScope}
              localization={this.props.localization}
            />
          ) : null}
          <div style={{ flex: 1 }}>
            <ReactResizeDetector
              handleWidth
              refreshMode="throttle"
              refreshRate={500}
            >
              {({ width }: any) => (
                <>
                  {without(assetNamesOfLinks, previousLabel)
                    .slice(0, calcMaxLength(width)) // 表示限界を計算
                    .map(assetName => (
                      <AssetLink
                        key={assetName}
                        name={assetName}
                        showPopover={!showBackButton}
                        className={cn.assetLinkButton}
                        openFile={this.handleOpenFile}
                        insertAsset={this.handleInsertAsset}
                        onClose={this.handleCloseLinkPopover}
                        isAvailableScope={this.isAvailableScope}
                        localization={this.props.localization}
                      />
                    ))}
                  <IconButton
                    className={cn.moreButton}
                    onClick={this.handleOpenLinkPopover}
                  >
                    <MoreHoriz />
                  </IconButton>
                </>
              )}
            </ReactResizeDetector>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={dcn.addButton}
            onClick={this.handleMoreButtonClick}
            startIcon={<Add />}
          >
            {showBackButton
              ? localization.editorCard.insertTo(this.props.label)
              : localization.editorCard.insertToStage}
          </Button>
        </div>
        <Popover
          open={Boolean(linkPopoverTarget)}
          anchorEl={linkPopoverTarget}
          onClose={this.handleCloseLinkPopover}
          classes={cn.popoverClasses}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          {assetNamesOfLinks.map(assetName => (
            <AssetLink
              key={assetName}
              name={assetName}
              showPopover={!showBackButton}
              className={cn.assetLinkButton}
              openFile={this.handleOpenFile}
              insertAsset={this.handleInsertAsset}
              onClose={this.handleCloseLinkPopover}
              isAvailableScope={this.isAvailableScope}
              localization={this.props.localization}
            />
          ))}
        </Popover>
        {/* 画面全体のアセット一覧 */}
        <div className={classes(dcn.root, show ? cn.in : cn.out)}>
          {categories.length ? (
            <div className={dcn.categoryWrapper}>
              {categories.map((cat, i) => (
                <div
                  key={i}
                  className={classes(
                    dcn.category,
                    i === activeCategoryIndex && dcn.active
                  )}
                  onClick={() => this.setState({ activeCategoryIndex: i })}
                >
                  <span>{cat.name}</span>
                  <img src={cat.iconUrl} alt="" />
                </div>
              ))}
            </div>
          ) : null}
          {showAllScopes ? null : (
            <div className={dcn.scopeWrapper}>
              <span className={dcn.scope}>
                {'+ ' + showingScopes.map(scope => scope.name).join(' ')}
              </span>
              <span>{localization.editorCard.selectedScope}</span>
            </div>
          )}
          <IconButton
            aria-label="Close"
            className={dcn.closer}
            onClick={this.handleClose}
          >
            <Close fontSize="large" />
          </IconButton>
          <div className={dcn.scroller}>
            {showingButtons.length > 0 ? (
              <div className={cn.wrapper}>
                {this.renderAssetButtons(showingButtons)}
              </div>
            ) : (
              <div className={cn.noAssetButton}>
                <div>
                  {localization.editorCard.noAssetAt(activeCategoryName)}
                </div>
                <div>{localization.editorCard.noAssetDescription}</div>
                <Button variant="contained" onClick={this.handleClose}>
                  {localization.editorCard.ok}
                </Button>
              </div>
            )}
          </div>
          <IconButton
            aria-label="Show or hide all variations"
            className={dcn.toggle}
            onClick={this.handleToggle}
          >
            {this.state.showAllVariations ? (
              <ColorLens fontSize="large" />
            ) : (
              <ColorLensOutlined fontSize="large" />
            )}
          </IconButton>
        </div>
        <AssetSelectorProvider
          localization={this.props.localization}
          codemirror={this.props.codemirror}
          installAsset={this.installAssetFromSelectorProvider}
          openFile={this.handleOpenFile}
        />
        <SkinSelectorProvider
          localization={this.props.localization}
          codemirror={this.props.codemirror}
          onChange={() => this.props.runApp()}
        />
      </>
    );
  }
}

export default withTheme(AssetPaneWrapper);
