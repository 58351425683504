import { Button, Grid } from '@material-ui/core';
import * as React from 'react';
import { style } from 'typestyle';
import { Link } from '../utils/components';

const cn = {
  root: style({
    marginTop: 64,
    padding: 64,
    backgroundColor: 'rgb(255,255,255)'
  }),
  button: style({
    display: 'block'
  })
};

const Footer = () => (
  <div className={cn.root}>
    <Grid container>
      <Grid item container direction="column" xs={6}>
        <div>
          <Button component={Link('/pages/post/enterprise')}>
            法人の方は、法人プランをご利用ください
          </Button>
        </div>
        <div>
          <Button component={Link('/pages/agreement/terms-of-use')}>
            利用規約
          </Button>
        </div>
        <div>
          <Button component={Link('/pages/agreement/terms-of-paid')}>
            月額プラン利用規約
          </Button>
        </div>
        <div>
          <Button component={Link('/pages/agreement/privacy-policy')}>
            プライバシーポリシー
          </Button>
        </div>
        <div>
          <Button component={Link('/pages/agreement/mail-order')}>
            特定商取引法に基づく表示
          </Button>
        </div>
        <div>
          <Button component={Link('/pages/post/brand')}>
            ブランドガイドライン
          </Button>
        </div>
        <div>
          <Button component={Link('/pages/agreement/software-license')}>
            ライセンス表示
          </Button>
        </div>
        <div>
          <Button component={Link('/pages/post/requirements')}>動作環境</Button>
        </div>
      </Grid>
    </Grid>
  </div>
);

export default Footer;
