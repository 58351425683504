import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  makeStyles,
  TextField,
  Tooltip
} from '@material-ui/core';
import * as React from 'react';
import { selector, selectorFamily, useRecoilValue } from 'recoil';
import { DialogClose } from './DialogClose';
import { LoadingPage } from './LoadingPage';

const useStyles = makeStyles(theme => ({
  actions: {
    marginTop: theme.spacing() * 4,
    marginBottom: theme.spacing()
  },
  textField: {
    '& input': {
      paddingTop: theme.spacing()
    }
  }
}));

export interface ShareDialog extends DialogProps {
  workId: string;
}

export function ShareDialog({ workId, ...props }: ShareDialog) {
  const url = `${location.origin}/works/${workId}`;

  const inputRef = React.useRef<HTMLInputElement>();
  const [copied, setCopied] = React.useState(false);
  React.useEffect(() => setCopied(false), [props.open]);

  const handleCopy = React.useCallback(() => {
    const input = inputRef.current;
    if (!input) return;
    input.select();
    document.execCommand('copy');
    setCopied(true);
  }, []);

  const handleSelect = React.useCallback(() => {
    inputRef.current?.select();
  }, []);

  const cn = useStyles();

  return (
    <Dialog {...props}>
      <DialogClose onClick={() => props.onClose?.({}, 'escapeKeyDown')} />
      <DialogTitle>このステージをスマホで開く</DialogTitle>
      <DialogContent dividers>
        <React.Suspense fallback={<LoadingPage />}>
          <QRCode text={url} />
        </React.Suspense>
        <div className={cn.actions}>
          <Tooltip
            title="コピーされました"
            open={copied}
            onClose={() => setCopied(false)}
            arrow
          >
            <TextField
              variant="filled"
              value={url}
              className={cn.textField}
              inputRef={inputRef}
              onClick={handleSelect}
            />
          </Tooltip>
          <Button variant="contained" color="secondary" onClick={handleCopy}>
            URL をコピー
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const qrCodeModule = selector({
  key: 'qrCodeModule',
  get: async () => {
    // async function を挟んでいるのはエラー回避のため
    // Dynamic Import の戻り値が Lazy Promise なせいで次のエラーが出る
    // TypeError: result.finally is not a function
    return await import('qrcode');
  }
});

const qrCode = selectorFamily({
  key: 'qrCode',
  get:
    (text: string) =>
    ({ get }) => {
      const QRCode = get(qrCodeModule);
      return QRCode.toDataURL(text);
    }
});

interface QRCodeProps {
  text: string;
}

function QRCode(props: QRCodeProps) {
  const dataUrl = useRecoilValue(qrCode(props.text));

  return React.useMemo(() => {
    return (
      <img
        src={dataUrl}
        alt=""
        style={{
          display: 'block',
          margin: '0px auto'
        }}
        width={256}
        height={256}
      />
    );
  }, [dataUrl]);
}
