import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { style } from 'typestyle';
import { actions } from '../ducks/actions';
import { LoadingPage } from './LoadingPage';

const cn = {
  root: style({
    flex: 1
  })
};

export type OnMessage = (event: {
  data: { value: { labelName: string; labelValue: string; href: string } };
}) => void;

export type OwnProps = {
  onMessage: OnMessage;
};

type Thumbnail = (...params: Parameters<typeof actions.make.thumbnail>) => void;

const Ide = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ide" */
      /* webpackPrefetch: true */
      '../ide/src/jsx/RootComponent'
    )
);

export function Feeles({ onMessage }: OwnProps) {
  const dispatch = useDispatch();
  const thumbnails = useSelector(state => state.make.thumbnails);
  const thumbnailsRef = React.useRef(thumbnails);
  thumbnailsRef.current = thumbnails;
  const onThumbnailChange = React.useCallback<Thumbnail>(payload => {
    const url = typeof payload === 'string' ? payload : payload.dataUrl;
    if (!thumbnailsRef.current.includes(url)) {
      dispatch(actions.make.thumbnail(url));
    }
  }, []);

  return React.useMemo(
    () => (
      <div className={cn.root}>
        <React.Suspense fallback={<LoadingPage />}>
          <Ide onMessage={onMessage} onThumbnailChange={onThumbnailChange} />
        </React.Suspense>
      </div>
    ),
    [onMessage, onThumbnailChange]
  );
}
