import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/show-hint.css';
import * as React from 'react';
import { useHint } from '../../hooks/useHint';

export interface HintProviderProps {
  codemirror?: CodeMirror.Editor;
}

export function HintProvider(props: HintProviderProps) {
  const hint = useHint();

  React.useEffect(() => {
    const onChange = (
      cm: CodeMirror.Editor,
      change?: CodeMirror.EditorChangeLinkedList
    ) => {
      // 無限ループになるのを防ぐ
      if (['setValues', 'complete'].includes(change?.origin || '')) return;
      // 文末では何もしない
      if (isEOL(cm)) return;

      const cursor = cm.getCursor();
      cm.scrollIntoView(cursor);
      cm.showHint({
        completeSingle: false,
        hint
      });
    };
    props.codemirror?.on('change', onChange);
    return () => {
      props.codemirror?.off('change', onChange);
    };
  }, [props.codemirror]);

  // キーバインドでヒントを出す。文末でもグローバル変数等を表示する
  React.useEffect(() => {
    // Ctrl-Space で autocomplete を起動
    const handler = (cm: CodeMirror.Editor) => {
      const cursor = cm.getCursor();
      cm.scrollIntoView(cursor);
      cm.showHint({
        completeSingle: true,
        hint
      });
    };
    props.codemirror?.addKeyMap({
      'Ctrl-Space': handler
    });
    return () => {
      props.codemirror?.removeKeyMap({
        'Ctrl-Space': handler
      });
    };
  }, [props.codemirror]);

  return null;
}

/**
 * カーソルが文末を表すなら true, そうでなければ false を返す
 */
function isEOL(instance: CodeMirror.Editor) {
  const cursor = instance.getCursor();
  const token = instance.getTokenAt(cursor);
  const segment = token?.string || '';
  return (
    segment.trim() === '' ||
    (segment.length === 1 && ';()){}'.includes(segment))
  );
}
