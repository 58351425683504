export const errorProps = [
  'message',
  'name',
  'fileName',
  'lineNumber',
  'line',
  'columnNumber',
  'column',
  'stack'
];

export default function cloneError(error: any) {
  if (!error || typeof error !== 'object') return {};
  const clone: any = {};
  for (const key of errorProps) {
    if (key in error) {
      clone[key] = error[key];
    }
  }
  return clone;
}
