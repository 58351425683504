import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { classes } from 'typestyle';

const useStyles = makeStyles(theme => ({
  root: {},
  common: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap'
  },
  test: {
    visibility: 'hidden' // 一瞬だけ全部描画するが、目には見えないようにする
  },
  readMore: {
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}));

export interface TruncateTextProps {
  text: string;
  maxWidth: number | string;
  lineClamp: number;
  className?: string;
  component?: React.ComponentType<{ children: string }>;
}

export function TruncateText(props: TruncateTextProps) {
  const ref = React.useRef<HTMLParagraphElement>(null); // text が実際に描画される DOM の参照
  const [readMore, setReadMore] = React.useState(false); //つづきを読む

  // props.text が前回と比べて変わったか
  const lastTextRef = React.useRef('');
  const changed = lastTextRef.current !== props.text;
  lastTextRef.current = props.text;

  // clamp する前の高さと比べて、小さくなっていたら、"つづきを読む" を出す
  const domHeightRef = React.useRef(0);
  const height = ref.current?.getBoundingClientRect().height || 0;
  const trancated = !changed && height < domHeightRef.current;

  // ちゃんと描画したらどのくらいの高さになるのか記録しておく
  domHeightRef.current = height;

  // １回 text がセットされるたびに２回ずつ render する
  const [count, update] = React.useState(0);
  React.useEffect(() => {
    update(c => c + 1); // overflow を加えて再描画する

    return () => {
      setReadMore(false);
    };
  }, [changed]);

  const cn = useStyles();

  return React.useMemo(
    () => (
      <div className={classes(cn.root, props.className)}>
        <span
          ref={ref}
          className={classes(cn.common, !readMore && changed && cn.test)}
          style={{
            maxWidth: props.maxWidth,
            WebkitLineClamp: !readMore && !changed ? props.lineClamp : undefined
          }}
          onClick={() => setReadMore(true)} // 上手く trancated できなかった場合の回避手段
        >
          {props.component ? (
            <props.component>{props.text}</props.component>
          ) : (
            props.text
          )}
        </span>
        {trancated && !readMore ? (
          <span className={cn.readMore} onClick={() => setReadMore(true)}>
            つづきを読む
          </span>
        ) : null}
      </div>
    ),
    [
      props.text,
      props.className,
      props.maxWidth,
      props.lineClamp,
      count,
      readMore
    ]
  );
}
