import { Document } from '@contentful/rich-text-types';
import { Button, Paper } from '@material-ui/core';
import { Asset, Entry } from 'contentful';
import * as React from 'react';
import { classes, media, style } from 'typestyle';
import { facebookIntent, twitterIntent } from '../utils/intent';
import { container } from '../utils/xlasses';
import { renderEntry } from './renderers';

export interface TweetFields {
  html: string;
}

export interface Fields {
  slug: string;
  topCover: Asset;
  topBody: Document;
  bandBackground: Asset;
  bandBody: Document;
  tweetCardEnabled: boolean;
  tweetCardLabel: string;
  tweetCardBody: Document;
  joiningCardEnabled: boolean;
  joiningCardLabel: string;
  joiningCardBody: Document;
  liveCardEnabled: boolean;
  liveCardLabel: string;
  liveCardBody: Document;
  tutorialCardLabel: string;
  tutorialCardBody: Document;
  goToTopLabel: string;
}

export interface FesProps {
  entry: Entry<Fields>;
}

export function isFes(entry: Entry<unknown>): entry is Entry<Fields> {
  return entry.sys.contentType.sys.id === 'fes';
}

const cn = {
  root: style({
    $nest: {
      '& h1,& h2,& h3,& h4,& h5,& h6': {
        display: 'inline-flex',
        alignItems: 'center',
        marginTop: 0
      },
      '& ul': {
        marginTop: 0
      }
    }
  }),
  top: style(
    {
      position: 'relative',
      textAlign: 'center',
      height: '80vh' // for PC or landscape device
    },
    media(
      { orientation: 'portrait' },
      {
        maxHeight: '50vh',
        opacity: 13 // media query が違うのに 同じ className を出力している
      }
    )
  ),
  topCover: style({
    objectFit: 'cover',
    // https://github.com/bfred-it/object-fit-images/
    fontFamily: "'object-fit: cover;'",
    width: '100%',
    height: '100%'
  }),
  topBody: style({
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    $nest: {
      '& img': {
        width: '100%',
        maxWidth: 700
      }
    }
  }),
  band: style({
    backgroundRepeat: 'repeat',
    color: 'white',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }),
  menu: style({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    padding: '2rem'
  }),
  paper: style({
    padding: '2rem',
    marginBottom: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }),
  twitterCard: style({
    $nest: {
      '& > p': {
        display: 'flex',
        flexWrap: 'wrap'
      }
    }
  }),
  electionImage: style({
    minWidth: '100%',
    maxWidth: '100%'
  }),
  ul: style({
    marginRight: 'auto'
  }),
  header: style({
    fontSize: 16,
    fontWeight: 600
  }),
  iframe: style({
    width: '100%'
  }),
  goTop: style({
    width: '100%',
    padding: '1rem'
  }),
  divider: style({
    marginTop: '2rem',
    marginBottom: '2rem'
  }),
  snsWidget: style({
    zIndex: 100,
    position: 'sticky',
    top: 0,
    marginLeft: 'auto',
    marginRight: 16,
    display: 'flex',
    flexDirection: 'column',
    height: 0,
    paddingTop: 64,
    marginTop: -64,
    width: 80
  }),
  snsIcon: style({
    cursor: 'pointer',
    paddingTop: 16
  }),
  watchOnYouTube: style({
    zIndex: 1,
    maxWidth: '100%'
  }),
  watchOnYouTubeCover: style({
    position: 'absolute',
    objectPosition: 'top',
    top: 0
  }),
  watchOnYouTubeWrapper: style({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }),
  watchOnYouTubeContainer: style({
    height: 200,
    width: '100%',
    position: 'relative'
  })
};

export function Fes({ entry }: FesProps) {
  const rootRef = React.useRef<HTMLDivElement>(null);
  const scroll = React.useCallback(
    (id: string) => () => {
      const element = document.getElementById(id);
      if (!element) return;
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    },
    []
  );

  // Render twitter embeds
  React.useEffect(() => {
    try {
      (window as any).twttr.widgets.load();
    } catch (error) {
      console.warn('Twitter SDK Error', error);
    }
  }, [entry]);

  return (
    <div ref={rootRef} className={cn.root}>
      <div id="top" className={cn.top}>
        <img
          src={entry.fields.topCover.fields.file.url}
          alt={entry.fields.topCover.fields.title}
          className={cn.topCover}
        />
        <div className={cn.topBody}>{renderEntry(entry.fields.topBody)}</div>
      </div>
      <div
        className={cn.band}
        style={{
          backgroundImage: `url(${entry.fields.bandBackground.fields.file.url})`
        }}
      >
        {renderEntry(entry.fields.bandBody)}
      </div>
      <div className={cn.menu}>
        <Button color="primary" onClick={scroll('twitter')}>
          {entry.fields.tweetCardLabel}
        </Button>
        <Button color="primary" onClick={scroll('family')}>
          {entry.fields.joiningCardLabel}
        </Button>
        <Button color="primary" onClick={scroll('youtube')}>
          {entry.fields.liveCardLabel}
        </Button>
        <Button color="primary" onClick={scroll('tutorial')}>
          {entry.fields.tutorialCardLabel}
        </Button>
      </div>
      <div className={cn.snsWidget}>
        <img
          src={require('../resources/fes/twitter.svg')}
          alt="Tw"
          className={cn.snsIcon}
          onClick={twitterIntent('', 'ハックフォープレイ')}
        />
        <img
          src={require('../resources/fes/facebook.svg')}
          alt="Fb"
          className={cn.snsIcon}
          onClick={facebookIntent(window.location.href, 'ハックフォープレイ')}
        />
      </div>
      <div className={container.large}>
        <Paper
          id="twitter"
          elevation={2}
          className={classes(cn.paper, cn.twitterCard)}
        >
          {renderEntry(entry.fields.tweetCardBody)}
        </Paper>
        <Paper id="family" elevation={2} className={cn.paper}>
          {renderEntry(entry.fields.joiningCardBody)}
        </Paper>
        <Paper id="youtube" elevation={2} className={cn.paper}>
          {renderEntry(entry.fields.liveCardBody)}
        </Paper>
        <Paper id="tutorial" elevation={2} className={cn.paper}>
          {renderEntry(entry.fields.tutorialCardBody)}
        </Paper>
      </div>
      <Paper elevation={2}>
        <Button color="primary" className={cn.goTop} onClick={scroll('top')}>
          {entry.fields.goToTopLabel}
        </Button>
      </Paper>
    </div>
  );
}
