import { uniqueId } from 'lodash-es';
import { isTruly } from '../../../utils/filterTruly';
import babelFile from './babelFile';
import normalize from './normalize';
import { decode } from './sanitizeHTML';
import separate from './separate';
import validateType from './validateType';

const maybeBase64String = /^[a-zA-Z0-9+/=]+$/;

export default class ImmutableFile implements IFileParams {
  readonly id: string;
  readonly type: string;
  readonly text: string;
  readonly name: string;
  readonly path: string;
  readonly moduleName: string;
  readonly plain: string;
  readonly ext: string;
  readonly lastModified: number;
  readonly blob?: Blob;

  _transpileCache?: string; // Babel した結果. seed で与えられた時だけ設定する
  get transpiled(): Promise<string> {
    if (this._transpileCache) {
      return Promise.resolve(this._transpileCache);
    }
    return babelFile(this);
  }

  readonly isTrashed: boolean;
  get options() {
    return { isTrashed: this.isTrashed };
  }

  constructor(seed: Partial<IFileParams>) {
    if (typeof seed.name !== 'string') {
      throw new TypeError(`Invalid seed: ${JSON.stringify(seed)}`);
    }
    if (typeof seed.type !== 'string') {
      throw new TypeError(`${seed.name} doesn't has 'type' property`);
    }
    this.id = uniqueId();
    this.isTrashed = Boolean(seed.options && seed.options.isTrashed);
    this.type = seed.type;
    this.text = seed.text || '';
    this.blob = seed.blob; // Use as a binary file if set
    const normalized = normalize(seed.name);
    const { name, path, moduleName, plain, ext } = separate(normalized);
    this.name = name;
    this.path = path;
    this.moduleName = moduleName;
    this.plain = plain;
    this.ext = ext;
    this.lastModified = seed.lastModified || 0;
  }

  static fromSeed(seed: IFileSeed) {
    const { composed: _composed, _transpileCache, ...props } = seed;
    // 歴史的な理由で HTML サニタイズされていることがある
    const composed =
      _composed.substr(0, 5) === '<!--\n' && _composed.substr(-4) === '\n-->'
        ? decode(_composed)
        : _composed;

    if (validateType('blob', props.type)) {
      // base64 encode された文字列を Blob にする
      const bin = atob(composed);
      let byteArray = new Uint8Array(bin.length);
      for (let i = bin.length - 1; i >= 0; i--) {
        byteArray[i] = bin.charCodeAt(i);
      }
      const blob = new Blob([byteArray.buffer], { type: seed.type });
      return new ImmutableFile({
        ...props,
        blob
      });
    } else {
      // 歴史的な理由で URI encode & base64 encode されたりされなかったりする
      const tryParse = () => {
        if (!maybeBase64String.test(composed)) return composed; // base64 string ではない
        try {
          console.log(
            'decoding',
            props.name,
            'composed:',
            composed.substr(0, 20)
          );
          const text = decodeURIComponent(escape(atob(composed)));
          console.log('successfuly decoded', text.substr(0, 20));
          return text;
        } catch (error) {
          console.error(error);
        }
        return '';
      };
      const file = new ImmutableFile({
        ...props,
        text: tryParse()
      });
      if (_transpileCache) {
        // precompiled javascript code を入れて babel をスキップ
        file._transpileCache = _transpileCache;
      }
      return file;
    }
  }

  static fromSeeds(seeds: IFileSeed[]) {
    return seeds
      .map(seed => {
        try {
          return ImmutableFile.fromSeed(seed);
        } catch (error) {
          console.error(error);
          return undefined;
        }
      })
      .filter(isTruly);
  }

  static fromFile(file: File): Promise<ImmutableFile> {
    if (validateType('blob', file.type)) {
      return Promise.resolve(
        new ImmutableFile({
          type: file.type,
          name: file.name,
          blob: file,
          lastModified: file.lastModified
        })
      );
    } else {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = e => {
          resolve(
            new ImmutableFile({
              type: file.type,
              name: file.name,
              text: reader.result as string,
              lastModified: file.lastModified
            })
          );
        };
        reader.readAsText(file);
      });
    }
  }

  set(update: PartialIFileParams) {
    return new ImmutableFile({
      ...this,
      ...update,
      lastModified: Date.now()
    });
  }

  get component() {
    console.trace();
    throw new Error('ImmutableFile.component is unsupported');
  }

  async compose(): Promise<IFileSeed> {
    const seed: IFileSeed = {
      type: this.type,
      name: this.name,
      lastModified: this.lastModified,
      options: {
        isTrashed: this.isTrashed
      },
      composed: this.blob ? (await this.toDataURL()).split(',')[1] : this.text
    };
    const _transpileCache = await Promise.race([
      this.transpiled,
      wait(100) // 100ms 以内
    ]);
    if (_transpileCache) {
      seed._transpileCache = _transpileCache;
    }
    return seed;
  }

  private static readonly dataURLCache = new WeakMap<
    ImmutableFile,
    Promise<string>
  >();
  toDataURL(): Promise<string> {
    const cache = ImmutableFile.dataURLCache.get(this);
    if (cache) return cache;

    const promise: Promise<string> = new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = () => {
        const dataURL = reader.result as string;
        resolve(dataURL);
      };
      const blob = this.blob || new Blob([this.text], { type: this.type });
      if (!blob) {
        throw new Error(`Failed to make Blob of ${this.name}`);
      }
      reader.readAsDataURL(blob);
    });
    ImmutableFile.dataURLCache.set(this, promise);
    return promise;
  }

  is(name: string): boolean {
    return validateType(name, this.type);
  }

  rename(newName: string) {
    const { path, ext } = this;
    return this.set({ name: path + newName + ext });
  }

  move(newPath: string) {
    if (newPath.lastIndexOf('/') !== newPath.length - 1) {
      newPath += '/';
    }
    return this.set({ name: newPath + this.plain + this.ext });
  }

  get json() {
    console.trace();
    throw new Error('ImmutableFile.json is not supported');
  }
}

function wait(ms = 1000) {
  return new Promise<void>(resolve => setTimeout(() => resolve(), ms));
}
