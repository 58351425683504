import * as React from 'react';
import { atom, useRecoilValue } from 'recoil';
import { style } from 'typestyle';
import { Localization } from '../../localization';
import ErrorMessage from './ErrorMessage';
import { iframeOrigin } from './Monitor';

export interface ScreenProps {
  setLocation: (location?: string) => void;
  localization: Localization;
  error: Error | null;
}

/**
 * iframe の参照を保持する
 */
export const iframeRefAtom = atom({
  key: 'iframeRefAtom',
  default: React.createRef<HTMLIFrameElement>()
});

const cn = {
  root: style({
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    background: 'linear-gradient(rgba(0,0,0,0.8), rgba(128,128,128,0.8))',
    boxSizing: 'border-box',
    overflow: 'hidden',
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  }),
  parent: style({
    flex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  frame: style({
    border: '0 none',
    flex: '0 0 auto'
  })
};

export function Screen({
  error = null,
  localization,
  setLocation
}: ScreenProps) {
  const workId = /^\/works\/(\w+)\/?/.exec(window.location.pathname)?.[1];
  const sandboxSrc =
    iframeOrigin + '/compatible.html' + (workId ? `?workId=${workId}` : '');
  const iframeRef = useRecoilValue(iframeRefAtom);

  return (
    <div className={cn.root}>
      <ErrorMessage
        error={error}
        localization={localization}
        setLocation={setLocation}
      />
      <div className={cn.parent}>
        <iframe
          ref={iframeRef}
          allowFullScreen // for Firefox 75
          sandbox="allow-forms allow-scripts allow-same-origin allow-modals allow-popups allow-presentation"
          allow="fullscreen; geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb"
          className={cn.frame}
          width="100%"
          height="100%"
          src={sandboxSrc}
          onLoad={e => {
            e.currentTarget.focus();
          }}
        />
      </div>
    </div>
  );
}
