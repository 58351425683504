import { Grid } from '@material-ui/core';
import { Entry } from 'contentful';
import * as React from 'react';

export interface Fields {
  html: string;
}

export interface EmbeddedTweetProps {
  entry: Entry<Fields>;
  block?: boolean;
}

export function isEmbeddedTweet(entry: Entry<unknown>): entry is Entry<Fields> {
  return entry.sys.contentType.sys.id === 'embeddedTweet';
}

export function EmbeddedTweet({ entry, block = false }: EmbeddedTweetProps) {
  return React.useMemo(
    () => (
      <Grid
        item
        xs={12}
        sm={block ? 12 : 6}
        dangerouslySetInnerHTML={{
          __html: sanitize(entry.fields.html)
        }}
      ></Grid>
    ),
    [entry]
  );
}

export function sanitize(html: string) {
  return html.replace(/<script.*<\/script>/g, '');
}
