import { cards, customers, sources } from 'stripe';

type Customer = customers.ICustomer;

/**
 * サブスクリプションを取得する
 * もし有効なサブスクリプションがなければ、例えキャンセル済みでも最初の１件を返す
 */
export function getSubscription(customer?: Customer) {
  return (
    customer?.subscriptions.data.find(sub => sub.status !== 'canceled') ||
    customer?.subscriptions.data[0]
  );
}

/**
 * デフォルトの支払い方法（カード）を取得する
 */
export function getDefaultCard(customer?: Customer) {
  if (!customer) {
    return undefined;
  }
  const { default_source, sources } = customer;
  if (!default_source) {
    return undefined;
  }
  if (typeof default_source === 'string') {
    for (const source of sources?.data || []) {
      if (
        source.id === default_source &&
        source.object === 'source' &&
        source.type === 'card'
      ) {
        return source.card;
      }
    }
    return undefined;
  }
  if (default_source.object === 'source' && default_source.type === 'card') {
    return default_source.card;
  }
}
