import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { last } from 'lodash-es';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { classes } from 'typestyle';
import { actions } from '../ducks/actions';
import { getId } from '../hooks/useHashCommentId';
import { useMarkAsRead } from '../hooks/useMarkAsRead';
import { commentAtom, notificationAtom } from '../recoil';
import { NotificationStagePlayMore } from './NotificationStagePlayMore';
import { ResponsiveEmbed } from './ResponsiveEmbed';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 48,
    display: 'flex',
    alignItems: 'center'
  },
  fallback: {
    height: 48,
    overflow: 'hidden'
  }
}));

interface NotificationItemProps {
  id: string;
  divider?: boolean;
}

export function NotificationItem(props: NotificationItemProps) {
  const cn = useStyles();
  const item = useRecoilValue(notificationAtom(props.id));

  return React.useMemo(() => {
    if (!item) {
      return null; // type hint
    }
    const { content, createdAt } = item;
    const created = createdAt.toDate().toLocaleDateString();
    const unread = isUnread(item);

    return (
      <>
        {props.divider ? <Divider /> : null}
        <React.Suspense fallback={<div className={cn.fallback}></div>}>
          <div className={cn.container}>
            {content.type === 'official-work' ? (
              <ItemOfficialWork
                created={created}
                text={content.text}
                title={content.title}
                unread={unread}
                workId={content.workId}
              />
            ) : content.type === 'stage-play' ? (
              <SingleLine>
                <Unread unread={unread} />
                <ItemStagePlay
                  icon
                  players={content.players}
                  workId={content.workId}
                />
              </SingleLine>
            ) : content.type === 'official-youtube' ||
              content.type === 'youtuber-tani' ? (
              <ItemWithYoutube
                created={created}
                text={
                  content.type === 'youtuber-tani'
                    ? content.title + ' がえらばれました！'
                    : content.text
                }
                title={
                  content.type === 'youtuber-tani'
                    ? '公式 YouTube チャンネルに のりました！'
                    : content.title
                }
                unread={unread}
                videoId={content.videoId}
              />
            ) : content.type === 'comment-work' ? (
              <SingleLine>
                <Unread unread={unread} />
                <ItemCommentToWork
                  commentId={content.commentId}
                  content={content}
                />
              </SingleLine>
            ) : (
              <Typography variant="body1" color="textSecondary">
                {(content as any).type}
                は未知のタイプです。アップデートしてください: comments/
                {props.id}
              </Typography>
            )}
          </div>
        </React.Suspense>
      </>
    );
  }, [item]);
}

interface ItemOfficialWorkProps {
  created: string;
  title: string;
  text: string;
  unread: boolean;
  workId: string;
}

function ItemOfficialWork(props: ItemOfficialWorkProps) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={2}>
        <Link to={`/works/${props.workId}`}>
          <ResponsiveEmbed verticalPerHorizontal={2 / 3}>
            <img src={`/api/works/${props.workId}/thumbnail`} alt="" />
          </ResponsiveEmbed>
        </Link>
      </Grid>
      <Grid item xs={6} sm={10} container direction="column" justify="center">
        <Metadata created={props.created} unread={props.unread} />
        <Typography variant="h6" gutterBottom>
          {props.title}
        </Typography>
        <Typography variant="body1">
          {props.text}
          <Link to={`/works/${props.workId}`}>ひらく</Link>
        </Typography>
      </Grid>
    </Grid>
  );
}

interface ItemWithYoutubeProps {
  created: string;
  text: string;
  title: string;
  unread: boolean;
  videoId: string;
}

function ItemWithYoutube(props: ItemWithYoutubeProps) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={2}>
        <a
          href={`https://www.youtube.com/watch?v=${props.videoId}`}
          referrerPolicy="no-referrer"
          target="_blank"
          rel="noopener"
        >
          <ResponsiveEmbed verticalPerHorizontal={3 / 4}>
            <img
              src={`https://img.youtube.com/vi/${props.videoId}/default.jpg`}
              alt=""
            />
          </ResponsiveEmbed>
        </a>
      </Grid>
      <Grid item xs={6} sm={10} container direction="column" justify="center">
        <Metadata created={props.created} unread={props.unread} />
        <Typography variant="h6" gutterBottom>
          {props.title}
        </Typography>
        <Typography variant="body1">
          {props.text}
          <a
            href={`https://www.youtube.com/watch?v=${props.videoId}`}
            referrerPolicy="no-referrer"
            target="_blank"
            rel="noopener"
          >
            YouTube でみる
          </a>
        </Typography>
      </Grid>
    </Grid>
  );
}

const useStylesMetadata = makeStyles(theme => ({
  bold: { fontWeight: 600 },
  contentNew: { color: 'rgb(255,0,0)', marginRight: 16 },
  contentCreated: { color: 'rgba(0, 0, 0, 0.54)' }
}));

interface MetadataProps {
  created: string;
  unread: boolean;
}

function Metadata(props: MetadataProps) {
  const cn = useStylesMetadata();

  return React.useMemo(
    () => (
      <div className={cn.bold}>
        {props.unread ? <small className={cn.contentNew}>● NEW!!</small> : null}
        {props.created ? (
          <small className={cn.contentCreated}>{props.created}</small>
        ) : null}
      </div>
    ),
    [props.unread]
  );
}

const useStylesItemStagePlay = makeStyles(theme => ({
  space: {
    paddingLeft: theme.spacing() / 2
  },
  title: {
    color: theme.palette.text.secondary
  }
}));

export interface ItemStagePlayProps {
  icon: boolean;
  players: string[];
  workId: string;
}

/**
 * 共有するためにコンポーネントを分割している
 */
export function ItemStagePlay(props: ItemStagePlayProps) {
  const cn = useStylesItemStagePlay();

  const uid = last(props.players);
  const work = useSelector(state => state.work.works[props.workId])?.data;
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!work) {
      dispatch(actions.work.fetchIfNeeded(props.workId));
    }
  }, [props.workId]);

  if (!uid) return null; // type hint

  return (
    <>
      <UserLink uid={uid} icon={props.icon} />
      <span className={cn.space}>さん</span>
      <NotificationStagePlayMore
        players={props.players}
        title={work?.title || '...'}
      />
      <span className={cn.space}>が</span>
      <Link
        to={`/works/${props.workId}`}
        className={classes(cn.title, cn.space)}
      >
        {work?.title || ''}
      </Link>
      <span className={cn.space}>をあそびました</span>
    </>
  );
}

const useStylesItemCommentToWork = makeStyles(theme => ({
  space: {
    paddingLeft: theme.spacing() / 2
  },
  text: {
    color: theme.palette.text.secondary
  }
}));

export interface ItemCommentToWorkProps {
  commentId: string;
  content: NotificationDocContent;
}

export function ItemCommentToWork(props: ItemCommentToWorkProps) {
  const cn = useStylesItemCommentToWork();
  const comment = useRecoilValue(commentAtom(props.commentId));

  const markAsRead = useMarkAsRead();
  return (
    <>
      <UserLink uid={comment.uid} icon />
      <span className={cn.space}>さん</span>
      <span className={cn.space}>が</span>
      <Link
        to={`/works/${comment.workId}#${getId(props.commentId)}`}
        className={classes(cn.text, cn.space)}
        onClick={() => {
          markAsRead(content => content === props.content);
        }}
      >
        {comment.text}
      </Link>
      <span className={cn.space}>
        {comment.parentId ? 'と返信しました' : 'とコメントしました'}
      </span>
    </>
  );
}

const useStylesSingleLine = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&>*': {
      verticalAlign: 'middle'
    }
  }
}));

interface SingleLineProps {
  children: React.ReactNode;
}

function SingleLine(props: SingleLineProps) {
  const cn = useStylesSingleLine();
  return <div className={cn.root}>{props.children}</div>;
}

interface UserLinkProps {
  icon: boolean;
  uid: string;
}

const useStylesUserLink = makeStyles(theme => ({
  iconWrapper: {
    lineHeight: 0 // 画像が下に行くのを防ぐ
  },
  icon: {
    height: 32,
    width: 32,
    marginRight: theme.spacing()
  },
  displayName: {
    color: theme.palette.text.primary,
    fontWeight: 600
  }
}));

function UserLink({ icon, uid }: UserLinkProps) {
  const cn = useStylesUserLink();
  const user = useSelector(state => (uid ? state.user.byUid[uid] : undefined));
  const iconUrl = user?.data?.iconUrl;

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(actions.user.fetchIfNeeded({ uid }));
  }, [uid]);

  return (
    <>
      {icon ? (
        <Link to={`/users/${uid}`} className={cn.iconWrapper}>
          {iconUrl ? (
            <img src={iconUrl} alt="icon" className={cn.icon} />
          ) : null}
        </Link>
      ) : null}
      <Link to={`/users/${uid}`} className={cn.displayName}>
        {user?.data?.displayName || '_'}
      </Link>
    </>
  );
}

const useStylesUnread = makeStyles(theme => ({
  unread: {
    display: 'inline-block',
    backgroundColor: 'rgb(255,0,0)',
    borderRadius: '50%',
    width: 8,
    height: 8,
    alignSelf: 'center',
    marginLeft: 8,
    marginRight: 8,
    opacity: 0,
    transition: theme.transitions.create('opacity')
  },
  showUnread: {
    opacity: 1
  }
}));

interface UnreadProps {
  unread: boolean;
}

function Unread(props: UnreadProps) {
  const cn = useStylesUnread();

  return (
    <span className={classes(cn.unread, props.unread && cn.showUnread)}></span>
  );
}

/**
 * 未読であれば true
 */
export function isUnread(item: INotificationDocument) {
  return item.updatedAt.toMillis() > (item.readAt?.toMillis() || 0);
}
