import {
  Button,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import { Add, ExpandLess, ExpandMore } from '@material-ui/icons';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { classes, style } from 'typestyle';
import { StoreState } from '../../../../ducks';
import { Localization } from '../../localization';
import findAssetButton from './findAssetButton';
import { getScopeNames } from './getScopeNames';
import { InsertAsset, OpenFile } from './type';

export interface AssetLinkProps {
  name: string;
  className: string;
  showPopover: boolean;
  openFile: OpenFile;
  insertAsset: InsertAsset;
  onClose: () => void;
  isAvailableScope: (scopeName: string) => void;
  localization: Localization;
  // Injected by withAsset
  asset: StoreState['asset'];
}

interface State {
  name: string;
  iconUrl: string;
  insertCode: string;
  scopeNames: string[];
  anchorEl: null | HTMLElement;
  openScopes: boolean;
}

const iconSize = 32;
const padding = 2;
export const assetLinkWidth = iconSize + padding * 2;

export const cn = {
  button: style({
    minWidth: assetLinkWidth,
    padding
  }),
  icon: style({
    maxHeight: iconSize,
    width: iconSize
  }),
  title: style({
    justifyContent: 'space-between'
  })
};

export default function AssetLinkWrapper(props: Omit<AssetLinkProps, 'asset'>) {
  const asset = useSelector(state => state.asset);
  return <AssetLink {...props} asset={asset} />;
}

class AssetLink extends React.Component<AssetLinkProps, State> {
  state = {
    name: '',
    iconUrl: '',
    insertCode: '',
    scopeNames: [],
    anchorEl: null,
    openScopes: false
  };

  static getDerivedStateFromProps(props: AssetLinkProps, state: State) {
    if (props.name !== state.name) {
      // まだ計算されていない
      const assetButton = findAssetButton(props.asset.assetPackage, props.name);
      if (assetButton) {
        return {
          name: props.name,
          iconUrl: assetButton.iconUrl,
          insertCode: assetButton.insertCode,
          scopeNames: getScopeNames(
            props.asset.assetPackage,
            assetButton
          ).filter(props.isAvailableScope)
        };
      }
    }
    return null;
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (this.props.showPopover) {
      this.setState({
        anchorEl: event.currentTarget
      });
    } else {
      // 選択肢を出さず、ファイルを開く
      this.handleOpenFile();
    }
  };

  handleToggleScopes = () => {
    this.setState(prev => ({
      openScopes: !prev.openScopes
    }));
  };

  handleOnceMoreInsert = (scopeName: string) => () => {
    const { insertCode } = this.state;

    if (!insertCode) return;
    this.props.insertAsset({ insertCode, scopeName });
    this.handleClose();
  };

  handleOpenFile = () => {
    const { name, iconUrl } = this.state;
    if (!name) return;
    this.props.openFile({ name, iconUrl });
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ anchorEl: null, openScopes: false });
    this.props.onClose();
  };

  render() {
    const { name, className, localization } = this.props;
    const { iconUrl, insertCode } = this.state;

    return (
      <>
        <Tooltip title={localization.editorCard.edit(name)}>
          <Button
            variant="outlined"
            className={classes(className, cn.button)}
            disabled={!this.state.name}
            onClick={this.handleClick}
          >
            {iconUrl ? (
              <img src={iconUrl} alt={name} className={cn.icon} />
            ) : (
              <span className={cn.icon}>{name}</span>
            )}
          </Button>
        </Tooltip>
        <Menu
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
        >
          <MenuItem disabled dense divider className={cn.title}>
            <img src={iconUrl} className={cn.icon} />
            <span>{name}</span>
          </MenuItem>
          <MenuItem disabled={!insertCode} onClick={this.handleToggleScopes}>
            <ListItemText>
              {localization.editorCard.onceMoreInsert}
            </ListItemText>
            {this.state.openScopes ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={this.state.openScopes} timeout="auto" unmountOnExit>
            <List disablePadding>
              {this.state.scopeNames.map(scopeName => (
                <ListItem key={scopeName} button>
                  <ListItemIcon>
                    <Add />
                  </ListItemIcon>
                  <ListItemText
                    inset
                    primary={scopeName}
                    onClick={this.handleOnceMoreInsert(scopeName)}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
          <MenuItem disabled={!this.state.name} onClick={this.handleOpenFile}>
            {localization.editorCard.edit(name)}
          </MenuItem>
        </Menu>
      </>
    );
  }
}
