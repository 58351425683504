import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoadingPage } from '../../../../components/LoadingPage';
import { EN, JA } from '../../../../components/lang';
import { canUseExplanation } from '../../../../ducks/user';
import { useCodeExplanation } from '../../../../hooks/useCodeExplanation';
import { analytics } from '../../../../utils/analytics';

interface AICodeExplanation {
  filename: string;
  code: string;
}

function AICodeExplanation(props: AICodeExplanation) {
  const { html, isError, isLoading } = useCodeExplanation(props);
  useEffect(() => {
    analytics.explainCode();
  }, []);

  return (
    <div className="ai-code-explanation">
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div
          className="ai-generated-message"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      )}
      {isError ? (
        <p className="error-message">
          <JA>
            すみません、エラーが起きてしまいました🙇‍♂️
            <br />
            もう少しあとに、もう一度試してみてください
          </JA>
          <EN>
            Sorry, there was an error 🙇‍♂️
            <br />
            Please try again a little later!
          </EN>
        </p>
      ) : null}
    </div>
  );
}

/** AICodeExplanationをソースコード下部のバーとして埋め込むためのラッパー */
export function AIMenuBar(props: AICodeExplanation) {
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setExpanded(false);
  }, [props.code, props.filename]);

  const isEnabled = useSelector(canUseExplanation);
  if (!isEnabled) return null;

  return (
    <div className="ai-explanation-bar">
      <div className="menu-bar">
        <span>AI解説</span>
        <span className="caption">
          AIはまちがえることがあります。気をつけて使ってください。
        </span>
        <button onClick={() => setExpanded(b => !b)}>
          {expanded ? <ExpandMore /> : <ExpandLess />}
          {expanded ? 'とじる' : 'ひらく'}
        </button>
      </div>
      {expanded ? <AICodeExplanation {...props} /> : null}
    </div>
  );
}
