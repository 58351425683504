import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';

interface ResponsiveEmbed extends React.DOMAttributes<HTMLDivElement> {
  /**
   * 縦の長さを横の長さで割った長さの比
   * 例えば、正方形であれば 1 になる
   */
  verticalPerHorizontal: number;
  /**
   * 親要素の Ref を参照したい時に使う
   */
  parentRef?: React.RefObject<HTMLDivElement>;
  className?: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    '& > *': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0
    }
  }
}));

/**
 * 横幅に合わせて img 要素を配置したとき、実際に画像が読み込まれるまで画像の高さが決まらないために
 * レイアウトが崩れる問題を、比を与えておくことで解決する
 * ratio は、縦の長さを 1 とした場合の
 */
export function ResponsiveEmbed({
  verticalPerHorizontal,
  parentRef,
  className,
  ...props
}: ResponsiveEmbed) {
  const classes = useStyles();

  const style = React.useMemo<React.CSSProperties | undefined>(() => {
    return verticalPerHorizontal
      ? { height: 0, paddingBottom: verticalPerHorizontal * 100 + '%' }
      : undefined;
  }, [verticalPerHorizontal]);

  return (
    <div
      {...props}
      className={classNames(classes.root, className)}
      style={style}
      ref={parentRef}
    >
      {props.children}
    </div>
  );
}
