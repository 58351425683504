import { IconButton, IconButtonProps, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { classes } from 'typestyle';
import Close from '../icons/Close';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    '&+*': {
      paddingRight: theme.spacing() * 6 // DialogTitle に重ならないようにする
    }
  }
}));

export function DialogClose({ className, ...props }: IconButtonProps) {
  const cn = useStyles();

  return (
    <IconButton
      aria-label="Close"
      className={classes(cn.root, className)}
      {...props}
    >
      <Close />
    </IconButton>
  );
}
