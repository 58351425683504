import jsyaml from 'js-yaml';
import { makeRe } from 'minimatch';
import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { StoreState } from '../../../ducks';

const tryParseYAML = (text: string, defaultValue = {}) => {
  try {
    return jsyaml.safeLoad(text);
  } catch (e) {
    console.error(e);
    return defaultValue;
  }
};
const tryParseJSON = (text: string, defaultValue = {}) => {
  try {
    return JSON.parse(text);
  } catch (e) {
    console.error(e);
    return defaultValue;
  }
};

export function useLoadConfig() {
  const store = useStore<StoreState>();

  return useCallback(
    (ext: string) => {
      const files = store.getState().file.files;

      const _ext = `**/*.${ext}`;
      const json = `**/*.${ext}.json`;
      const yaml = `**/*.${ext}.yml`;
      // TODO: オブジェクト（ハッシュ）以外も使えるようにする
      const glob = (...args: Parameters<typeof makeRe>) => {
        const regExp = makeRe(...args);
        return files.filter(file => regExp.test(file.name));
      };
      const values = [
        // .json (JSON)
        ...glob(json, { dot: true }).map(file => tryParseJSON(file.text, {})),
        // .yml (YAML)
        ...glob(yaml, { dot: true }).map(file => tryParseYAML(file.text, {})),
        // .(ext) (JSON)
        ...glob(_ext, { dot: true }).map(file => tryParseJSON(file.text, {}))
      ];

      return Object.assign({}, ...values);
    },
    [store]
  );
}
