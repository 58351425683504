import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import {
  ArrowForward,
  Close,
  HelpOutline,
  SwapHoriz
} from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { Asearch, MatchMode } from 'asearch.ts';
import { debounce, flatten, uniqBy } from 'lodash-es';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Subject } from 'rxjs';
import { classes, style } from 'typestyle';
import { State } from '../../../../ducks/asset';
import { canAuthUserUseAsset } from '../../../../ducks/user';
import { useDebouncedCallback } from '../../../../hooks/useDebouncedCallback';
import { objectFit } from '../../../../utils/xlasses';
import { Localization } from '../../localization';
import { regex } from './extractAssetNames';
import findAssetButton from './findAssetButton';
import { OpenFile } from './type';

interface Selection {
  start: CodeMirror.Position;
  end: CodeMirror.Position;
  text: string;
  anchorEl: Element;
}

type IOutput = State['assetPackage']['buttons'][number];

/**
 * 現在選択（クリック）されている部分
 * CodeMirror Widget と React Component の橋渡しをする
 */
const selection$ = new Subject<Selection | undefined>();

export interface AssetSelectorProviderProps {
  codemirror?: CodeMirror.Editor;
  localization: Localization;
  installAsset: (name: string) => void;
  openFile: OpenFile;
}

const useStyles = makeStyles(theme => ({
  searchText: {
    marginBottom: theme.spacing()
  },
  scroller: {
    height: '50vh', // 絞り込み検索で高さがガタガタしないよう固定にする
    overflow: 'scroll',
    paddingTop: 24,
    paddingBottom: 80
  },
  item: {
    textAlign: 'center',
    cursor: 'pointer',
    width: '14em',
    transition: theme.transitions.create('background-color', {
      duration: 100
    }),
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey.A100
    }
  },
  selected: {
    backgroundColor: theme.palette.grey[200],
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
    textDecoration: 'line-through'
  },
  previews: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 24
    }
  },
  closer: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
    display: 'inline-block',
    flexShrink: 0,
    userSelect: 'none',
    overflow: 'hidden'
  }
}));

/**
 * アセットの名前を簡単に書き換える UI を提供する
 */
export function AssetSelectorProvider(props: AssetSelectorProviderProps) {
  const cn = useStyles();
  const [selection, setSelection] = React.useState<Selection>();
  const assetNameRef = React.useRef('');
  if (selection) {
    assetNameRef.current = selection.text;
  }
  const iconUrlRef = useAssetIconUrlRef(selection?.text);
  const handleOpenFile = React.useCallback(() => {
    setSelection(undefined);
    const name = assetNameRef.current;
    if (!name) return;
    props.openFile({ name, iconUrl: iconUrlRef.current });
  }, []);

  const [showDialog, setShowDialog] = React.useState(false); // ダイアログを出すか、Popover を出すか。false なら Popover を出す

  const { codemirror, localization, installAsset } = props;

  const widgetsRef = React.useRef<Element[]>([]);
  React.useEffect(() => {
    if (!codemirror) return;

    const render = debounce(
      () => {
        widgetsRef.current.forEach(node => node.parentNode?.removeChild(node));
        widgetsRef.current = renderWidgets(codemirror);
      },
      500,
      { leading: true }
    );
    render();
    codemirror.on('update', render);
    const closer = () => {
      // コードが変わったら Selection がズレてしまうので閉じる
      setSelection(undefined);
    };
    codemirror.on('beforeChange', closer);
    return () => {
      render.cancel();
      codemirror.off('update', render);
      codemirror.off('beforeChange', closer);
    };
  }, [codemirror]);

  React.useEffect(() => {
    const sub = selection$.subscribe(setSelection);
    return () => {
      sub.unsubscribe();
    };
  }, []);

  const close = React.useCallback(() => {
    selection$.next(undefined);
    setShowDialog(false);
    setSearchText(''); // 絞り込み検索はリセットする
  }, []);

  const asset = useSelector(state => state.asset.assetPackage);
  const currentAsset = selection?.text
    ? findAssetButton(asset, selection.text)
    : null;

  const [selected, setSelected] = React.useState<IOutput>();
  const replaceToSelected = React.useCallback(() => {
    close();
    if (!selected || !selection) return;
    installAsset(selected.name);
    codemirror?.replaceRange(selected.name, selection.start, selection.end);
  }, [installAsset, codemirror, selection, selected]);

  const [searchText, setSearchText] = React.useState(''); // 絞り込み検索
  const [setSearchTextDebounced] = useDebouncedCallback(setSearchText, [], 100); // 検索はやや重いので debounce する

  const canUseAsset = useSelector(canAuthUserUseAsset);
  const buttons = React.useMemo(() => {
    const tops = asset.buttons
      .filter(item => item.name in asset.module) // モジュールのあるアセットだけに絞る
      .map(item => item.variations || item); // バリエーションを展開する
    const alls = flatten(tops);
    // 絞り込み検索
    const asearch = new Asearch(searchText, MatchMode.Include);
    const ambig = Math.max(0, Math.min(2, searchText.length - 2)); // 4 文字以上入力したら曖昧度を 2 にする
    return uniqBy(alls, 'name') // name でユニークにする
      .filter(button => asearch.match(button.name, ambig)) // name で絞り込む
      .sort((a, b) => (a.name > b.name ? 1 : -1)) // name でソートする
      .map((button, i) => (
        <Grid
          key={i}
          item
          className={classes(
            cn.item,
            selected?.name === button.name && cn.selected,
            !canUseAsset && button.plan !== 'free' && cn.disabled
          )}
          onClick={() =>
            setSelected(selected?.name === button.name ? undefined : button)
          }
        >
          <Preview asset={button} />
        </Grid>
      ));
  }, [asset, selected, canUseAsset, searchText, showDialog]);

  // scroller の横幅を固定する
  const scrollerRef = React.useRef<HTMLDivElement>(null);
  const [minWidth, setMinWidth] = React.useState(0);
  React.useEffect(() => {
    // 横幅を取得して minWidth にセットする => 絞り込みをして件数が減っても、横幅が小さくならない
    const width = scrollerRef.current?.getBoundingClientRect().width || 0;
    setMinWidth(current => Math.max(current, width));
  }, [buttons]);

  return (
    <>
      <Menu
        open={Boolean(selection) && !showDialog}
        anchorEl={selection?.anchorEl}
        onClose={() => setSelection(undefined)}
        getContentAnchorEl={null}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
      >
        <MenuItem onClick={handleOpenFile}>
          <ListItemIcon>
            {iconUrlRef.current ? (
              <img
                src={iconUrlRef.current}
                alt={assetNameRef.current}
                className={cn.icon}
              />
            ) : (
              <HelpOutline />
            )}
          </ListItemIcon>
          <Typography variant="inherit">{`${assetNameRef.current} の中身をみる`}</Typography>
        </MenuItem>
        <MenuItem onClick={() => setShowDialog(true)}>
          <ListItemIcon>
            <SwapHoriz />
          </ListItemIcon>
          <Typography variant="inherit">別のアセットにする</Typography>
        </MenuItem>
      </Menu>
      <Dialog
        open={Boolean(selection) && showDialog}
        maxWidth="md"
        onClose={close}
      >
        <IconButton className={cn.closer} onClick={close}>
          <Close />
        </IconButton>
        <DialogTitle>{localization.editorCard.replaceToOtherAsset}</DialogTitle>
        <DialogContent>
          <Alert severity="info">{localization.editorCard.replaceNotice}</Alert>
          {canUseAsset ? null : (
            <Alert
              severity="warning"
              action={
                <Button
                  variant="outlined"
                  color="primary"
                  target="_blank"
                  href="/promotion"
                >
                  {localization.editorCard.seeMore}
                </Button>
              }
            >
              {localization.editorCard.youCanNotUsePaidAssets}
            </Alert>
          )}
        </DialogContent>
        <DialogContent>
          <TextField
            name="searchText"
            variant="outlined"
            size="small"
            label="Search"
            defaultValue="" // debounce の影響を受けないよう value は与えない
            onChange={event => setSearchTextDebounced(event.target.value)}
            className={cn.searchText}
          />
          <Grid
            container
            spacing={2}
            alignItems="flex-start"
            className={cn.scroller}
            ref={scrollerRef}
            style={{ minWidth }}
          >
            {buttons}
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <div className={cn.previews}>
            <Preview
              asset={currentAsset}
              label={localization.editorCard.currentAsset}
              large
            />
            <ArrowForward />
            <Preview
              asset={selected}
              label={localization.editorCard.selectedAsset}
              large
            />
          </div>
          <Button
            disabled={!selected}
            variant="contained"
            color="primary"
            onClick={replaceToSelected}
          >
            {localization.editorCard.replace}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

interface PreviewProps {
  label?: string;
  asset?: IOutput | null;
  large?: boolean;
}

const useStylesPreview = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  img: {
    height: 32,
    width: 32,
    marginRight: 4
  },
  imgLarge: {
    height: 48,
    width: 48
  }
}));

function Preview({ asset, label, large }: PreviewProps) {
  const cn = useStylesPreview();

  return (
    <div>
      {label ? (
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
      ) : null}
      <div className={cn.wrapper}>
        <img
          src={asset?.iconUrl || ''}
          className={classes(cn.img, large && cn.imgLarge, objectFit.contain)}
          alt={asset?.name}
        />
        <Typography variant="body1">{asset?.name}</Typography>
      </div>
    </div>
  );
}

const cn = {
  widget: style({
    color: 'transparent',
    borderBottom: '1px solid #a11',
    transform: 'translate(0px, -18px)',
    cursor: 'pointer',
    zIndex: 10
  })
};

function renderWidgets(editor: CodeMirror.Editor) {
  const codes = editor.getValue().split('\n');
  const widgets: Element[] = [];

  for (let line = 0; line < codes.length; line++) {
    const code = codes[line];
    regex.lastIndex = 0; // 正規表現のマッチを初期化
    for (let index = 0; index < 100; index++) {
      const result = regex.exec(code);
      if (!result) break;
      const [_, _prefix, text] = result; // 3番目にアセット名が来る
      if (text === 'プレイヤー') continue; // プレイヤーからは変更できない
      const start: CodeMirror.Position = {
        line,
        ch: result.index + _prefix.length + 1 // ' の分足す
      };
      const end: CodeMirror.Position = {
        line,
        ch: start.ch + text.length
      };
      const element = document.createElement('div');
      element.textContent = text;
      element.className = cn.widget;
      element.addEventListener(
        'click',
        () => {
          selection$.next({
            text,
            start,
            end,
            anchorEl: element
          });
        },
        { passive: true }
      );
      editor.addWidget(start, element, false);
      widgets.push(element);
    }
  }
  return widgets;
}

function useAssetIconUrlRef(name?: string) {
  const assetPackage = useSelector(state => state.asset.assetPackage);
  const assetButton = React.useMemo(() => {
    if (!name) return;
    return findAssetButton(assetPackage, name);
  }, [assetPackage, name]);
  const ref = React.useRef(assetButton?.iconUrl);
  ref.current = assetButton?.iconUrl;
  return ref;
}
