import { Card } from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import {
  cardPropsAtom,
  cardVisibilityAtom,
  globalEventAtom,
  localizationAtom
} from '../../atoms';
import ImmutableFile from '../../File/ImmutableFile';
import normalize from '../../File/normalize';
import { useGlobalEvent } from '../../hooks/useGlobalEvent';
import { useLoadConfig } from '../../hooks/useLoadConfig';
import { SetCardVisibility } from '../defaultState';
import ShotPane from './ShotPane';

export interface ShotCardProps {
  setCardVisibility: SetCardVisibility;
}

export function ShotCard(props: ShotCardProps) {
  const files = useSelector(state => state.file.files).filter(
    file => !file.isTrashed && file.name.endsWith('.js')
  );
  const filesRef = React.useRef(files);
  filesRef.current = files;

  const localization = useRecoilValue(localizationAtom);
  const globalEvent = useRecoilValue(globalEventAtom);
  const cardProps = useRecoilValue(cardPropsAtom);
  const order = cardProps?.ShotCard.order;
  const visible = useRecoilValue(cardVisibilityAtom).ShotCard;

  const [file, setFile] = React.useState<ImmutableFile>();
  useGlobalEvent(
    'message.code',
    event => {
      const value = event.data?.value;
      if (value) {
        // feeles.openCode()
        const filePath = normalize(value);
        const file = filesRef.current.find(file => file.name === filePath);
        if (!file) {
          throw new Error(`NotFound: ${value} by feeles.openCode()`);
        }
        setFile(file);
        props.setCardVisibility('ShotCard', true);
      } else {
        // feeles.closeCode()
        props.setCardVisibility('ShotCard', false);
      }
    },
    []
  );

  const loadConfig = useLoadConfig();

  return (
    <div
      id="ShotCard"
      style={{
        position: 'relative',
        width: 0,
        order,
        boxSizing: 'border-box',
        maxWidth: '100%',
        direction: 'ltr',
        flex: '0 0 auto',
        flexBasis: visible ? '50%' : 0,
        padding: visible ? '16px 0 16px 20px' : 0,
        overflow: visible ? 'initial' : 'hidden',
        maxHeight: '100%'
      }}
    >
      <Card
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          position: 'relative',
          overflow: 'visible', // position: sticky のために必要
          maxHeight: '100%'
        }}
      >
        {visible ? (
          <ShotPane
            file={file}
            localization={localization}
            loadConfig={loadConfig}
            globalEvent={globalEvent}
          />
        ) : null}
      </Card>
      <div id="ShotCard-BottomAnchor" />
    </div>
  );
}
