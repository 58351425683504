import { Theme, withTheme } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Home } from '@material-ui/icons';
import { Pos } from 'codemirror';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { style } from 'typestyle';
import { actions } from '../../../../ducks/file';
import ImmutableFile from '../../File/ImmutableFile';
import { Localization } from '../../localization';
import preserveTrailingSpaceBeautify from '../../utils/preserveTrailingSpaceBeautify';
import CardFloatingBar from '../CardFloatingBar';
import { AIMenuBar } from './AICodeExplanation';
import AssetPane from './AssetPane';
import { DropdownProvider } from './DropdownProvider';
import Editor from './Editor';
import ErrorPane from './ErrorPane';
import FixAndMessage from './FixAndMessage';
import foldAsset from './foldAsset';
import { GlobalsProvider } from './GlobalsProvider';
import { HintProvider } from './HintProvider';
import LineWidget from './LineWidget';
import MenuBar from './MenuBar';
import PlayMenu from './PlayMenu';
import { SelectionBalloonProvider } from './SelectionBalloonProvider';
import { errorOf } from '../../../../utils/error';

export interface SourceEditorProps {
  theme: Theme;
  filePath: string;
  setLocation: (location?: string) => void;
  href: string;
  loadConfig: (name: string) => void;
  localization: Localization;
  label: string;
  iconUrl: string;
  filePathToBack: string;
  globalEvent: any;
  isExpandingEditorCard: boolean;
  setExpandingEditorCard: (value: boolean) => void;
  unsetLocalChange: () => void;
  // Injected by withFiles (for JSX)
  files: ImmutableFile[];
  putFile: typeof actions.putFile;
}

interface State {
  file: null | ImmutableFile;
  babelError?: Error;

  hasHistory: boolean;
  loading: boolean;
}

const cn = {
  root: style({
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  }),
  editorContainer: style({
    flex: '1 1 auto',
    position: 'relative'
  }),
  barButton: style({
    padding: 0,
    lineHeight: 2
  }),
  barButtonLabel: style({
    fontSize: '.5rem'
  }),
  progress: style({
    borderRadius: 0
  }),
  blank: style({
    flex: '1 1 auto'
  }),
  icon: style({
    width: 44,
    alignSelf: 'center'
  }),
  menuBarContainer: style({
    display: 'flex',
    padding: 8
  })
};

const getCn = (props: SourceEditorProps) => ({
  tabContainer: style({
    flex: 1,
    display: 'flex',
    borderColor: props.theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: '0px 0px 1px 1px',
    alignItems: 'center'
  })
});

export function SourceEditorWrapper(
  props: Omit<SourceEditorProps, 'files' | 'putFile' | 'unsetLocalChange'>
) {
  let files = useSelector(state => state.file.files);
  files = files.filter(file => !file.isTrashed); // ゴミ箱にあるファイルを除外
  const dispatch = useDispatch();
  const putFile: any = React.useCallback((payload: any) => {
    dispatch(actions.putFile(payload));
  }, []);

  const unsetLocalChange = React.useCallback(() => {
    dispatch(actions.setLocalChange(false));
  }, []);

  return (
    <SourceEditor
      {...props}
      files={files}
      putFile={putFile}
      unsetLocalChange={unsetLocalChange}
    />
  );
}

export default withTheme(SourceEditorWrapper);

class SourceEditor extends React.PureComponent<SourceEditorProps, State> {
  state: State = {
    file: null,

    hasHistory: false,
    loading: false
  };

  codemirror?: CodeMirror.Editor;

  static getDerivedStateFromProps(
    nextProps: SourceEditorProps,
    prevState: State
  ) {
    const file = nextProps.files.find(
      (file: any) => file.name === nextProps.filePath
    );
    if (file && file !== prevState.file) {
      return {
        file
      };
    }
    return null;
  }

  handleCodemirror = (codemirror: CodeMirror.Editor) => {
    this.codemirror = codemirror;
    const onChange = (cm: CodeMirror.Editor) => {
      this.setState({
        hasHistory: cm.historySize().undo > 0
      });
    };
    this.codemirror.on('change', onChange);
    this.codemirror.on('swapDoc', onChange);
    this.codemirror.on('swapDoc', this.foldAll);
    this.foldAll(codemirror);
    this.forceUpdate();
  };

  foldAll = (cm: CodeMirror.Editor) => {
    const opt = { rangeFinder: foldAsset };
    for (let line = cm.lineCount() - 1; line >= 0; line--) {
      cm.foldCode(new Pos(line, 0), opt, 'fold');
    }
  };

  runApp = async (href?: string) => {
    const { file } = this.state;
    if (!this.codemirror || !file) return;
    this.props.unsetLocalChange();
    this.setState({ babelError: undefined });

    if (file.text === this.codemirror.getValue()) {
      // 再読み込み
      this.props.setLocation(href);
      return;
    }

    // ファイルの中身が変更されていた
    const current = this.codemirror.getValue();

    this.setState({ loading: true });

    // Like a watching
    try {
      const nextFile = file.set({ text: current });
      await nextFile.transpiled; // トランスパイルの結果が出るのを待つ
      this.beautify(); // Auto beautify
      await this.props.putFile({ file: nextFile, isUserAction: true });

      // 再読み込み
      this.props.setLocation(href);
    } catch (error) {
      this.props.globalEvent.emit('message.editor', {
        data: { value: file.name }
      }); // もう一度ファイルを開かせる

      this.setState({
        babelError: errorOf(error)
      });
      console.error(error);
    }

    this.setState({ loading: false });
  };

  handleUndo = () => {
    if (!this.codemirror) return;
    this.codemirror.undo();
  };

  handleRestore = () => {
    const { file } = this.state;
    const cm = this.codemirror;
    if (!file || !cm) return;
    const { left, top } = cm.getScrollInfo();
    cm.scrollTo(left, top);

    // 変更を加える前の状態(前回保存したところ)に戻す
    while (cm.historySize().undo > 0) {
      cm.undo(); // ひとつ前に戻す
      if (cm.getValue() === file.text) {
        // 前回の保存内容と同じになった
        break;
      }
    }

    if (cm.getValue() !== file.text) {
      // 履歴を遡っても同じにはならなかった(履歴が混在している)
      cm.clearHistory();
      cm.setValue(file.text);
    }
    cm.scrollTo(left, top);
    this.runApp();
  };

  getBeautifiedCode = (code: string, file: ImmutableFile) => {
    // import .jsbeautifyrc
    let configs: any = {};
    try {
      const [runCommand] = this.props.files.filter(file =>
        file.name.endsWith('.jsbeautifyrc')
      );
      if (runCommand) {
        configs = JSON.parse(runCommand.text);
      }
    } catch (error) {
      console.error(error);
    }

    const beautified = preserveTrailingSpaceBeautify(
      code,
      (file.is('javascript') || file.is('json')
        ? configs.js
        : file.is('html')
        ? configs.html
        : file.is('css')
        ? configs.css
        : {}) || {}
    );
    return beautified;
  };

  beautify = (preBeautified?: string) => {
    const { file } = this.state;
    const cm = this.codemirror;
    if (!file || !cm) return;
    const code = cm.getValue();
    const beautified = preBeautified || this.getBeautifiedCode(code, file);

    if (code !== beautified) {
      // undo => beautify => setValue することで history を 1 つに
      const { left, top } = cm.getScrollInfo();
      cm.undo();
      const from = new Pos(0, 0);
      const size = this.codemirror?.lineCount() || 1;
      const to = new Pos(
        size - 1,
        this.codemirror?.getLine(size - 1).length || 0
      );
      cm.replaceRange(beautified, from, to, 'beautify');
      cm.setValue(beautified);
      cm.scrollTo(left, top);

      this.props.unsetLocalChange();
    }
  };

  setValue(value: string) {
    if (!this.codemirror) return;
    const { left, top } = this.codemirror.getScrollInfo();
    this.codemirror.setValue(value);
    this.codemirror.scrollTo(left, top);
  }

  saveFileIfNeeded = async () => {
    const cm = this.codemirror;
    const { file } = this.state;
    if (!cm || !file) return;
    const text = cm.getValue(); // 現在のコード
    if (file.text == text) return; // 変わっていない

    const nextFile = file.set({ text });
    try {
      await nextFile.transpiled; // トランスパイルの結果が出るのを待つ
      await this.props.putFile({ file: nextFile, isUserAction: true });
    } catch (error) {
      this.setState({
        babelError: errorOf(error)
      });
      throw error;
    }
  };

  render() {
    const { iconUrl, localization } = this.props;
    const { file } = this.state;

    if (!file) {
      return null;
    }

    const extraKeys = {
      'Ctrl-Enter': () => {
        // Key Binding された操作の直後にカーソルが先頭に戻ってしまう(?)ため,
        // それをやり過ごしてから実行する
        window.setTimeout(this.runApp, 10);
      },
      'Ctrl-Alt-B': () => {
        // Key Binding された操作の直後にカーソルが先頭に戻ってしまう(?)ため,
        // それをやり過ごしてから実行する
        window.setTimeout(() => this.beautify(), 10);
      }
    };
    const foldOptions: any = {
      widget: '▶︎ OPEN',
      minFoldSize: 1,
      scanUp: false
    };
    if (file.is('javascript')) {
      foldOptions.rangeFinder = foldAsset;
    }

    const showBackButton = this.props.filePath !== this.props.filePathToBack;

    const dcn = getCn(this.props);

    return (
      <div className={cn.root}>
        <CardFloatingBar padding={0}>
          {localization.editorCard.title}
          {iconUrl ? (
            <img src={iconUrl} alt="" className={cn.icon} />
          ) : !showBackButton ? (
            <Home fontSize="large" className={cn.icon} />
          ) : null}
          <div className={dcn.tabContainer}>
            {this.codemirror && (
              <AssetPane
                label={this.props.label}
                codemirror={this.codemirror}
                runApp={this.runApp}
                localization={localization}
                globalEvent={this.props.globalEvent}
                filePath={this.props.filePath}
                filePathToBack={this.props.filePathToBack}
                isExpandingEditorCard={this.props.isExpandingEditorCard}
                saveFileIfNeeded={this.saveFileIfNeeded}
              />
            )}
            <PlayMenu
              runApp={this.runApp}
              href={this.props.href}
              localization={localization}
            />
          </div>
        </CardFloatingBar>
        {this.state.loading ? (
          <LinearProgress color="primary" className={cn.progress} />
        ) : null}
        <div className={cn.editorContainer}>
          <Editor
            file={file}
            loadConfig={this.props.loadConfig}
            codemirrorRef={this.handleCodemirror}
            extraKeys={extraKeys}
            foldOptions={foldOptions}
          />
        </div>
        <ErrorPane
          code={this.codemirror?.getValue('\n') || ''}
          error={this.state.babelError}
          localization={localization}
          onRestore={this.handleRestore}
          canRestore={this.state.hasHistory}
        />
        <AIMenuBar
          code={this.codemirror?.getValue('\n') || ''}
          filename={file.name}
        />
        <div className={cn.menuBarContainer}>
          <MenuBar
            localization={localization}
            handleUndo={this.handleUndo}
            runApp={this.runApp}
            hasHistory={this.state.hasHistory}
            filePath={this.props.filePath}
            label={this.props.label}
            filePathToBack={this.props.filePathToBack}
            globalEvent={this.props.globalEvent}
            isExpandingEditorCard={this.props.isExpandingEditorCard}
            setExpandingEditorCard={this.props.setExpandingEditorCard}
          />
        </div>
        {this.codemirror && (
          <LineWidget
            codemirror={this.codemirror}
            runApp={this.runApp}
            localization={localization}
          />
        )}
        <FixAndMessage
          codemirror={this.codemirror}
          localization={this.props.localization}
        />
        <HintProvider codemirror={this.codemirror} />
        <SelectionBalloonProvider
          codemirror={this.codemirror}
          localization={this.props.localization}
        />
        <DropdownProvider codemirror={this.codemirror} />
        <GlobalsProvider codemirror={this.codemirror} />
        <LocalChangeProvider codemirror={this.codemirror} />
      </div>
    );
  }
}

interface LocalChangeProviderProps {
  codemirror?: CodeMirror.Editor;
}

function LocalChangeProvider(props: LocalChangeProviderProps) {
  const localChangeRef = React.useRef(false);
  localChangeRef.current = useSelector(state => state.file.localChange);

  const dispatch = useDispatch();
  const setLocalChange = React.useCallback(
    (cm: any, changes: CodeMirror.EditorChange[]) => {
      if (changes.some(c => c.origin === 'beautify')) return;
      if (!localChangeRef.current) {
        dispatch(actions.setLocalChange(true));
      }
    },
    []
  );
  const unsetLocalChange = React.useCallback(() => {
    if (localChangeRef.current) {
      dispatch(actions.setLocalChange(false));
    }
  }, []);

  React.useEffect(() => {
    props.codemirror?.on('changes', setLocalChange);
    props.codemirror?.on('swapDoc', unsetLocalChange);
    return () => {
      props.codemirror?.off('changes', setLocalChange);
      props.codemirror?.off('swapDoc', unsetLocalChange);
    };
  }, [props.codemirror]);

  return null;
}
