import {
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import {
  ArrowDropDown,
  ArrowDropUp,
  ChatBubble,
  Delete,
  Edit,
  Flag,
  MoreVert,
  ThumbDown,
  ThumbUp
} from '@material-ui/icons';
import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  atomFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue
} from 'recoil';
import { classes } from 'typestyle';
import { requestWithAuth } from '../ducks/requestWithAuth';
import { endpoint } from '../env';
import { useFirestore } from '../hooks/useFirestore';
import { getId, useHashCommentId } from '../hooks/useHashCommentId';
import { commentAtom, workReplyIdsAtom } from '../recoil';
import { useConfirm } from './ConfirmManager';
import { LoadingButton } from './LoadingButton';
import { noticeAtom } from './NoticeManager';
import { ScrollIntoView } from './ScrollIntoView';
import { TextWithURL } from './TextWithURL';
import { TruncateText } from './TrancateText';
import { UserIconLink } from './UserNameLink';
import { validate, WorkCommentForm } from './WorkCommentForm';
import {
  reportedCommentIdsAtom,
  WorkCommentReportDialog
} from './WorkCommentReportDialog';
import { messageOf } from '../utils/error';

const useStyles = makeStyles(theme => ({
  root: {
    width: 312,
    boxSizing: 'border-box'
  },
  isReply: {
    paddingLeft: theme.spacing() * 4
  },
  content: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create('background-color')
  },
  contentScrollTarget: {
    backgroundColor: theme.palette.secondary.light
  },
  metadata: {
    flex: 1,
    marginLeft: theme.spacing()
  },
  space: {
    flex: 1
  },
  heading: {
    marginBottom: theme.spacing() / 2,
    '&>*': {
      marginRight: theme.spacing()
    }
  },
  userName: {
    fontSize: 'small',
    fontWeight: 700
  },
  actions: {
    marginBottom: theme.spacing() * 2,
    marginLeft: -theme.spacing() - 2, // 微調整
    marginRight: -theme.spacing() * 2,
    display: 'flex',
    justifyContent: 'space-between',
    '&>*': {
      marginRight: theme.spacing() * 2
    },
    color: grey[500]
  },
  actionsInReply: {
    marginLeft: theme.spacing() * 3
  },
  divider: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing() * 2
  },
  upVotes: {
    display: 'flex',
    alignItems: 'center',
    '&>button': {
      paddingRight: theme.spacing()
    }
  },
  replyText: {
    color: theme.palette.action.disabled
  },
  secondaryText: {
    color: theme.palette.secondary.main
  },
  replys: {
    '&>*': {
      marginTop: theme.spacing()
    }
  },
  moreButton: {
    marginLeft: theme.spacing() * 4
  }
}));

export interface WorkCommentItemProps {
  isReply?: boolean;
  workId: string;
  commentId: string;
  /**
   * リプライツリーの上位となるコメントの ID
   */
  parentId: string;
  /**
   * 返信ボタンを押した時の挙動を変更する
   * このコメント自体が返信であることのフラグも兼ねている
   */
  onReply?: (replyTo: string) => void;
}

export const WorkCommentItem = React.memo(
  function WorkCommentItem(props: WorkCommentItemProps) {
    const comment = useRecoilValue(commentAtom(props.commentId));
    const uid = comment?.uid;
    const user = useSelector(state =>
      uid ? state.user.byUid[uid] : undefined
    )?.data;
    const toName = useSelector(state => {
      const to = comment?.replyUid;
      return to ? state.user.byUid[to] : undefined;
    })?.data?.displayName;

    const replyIds = useRecoilValue(workReplyIdsAtom(props.commentId));
    const hashId = useHashCommentId();
    const isTargetInReplys = hashId ? replyIds.includes(hashId) : false;
    const [openReply, setOpenReply] = React.useState(isTargetInReplys);
    React.useEffect(() => {
      if (isTargetInReplys) {
        setOpenReply(true);
      }
    }, [isTargetInReplys]);

    const [replying, setReplying] = React.useState(false);
    const [replyTo, setReplyTo] = React.useState('');
    const handleReply = React.useCallback(
      (replyTo: string) => {
        if (props.onReply) {
          props.onReply(replyTo);
        } else {
          setReplying(true);
          setReplyTo(replyTo);
          setOpenReply(true);
        }
      },
      [props.onReply]
    );
    const handleCancel = React.useCallback(() => {
      setReplying(false);
      setReplyTo('');
    }, []);

    const authUserUid = useSelector(state => state.auth.userInfo?.uid);
    const firestore = useFirestore();
    const confirm = useConfirm();
    const handleDelete = useRecoilCallback(async ({ set }) => {
      setAnchorEl(undefined);
      const result = await confirm('本当に削除しますか？', 'はい', 'いいえ');
      if (!result) return;
      try {
        await firestore().collection('comments').doc(props.commentId).update({
          deletedAt: firestore.Timestamp.now()
        });
      } catch (error) {
        set(noticeAtom, { severity: 'error', children: messageOf(error) });
      }
    }, []);

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
    const [editing, setEditing] = React.useState(false);
    const [reporting, setReporting] = React.useState(false);
    const ids = useRecoilValue(reportedCommentIdsAtom);

    // このコメントまでスクロールする
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const [scrolled, setScrolled] = React.useState(false);
    const doScroll = hashId === props.commentId && !scrolled;
    React.useEffect(() => {
      if (doScroll) {
        setTimeout(() => {
          scrollRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest'
          });
        }, 100);
        setTimeout(() => {
          setScrolled(true);
        }, 2000);
      }
    }, [doScroll]);

    const cn = useStyles();

    if (!comment) {
      return null; // type fix
    }

    // コメントの編集中
    if (editing) {
      return (
        <Editing
          commentId={props.commentId}
          defaultValue={comment.text}
          onClose={() => setEditing(false)}
          isReply={props.isReply}
        />
      );
    }

    return (
      <div className={classes(cn.root, props.isReply && cn.isReply)}>
        <div
          id={getId(props.commentId)}
          ref={scrollRef}
          className={classes(cn.content, doScroll && cn.contentScrollTarget)}
        >
          <UserIconLink uid={comment.uid} size={props.isReply ? 24 : 32} />
          <div className={cn.metadata}>
            <div className={cn.heading}>
              <span className={cn.userName}>{user?.displayName}</span>
              <Typography variant="caption" color="textSecondary">
                {moment(comment.createdAt.toDate()).fromNow()}
              </Typography>
              {comment.updatedAt ? (
                <Typography variant="caption" color="textSecondary">
                  (編集済み)
                </Typography>
              ) : null}
            </div>
            {toName ? (
              <Typography
                variant="body2"
                color="textSecondary"
              >{`返信：${toName}さん`}</Typography>
            ) : null}
            {comment.deletedAt ? (
              <Typography variant="body2" color="textSecondary" gutterBottom>
                このコメントは削除されました
              </Typography>
            ) : (
              <TruncateText
                text={comment.text}
                maxWidth={272}
                lineClamp={3}
                component={TextWithURL}
              />
            )}
            {authUserUid && !comment.deletedAt ? (
              <div className={cn.actions}>
                <UpVote commentId={props.commentId} />
                <DownVote commentId={props.commentId} />
                <Button
                  startIcon={<ChatBubble fontSize="small" color="disabled" />}
                  className={cn.replyText}
                  onClick={() => handleReply(comment.uid)}
                >
                  返信
                </Button>
                <div className={cn.space}></div>
                <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
                  <MoreVert fontSize="small" color="disabled" />
                </IconButton>
                <Menu
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(undefined)}
                >
                  {uid === authUserUid ? (
                    <MenuItem
                      onClick={() => {
                        setEditing(true);
                        setAnchorEl(undefined);
                      }}
                    >
                      <ListItemIcon>
                        <Edit fontSize="small" />
                      </ListItemIcon>
                      編集する
                    </MenuItem>
                  ) : null}
                  {uid === authUserUid ? (
                    <MenuItem onClick={handleDelete}>
                      <ListItemIcon>
                        <Delete fontSize="small" />
                      </ListItemIcon>
                      削除する
                    </MenuItem>
                  ) : (
                    <MenuItem
                      disabled={ids.includes(props.commentId)}
                      onClick={() => {
                        setReporting(true);
                        setAnchorEl(undefined);
                      }}
                    >
                      <ListItemIcon>
                        <Flag fontSize="small" />
                      </ListItemIcon>
                      {ids.includes(props.commentId)
                        ? '報告しました'
                        : '報告する'}
                    </MenuItem>
                  )}
                </Menu>
              </div>
            ) : null}
          </div>
        </div>
        {replyIds.length > 0 ? (
          openReply ? (
            <Button
              color="secondary"
              endIcon={<ArrowDropUp />}
              onClick={() => setOpenReply(false)}
              className={cn.moreButton}
            >
              {replyIds.length}件の返信をかくす
            </Button>
          ) : (
            <Button
              color="secondary"
              endIcon={<ArrowDropDown />}
              onClick={() => setOpenReply(true)}
              className={cn.moreButton}
            >
              {replyIds.length}件の返信を読む
            </Button>
          )
        ) : null}
        {props.isReply ? null : openReply ? (
          <>
            <div className={cn.replys}>
              {replyIds.map(id => (
                <WorkCommentItem
                  key={id}
                  isReply
                  commentId={id}
                  parentId={props.commentId}
                  workId={props.workId}
                  onReply={handleReply}
                />
              ))}
            </div>
          </>
        ) : null}
        {replying ? (
          <WorkCommentForm
            workId={props.workId}
            parentId={props.commentId}
            to={replyTo}
            onCancel={handleCancel}
          />
        ) : null}
        {props.isReply ? null : <Divider className={cn.divider} />}
        <WorkCommentReportDialog
          workId={props.workId}
          commentId={props.commentId}
          open={reporting}
          onClose={() => setReporting(false)}
        />
      </div>
    );
  },
  (prev, next) => prev.commentId !== next.commentId
);

const useStylesEditing = makeStyles(theme => ({
  root: {
    width: 312,
    boxSizing: 'border-box'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing() * 3,
    '&>*': {
      marginLeft: theme.spacing()
    }
  }
}));

interface EditingProps {
  isReply?: boolean;
  commentId: string;
  defaultValue: string;
  onClose: () => void;
}

function Editing(props: EditingProps) {
  const cn = useStylesEditing();
  const [text, setText] = React.useState(props.defaultValue);
  const invalid = validate(text);
  const [loading, setLoading] = React.useState(false);

  const firestore = useFirestore();
  const handleSave = useRecoilCallback(
    async ({ set }) => {
      setLoading(true);
      try {
        await firestore().collection('comments').doc(props.commentId).update({
          text,
          updatedAt: firestore.Timestamp.now()
        });
        props.onClose();
      } catch (error) {
        set(noticeAtom, {
          severity: 'error',
          children: messageOf(error)
        });
      } finally {
        setLoading(false);
      }
    },
    [text]
  );

  return (
    <ScrollIntoView className={cn.root}>
      <TextField
        variant="filled"
        fullWidth
        multiline
        value={text}
        onChange={e => setText(e.currentTarget.value)}
        error={invalid}
        helperText={invalid ? '別のサイトの URL が含まれています。' : ''}
      />
      <div className={cn.actions}>
        <Button variant="contained" disableElevation onClick={props.onClose}>
          キャンセル
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          disableElevation
          color="secondary"
          onClick={handleSave}
          disabled={text === props.defaultValue || !text || invalid}
        >
          保存
        </LoadingButton>
      </div>
    </ScrollIntoView>
  );
}

const upVotesNumAtom = atomFamily({
  key: 'upVotesNumAtom',
  default: (commentId: string) => 0
});

interface VoteProps {
  commentId: string;
}

function UpVote(props: VoteProps) {
  const upVotes = useRecoilValue(commentAtom(props.commentId))?.upVotes;
  const server = upVotes?.length || 0;
  const [local, setLocal] = useRecoilState(upVotesNumAtom(props.commentId));
  // 値を同期する
  React.useEffect(() => {
    if (local !== server) {
      setLocal(server);
    }
  }, [server]);

  const uid = useSelector(state => state.auth.userInfo?.uid);
  const voted =
    local > server
      ? true // ローカルだけが増えている => 投票中
      : local < server
      ? false // ローカルだけが減っている => 削除中
      : !!upVotes?.includes(uid || '');

  const toggle = useRecoilCallback(
    async ({ set }) => {
      try {
        setLocal(local + (voted ? -1 : 1));
        await requestWithAuth(endpoint + '/votesToComment', 'POST', {
          commentId: props.commentId,
          down: 'false',
          remove: voted ? 'true' : 'false'
        });
      } catch (error) {
        set(noticeAtom, {
          severity: 'error',
          children:
            'いいねできませんでした。時間をおいてから、もう一度お試しください'
        });
      }
    },
    [props.commentId, uid, local, voted]
  );

  const cn = useStyles();

  return (
    <Tooltip title="いいね">
      <div className={cn.upVotes}>
        <IconButton disabled={!uid} onClick={toggle}>
          <ThumbUp fontSize="small" color={voted ? 'secondary' : 'disabled'} />
        </IconButton>
        <Typography
          variant="body1"
          color={voted ? 'secondary' : 'textSecondary'}
        >
          {local > 100 ? '99+' : local || ''}
        </Typography>
      </div>
    </Tooltip>
  );
}

const downVotedAtom = atomFamily({
  key: 'downVotedAtom',
  default: (commentId: string) => false
});

function DownVote(props: VoteProps) {
  const uid = useSelector(state => state.auth.userInfo?.uid);

  const downVotes = useRecoilValue(commentAtom(props.commentId))?.downVotes;
  const server = !!downVotes?.includes(uid || ''); // サーバーから Subscribe された値
  const [local, setLocal] = useRecoilState(downVotedAtom(props.commentId)); // ローカルで一時的に保持された値
  // 値を同期する
  React.useEffect(() => {
    if (server !== local) {
      setLocal(server);
    }
  }, [server]);

  const toggle = useRecoilCallback(
    async ({ set }) => {
      try {
        setLocal(local ? false : true); // 直ちに DOM に反映する
        await requestWithAuth(endpoint + '/votesToComment', 'POST', {
          commentId: props.commentId,
          down: 'true',
          remove: local ? 'true' : 'false'
        });
      } catch (error) {
        set(noticeAtom, {
          severity: 'error',
          children:
            'よくないねできませんでした。時間をおいてから、もう一度お試しください'
        });
      }
    },
    [props.commentId, uid, local]
  );

  return (
    <Tooltip title="よくない">
      <IconButton disabled={!uid} onClick={toggle}>
        <ThumbDown fontSize="small" color={local ? 'secondary' : 'disabled'} />
      </IconButton>
    </Tooltip>
  );
}
