import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import { getAuthUser } from '../ducks/auth';
import { requestWithAuth } from '../ducks/requestWithAuth';
import { endpoint } from '../env';
import { commentAtom } from '../recoil';
import { LoadingButton } from './LoadingButton';
import { noticeAtom } from './NoticeManager';

export const reportedCommentIdsAtom = atom<string[]>({
  key: 'reportedCommentIdsAtom',
  default: []
});

const useStyles = makeStyles(theme => ({
  freeTextContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  otherText: {
    display: 'inline',
    verticalAlign: 'sub',
    marginRight: theme.spacing()
  },
  freeTextField: {
    flex: 1
  }
}));

export interface WorkCommentReportDialogProps extends DialogProps {
  workId: string;
  commentId: string;
}

const reportChoices = [
  '個人情報（名前や住所など）が書かれています',
  '悪口やバカにするような内容が書かれています',
  'イタズラ目的で書かれています'
];

export function WorkCommentReportDialog({
  workId,
  commentId,
  ...props
}: WorkCommentReportDialogProps) {
  const reporter = useSelector(getAuthUser);
  const work = useSelector(state => state.work.works[workId])?.data;
  const comment = useRecoilValue(commentAtom(commentId));
  const reported = useSelector(state =>
    comment?.uid ? state.user.byUid[comment.uid] : undefined
  )?.data;

  const [selected, setSelected] = React.useState(0);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [loading, setLoading] = React.useState(false);
  const handleSend = useRecoilCallback(
    async ({ set }) => {
      setLoading(true);
      try {
        const text =
          selected > -1 ? reportChoices[selected] : inputRef.current?.value;
        await requestWithAuth(endpoint + '/reports', 'POST', {
          message: `${reported?.displayName} さんのコメント「${comment?.text}」に対して、\n${reporter?.displayName} さんが「${text}」と報告しています。\n場所: <${location.href}|${work?.title}>`
        });
        set(noticeAtom, {
          severity: 'success',
          children: '報告しました！ありがとうございました'
        });
        set(reportedCommentIdsAtom, curr => curr.concat(commentId));
        props.onClose?.({}, 'escapeKeyDown');
      } catch (error) {
        set(noticeAtom, {
          severity: 'error',
          children: 'エラーが起きたため、報告できませんでした'
        });
      } finally {
        setLoading(false);
      }
    },
    [selected]
  );

  React.useEffect(() => {
    if (selected === -1) {
      inputRef.current?.focus(); // その他が選択されたら focus する
    }
  }, [selected]);

  const cn = useStyles();

  return (
    <Dialog {...props}>
      <DialogTitle>このコメントについて教えてください</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="report reason"
            value={selected}
            onChange={(e, next) => setSelected(parseInt(next) ?? 0)}
          >
            {reportChoices.map((text, i) => (
              <FormControlLabel
                key={i}
                value={i}
                control={<Radio />}
                label={text}
              />
            ))}
            <FormControlLabel
              value={-1}
              control={<Radio />}
              classes={{ label: cn.freeTextContainer }}
              label={
                <>
                  <Typography className={cn.otherText}>その他</Typography>
                  <TextField
                    className={cn.freeTextField}
                    placeholder="ここに理由を書いてください"
                    inputRef={inputRef}
                    onFocus={() => setSelected(-1)}
                    disabled={loading}
                  />
                </>
              }
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disableElevation
          onClick={() => props.onClose?.({}, 'escapeKeyDown')}
          disabled={loading}
        >
          とじる
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          disableElevation
          color="secondary"
          onClick={handleSend}
        >
          報告する
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
