import * as React from 'react';
import { requestWithAuth } from '../ducks/requestWithAuth';
import { container } from '../utils/xlasses';
import { LoadingButton } from './LoadingButton';

export function PaymentHistory() {
  return (
    <div className={container.medium}>
      <CustomerPortalLink>お支払い履歴を見る</CustomerPortalLink>
    </div>
  );
}

interface CustomerPortalLinkProps {
  children: React.ReactNode;
}

/** StripeのCustomer Portalを開くリンク。一時的なURLをfetchで取得し、そのURLに遷移する */
function CustomerPortalLink(props: CustomerPortalLinkProps) {
  const [loading, setLoading] = React.useState(false);

  /**
   * StripeのCustomer Portalを開く。URLはAPIで毎回生成する
   */
  const openCustomerPortal = async () => {
    setLoading(true); // ページ遷移するので、ローディング状態はこのままにする
    try {
      const { url } = await requestWithAuth('/api/stripeCustomerPortal');
      location.href = url;
    } catch (error) {
      console.error(error);
      alert('エラーが発生しました。しばらくしてからもう一度お試しください。');
    }
  };

  return (
    <LoadingButton loading={loading} onClick={openCustomerPortal}>
      {props.children}
    </LoadingButton>
  );
}
