import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import { Asset, Entry } from 'contentful';
import * as React from 'react';
import { container } from '../utils/xlasses';
import { renderEntry } from './renderers';

export interface Fields {
  slug: string;
  header: Document;
  caption: Document;
  cover: Asset;
  body: Document;
}

export interface PostProps {
  entry: Entry<Fields>;
}

export function isPost(entry: Entry<unknown>): entry is Entry<Fields> {
  return entry.sys.contentType.sys.id === 'post';
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    color: '#232F34'
  },
  gradient: {
    background: 'linear-gradient(180deg, #4A90E2, #3C82F0)',
    backgroundColor: '#3C82F0',
    color: theme.palette.primary.contrastText
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      maxHeight: 400
    }
  },
  header: {
    textAlign: 'center'
  },
  mincho: {
    fontFamily:
      '游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN","Hiragino Mincho Pro",HGS明朝E,メイリオ,Meiryo,serif',
    '& *': {
      lineHeight: 1.5,
      fontWeight: 'initial' // 明朝体は太くしなくて良い
    }
  },
  gothic: {
    fontFamily: '游ゴシック体, 游ゴシック, YuGothic, "Yu Gothic"'
  },
  caption: {
    maxWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  cover: {
    objectFit: 'cover',
    objectPosition: 'left top',
    width: '100%',
    height: '100%'
  },
  beside: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 60,
    paddingRight: 60
  },
  besideSmall: {
    paddingLeft: 16,
    paddingRight: 16
  },
  body: {
    fontFamily:
      '"Hiragino Kaku Gothic Pro", "Roboto", "Helvetica", "Arial", sans-serif',
    '& ul': {
      marginTop: 0
    },
    '& h1': {
      marginBottom: 0
    },
    '& p': {
      minHeight: '1em',
      marginTop: 8
    },
    '& iframe': {
      maxWidth: 600,
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  bodySmall: {
    '& > *': {
      marginLeft: 8,
      marginRight: 8
    }
  },
  small: {
    '& h1': {
      fontSize: 20
    },
    '& h2': {
      fontSize: 19
    },
    '& h3': {
      fontSize: 18
    },
    '& h4, & h5, & h6': {
      fontSize: 17
    }
  }
}));

export function Post({ entry }: PostProps) {
  const cn = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isWideScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const { header, caption, cover, body } = entry.fields;

  return (
    <div className={classNames(cn.root, isSmallScreen && cn.small)}>
      <Grid container className={cn.top}>
        {!isWideScreen ? (
          <Grid
            item
            xs={12}
            className={classNames(cn.header, cn.mincho, cn.gradient)}
          >
            {header ? renderEntry(header) : null}
          </Grid>
        ) : null}
        {cover ? (
          <Grid item xs={12} lg={6}>
            <img
              src={cover.fields.file.url}
              className={cn.cover}
              alt={cover.fields.file.fileName}
            />
          </Grid>
        ) : null}
        <Grid
          item
          xs={12}
          lg={6}
          className={classNames(
            isWideScreen ? cn.beside : cn.besideSmall,
            cn.gradient
          )}
        >
          {header && isWideScreen ? (
            <div className={cn.mincho}>{renderEntry(header)}</div>
          ) : null}
          {caption ? (
            <div className={classNames(cn.gothic, cn.caption)}>
              {renderEntry(caption)}
            </div>
          ) : null}
        </Grid>
      </Grid>
      <div
        className={classNames(
          container.medium,
          cn.body,
          isSmallScreen && cn.bodySmall
        )}
      >
        {renderEntry(body, options, 'secondary')}
      </div>
    </div>
  );
}

const options: Options = {
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => <Heading>{children}</Heading>
  }
};

const useStylesHeading = makeStyles(theme => ({
  root: {
    borderLeft: `6px solid #4A90E2`,
    backgroundColor: '#EDF0F3',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 14,
    marginTop: 60,
    marginBottom: 24
  }
}));

function Heading(props: { children: React.ReactNode }) {
  const cn = useStylesHeading();
  return (
    <div className={cn.root}>
      <h2>{props.children}</h2>
    </div>
  );
}
