import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { style } from 'typestyle';
import { useFirestore } from '../hooks/useFirestore';
import { convertToComment, parentalControlState } from '../recoil';
import { analytics } from '../utils/analytics';
import { noticeAtom } from './NoticeManager';
import { ScrollIntoView } from './ScrollIntoView';
import { TextWithURL } from './TextWithURL';
import { TruncateText } from './TrancateText';
import { UserIconLink } from './UserNameLink';
import { receiveCommentAtom } from './WorkInfoPanel';
import { messageOf } from '../utils/error';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing() * 2,
    marginBottom: theme.spacing() * 4
  },
  spaces: {
    marginTop: theme.spacing() * 2,
    marginBottom: theme.spacing() * 3
  },
  chat: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  textField: {
    marginLeft: theme.spacing()
  },
  text: {
    backgroundColor: grey[100],
    padding: theme.spacing(),
    marginTop: theme.spacing() * 2,
    marginBottom: theme.spacing() * 3
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(),
    '&>*': {
      marginLeft: theme.spacing()
    }
  },
  ul: {
    '&>*': {
      marginBottom: theme.spacing()
    }
  },
  toName: {
    marginLeft: theme.spacing() * 6
  }
}));

const inputProps = {
  classes: {
    root: style({
      paddingTop: 8,
      paddingBottom: 8
    })
  }
};

export interface WorkCommentFormProps {
  workId: string;
  /**
   * 返信先の Comment ID
   */
  parentId?: string;
  /**
   * 返信先のユーザーの UID
   */
  to?: string;
  onCancel?: () => void;
}

export function WorkCommentForm(props: WorkCommentFormProps) {
  const uid = useSelector(state => state.auth.userInfo?.uid);
  const toName = useSelector(state =>
    props.to ? state.user.byUid[props.to] : undefined
  )?.data?.displayName;

  const [text, setText] = React.useState('');
  const invalid = React.useMemo(() => validate(text), [text]);
  const [opened, setOpened] = React.useState(false);
  const [sending, setSending] = React.useState(false);

  const parentalControl = useRecoilValue(parentalControlState);
  const disableSend = parentalControl?.enableSend !== true; // true に設定しなければコメント不可

  const disableReceive = !useRecoilValue(receiveCommentAtom(props.workId)); // この作品がコメントを受け取る設定になっているか？
  const workUid = useSelector(state => state.work.works[props.workId])?.data
    ?.uid;

  // キャンセル・送信ボタンを表示するか？
  const [showActions, setShowActions] = React.useState(false);

  const firestore = useFirestore();
  const handleSend = useRecoilCallback(
    async ({ set }) => {
      setOpened(false);
      if (!uid || !text || disableSend || disableReceive || invalid || !workUid)
        return;
      setSending(true);
      try {
        const comment: IComment = {
          workId: props.workId,
          workUid,
          uid,
          text,
          upVotes: [],
          downVotes: [],
          createdAt: firestore.Timestamp.now()
        };
        if (props.parentId) {
          comment.parentId = props.parentId;
        }
        if (props.to) {
          comment.replyUid = props.to;
        }
        await firestore()
          .collection('comments')
          .withConverter(convertToComment)
          .add(comment);
        handleCancel();
        analytics.commentToWork();
      } catch (error) {
        set(noticeAtom, { severity: 'error', children: messageOf(error) });
      } finally {
        setSending(false);
      }
    },
    [text, uid, disableSend, disableReceive, invalid, workUid]
  );

  const handleCancel = React.useCallback(() => {
    setText('');
    setShowActions(false);
    props.onCancel?.();
  }, []);

  const cn = useStyles();

  if (!uid) {
    return (
      <div className={cn.spaces}>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          ログインしていないのでコメントできません
        </Typography>
      </div>
    );
  }

  return (
    <ScrollIntoView className={cn.root}>
      {toName ? (
        <Typography variant="body2" color="textSecondary" className={cn.toName}>
          返信：{toName}さん
        </Typography>
      ) : null}
      <div className={cn.chat}>
        <UserIconLink uid={uid} size={40} />
        <TextField
          variant="filled"
          fullWidth
          multiline
          placeholder="あなたのコメントを入力…"
          InputProps={inputProps}
          value={text}
          onChange={e => setText(e.target.value)}
          autoFocus={!!props.parentId} // 明示的に返信ボタンを押した場合のみ autoFocus
          className={cn.textField}
          disabled={disableSend || disableReceive}
          onFocus={() => setShowActions(true)}
          error={invalid}
          helperText={invalid ? '別のサイトの URL が含まれています。' : ''}
        />
      </div>
      {disableSend ? (
        <Typography variant="body2" color="textSecondary">
          みまもり設定により、あなたはコメントを送ることができません。
        </Typography>
      ) : disableReceive ? (
        <Typography variant="body2" color="textSecondary">
          この作品ではコメントを受け取らない設定になっています。
        </Typography>
      ) : null}
      <Collapse in={showActions}>
        <div className={cn.actions}>
          <Button
            variant="contained"
            disableElevation
            disabled={sending || disableSend || disableReceive}
            onClick={handleCancel}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={
              !text || disableSend || disableReceive || sending || invalid
            }
            disableElevation
            onClick={() => setOpened(true)}
          >
            送信
          </Button>
        </div>
      </Collapse>
      <Dialog open={opened} onClose={() => setOpened(false)}>
        <DialogTitle>コメントを送る前に</DialogTitle>
        <Divider />
        <DialogContent>
          <TruncateText
            text={text}
            maxWidth="100%"
            className={cn.text}
            lineClamp={3}
            component={TextWithURL}
          />
          <Typography variant="h6">次のことに気をつけてください！</Typography>
          <ul className={cn.ul}>
            <li>個人情報（名前や住所など）をのせてはいけません</li>
            <li>他の人の悪口を書いてはいけません</li>
            <li>そのほか、イタズラなどもしてはいけません</li>
          </ul>
          <Typography gutterBottom>
            ハックフォープレイでは、このようなコメントは削除されます
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            disableElevation
            variant="contained"
            onClick={() => setOpened(false)}
          >
            やっぱりやめる
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            onClick={handleSend}
          >
            コメントを送る
          </Button>
        </DialogActions>
      </Dialog>
    </ScrollIntoView>
  );
}

/**
 * same-origin でない URL が含まれている場合は true を返す
 */
export function validate(text: string) {
  const regExp =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;

  for (let index = 0; index < 1000; index++) {
    const result = regExp.exec(text);
    if (!result) {
      break;
    }
    try {
      const url = new URL(result[0]);
      if (url.origin !== location.origin) {
        return true;
      }
    } catch (error) {
      return true;
    }
  }
  return false;
}
