import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  Typography
} from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  ReactStripeElements,
  injectStripe
} from 'react-stripe-elements';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { classes, style } from 'typestyle';
import { requestWithAuth } from '../ducks/requestWithAuth';
import { endpoint } from '../env';
import { authUidAtom, customerState } from '../recoil';
import theme from '../settings/theme';
import { Link } from '../utils/components';
import { messageOf } from '../utils/error';
import { getDefaultCard } from '../utils/stripeHelpers';
import { container, margin, marginR, marginV } from '../utils/xlasses';
import { useConfirm } from './ConfirmManager';
import { LoadingButton } from './LoadingButton';
import { noticeAtom } from './NoticeManager';
import { NeedSignIn } from './SignInDialog';

function Card() {
  const customer = useRecoilValue(customerState);
  const brand = getDefaultCard(customer)?.brand;
  if (!brand) return null;

  const cn = {
    img: style({
      marginTop: 16,
      marginRight: 16,
      maxWidth: 64
    })
  };

  return (
    <img
      src={
        brand === 'Visa'
          ? require('../resources/visa.jpg')
          : brand === 'MasterCard'
          ? require('../resources/mastercard.png')
          : brand === 'JCB'
          ? require('../resources/jcb.gif')
          : brand === 'American Express'
          ? require('../resources/amex.png')
          : brand === 'Diners Club'
          ? require('../resources/diners.gif')
          : brand === 'Discover'
          ? require('../resources/discover.jpg')
          : ''
      }
      alt={brand}
      className={cn.img}
    />
  );
}

const cn = {
  width400: style({
    width: '100%',
    maxWidth: 400
  }),
  width300: style({
    width: '100%',
    maxWidth: 300
  }),
  width150: style({
    width: '100%',
    maxWidth: 150
  }),
  divider: style({
    marginBottom: 10
  }),
  cardElement: style({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: 500,
    minWidth: 100
  }),
  input: style({
    padding: '18.5px 14px',
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor:
      theme.palette.type === 'light'
        ? 'rgba(0, 0, 0, 0.23)'
        : 'rgba(255, 255, 255, 0.23)',
    transition: theme.transitions.create('border-color'),
    $nest: {
      '&:hover': {
        borderColor: theme.palette.text.primary
      },
      '& input': {
        padding: 0
      }
    }
  }),
  button: style({
    margin: theme.spacing()
  })
};

function CheckoutFormComponent({
  stripe
}: ReactStripeElements.InjectedStripeProps) {
  const { initialized, userInfo } = useSelector(state => state.auth);
  const customer = useRecoilValue(customerState);
  const card = getDefaultCard(customer);

  const [error, setError] = React.useState<stripe.Error | null>(null);
  const [numberOk, setNumberOk] = React.useState(false);
  const [expireOk, setExpireOk] = React.useState(false);
  const [cvcOk, setCvcOk] = React.useState(false);
  const complete = numberOk && expireOk && cvcOk;
  const [email, setEmail] = React.useState<string>();
  const [processing, setProcessing] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  // フォームを表示するかどうか。支払い情報がない場合は自動的に表示する
  const [showForm, setShowForm] = React.useState(!card);
  React.useEffect(() => {
    if (showForm !== !card) {
      setShowForm(!card);
    }
  }, [card]);

  React.useEffect(() => {
    if (userInfo && email === undefined) {
      setEmail(userInfo.email || ''); // 初期値だけ代入
    }
  }, [userInfo]);

  const handleSubmit = useRecoilCallback(
    async ({ getLoadable, reset, set }) => {
      const loadable = getLoadable(authUidAtom);
      const uid = loadable.state === 'hasValue' ? loadable.contents : undefined;
      if (!email || !uid || !stripe) return;
      try {
        setProcessing(true);
        const { source, error } = await stripe.createSource({
          type: 'card',
          currency: 'jpy',
          owner: { email },
          metadata: { uid }
        });
        if (error) {
          console.error(error);
          setError(error);
        }
        if (source) {
          await requestWithAuth(endpoint + '/paymentMethods', 'PUT', {
            source: source.id
          });
          setShowForm(false);
          reset(customerState);
          set(noticeAtom, {
            severity: 'success',
            children: 'お支払い情報を確認できました。プランを変更しましょう！',
            closeText: 'プランを見る',
            role: '/plans'
          });
        }
      } catch (error) {
        set(noticeAtom, {
          severity: 'error',
          children:
            'お支払い情報を登録できませんでした。チャットでお問い合わせください'
        });
        window.Rollbar?.error(error);
        await window._slaask?.show();
        const snippet =
          '@teramotodaiki お支払い情報を登録できませんでした\n```\n' +
          messageOf(error) +
          '\n```'; // スニペットとしてエラーメッセージの全文を送る
        window._slaask?.sendMessageAsContact(snippet);
      } finally {
        setProcessing(false);
      }
    },
    [email, stripe]
  );

  const confirm = useConfirm();
  const handleDelete = useRecoilCallback(
    async ({ reset, set }) => {
      const result = await confirm(
        'この操作はやり直せません。本当に削除しますか？',
        'はい、削除します',
        'いいえ'
      );
      if (!result) return;
      try {
        setProcessing(true);
        await requestWithAuth(endpoint + '/paymentMethods', 'DELETE');
        setShowForm(true);
        reset(customerState);
        set(noticeAtom, {
          severity: 'success',
          children: 'お支払い情報を削除しました'
        });
        window.gtag?.('event', 'add_payment_info');
      } catch (error) {
        set(noticeAtom, {
          severity: 'error',
          children:
            'お支払い情報を削除できませんでした。チャットでお問い合わせください'
        });
        window.Rollbar?.error(error);
        await window._slaask?.show();
        const snippet =
          '@teramotodaiki お支払い情報を削除できませんでした\n```\n' +
          messageOf(error) +
          '\n```'; // スニペットとしてエラーメッセージの全文を送る
        window._slaask?.sendMessageAsContact(snippet);
      } finally {
        setProcessing(false);
      }
    },
    [stripe]
  );

  if (initialized && !userInfo) {
    return <NeedSignIn />;
  }

  return (
    <>
      <Typography variant="h6">お支払い情報</Typography>
      <div className={container.medium}>
        {error && error.message}
        {card ? (
          <div className={margin.large}>
            <Typography variant="h6" color="textSecondary">
              クレジットカード
            </Typography>
            <Card />
            <span>**** **** **** {card.last4}</span>
          </div>
        ) : null}
        {showForm ? (
          <>
            <FormControl error={!email} className={classes(cn.width400)}>
              <InputLabel htmlFor="email">メールアドレス</InputLabel>
              <Input
                disableUnderline
                id="email"
                value={email || ''}
                className={cn.input}
                onChange={event => setEmail(event.target.value)}
                aria-describedby="helper-email"
                fullWidth
              />
              <FormHelperText id="helper-email">
                ※お支払状況をお知らせするために使用します
              </FormHelperText>
            </FormControl>
            <div className={marginV.large}>
              <InputLabel htmlFor="number">カード番号</InputLabel>
              <CardNumberElement
                id="number"
                className={classes(cn.input, cn.width300)}
                onChange={event => setNumberOk(event.complete)}
              />
            </div>
            <div className={marginV.large}>
              <InputLabel htmlFor="expiry">有効期限</InputLabel>
              <CardExpiryElement
                id="expiry"
                className={classes(cn.input, cn.width150)}
                onChange={event => setExpireOk(event.complete)}
              />
            </div>
            <div className={marginV.large}>
              <InputLabel htmlFor="cvc">セキュリティコード</InputLabel>
              <CardCVCElement
                id="cvc"
                className={classes(cn.input, cn.width150)}
                onChange={event => setCvcOk(event.complete)}
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={event => setChecked(event.target.checked)}
                  />
                }
                label={
                  <>
                    <Button
                      color="primary"
                      component={Link('/pages/agreement/terms-of-paid')}
                      target="_blank"
                      rel="noopener"
                    >
                      月額プラン利用規約
                    </Button>
                    <span>を読み、その内容に同意しました</span>
                  </>
                }
              />
            </div>
            <div className={marginV.large}>
              <LoadingButton
                variant="contained"
                color="primary"
                className={marginR.medium}
                onClick={handleSubmit}
                disabled={!complete || !email || !checked}
                loading={processing}
              >
                {card ? '変更する' : 'カードを登録する'}
              </LoadingButton>
              {card ? (
                <Button
                  variant="outlined"
                  className={cn.button}
                  onClick={() => setShowForm(false)}
                >
                  変更をキャンセル
                </Button>
              ) : null}
            </div>
          </>
        ) : null}
        {showForm ? null : (
          <Button
            color={card ? 'default' : 'primary'}
            variant={card ? 'outlined' : 'contained'}
            className={cn.button}
            onClick={() => setShowForm(true)}
            disabled={processing || !card}
          >
            {card ? `カードを変更する` : `カードを登録する`}
          </Button>
        )}
        {showForm || !card ? null : (
          <Button
            variant="text"
            className={cn.button}
            onClick={handleDelete}
            disabled={processing || !card}
          >
            カードを削除する
          </Button>
        )}
      </div>
    </>
  );
}

export const CheckoutForm =
  process.env.NODE_ENV === 'development'
    ? () => <CheckoutFormComponent stripe={null} elements={null} />
    : injectStripe(CheckoutFormComponent);
