import { Button, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PlayCircleOutline } from '@material-ui/icons';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  atom,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue
} from 'recoil';
import { useRecoilEffect } from '../../../../hooks/useRecoilEffect';
import {
  cardPropsAtom,
  cardVisibilityAtom,
  globalEventAtom,
  hrefAtom,
  isEditorCardExpandedAtom,
  localizationAtom
} from '../../atoms';
import { useGlobalEvent } from '../../hooks/useGlobalEvent';
import { useLoadConfig } from '../../hooks/useLoadConfig';
import { useSetLocation } from '../../hooks/useSetLocation';
import { SetCardVisibility } from '../defaultState';
import SourceEditor from './SourceEditor';

export interface EditorCardProps {
  scrollToCard: (name: string) => void;
  setCardVisibility: SetCardVisibility;
}

const useStyles = makeStyles(theme => ({
  noFileBg: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main
  }
}));

export interface ITab {
  label: string;
  filePath?: string;
  iconUrl?: string;
  tabs?: ITab[];
}

export class Tab implements ITab {
  label: string;
  filePath?: string;
  iconUrl?: string;
  tabs?: Tab[] = [];

  constructor({ label, filePath, iconUrl, tabs }: ITab) {
    this.label = label;
    this.iconUrl = iconUrl;
    if (filePath) {
      this.filePath = filePath;
    } else if (tabs) {
      this.tabs = tabs.map(props => new Tab(props));
    }
  }

  static find(tabs: Tab[], filePath: string): Tab | undefined {
    for (const tab of tabs) {
      if (tab.filePath === filePath) return tab;
    }
    for (const tab of tabs) {
      if (Array.isArray(tab.tabs)) {
        const found = Tab.find(tab.tabs, filePath);
        if (found) return found;
      }
    }
  }
}

/**
 * EditorCard のプルダウンメニューの中で表示するファイルのリスト
 */
export const tabsAtom = atom<ITab[]>({
  key: 'tabsAtom',
  default: []
});

/**
 * 現在開いているファイルの名前. 空文字の場合は何も開いていない
 */
export const filePathAtom = atom({
  key: 'filePathAtom',
  default: ''
});

export function EditorCard(props: EditorCardProps) {
  const dcn = useStyles();

  let files = useSelector(state => state.file.files);
  files = files.filter(file => !file.isTrashed); // ゴミ箱にあるファイルを除外
  const localization = useRecoilValue(localizationAtom);
  const href = useRecoilValue(hrefAtom);
  const [isExpandingEditorCard, setExpandingEditorCard] = useRecoilState(
    isEditorCardExpandedAtom
  );
  const globalEvent = useRecoilValue(globalEventAtom);
  const cardProps = useRecoilValue(cardPropsAtom);
  const [filePath, setFilePath] = useRecoilState(filePathAtom); // 現在開いているファイルの名前. 空文字の場合は何も開いていない

  const [label, setLabel] = React.useState(''); // このファイルの呼び名（アセットから取得する）
  const [iconUrl, setIconUrl] = React.useState(''); // このファイルのアイコン（アセットから取得する）
  const [filePathToBack, setFilePathToBack] = React.useState(''); // 最初に表示するファイルのパス. ホーム

  const _setLocation = useSetLocation();
  const setLocation = (href?: string) => {
    _setLocation(href);
    props.scrollToCard('MonitorCard');
  };

  const filesRef = React.useRef(files);
  filesRef.current = files;
  const openFile = useRecoilCallback(
    (
      { getLoadable, set },
      tabs: ITab[],
      filePath: string,
      options: any = {}
    ) => {
      if (!filePath) return; // 無効なファイルパスを開こうとした
      const loadableFP = getLoadable(filePathAtom);
      if (loadableFP.state !== 'hasValue') return; // type error
      if (loadableFP.contents === filePath) return; // 今と同じファイル

      const file = filesRef.current.find(f => f.name === filePath); // file type を知るために探す
      if (!file) return; // ファイルが見つからなかった
      if (file.is('text')) {
        // テキストの場合は EditorCard で open
        const existTab = Tab.find(tabs, filePath);
        if (!existTab) {
          // 開こうとしているファイルを tabs の先頭に追加
          const tab = new Tab({ filePath, label: file.plain + file.ext });
          set(tabsAtom, curr => [tab].concat(curr));
        }
        setFilePath(filePath);
        setLabel(
          options.label
            ? options.label + ''
            : existTab
            ? existTab.label
            : file.plain
        );
        setIconUrl(options.iconUrl || '');

        props.setCardVisibility('EditorCard', true);
        // タブの選択が変化したら EditorCard にスクロールする
        props.scrollToCard('EditorCard');
      }
    },
    []
  );

  const tabs = useRecoilValue(tabsAtom);
  const tabsRef = React.useRef(tabs);
  tabsRef.current = tabs;
  useGlobalEvent(
    'message.editor',
    event => {
      const { value, options } = event.data;
      if (value) {
        // feeles.openEditor()
        openFile(tabsRef.current, value, options);
      } else {
        // feeles.closeEditor()
        props.setCardVisibility('EditorCard', false);
      }
    },
    []
  );

  // init.fileName があるとき Mount 後に開いておく
  useRecoilEffect(({ set }) => {
    try {
      const init = cardProps?.EditorCard.init as any;
      const filePath = init?.filePath || init?.fileName; // 後方互換性
      const tabs = init.tabs?.map((seed: ITab) => new Tab(seed));
      if (typeof filePath === 'string' && Array.isArray(tabs)) {
        set(tabsAtom, tabs);
        setFilePathToBack(filePath); // ホームに設定
        openFile(tabs, filePath);
      }
    } catch (e) {
      // continue regardless of error
    }
  }, []);

  const order = cardProps?.EditorCard.order;
  const visible = useRecoilValue(cardVisibilityAtom).EditorCard;

  const restrained = useSelector(state => state.make.restrained);
  const isOwner = useSelector(state => state.make.isOwner);

  const loadConfig = useLoadConfig();

  if (restrained && !isOwner) {
    return null; // EditorCard を表示しない
  }

  return (
    <div
      id="EditorCard"
      style={{
        position: 'relative',
        width: 0,
        order,
        boxSizing: 'border-box',
        maxWidth: '100%',
        direction: 'ltr',
        flex: '0 0 auto',
        flexBasis: visible ? (isExpandingEditorCard ? '75%' : '50%') : 0,
        padding: visible ? '16px 16px 16px 4px' : 0,
        overflow: visible ? 'initial' : 'hidden',
        display: 'flex'
      }}
    >
      <Card
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          position: 'relative',
          overflow: 'visible' // position: sticky のために必要
        }}
      >
        {filePath ? (
          <SourceEditor
            filePath={filePath}
            setLocation={setLocation}
            href={href}
            loadConfig={loadConfig}
            localization={localization}
            label={label}
            iconUrl={iconUrl}
            filePathToBack={filePathToBack}
            globalEvent={globalEvent}
            isExpandingEditorCard={isExpandingEditorCard}
            setExpandingEditorCard={setExpandingEditorCard}
          />
        ) : (
          <div className={dcn.noFileBg}>
            <Button
              variant="contained"
              size="large"
              onClick={() => setLocation()}
              endIcon={<PlayCircleOutline />}
            >
              Feeles
            </Button>
          </div>
        )}
      </Card>
      <div id="EditorCard-BottomAnchor" />
    </div>
  );
}

export { default as SourceEditor } from './SourceEditor';
