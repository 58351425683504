import { SceneMap } from '@hackforplay/next';
import { CircularProgress } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Launch } from '@material-ui/icons';
import * as React from 'react';
import { ReactMapEditorWithoutProvider, recoils } from 'react-map-editor';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilStateLoadable } from 'recoil';
import { style } from 'typestyle';
import { actions } from '../ducks/actions';
import * as helpers from '../ducks/helpers';
import { useDebouncedCallback } from '../hooks/useDebouncedCallback';
import { useUpdated } from '../hooks/useUpdated';
import { ErrorBoundary } from './ErrorBoundary';

const cn = {
  root: style({
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }),
  flex: style({
    flexGrow: 1
  }),
  code: style({
    height: '5rem',
    width: '100%',
    fontFamily: `Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace`,
    overflow: 'scroll',
    backgroundColor: 'lightgrey',
    padding: 20,
    borderRadius: 2
  }),
  migrate: style({
    opacity: 0.4
  }),
  error: style({
    color: 'red'
  }),
  progress: style({
    marginRight: 8
  })
};

const rmeStyle: React.CSSProperties = {
  flex: 1,
  height: 0
};

type Params = {
  id?: string;
};

const emptyMapData = helpers.has<SceneMap>(defaultMap());

export default function MapEditor({}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<Params>();

  const idRef = React.useRef(id);
  React.useEffect(() => {
    if (id) {
      dispatch(actions.map.load.started({ id }));
    }
    idRef.current = id;
  }, [id]);

  // 初回セーブ時のみ URL を変更する
  const isFirstSave = useSelector(state => state.maps.isFirstSave);
  const current = useSelector(state => state.maps.currentEditingPath);
  React.useEffect(() => {
    if (isFirstSave && current) {
      history.replace(`/${current}`);
    }
  }, [isFirstSave, current]);

  const mapState = useSelector(state =>
    id ? state.maps.sceneMaps[id] : emptyMapData
  );
  const updateErrorMessage = useSelector(
    state => state.maps.updateErrorMessage
  );

  const [changed, setChanged] = React.useState(false);
  const [save] = useDebouncedCallback(
    (json: string) => {
      const canvas = document.querySelector('canvas');
      if (!canvas) return;
      const thumbnail = resizeThumbnail(canvas);
      // 新規保存か、上書き保存か
      // 他の人のページを見ていることはないと想定する. id があれば上書き, そうでなければ新規
      // TODO: https://www.notion.so/teramotodaiki/Statefull-canUpdate-canDelete-component-d3dfc285dbaf4b10b446241fa72f5075
      const id = idRef.current;
      if (id) {
        dispatch(actions.map.update.started({ json, id, thumbnail }));
      } else {
        dispatch(actions.map.createNew.started({ json, thumbnail }));
      }
      setChanged(false);
    },
    [],
    3000
  );

  const userInfo = useSelector(state => state.auth.userInfo);
  const userRef = React.useRef(userInfo);
  userRef.current = userInfo;

  const sceneMapRef = React.useRef<SceneMap>();
  const onUpdate = React.useCallback((nextValue: SceneMap) => {
    if (
      sceneMapRef.current &&
      sceneMapRef.current !== nextValue &&
      userRef.current
    ) {
      setChanged(true);
      save(JSON.stringify(reduceFileSize(nextValue)));
    }
    sceneMapRef.current = nextValue;
  }, []);

  // ログインしたら即座に保存する
  useUpdated(() => {
    if (!userInfo || !sceneMapRef.current) return;
    setChanged(true);
    save(JSON.stringify(sceneMapRef.current));
  }, [userInfo]);

  const isUpdating = useSelector(state => state.maps.isUpdating);

  // コンポーネントが破棄されたタイミングでストアの情報をリセットする
  React.useEffect(
    () => () => {
      dispatch(actions.map.reset());
    },
    []
  );

  // マップエディタは初回に与えられた map を元に描画を行うので、初回の参照が得られるまでは描画しない
  const initialMapRef = React.useRef<SceneMap>();
  if (!initialMapRef.current) {
    if (!mapState || mapState.isProcessing) {
      return <div>Loading Map Data...</div>;
    }
    if (mapState.isEmpty) {
      return <div>Not Found</div>;
    }
    if (mapState.isInvalid || !mapState.data) {
      return <div>Error</div>;
    }
    initialMapRef.current = mapState.data;
  }

  return (
    <div className={cn.root}>
      <ErrorBoundary>
        <AppBar position="static" color="default" elevation={0}>
          <Toolbar>
            <Typography variant="h6" color="inherit">
              マップエディタ
            </Typography>
            <div className={cn.flex} />
            {updateErrorMessage ? (
              <small className={cn.error}>{updateErrorMessage}</small>
            ) : isUpdating ? (
              <>
                <CircularProgress className={cn.progress} size="1em" />
                <small>保存中</small>
              </>
            ) : changed ? (
              <>
                <CircularProgress className={cn.progress} size="1em" />
                <small>待機中</small>
              </>
            ) : id ? (
              <small>ほぞんされています</small>
            ) : null}
            <Button
              color="primary"
              target="_blank"
              rel="noopener"
              href="https://helpfeel.com/hackforplay/%E3%83%9E%E3%83%83%E3%83%97%E3%82%A8%E3%83%87%E3%82%A3%E3%82%BF%EF%BC%88%CE%B2%E7%89%88%EF%BC%89%E3%82%92%E4%BD%BF%E3%81%A3%E3%81%A6%E3%81%BF%E3%81%9F%E3%81%84-6065ebdde0c3eb001c3344d3"
              referrerPolicy="no-referrer"
              endIcon={<Launch />}
            >
              使い方
            </Button>
            {id ? <MigrateDialog id={id} /> : null}
          </Toolbar>
        </AppBar>
        <ReactMapEditorWithoutProvider style={rmeStyle} />
        <SceneMapSubscriber
          defaultValue={initialMapRef.current}
          onUpdate={onUpdate}
        />
      </ErrorBoundary>
    </div>
  );
}

interface SceneMapSubscriberProps {
  defaultValue?: SceneMap;
  onUpdate: (nextValue: SceneMap) => void;
}

function SceneMapSubscriber(props: SceneMapSubscriberProps) {
  const [sceneMapLoadable, setSceneMap] = useRecoilStateLoadable(
    recoils.sceneMapState
  );
  React.useEffect(() => {
    if (
      sceneMapLoadable.state === 'hasValue' &&
      props.defaultValue !== sceneMapLoadable.contents
    ) {
      props.onUpdate(sceneMapLoadable.contents);
    }
  }, [sceneMapLoadable]);

  React.useEffect(() => {
    if (props.defaultValue) {
      setSceneMap(props.defaultValue);
    }
  }, []);

  return null;
}

interface MigrateDialogProps {
  id: string;
}

function MigrateDialog(props: MigrateDialogProps) {
  const mapDocument = useSelector(state => state.maps.maps[props.id]);
  const [open, setOpen] = React.useState(false);
  const textarea = React.useRef<HTMLTextAreaElement>(null);

  const copyCode = React.useCallback(() => {
    if (textarea.current) {
      textarea.current.select();
      document.execCommand('copy');
      alert('コピーされました！');
    }
  }, [textarea.current]);

  const jsonUrl = mapDocument?.data?.jsonUrl;
  const code = jsonUrl
    ? `
await Hack.loadMap(
  'map1',
  '${jsonUrl}'
);`.trim()
    : '保存すると、ここにコードが表示されます';

  return (
    <>
      <Button className={cn.migrate} onClick={() => setOpen(true)}>
        旧キットで使う
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="alert-dialog-title">
          {'マップをコードに変換しました'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            旧RPGキットの場合は、このコードをコピーして、「Hack.changeMap('map1');」のすぐ上に書き足して下さい
            <br />
            RPGキット２の場合は、「はいけいをかえる」ボタンを押してください
          </DialogContentText>
          <textarea
            className={cn && cn.code}
            readOnly
            ref={textarea}
            wrap="off"
          >
            {code}
          </textarea>
        </DialogContent>
        <DialogActions>
          <Button onClick={copyCode} color="primary">
            コピー
          </Button>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function defaultMap(): SceneMap {
  // 15x10 の草原からスタート
  const row = (index: number) => Array.from({ length: 15 }).map(() => index);
  const table = (index: number) =>
    Array.from({ length: 10 }).map(() => row(index));

  return {
    base: 1000,
    tables: [table(-1), table(-1), table(-1)],
    squares: [
      {
        index: 1000,
        placement: {
          type: 'Ground'
        },
        tile: {
          size: [32, 32],
          image: {
            type: 'data-url',
            src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsSAAALEgHS3X78AAAA4UlEQVRYw8WXMQ7CQAwE8xEED+GBlFT5RFpKHsGPQJzkZtFqvQFuCxe+8ykT39pxlu1xfr7tejsOW++nYeXjPouvdTyP+3h+iQOwwMt2GMbA0Gfxtc7A8wAshSyl3StQV1JgeQCV4gpkfld8zM8DoOi6KXfFxvw8gNtQGBiWoWpgVITTAdwHqXj3RfIALIWqtaqUs7LF+DzA7LKTGpgOgGJRrZaJyy3Dj1YcA/hXGXbFmAfYO1y6DUf2gRiAO2i4DUiNcHmAX4vLHWbzAN2Ufzu22/8F0wD2iq/7uVVlHAd4AY/m2cw040lfAAAAAElFTkSuQmCC'
          },
          author: {
            name: 'ぴぽや',
            url: 'http://blog.pipoya.net/'
          }
        }
      }
    ]
  };
}

function reduceFileSize(sceneMap: SceneMap) {
  const used = new Set([sceneMap.base]);
  for (const table of sceneMap.tables) {
    for (const row of table) {
      for (const item of row) {
        used.add(item);
      }
    }
  }
  // @ts-expect-error SceneMapに型をつける
  const squares = sceneMap.squares.filter(item => used.has(item.index));
  if (squares.length === sceneMap.squares.length) {
    return sceneMap; // 変更なし
  }
  return {
    ...sceneMap,
    squares
  };
}

/**
 * サムネイルをそのまま保存するとデータサイズが大きくなるので、
 * 480x320 に縮小して保存する。足りない部分は黒で塗りつぶす
 */
function resizeThumbnail(source: HTMLCanvasElement) {
  const distWidth = 480;
  const distHeight = 320;
  if (source.width <= distWidth && source.height <= distHeight) {
    return source.toDataURL('image/png'); // そのまま出力
  }

  const scale = Math.min(distWidth / source.width, distHeight / source.height);
  const dist = document.createElement('canvas');
  dist.width = distWidth;
  dist.height = distHeight;
  const output = dist.getContext('2d');
  if (!output) {
    throw new Error('CanvasRenderingContext2D を生成できません');
  }
  output.fillStyle = 'black';
  output.fillRect(0, 0, dist.width, dist.height);
  output.drawImage(
    source,
    0,
    0,
    source.width,
    source.height,
    0,
    0,
    scale * source.width,
    scale * source.height
  );
  return dist.toDataURL('image/png');
}
