import { makeStyles } from '@material-ui/core/styles';
import { emphasize, fade } from '@material-ui/core/styles/colorManipulator';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { hierarchyCardFilterAtom } from '.';
import ImmutableFile from '../../File/ImmutableFile';
import DirCard from './DirCard';
import getHierarchy, { IHierarchy } from './getHierarchy';

export interface RootProps {
  isDirOpened: <T, S>(dir: IHierarchy, pass: T, fail: S) => T | S;
  handleFileSelect: (file: ImmutableFile) => void;
  handleDirToggle: (cd: IHierarchy) => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    paddingBottom: 40,
    overflowX: 'hidden',
    overflowY: 'scroll',
    fontFamily: theme.typography.fontFamily,
    backgroundColor: fade(emphasize(theme.palette.background.paper, 1), 0.07)
  }
}));

export function Root(props: RootProps) {
  const dcn = useStyles();

  const files = useSelector(state => state.file.files);
  const filter = useRecoilValue(hierarchyCardFilterAtom);

  return React.useMemo(() => {
    const showingFiles = files.filter(filter);
    return (
      <div className={dcn.root}>
        <DirCard
          dir={getHierarchy(showingFiles)}
          isDirOpened={props.isDirOpened}
          handleFileSelect={props.handleFileSelect}
          handleDirToggle={props.handleDirToggle}
          isRoot
        />
      </div>
    );
  }, [files, filter]);
}
