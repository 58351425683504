import { Theme, withTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { style } from 'typestyle';
import ImmutableFile from '../../File/ImmutableFile';
import FileCard from './FileCard';
import { IHierarchy } from './getHierarchy';

export interface DirCardProps {
  theme: Theme;
  dir: IHierarchy;
  isDirOpened: <T, S>(dir: IHierarchy, pass: T, fail: S) => T | S;
  handleFileSelect: (file: ImmutableFile) => void;
  handleDirToggle: (cd: IHierarchy) => void;
  isRoot?: boolean;
}

const cn = {
  root: style({
    borderWidth: 0,
    paddingTop: 16,
    paddingRight: 0,
    paddingBottom: 80,
    paddingLeft: 16
  })
};
const getCn = (props: DirCardProps) => ({
  dir: style({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-around',
    boxSizing: 'border-box',
    marginTop: 4,
    marginRight: 8,
    borderWidth: 4,
    borderRadius: 2,
    height: props.isDirOpened(props.dir, 'auto', 40),
    paddingBottom: props.isDirOpened(props.dir, props.theme.spacing(2), 0),
    paddingLeft: props.isDirOpened(props.dir, props.theme.spacing(2), 0),
    borderStyle: 'solid',
    borderColor: props.theme.palette.primary.main,
    transition: props.theme.transitions.create([
      'margin',
      'padding-bottom',
      'border'
    ])
  }),
  closed: style({
    cursor: 'pointer',
    color: props.theme.palette.text.secondary,
    paddingLeft: props.theme.spacing(2)
  }),
  closer: style({
    cursor: 'pointer',
    marginLeft: -props.theme.spacing(2),
    backgroundColor: props.theme.palette.primary.main
  }),
  closerLabel: style({
    fontWeight: 'bold',
    paddingLeft: props.theme.spacing(2),
    color: props.theme.palette.primary.contrastText
  })
});

class DirCard extends React.PureComponent<DirCardProps> {
  static propTypes = {};

  static defaultProps = {
    isRoot: false
  };

  render() {
    const dcn = getCn(this.props);
    const { isRoot, isDirOpened, handleDirToggle } = this.props;
    const cd = this.props.dir;

    const transfer = {
      isDirOpened: this.props.isDirOpened,
      handleFileSelect: this.props.handleFileSelect,
      handleDirToggle: this.props.handleDirToggle
    };

    return (
      <div className={isRoot ? cn.root : dcn.dir}>
        {isDirOpened(
          cd,
          <>
            <>
              {isRoot ? null : (
                <DirCloser
                  key="closer"
                  classes={dcn}
                  onClick={() => handleDirToggle(cd)}
                />
              )}
            </>
            <>
              {cd.dirs.map(dir => (
                <WrappedDirCard key={dir.path} dir={dir} {...transfer} />
              ))}
            </>
            <>
              {cd.files.map(file => (
                <FileCard key={file.name} file={file} {...transfer} />
              ))}
            </>
          </>,
          <div className={dcn.closed} onClick={() => handleDirToggle(cd)}>
            {cd.path}
          </div>
        )}
      </div>
    );
  }
}

const WrappedDirCard = withTheme(DirCard);

export default WrappedDirCard;

export interface DirCloserProps {
  classes: { closer: string; closerLabel: string };
  onClick: () => void;
}

export const DirCloser = withTheme(
  React.forwardRef((props: DirCloserProps, ref: React.Ref<HTMLDivElement>) => {
    return (
      <div ref={ref} className={props.classes.closer} onClick={props.onClick}>
        <span className={props.classes.closerLabel}>../</span>
      </div>
    );
  })
);
