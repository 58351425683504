import * as React from 'react';
import { useParams } from 'react-router';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { Page } from './Page';

export interface Params {
  contentType?: string;
  slug?: string;
}

export function PageRouter({}) {
  const { contentType, slug } = useParams<Params>();
  return (
    <ErrorBoundary>
      <Page contentType={contentType || ''} slug={slug || ''} />
    </ErrorBoundary>
  );
}
