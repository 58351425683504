import {
  documentToReactComponents,
  Options
} from '@contentful/rich-text-react-renderer';
import {
  Block,
  BLOCKS,
  Document,
  helpers,
  Inline,
  INLINES,
  Node
} from '@contentful/rich-text-types';
import { ButtonProps, Divider } from '@material-ui/core';
import * as React from 'react';
import { style } from 'typestyle';
import { EmbeddedAsset, isEmbeddedAsset } from './EmbeddedAsset';
import { EmbeddedTweet, isEmbeddedTweet } from './EmbeddedTweet';
import { FormLink, isFormLink } from './FormLink';
import { GridItem, isGridItem } from './GridItem';
import { GridLayout } from './GridLayout';
import { HyperLink } from './HyperLink';
import { Icon, isIcon } from './Icon';
import { IframeEmbed, isIframeEmbed } from './IframeEmbed';
import { ImageLink, isImageLink } from './ImageLink';

export function embeddedEntry(node: Block | Inline) {
  const entry = node.data.target;
  const block = helpers.isBlock(node);
  if (!entry) {
    console.warn('Is this page published?', node);
    return null;
  }
  if (isEmbeddedTweet(entry)) {
    return <EmbeddedTweet entry={entry} block={block} />;
  }
  if (isGridItem(entry)) {
    return <GridItem entry={entry} block={block} />;
  }
  if (isIcon(entry)) {
    return <Icon entry={entry} block={block} />;
  }
  if (isIframeEmbed(entry)) {
    return <IframeEmbed entry={entry} block={block} />;
  }
  if (isImageLink(entry)) {
    return <ImageLink entry={entry} block={block} />;
  }
  if (isFormLink(entry)) {
    return <FormLink entry={entry} block={block} />;
  }
  console.warn(entry);
  return null;
}

export function embeddedAsset(node: Block | Inline) {
  const asset = node.data.target;
  if (isEmbeddedAsset(asset)) {
    return <EmbeddedAsset asset={asset} block />;
  }
  console.warn(asset);
  return null;
}

const cn = {
  hr: style({
    marginTop: 16,
    marginBottom: 16,
    alignSelf: 'stretch'
  }),
  p: style({
    lineHeight: 1.5,
    whiteSpace: 'pre-wrap'
  })
};

export function renderEntry(
  document: Document,
  override?: Options,
  color?: ButtonProps['color']
) {
  const parentMap = makeParentMap(document);

  const options: Options = {
    renderNode: {
      [BLOCKS.DOCUMENT]: (node, children) => (
        <GridLayout node={node}>{children}</GridLayout>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={cn.p}>{children}</p>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        const parent = parentMap.get(node);
        const isInHeading = parent && parent.nodeType.startsWith('heading');
        return (
          <HyperLink
            node={node}
            color={color || 'primary'}
            variant={isInHeading ? 'contained' : 'text'}
          >
            {children}
          </HyperLink>
        );
      },
      [BLOCKS.HR]: () => <Divider className={cn.hr} />,
      [BLOCKS.EMBEDDED_ASSET]: embeddedAsset,
      [INLINES.EMBEDDED_ENTRY]: embeddedEntry,
      [BLOCKS.EMBEDDED_ENTRY]: embeddedEntry,
      ...(override?.renderNode || {})
    },
    renderMark: override?.renderMark,
    renderText: override?.renderText || renderText
  };

  return documentToReactComponents(document, options);
}

function makeParentMap(node?: Node, map = new WeakMap<Node, Node>()) {
  if (!node) return map;
  if (helpers.isInline(node) || helpers.isBlock(node)) {
    for (const child of node.content) {
      map.set(child, node);
      makeParentMap(child, map);
    }
  }
  return map;
}

function renderText(text: string) {
  return <>{Array.from(nToBr(text))}</>;
}

function* nToBr(text: string) {
  const lines = text.split('\n');
  for (let index = 0; index < lines.length; index++) {
    if (index > 0) yield <br key={index} />;
    yield lines[index];
  }
}
