import { makeStyles, Typography, TypographyProps } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';
import classNames from 'classnames';
import * as React from 'react';

const useStyleIconTypography = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing()
  },
  span: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

export interface IconTypographyProps extends TypographyProps {
  icon: SvgIconComponent;
}

export function IconTypography(props: IconTypographyProps) {
  const cn = useStyleIconTypography();

  const { icon, ...rest } = props;

  return (
    <Typography {...rest} className={classNames(props.className, cn.root)}>
      <props.icon className={cn.icon} component="svg" />
      <span className={cn.span}>{props.children}</span>
    </Typography>
  );
}
