import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { Send } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { validate } from 'email-validator';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { requestWithAuth } from '../ducks/requestWithAuth';
import { endpoint } from '../env';
import { parentalControlState } from '../recoil';
import { isEduEmail } from '../utils/isEduEmail';
import { LoadingButton } from './LoadingButton';
import { noticeAtom } from './NoticeManager';

const useStylesParentalControlDialog = makeStyles(theme => ({
  root: {
    maxWidth: 420
  },
  title: {
    display: 'flex'
  },
  input: {
    marginTop: 16,
    marginBottom: 32
  },
  img: {
    marginRight: 8
  },
  divider: {
    marginTop: 32,
    marginBottom: 8
  },
  alert: {
    marginBottom: 16
  },
  email: {
    padding: '0.25em 1em',
    backgroundColor: 'rgba(41, 121, 255, 0.1)'
  },
  emailContainer: {
    marginBottom: 16
  }
}));

export interface ParentalControlDialogProps extends DialogProps {
  changeEmail?: boolean;
  /**
   * 新規登録またはメールアドレス変更のメールを送信した後にコールされるコールバック
   */
  onMailAddressSent?: () => void;
  /**
   * 設定変更リンク付きのメールを送信した後にコールされるコールバック
   */
  onMailLinkSent?: () => void;
}

export function ParentalControlDialog({
  changeEmail = false,
  onMailAddressSent,
  onMailLinkSent,
  ...props
}: ParentalControlDialogProps) {
  const cn = useStylesParentalControlDialog();

  // 現在のメールアドレスを登録
  const loadable = useRecoilValueLoadable(parentalControlState);
  const current =
    loadable.state === 'hasValue' ? loadable.contents?.email : null;

  // 操作タイプ
  const type = changeEmail ? 'change' : current ? 'send' : 'register';

  const [input, setInput] = React.useState('');
  const [error, setError] = React.useState<Error>();
  const invalidMessage =
    input && !validate(input)
      ? '正しいメールアドレスを入力してください'
      : undefined;

  const setNotice = useSetRecoilState(noticeAtom);
  const [loading, setLoading] = React.useState(false);
  const sendMailAddress = React.useCallback(async () => {
    setError(undefined);
    setLoading(true);
    try {
      const result = await requestWithAuth(
        endpoint + '/parentalControlEmail',
        'PUT',
        { email: input }
      );
      if (!result.ok) {
        return setError(result.message); // 失敗
      }

      props.onClose?.({}, 'escapeKeyDown');
      onMailAddressSent?.();
      setNotice({
        severity: 'info',
        icon: <Send />,
        children:
          '入力されたアドレスにメールを送信しました。メールをご確認ください'
      });
    } catch (error) {
      console.error(error);
      setError(
        new Error(
          'メールを送信できませんでした。しばらく待ってもう一度お試しください'
        )
      );
    } finally {
      setLoading(false);
    }
  }, [input, onMailAddressSent]);

  const sendMailLink = React.useCallback(async () => {
    setError(undefined);
    setLoading(true);
    try {
      const result = await requestWithAuth(
        endpoint + '/parentalControlEmail',
        'POST'
      );
      if (!result.ok) {
        return setError(result.message); // 失敗
      }

      props.onClose?.({}, 'escapeKeyDown');
      onMailLinkSent?.();
      setNotice({
        severity: 'info',
        icon: <Send />,
        children:
          '登録されたアドレスにメールを送信しました。メールをご確認ください'
      });
    } catch (error) {
      console.error(error);
      setError(
        new Error(
          'メールを送信できませんでした。しばらく待ってもう一度お試しください'
        )
      );
    } finally {
      setLoading(false);
    }
  }, [onMailLinkSent]);

  if (loadable.state === 'loading') {
    return null; // ダイアログを出せない
  }
  if (loadable.state === 'hasError') {
    return (
      <Dialog {...props}>
        <DialogTitle>みまもり設定の情報を取得できませんでした</DialogTitle>
      </Dialog>
    );
  }

  return (
    <Dialog {...props} classes={{ paper: cn.root }}>
      <Alert severity="info">
        ほごしゃの方に見せてください。
        <a
          href="https://www.notion.so/f1c036d5a65d45e2ae3b7e26df356002"
          target="_blank"
          rel="noopener"
        >
          詳細
        </a>
      </Alert>
      {type === 'change' ? (
        <DialogContent>みまもり設定用メールアドレスの変更</DialogContent>
      ) : (
        <DialogContent className={cn.title}>
          <img
            src={require('../resources/promotion/blue_bossslime-402fbc89-70ac-488e-8d65-5efe4d63439f.png')}
            alt="青色のボススライム"
            className={cn.img}
          />
          <Typography variant="h6">
            コメント機能を利用するには
            <br />
            みまもり設定の変更が必要です
          </Typography>
        </DialogContent>
      )}
      <DialogContent>
        {type === 'send' ? (
          <div className={cn.emailContainer}>
            <Typography variant="body2">
              登録されているメールアドレス
            </Typography>
            <Typography variant="body1" className={cn.email}>
              {current}
            </Typography>
          </div>
        ) : (
          <TextField
            variant="outlined"
            color="secondary"
            fullWidth
            placeholder="保護者の方のメールアドレスを入力"
            value={input}
            onChange={e => setInput(e.target.value)}
            className={cn.input}
            error={invalidMessage !== undefined}
            helperText={invalidMessage}
          />
        )}
        {isEduEmail(input) ? (
          <Alert severity="warning">
            教育機関のメールアドレスは上手くメールが届かないことが多いので、別のメールアドレスをご利用ください。
            <br />
            どうしてもご利用されたい場合は、各自治体のご担当者までお問い合わせいただき、
            <i>@hackforplay.com</i> からのメールが届くように設定してください。
          </Alert>
        ) : null}
        {error ? (
          <Alert
            severity="error"
            onClose={() => setError(undefined)}
            className={cn.alert}
          >
            {error.message}
          </Alert>
        ) : null}
        <Li>みまもり設定の変更には、保護者の方のメールアドレスが必要です</Li>
        <Li>
          登録いただいたメールアドレスは
          <Link to="/pages/agreement/privacy-policy">プライバシーポリシー</Link>
          に則り適切に管理いたします
        </Li>
      </DialogContent>
      <Divider className={cn.divider} />
      <DialogActions>
        <Button onClick={() => props.onClose?.({}, 'escapeKeyDown')}>
          キャンセル
        </Button>
        {type === 'send' ? (
          <LoadingButton
            loading={loading}
            variant="contained"
            color="secondary"
            onClick={sendMailLink}
          >
            メールを送信
          </LoadingButton>
        ) : (
          <LoadingButton
            loading={loading}
            variant="contained"
            color="secondary"
            onClick={sendMailAddress}
            disabled={Boolean(invalidMessage || !input)}
          >
            メールを送信
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

interface LiProps {
  children: React.ReactNode;
}

function Li(props: LiProps) {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      component="li"
      gutterBottom
    >
      {props.children}
    </Typography>
  );
}
