import { difference } from 'lodash-es';
import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import firebase from '../settings/firebase';
import { actions, PaginateResult } from './actions';
import { asyncEpic, SKIP } from './asyncEpic';
import { empty, from, has, processing, Statefull } from './helpers';
import { reducerWithImmer } from './reducer-with-immer';

const firestore = firebase.firestore;

export type State = {
  byId: {
    [seriesId: string]: Statefull<ISeries> | undefined;
  };
  workIdToSeriesId: {
    [workId: string]: Statefull<string> | undefined;
  };
  mine: PaginateResult<string>;
  isCreating: boolean;
  isDeleting: boolean;
};

const initialState: State = {
  byId: {},
  workIdToSeriesId: {},
  mine: {
    data: [],
    mayHasMore: true
  },
  isCreating: false,
  isDeleting: false
};

const pageSize = 15;

export default reducerWithImmer(initialState)
  .case(actions.series.set, (draft, payload) => {
    const state = payload.data?.id ? from(payload.data) : empty<ISeries>();
    if (payload.data?.id) {
      draft.byId[payload.data.id] = state;
    }
    const idState = from(payload.data?.id);
    for (const workId of payload.data?.works || []) {
      draft.workIdToSeriesId[workId] = idState;
    }
    if (payload.workId) {
      draft.workIdToSeriesId[payload.workId] = idState;
    }
  })
  .case(actions.series.fetchByWorkId.started, (draft, payload) => {
    draft.workIdToSeriesId[payload] = processing();
  })
  // .case(actions.series.fetchByWorkId.done, (draft, payload) => {})
  .case(actions.series.fetchByWorkId.failed, (draft, payload) => {
    draft.workIdToSeriesId[payload.params] = empty();
  })
  .case(actions.series.listMine.done, (draft, payload) => {
    const { data, mayHasMore, timestamp } = payload.result;
    if (mayHasMore !== undefined) {
      draft.mine.mayHasMore = mayHasMore;
    }
    if (timestamp !== undefined) {
      draft.mine.timestamp = timestamp;
    }
    for (const item of data) {
      draft.mine.data.push(item.id);
    }
  })
  .case(actions.series.create.started, draft => {
    draft.isCreating = true;
  })
  .case(actions.series.create.done, (draft, payload) => {
    draft.isCreating = false;
    const { id } = payload.result;
    draft.byId[id] = has(payload.result);
    draft.mine.data.unshift(id); // 新しいアイテムは先頭に追加
  })
  .case(actions.series.create.failed, (draft, payload) => {
    draft.isCreating = false;
  })
  .case(actions.series.update.started, (draft, payload) => {
    // 同期的にストアを更新する
    const { data, id } = payload;
    const series = draft.byId[id]?.data;
    if (!series) return;
    if (data.works) {
      // works が更新されている場合、削除された workId の参照を削除する
      difference(series.works, data.works).forEach(workId => {
        delete draft.workIdToSeriesId[workId];
      });
    }
    Object.assign(series, data);
  })
  .case(actions.series.delete.started, draft => {
    draft.isDeleting = true;
  })
  .case(actions.series.delete.done, (draft, { params: { id } }) => {
    draft.isDeleting = false;
    delete draft.byId[id];
    const index = draft.mine.data.indexOf(id);
    if (index >= 0) {
      draft.mine.data.splice(index, 1);
    }
  })
  .case(actions.series.delete.failed, draft => {
    draft.isDeleting = false;
  })
  .toReducer();

export const epics = combineEpics(
  asyncEpic(actions.series.fetchByWorkId, async (action, state) => {
    const cacheId = state.series.workIdToSeriesId[action.payload]?.data;
    const cache = cacheId ? state.series.byId[cacheId] : undefined;
    if (cache) {
      // 既に取得済み
      return {
        workId: action.payload,
        data: cache.data
      };
    }

    const snapshot = await firestore()
      .collection('series')
      .where('works', 'array-contains', action.payload)
      .limit(1)
      .withConverter(convertToSeries)
      .get();
    const result = snapshot.docs[0];
    return {
      workId: action.payload,
      data: result?.data()
    };
  }),
  action$ =>
    action$.pipe(
      filter(actions.series.fetchByWorkId.done.match),
      map(action => actions.series.set(action.payload.result))
    ),

  // 自分が作ったシリーズを取得する
  asyncEpic(actions.series.listMine, async (action, state) => {
    const uid = state.auth.userInfo?.uid;
    const { mayHasMore, timestamp } = state.series.mine;
    if (!uid || !mayHasMore) {
      return { data: [] }; // ログアウトしていた場合か最後まで取得し切っていた場合は空のデータを返す
    }

    let query = firestore()
      .collection('series')
      .where('uid', '==', uid)
      .limit(pageSize)
      .orderBy('createdAt', 'desc')
      .withConverter(convertToSeries);
    if (timestamp) {
      query = query.startAfter(timestamp);
    }

    const snapshot = await query.get();

    const cursor =
      snapshot.size > 0
        ? snapshot.docs[snapshot.size - 1].get('createdAt')
        : undefined;
    return {
      data: snapshot.docs.map(doc => doc.data()),
      mayHasMore: snapshot.size >= pageSize,
      timestamp: cursor
    };
  }),
  action$ =>
    action$.pipe(
      filter(actions.series.listMine.done.match),
      mergeMap(({ payload }) =>
        of(...payload.result.data.map(data => actions.series.set({ data })))
      )
    ),

  // シリーズをあたらしくつくる
  asyncEpic(actions.series.create, async (action, state) => {
    const uid = state.auth.userInfo?.uid;
    if (!uid) return SKIP; // type hint
    const ref = await firestore()
      .collection('series')
      .withConverter(convertToSeries)
      .add({
        id: '', // omit
        title: '',
        works: [],
        ...action.payload,
        uid,
        createdAt: firestore.Timestamp.now(),
        updatedAt: firestore.Timestamp.now()
      });
    const snapshot = await ref.get();
    const created = snapshot.data();
    if (!created) {
      throw new Error('Failed to create series ' + ref.path);
    }
    return created;
  }),

  // シリーズの内容を更新する
  asyncEpic(actions.series.update, async ({ payload: { id, data } }) => {
    data = {
      ...data,
      updatedAt: firestore.Timestamp.now()
    };
    await firestore()
      .collection('series')
      .doc(id)
      .withConverter(convertToSeries)
      .update(data);
  }),

  // シリーズを削除する
  asyncEpic(actions.series.delete, async action => {
    await firestore().collection('series').doc(action.payload.id).delete();
    action.payload.callback();
  })
);

const convertToSeries: firebase.firestore.FirestoreDataConverter<ISeries> = {
  fromFirestore: snapshot =>
    ({
      id: snapshot.id,
      ...snapshot.data()
    } as ISeries),
  toFirestore: ({ id, ...data }: ISeries) => data
};
