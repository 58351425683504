import { Avatar, Grid, Typography } from '@material-ui/core';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { defaultIconUrl } from './Header';
import { cn } from './Profile';

type Params = { nickname?: string };

export function ProfileOfAnonymous() {
  const { nickname } = useParams<Params>();

  return (
    <div className={cn.root}>
      <Grid container spacing={2} alignItems="center" className={cn.container}>
        <Grid item xs={2}>
          <Avatar className={cn.avatar} src={defaultIconUrl} />
        </Grid>
        <Grid item xs={5}>
          <Typography variant="h5">{nickname || 'Unknown'}</Typography>
        </Grid>
      </Grid>
    </div>
  );
}
