export function messageOf(error: unknown) {
  if (error instanceof Error) {
    return error.message;
  }
  if (typeof error === 'string') {
    return error;
  }
  if (!error) {
    return String(error);
  }
  if (typeof error === 'object' && 'message' in error) {
    return String(error.message);
  }
  try {
    return JSON.stringify(error);
  } catch (error) {
    return String(error);
  }
}

export function errorOf(error: unknown) {
  if (error instanceof Error) {
    return error;
  }
  return new Error(messageOf(error));
}
