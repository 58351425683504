import { Entry } from 'contentful';
import * as React from 'react';
import { LoadingPage } from '../components/LoadingPage';
import { Agreement, isAgreement } from './Agreement';
import { Fes, isFes } from './Fes';
import { getContentfulClient } from './getContentfulClient';
import { isPost, Post } from './Post';
import { isSpecialPage, SpecialPage } from './SpecialPage';

export interface PageProps {
  contentType: string;
  slug: string;
}

export function Page({ contentType, slug }: PageProps) {
  const [notFound, setNotFound] = React.useState(false);
  const [entry, setEntry] = React.useState<Entry<unknown>>();

  // TODO: move to redux
  React.useEffect(() => {
    setNotFound(false);
    let canceled = false;
    getContentfulClient()
      .getEntries({ content_type: contentType, ['fields.slug']: slug })
      .then(entries => {
        if (canceled) return;
        const [entry] = entries.items;
        if (!entry) return setNotFound(true);
        setEntry(entry);
      })
      .catch(err => {
        console.error(err);
        if (canceled) return;
        setNotFound(true);
      });
    return () => {
      canceled = true;
    };
  }, [contentType, slug]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (notFound) {
    if (contentType === 'specialPage') return null;
    return <Page contentType="specialPage" slug="404" />;
  }

  if (!entry) {
    return <LoadingPage />;
  }

  if (isFes(entry)) {
    return <Fes entry={entry} />;
  }
  if (isSpecialPage(entry)) {
    return <SpecialPage entry={entry} />;
  }
  if (isAgreement(entry)) {
    return <Agreement entry={entry} />;
  }
  if (isPost(entry)) {
    return <Post entry={entry} />;
  }

  return <div>このページは表示できません</div>;
}
