import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { style } from 'typestyle';
import { DialogClose } from './DialogClose';
import { ResponsiveEmbed } from './ResponsiveEmbed';
import { SignInDialog } from './SignInDialog';

const cn = {
  center: style({
    justifyContent: 'center'
  })
};

/**
 * Google でログインしてステージを保存するよう促すダイアログ
 */
export function SuggestLoginDialog() {
  const { initialized, userInfo } = useSelector(state => state.auth);
  const changedByUser = useSelector(state => state.file.changedByUser);
  const isOwner = useSelector(state => state.make.isOwner);
  const [open, setOpen] = React.useState(false);
  const [openSignInDialog, setOpenSignInDialog] = React.useState(false);

  // 保存回数が一定に達したら自動的に開く
  // ２回目のセーブ時に表示され、以降は５回おきに表示
  React.useEffect(() => {
    if ((changedByUser - 2) % 5 === 0) {
      setOpen(true);
    }
  }, [changedByUser]);

  const close = () => {
    setOpen(false);
  };

  const signIn = () => {
    setOpen(false);
    setOpenSignInDialog(true);
  };

  const onClose = () => {
    setOpenSignInDialog(false);
  };

  if (!initialized || userInfo) {
    return null; // 未確定の時と, ログインしたら表示しない
  }
  if (!isOwner) {
    return null; // 他人のステージでは表示しない
  }

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>このステージは ほぞん されていません</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ハックフォープレイにログインすれば、ステージが ほぞん されます
          </DialogContentText>
          <DialogContentText>
            Googleアカウントをもっていれば、むりょうでログインできます
          </DialogContentText>
          <ResponsiveEmbed verticalPerHorizontal={9 / 16}>
            <iframe
              src="https://player.vimeo.com/video/407939848"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          </ResponsiveEmbed>
        </DialogContent>
        <DialogActions className={cn.center}>
          <Button
            variant="contained"
            color="primary"
            onClick={signIn}
            size="large"
          >
            ログイン
          </Button>
          <Button variant="outlined" onClick={close}>
            今はいい
          </Button>
        </DialogActions>
        <DialogActions>
          <span>すでにアカウントがある人は</span>
          <Button color="primary" onClick={signIn}>
            ログインする
          </Button>
        </DialogActions>
        <DialogClose onClick={close} />
      </Dialog>
      <SignInDialog open={openSignInDialog} onClose={onClose} />
    </>
  );
}
