const libraryRegEx =
  /import\s+['"]https\:\/\/cdn\.hackforplay\.xyz\/common\/([\d.]+)\/.*['"]/gi;
/**
 * ファイルを reduce で順番にみてライブラリのバージョンを取得する
 */
export function detectLibaryVersion(
  previous: string | undefined,
  current: ImmutableFile
) {
  if (previous) return previous; // 既に発見されている
  const text = current.text;
  if (typeof text !== 'string') return undefined;
  libraryRegEx.lastIndex = 0;
  const result = libraryRegEx.exec(text);
  return result ? result[1] : undefined;
}
