import { debounce } from 'lodash-es';
import { useCallback, useRef } from 'react';

interface DebouncedFunc<T extends (...args: any[]) => any> {
  /**
   * Call the original function, but applying the debounce rules.
   *
   * If the debounced function can be run immediately, this calls it and returns its return
   * value.
   *
   * Otherwise, it returns the return value of the last invokation, or undefined if the debounced
   * function was not invoked yet.
   */
  (...args: Parameters<T>): ReturnType<T> | undefined;

  /**
   * Throw away any pending invokation of the debounced function.
   */
  cancel(): void;

  /**
   * If there is a pending invokation of the debounced function, invoke it immediately and return
   * its return value.
   *
   * Otherwise, return the value from the last invokation, or undefined if the debounced function
   * was never invoked.
   */
  flush(): void;
}

/**
 * useCallback の戻り値を debounce する
 * @returns [debounce された関数, 通常の関数]
 */
export function useDebouncedCallback<Cb extends (...params: any[]) => any>(
  callback: Cb,
  deps: any[],
  wait?: number
): [DebouncedFunc<Cb>, Cb] {
  const stable = useCallback(callback, deps);
  const previous = useRef<typeof stable>(stable);
  const debounced = useRef<DebouncedFunc<Cb>>();

  if (!debounced.current) {
    // first
    debounced.current = debounce(stable, wait);
  }
  if (previous.current !== stable) {
    // changed
    previous.current = stable;
    debounced.current = debounce(stable, wait);
  }

  return [debounced.current, stable];
}
