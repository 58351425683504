import CodeMirror from 'codemirror';
import deepEqual from 'deep-equal';
import { includes } from 'lodash-es';
import * as React from 'react';

export interface CodeMirrorComponentProps {
  id: string;
  // CodeMirror options
  value: string;
  mode?: string;
  lineNumbers: boolean;
  indentUnit: number;
  indentWithTabs: boolean;
  matchBrackets: boolean;
  autoCloseBrackets: boolean;
  keyMap: string;
  foldOptions: any;
  dragDrop: boolean;
  extraKeys: any;
  readOnly: boolean;
  foldGutter: boolean;
}

export interface CodeMirrorComponentState {
  docs: Map<string, CodeMirror.Editor>;
}

export default class CodeMirrorComponent extends React.PureComponent<CodeMirrorComponentProps> {
  static defaultProps = {
    mode: null,
    lineNumbers: true,
    indentUnit: 4,
    indentWithTabs: true,
    matchBrackets: true,
    autoCloseBrackets: true,
    keyMap: 'default',
    dragDrop: false,
    extraKeys: {},
    readOnly: false,
    foldGutter: false
  };

  codeMirror?: CodeMirror.EditorFromTextArea;
  ref = React.createRef<HTMLTextAreaElement>();

  state = {
    // File ごとに存在する CodeMirror.Doc インスタンスのキャッシュ
    docs: new Map()
  };

  get options() {
    const gutters = [];
    if (this.props.lineNumbers) {
      gutters.push('CodeMirror-linenumbers');
    }
    if (this.props.foldGutter) {
      gutters.push('CodeMirror-foldgutter');
    }
    return {
      ...this.props,
      gutters
    };
  }

  componentDidMount() {
    // initialize CodeMirror
    if (this.ref.current) {
      this.codeMirror = CodeMirror.fromTextArea(this.ref.current, this.options);
      const { id } = this.props;

      const doc = this.codeMirror.getDoc();
      doc.setValue(this.props.value); // set default value
      doc.clearHistory();
      this.state.docs.set(id, doc);
    }
  }

  componentWillUnmount() {
    this.state.docs.clear();
    if (this.codeMirror) {
      this.codeMirror.toTextArea();
      this.codeMirror = undefined; // GC??
    }
  }

  componentDidUpdate(prevProps: CodeMirrorComponentProps) {
    if (!this.codeMirror) return;
    // タブ, value の更新
    if (prevProps.id !== this.props.id) {
      // 次のタブ (or undefined)
      let doc = this.state.docs.get(this.props.id);
      if (!doc) {
        // 新しく開かれたタブ（キャッシュに存在しない）
        // copy をもとに新しい Doc を作り、 value を更新
        doc = this.codeMirror.getDoc().copy(false);
        doc.setValue(this.props.value); // value の更新
        doc.clearHistory();
        this.state.docs.set(this.props.id, doc);
      }
      // 現在のタブと入れ替え
      this.codeMirror.swapDoc(doc);
    }
    // options の更新
    const ignoreKeys = ['id', 'value'];
    for (const [key, nextValue] of entries(this.props)) {
      if (includes(ignoreKeys, key)) continue;
      if (key === 'children') continue;
      if (!deepEqual(prevProps[key], this.props[key])) {
        // options の変更を CodeMirror に伝える
        this.codeMirror.setOption(key as any, nextValue);
      }
    }
  }

  getCodeMirror() {
    return this.codeMirror;
  }

  render() {
    return <textarea ref={this.ref} />;
  }
}

function entries<T extends object>(obj: T): [keyof T, any][] {
  return Object.entries(obj) as any;
}
