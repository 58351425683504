import {
  Button,
  Card,
  CardHeader,
  CardMedia,
  Collapse,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Typography
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { MoreVert, OpenInNew } from '@material-ui/icons';
import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { classes, keyframes, style } from 'typestyle';
import { actions } from '../ducks/actions';
import theme from '../settings/theme';
import { hover } from '../utils/xlasses';
import { LoadingButton } from './LoadingButton';
import { SortedGrid } from './SortedGrid';

export const cn = {
  root: style({
    padding: theme.spacing(6)
  }),
  headline: style({
    marginBottom: theme.spacing(4)
  }),
  card: style({
    cursor: 'pointer',
    textAlign: 'left',
    height: 208
  }),
  thumbnail: style({
    width: 240
  }),
  card_private: style({
    filter: `brightness(90%)`
  }),
  title: style({
    maxHeight: 48,
    overflow: 'hidden'
  }),
  noTitle: style({
    fontStyle: 'italic'
  }),
  subheader: style({
    maxWidth: 176,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  authorName: style({
    color: grey[500],
    $nest: {
      '&:hover': {
        color: grey[900]
      }
    }
  }),
  noAuthorName: style({
    color: grey[500],
    fontStyle: 'italic'
  }),
  more: style({
    width: '100%',
    position: 'relative',
    textAlign: 'center',
    $nest: {
      '&::before': {
        display: 'block',
        whiteSpace: 'pre',
        content: '""',
        position: 'relative',
        marginTop: -16,
        width: '100%',
        height: 16,
        background: 'linear-gradient(to bottom, transparent, white)'
      }
    }
  }),
  moreButton: style({
    fontSize: 'large',
    marginTop: 16,
    paddingTop: 16,
    paddingRight: 32,
    paddingBottom: 16,
    paddingLeft: 32
  }),
  cardMedia: {
    media: style({
      minHeight: 160,
      maxHeight: 160,
      objectFit: 'cover',
      backgroundColor: 'grey',
      animationName: keyframes({
        from: { backgroundColor: 'rgb(200,200,200)' },
        to: { backgroundColor: 'rgb(128,128,128)' }
      }),
      animationDuration: '2s',
      animationDirection: 'alternate',
      animationIterationCount: 'infinite',
      // https://github.com/bfred-it/object-fit-images/
      fontFamily: "'object-fit: contain;'"
    })
  },
  remove: style({
    color: 'red'
  })
};

export type OwnProps = {
  title: React.ReactNode;
  more: boolean;
  moreLink: string;
  showVisibility: boolean;
  className?: string;
};

export function MapList({ title, more, showVisibility, className }: OwnProps) {
  const userInfo = useSelector(state => state.auth.userInfo);
  const uid = userInfo && userInfo.uid;
  const myMapIds = useSelector(state => state.maps.myMapIds);
  const mapsMore = useSelector(state => state.maps.more);
  const dispatch = useDispatch();

  return React.useMemo(
    () => (
      <Paper elevation={2} className={classes(cn.root, className)}>
        {typeof title === 'string' ? (
          <Typography variant="h5" className={cn.headline}>
            {title}
          </Typography>
        ) : (
          title
        )}
        <Collapse collapsedHeight="284px" in={more || false}>
          <Grid container justify="center" spacing={1}>
            {myMapIds.map(id => (
              <MapListItem key={id} id={id} showVisibility={showVisibility} />
            ))}
            <Grid container justify="center">
              <LoadingButton
                variant="contained"
                color="primary"
                className={cn.moreButton}
                loading={mapsMore.isFetching}
                disabled={!mapsMore.mayHasMore}
                onClick={() => dispatch(actions.map.fetchMore.started())}
              >
                {mapsMore.mayHasMore ? 'もっと見る' : 'これで全部です'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Collapse>
        <Button variant="contained" startIcon={<OpenInNew />}>
          <Link to="/map-editor">マップをつくる</Link>
        </Button>
      </Paper>
    ),
    [myMapIds, uid, more]
  );
}

type MapListItemProps = {
  id: string;
  showVisibility?: boolean;
};

export function MapListItem({ id, showVisibility }: MapListItemProps) {
  const [open, setOpen] = React.useState(false);
  const anchorElRef = React.useRef<HTMLButtonElement>(null);
  const handleOpen = React.useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);
  const dispatch = useDispatch();
  const handleRemove = React.useCallback(() => {
    dispatch(actions.map.delete.started({ id }));
    setOpen(false);
  }, [id]);
  const handleCopy = React.useCallback(() => {
    dispatch(actions.map.copy.started({ id }));
    setOpen(false);
  }, [id]);

  const statefull = useSelector(state => state.maps.maps[id]);
  const data = statefull?.data;

  return (
    <SortedGrid item timestamp={data?.updatedAt}>
      <Link to={`/maps/${id}`}>
        {data ? (
          <Card
            elevation={0}
            className={classes(cn.card, cn.thumbnail, hover.growImageRoot)}
          >
            <div className={hover.growImageParent}>
              <CardMedia
                component="img"
                src={data.thumbnailUrl || `/api/maps/${id}/thumbnail`}
                classes={cn.cardMedia}
              />
            </div>
            <CardHeader
              action={
                <IconButton onClick={handleOpen} ref={anchorElRef}>
                  <MoreVert />
                </IconButton>
              }
              title={
                <Typography variant="caption">
                  {fromNow(data.updatedAt)}
                  {showVisibility
                    ? {
                        public: '・公開中',
                        limited: '・限定公開',
                        private: '・非公開'
                      }[data.visibility]
                    : ''}
                </Typography>
              }
            />
          </Card>
        ) : statefull?.isProcessing ? (
          <Card className={classes(cn.card, cn.thumbnail)}>
            <LinearProgress />
          </Card>
        ) : null}
      </Link>
      <Menu
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        getContentAnchorEl={null}
        anchorEl={anchorElRef.current}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCopy}>コピーする</MenuItem>
        <Divider />
        <MenuItem className={cn.remove} onClick={handleRemove}>
          ゴミばこにすてる
        </MenuItem>
      </Menu>
    </SortedGrid>
  );
}

function fromNow(timestamp: firebase.default.firestore.Timestamp | null) {
  return timestamp ? moment(timestamp.toDate()).fromNow() : null;
}
