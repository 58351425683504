import { shuffle } from 'lodash-es';

/**
 * 検索バーに表示するキーワード候補。順番はランダム
 * https://scrapbox.io/hackforplay-inc/検索バーでサジェストするキーワードの選定
 */
export const searchCandidates = shuffle([
  '逃走',
  '太鼓の',
  'モンスター',
  '謎解き',
  '鬼ごっこ',
  'アスレチック',
  'アクション',
  'ゲームセンター',
  '脱出',
  '脱獄',
  'バトルロイヤル',
  '育成',
  'シューティング',
  '弾幕',
  'よけろ',
  'シミュレーション',
  'スポーツ',
  'ホッケー',
  'サッカー',
  '物語',
  'ストーリー',
  'クイズ',
  'なぞなぞ',
  'アート',
  'バトル',
  'たおせ',
  'スキル',
  'ボス',
  'コラボ',
  'オリリンピック',
  'フェス',
  '大戦争',
  'パズル',
  'コピペOK',
  'ミニゲーム',
  '迷宮',
  '迷路',
  'タイピング',
  'ジャンプ',
  'クラフト',
  'すごろく',
  'フォート',
  'ダンジョン',
  '勉強',
  '国語',
  '算数',
  '理科',
  '英語',
  '歴史',
  '呼吸',
  'ハロウィン',
  'クリスマス',
  '桜',
  '雪',
  '雪合戦',
  'ゾンビ',
  'ドラゴン',
  'スライム',
  '勇者',
  'プリンセス',
  'ハンター',
  'ブロック',
  '商人',
  '死神',
  'サムライ',
  'メイジ',
  'ナイト',
  '村人',
  'ゴーレム',
  'ミミック',
  '宝箱',
  'まじょねこ',
  'カラス',
  'ゴースト',
  'ドラキュラ',
  'ボミィ',
  'メデューサ',
  'スイッチ',
  'トゲ',
  'うず',
  '爆弾',
  'ビーム',
  '姫',
  '魔王',
  '冒険',
  'ぼうけん',
  'アドベンチャー',
  'かくれんぼ',
  'ブラザーズ',
  '大乱闘',
  '小ネタ',
  '難易度',
  '簡単',
  '難しい',
  '激ムズ',
  '初級',
  '中級',
  '上級',
  'レース',
  'カート',
  '3D',
  'ホラー',
  '恐怖',
  '救う',
  '助けろ',
  '第一話',
  '人狼',
  '事件',
  '逃げ',
  'アンダー',
  '塔',
  'タワー',
  '城',
  '洞窟',
  '砂漠',
  '火山',
  '森',
  '草原',
  '無人島',
  '目指せ',
  '取り戻せ',
  'クエスト',
  '集め',
  '集めろ',
  '最強',
  '伝説',
  '対戦',
  'VS',
  '音楽',
  'リズム',
  '東方',
  'サバイバル',
  '日常',
  '生き残れ',
  '防衛',
  'ディフェンス',
  '宇宙',
  '仲間',
  '風',
  '海賊',
  'SCP',
  'ギミック',
  'トラップ',
  'コマンドバトル',
  'カード',
  '狩り',
  'スピードラン',
  'タイムアタック',
  '秒',
  'メーカー',
  'オリジナル',
  'クリッカー',
  'スコア',
  'キャッチ',
  '釣り',
  'ランダム',
  '学校',
  '学園',
  'マップ'
]);
