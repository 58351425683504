import firebase from '../settings/firebase';

const storage = firebase.storage;

export async function copyFile(source: string | undefined, dist: string) {
  const downloadUrl = await storage().ref(source).getDownloadURL();
  if (typeof downloadUrl !== 'string') {
    throw new Error('Failed to download. source=' + source);
  }
  const response = await fetch(downloadUrl);
  const blob = await response.blob();
  await storage().ref(dist).put(blob);
}
