import { Document } from '@contentful/rich-text-types';
import { Entry } from 'contentful';
import * as React from 'react';
import { classes, style } from 'typestyle';
import { container } from '../utils/xlasses';
import { renderEntry } from './renderers';

export interface Fields {
  slug: string;
  body: Document;
}

export interface SpecialPageProps {
  entry: Entry<Fields>;
}

export function isSpecialPage(entry: Entry<unknown>): entry is Entry<Fields> {
  return entry.sys.contentType.sys.id === 'specialPage';
}

const cn = {
  root: style({
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    $nest: {
      '& h1,& h2,& h3,& h4,& h5,& h6': {
        display: 'inline-flex',
        alignItems: 'center',
        marginTop: 0,
        marginBottom: 0 // h1 以外
      },
      '& h1': {
        marginBottom: '1em'
      },
      '& ul': {
        marginTop: 0
      }
    },
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  })
};

export function SpecialPage({ entry }: SpecialPageProps) {
  return (
    <div className={classes(cn.root, container.large)}>
      {renderEntry(entry.fields.body)}
    </div>
  );
}
