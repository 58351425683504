import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import * as React from 'react';
import { atom, useRecoilValue } from 'recoil';
import { classes, style } from 'typestyle';
import { Link } from '../utils/components';
import { objectFit } from '../utils/xlasses';

const logoUrl =
  'https://firebasestorage.googleapis.com/v0/b/hackforplay-development.appspot.com/o/attachments%2Fyoutuber-tani-logo.png?alt=media&token=6bed77ad-e5a3-4166-8458-502190402d35';

const cn = {
  root: style({
    position: 'fixed',
    top: 68,
    right: 68,
    zIndex: 1200,
    padding: 16,
    width: '90vw',
    maxWidth: 835
  }),
  icon: style({
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 4
  }),
  logo: style({
    width: '100%'
  })
};

export const popupAtom = atom<
  (NotificationDocContent & { type: 'youtuber-tani' }) | undefined
>({
  key: 'popupAtom',
  default: undefined
});

export function NotificationPopover() {
  const [closed, setClosed] = React.useState(false);
  const popupItem = useRecoilValue(popupAtom);
  const close = React.useCallback(() => setClosed(true), []);

  if (closed || !popupItem) {
    return null;
  }

  return (
    <Paper elevation={2} className={cn.root}>
      <Close className={cn.icon} onClick={close} />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <img
            src={logoUrl}
            alt="YouTuber たに のハックフォープレイ実況"
            className={classes(objectFit.contain, cn.logo)}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6" gutterBottom>
            あなたのゲームを YouTube で紹介させていただきました！
          </Typography>
          <Typography variant="body2">
            こんにちは！ハックフォープレイの公式 YouTuber の TANI
            です。あなたのステージ「{popupItem.title}
            」を、公式チャンネルで実況プレイさせていただきました！
          </Typography>
          <Typography variant="body2" gutterBottom>
            くわしくは、お知らせを見に来て下さい
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link('/notifications')}
            onClick={close}
          >
            お知らせを見る
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
