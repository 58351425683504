import * as React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const Close = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M13.414 12l4.243-4.243a1 1 0 0 0-1.414-1.414L12 10.586 7.757 6.343a1 1 0 0 0-1.414 1.414L10.586 12l-4.243 4.243a1 1 0 0 0 1.414 1.414L12 13.414l4.243 4.243a1 1 0 0 0 1.414-1.414L13.414 12z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Close;
