import {
  AppBar,
  CircularProgress,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { ArrowBack, Search } from '@material-ui/icons';
import { compile } from 'path-to-regexp';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { style } from 'typestyle';
import { actions } from '../ducks/actions';
import { useDebouncedCallback } from '../hooks/useDebouncedCallback';
import { useSiteMap } from '../hooks/useSiteMap';
import { searchCandidates } from '../settings/searchCandidates';
import { analytics } from '../utils/analytics';
import { Link } from '../utils/components';
import { ScrollContainer } from './ScrollContainer';

const cn = {
  toolbar: style({
    minHeight: 48, // 上下のマージンをなくす
    backgroundColor: grey[50]
  }),
  blank: style({
    flex: 1
  })
};

type Params = {
  query?: string;
};

type OnChange = NonNullable<
  React.InputHTMLAttributes<HTMLInputElement>['onChange']
>;
type OnKeyPress = NonNullable<
  React.DOMAttributes<HTMLInputElement>['onKeyPress']
>;

export function SearchBar() {
  const params = useParams<Params>();
  const history = useHistory();
  const search = useSelector(state => state.work.search);
  const siteMap = useSiteMap();
  const dispatch = useDispatch();
  const [query, setQuery] = React.useState(params.query || '');

  React.useEffect(() => {
    if (!query || search.query === query) return;
    analytics.search(query);
    dispatch(actions.work.search.started({ query }));
  }, [params.query]);

  const [debouncedSearch, handleSearch] = useDebouncedCallback(
    (query: string) => {
      if (!siteMap?.searchTo || !query) return;
      const toPath = compile(siteMap.searchTo);
      query = query.replace(/\\/g, ''); // "\" を削除
      query = encodeURIComponent(query); // "#" などを encode
      const url = toPath({ query });
      history.push(url);
    },
    [siteMap],
    2000
  );

  const handleChangeSearch = React.useCallback<OnChange>(
    event => {
      setQuery(event.currentTarget.value);
      debouncedSearch(event.currentTarget.value);
    },
    [debouncedSearch]
  );
  const handleCandidateClick = (keyword: string) => {
    setQuery(keyword);
    handleSearch(keyword);
    analytics.searchCandidate(keyword);
  };

  const handleKeyDown = React.useCallback<OnKeyPress>(
    event => {
      switch (event.key) {
        case 'Enter': {
          // IME の "確定" を含む
          handleSearch(event.currentTarget.value);
          debouncedSearch.cancel();
          break;
        }
        default:
          break;
      }
    },
    [debouncedSearch]
  );

  if (!siteMap) {
    // TODO: アニメーション
    return null;
  }
  const { backTo } = siteMap;

  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar className={cn.toolbar}>
        {backTo ? (
          <IconButton aria-label="Back" component={Link(backTo)}>
            <ArrowBack />
          </IconButton>
        ) : null}
        {siteMap.text ? (
          <Typography variant="h5">{siteMap.text}</Typography>
        ) : null}
        <div className={cn.blank} />
      </Toolbar>
      <div className="search-bar">
        <div className="search-box">
          {search.loading ? (
            <CircularProgress size={20} className="search-state-icon" />
          ) : (
            <Search className="search-state-icon" />
          )}
          <input
            id="docsearch-input"
            type="text"
            value={query}
            onChange={handleChangeSearch}
            onKeyDown={handleKeyDown}
          />
        </div>
        <ScrollContainer className="search-candidates">
          {searchCandidates.map(keyword => (
            <div
              key={keyword}
              className="search-candidate"
              onClick={() => handleCandidateClick(keyword)}
            >
              {keyword}
            </div>
          ))}
        </ScrollContainer>
      </div>
    </AppBar>
  );
}
