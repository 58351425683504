import * as React from 'react';

export interface ErrorBoundaryProps {
  fallback?: React.ReactNode;
}

/**
 * https://reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = { hasError: false };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    if (process.env.NODE_ENV !== 'production') {
      console.info(error);
      console.trace();
    }
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    // You can also log the error to an error reporting service
    error && window.Rollbar && window.Rollbar.error(error);
    info && window.Rollbar && window.Rollbar.info(info);
    if (process.env.NODE_ENV !== 'production') {
      console.error(error);
      console.info(info);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        this.props.fallback ?? (
          <>
            <h1>エラーが発生しました 😢</h1>
            <h2>少し時間をおいて、また来て下さい</h2>
          </>
        )
      );
    }

    return this.props.children;
  }
}
