import {
  Button,
  Card,
  Grow,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core';
import { LiveTv } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../ducks/actions';
import { getUnwatched, getVideoId, isFree } from '../ducks/video';
import { Link } from '../utils/components';
import { container } from '../utils/xlasses';
import { LoadingButton } from './LoadingButton';
import { VideoItem } from './VideoList';
import { getAuthUser } from '../ducks/auth';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: -theme.spacing() * 2
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingRight: theme.spacing() / 2,
    alignItems: 'center',
    overflow: 'hidden'
  },
  showUnwatch: {
    padding: theme.spacing() / 2
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing() / 2
  },
  close: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing()
  },
  dialogContent: {
    padding: 0,
    maxHeight: 400
  },
  dialogActions: {
    justifyContent: 'flex-start'
  }
}));

/**
 * ステージをつくる画面に、最も新しい通知を１つだけ表示するためのカード
 */
export function SuggestVideoCard() {
  const cn = useStyles();

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(actions.video.listIfNeeded('season_1'));
  }, []);

  const unwatched = useSelector(getUnwatched);
  const initialized = useSelector(state => state.auth.initialized);

  // 視聴中に unwatched が変更されるのを防ぐため一度 unwatched が決定したら変更しない
  const [nowShowing, setNowShowing] = React.useState(unwatched);
  React.useEffect(() => {
    if (nowShowing || !initialized || !unwatched) return;
    setNowShowing(unwatched);
  }, [nowShowing, unwatched, initialized]);
  const uri = nowShowing?.uri;
  const id = uri && getVideoId(uri);

  // 「もう見た」ボタン
  const [watchedManualy, setWatchedManualy] = React.useState(false);
  const watchManualy = React.useCallback(() => {
    if (!id) return;
    setWatchedManualy(true);
    dispatch(actions.video.watch.started(id));
  }, [id]);

  // 「もう見た」を元に戻せる UI を表示するかどうか
  const idRef = React.useRef(id);
  idRef.current = id;
  const watchedAt = useSelector(state => {
    const id = idRef.current;
    return id && state.video.videoWatchedAt[id];
  });
  const showUnwatchButton = Boolean(watchedManualy && watchedAt);

  // 「もとにもどす」ボタン
  const unwatch = React.useCallback(() => {
    if (!id) return;
    dispatch(actions.video.unwatch.started(id));
    setWatchedManualy(false);
  }, [id]);

  // 視聴可能かどうか
  const canWatchVideo = Boolean(useSelector(getAuthUser)?.plans?.canWatchVideo);
  const canWatchThisVideo =
    canWatchVideo || (nowShowing ? isFree(nowShowing) : false);

  return (
    <>
      <Grow in={initialized && unwatched !== undefined}>
        <div className={classNames(container.large, cn.root)}>
          <div className={cn.heading}>
            <LiveTv />
            おすすめの授業動画
          </div>
          <Card
            elevation={2}
            className={classNames(cn.card, showUnwatchButton && cn.showUnwatch)}
          >
            {showUnwatchButton ? (
              <Alert severity="success">
                {nowShowing?.name || '動画'}を視聴済みにしました
              </Alert>
            ) : nowShowing ? (
              <VideoItem recommended video={nowShowing} />
            ) : (
              <Typography variant="body1">シーズン２をお楽しみに！</Typography>
            )}
            {showUnwatchButton ? (
              <Button variant="outlined" onClick={unwatch}>
                もとにもどす
              </Button>
            ) : !watchedAt && canWatchThisVideo ? (
              <Tooltip arrow title="この動画を「視聴済み」にします">
                <LoadingButton
                  loading={Boolean(watchedManualy)}
                  variant="outlined"
                  onClick={watchManualy}
                >
                  もう見た
                </LoadingButton>
              </Tooltip>
            ) : null}
            <Button color="secondary" component={Link('/videos/season_1')}>
              ほかの動画を見る
            </Button>
          </Card>
        </div>
      </Grow>
    </>
  );
}
