import { Paper } from '@material-ui/core';
import * as React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { classes } from 'typestyle';
import { container, margin, padding } from '../utils/xlasses';
import { CheckoutForm } from './CheckoutForm';
import { ErrorBoundary } from './ErrorBoundary';
import { LoadingPage } from './LoadingPage';

const key = process.env.REACT_APP_STRIPE_API;

export function Payment() {
  const [stripe, setStripe] = React.useState<stripe.Stripe | null>(null);
  React.useEffect(() => {
    if (!key) {
      throw new Error('REACT_APP_STRIPE_API is not set');
    }
    if (window.Stripe) {
      setStripe(window.Stripe(key));
    } else {
      document.querySelector('#stripe-js')?.addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        setStripe(window.Stripe(key));
      });
    }
  }, []);

  return (
    <Paper
      elevation={2}
      className={classes(margin.large, padding.large, container.medium)}
      style={{ minHeight: 300 }}
    >
      <ErrorBoundary>
        <React.Suspense fallback={<LoadingPage />}>
          <StripeProvider stripe={stripe}>
            <Elements>
              <CheckoutForm />
            </Elements>
          </StripeProvider>
        </React.Suspense>
      </ErrorBoundary>
    </Paper>
  );
}
