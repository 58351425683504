import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';

export interface CircleIconProps {
  size: number;
  src: string;
  inline?: boolean;
  className?: string;
}

const useStyles = makeStyles(theme => ({
  icon: {
    borderRadius: '50%',
    overflow: 'hidden',
    height: 24,
    width: 24
  },
  img: {
    width: '100%'
  },
  inline: {
    display: 'inline-flex'
  }
}));

export function CircleIcon(props: CircleIconProps) {
  const cn = useStyles();
  const style = React.useMemo<React.CSSProperties>(() => {
    return {
      width: props.size,
      height: props.size
    };
  }, [props.size]);

  return (
    <div
      className={classNames(
        cn.icon,
        props.inline && cn.inline,
        props.className
      )}
      style={style}
    >
      <img src={props.src} alt="" className={cn.img} />
    </div>
  );
}
