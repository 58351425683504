import { ListItem, ListItemText, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../ducks/actions';

const useStylesWorkItem = makeStyles(theme => ({
  root: {
    height: theme.spacing() * 10,
    paddingLeft: 0,
    '& > button': {
      visibility: 'collapse' // マウスオーバーした時だけ表示する
    },
    '&:hover > button': {
      visibility: 'visible' // マウスオーバーした時だけ表示する
    }
  },
  deleted: {
    opacity: 0.5
  },
  order: {
    color: theme.palette.text.secondary,
    flex: 0,
    minWidth: theme.spacing() * 3,
    marginRight: theme.spacing(),
    overflow: 'hidden',
    textAlign: 'right'
  },
  image: {
    flex: 0,
    height: '100%',
    minWidth: theme.spacing() * 12
  },
  text: {
    paddingLeft: theme.spacing(),
    '& > *': {
      maxWidth: 176,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
  }
}));

export interface SeriesWorkItemProps {
  id: string;
  order?: number;
  selected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  hideDeleted?: boolean;
}

export function SeriesWorkItem(props: SeriesWorkItemProps) {
  const cn = useStylesWorkItem();

  const work = useSelector(state => state.work.works[props.id]);
  const data = work?.data;
  const publishedAt = data?.publishedAt;
  const date = React.useMemo(() => {
    return typeof publishedAt?.toDate === 'function'
      ? moment(publishedAt.toDate()).fromNow()
      : '';
  }, [publishedAt]);
  const clearRate = data?.clearRate
    ? `・${(data.clearRate * 100) >> 0}%の人がクリア`
    : '';

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(actions.work.fetchIfNeeded(props.id));
  }, [props.id]);

  if (props.hideDeleted && (data?.deletedAt || data?.visibility !== 'public')) {
    return null; // 削除された/公開されていないステージ
  }

  return (
    <ListItem
      key={props.id}
      className={classNames(cn.root, data?.deletedAt && cn.deleted)}
      button
      selected={props.selected}
      onClick={props.onClick}
    >
      {props.order ? <span className={cn.order}>{props.order}</span> : null}
      {data?.thumbnailUrl ? (
        <img src={data.thumbnailUrl} alt="" className={cn.image} />
      ) : (
        <span className={cn.image} />
      )}
      <ListItemText
        primary={data?.title || ''}
        secondary={
          data?.deletedAt ? '削除されました' : `${date}に公開${clearRate}`
        }
        className={cn.text}
      />
    </ListItem>
  );
}
