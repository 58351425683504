import { Button } from '@material-ui/core';
import { Theme, useTheme } from '@material-ui/core/styles';
import {
  Fullscreen,
  FullscreenExit,
  KeyboardBackspace,
  Layers,
  LayersClear
} from '@material-ui/icons';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { style } from 'typestyle';
import { actions } from '../../../../ducks/actions';
import { getAuthUser } from '../../../../ducks/auth';
import { Localization } from '../../localization';
import SelectTab from './SelectTab';

export interface MenuBarProps {
  theme: Theme;
  localization: Localization;
  handleUndo: () => void;
  runApp: () => void;
  hasHistory: boolean;
  filePath: string;
  showLineWidget: boolean;
  setShowLineWidget: (value: boolean) => void;
  label: string;
  filePathToBack: string;
  globalEvent: any;
  isExpandingEditorCard: boolean;
  setExpandingEditorCard: (value: boolean) => void;
}

const getCn = (props: MenuBarProps) => ({
  icon: style({
    color: props.theme.typography.button.color
  }),
  button: style({
    marginRight: props.theme.spacing(),
    minWidth: 40 // 縦長にはならないように
  })
});

export default function MenuBarWrapper(
  props: Omit<MenuBarProps, 'theme' | 'showLineWidget' | 'setShowLineWidget'>
) {
  const theme = useTheme();
  const authUser = useSelector(getAuthUser);
  const showLineWidget = Boolean(authUser?.editor?.showLineWidget);
  const dispatch = useDispatch();
  const setShowLineWidget = React.useCallback((value: boolean) => {
    dispatch(actions.make.updateEditor.started({ showLineWidget: value }));
  }, []);

  return (
    <MenuBar
      {...props}
      theme={theme}
      showLineWidget={showLineWidget}
      setShowLineWidget={setShowLineWidget}
    />
  );
}

class MenuBar extends React.Component<MenuBarProps> {
  toggleLineWidget = () => {
    const { showLineWidget } = this.props;
    this.props.setShowLineWidget(!showLineWidget);
  };

  handleBack = () => {
    this.props.runApp();
    this.props.globalEvent.emit('message.editor', {
      data: { value: this.props.filePathToBack }
    });
  };

  toggleExpandingEditorCard = () => {
    this.props.setExpandingEditorCard(!this.props.isExpandingEditorCard);
  };

  render() {
    const dcn = getCn(this.props);
    const { filePath, filePathToBack, isExpandingEditorCard } = this.props;

    const showBackButton = filePath !== filePathToBack;

    return (
      <>
        <Button
          variant="outlined"
          size="small"
          disabled={!this.props.hasHistory}
          onClick={this.props.handleUndo}
          className={dcn.button}
          startIcon={<KeyboardBackspace />}
        >
          {this.props.localization.editorCard.undo}
        </Button>
        <Button
          variant="outlined"
          size="small"
          className={dcn.button}
          onClick={this.toggleLineWidget}
        >
          {this.props.showLineWidget ? (
            <Layers className={dcn.icon} fontSize="small" />
          ) : (
            <LayersClear fontSize="small" />
          )}
        </Button>
        <Button
          variant={isExpandingEditorCard ? 'contained' : 'outlined'}
          color="primary"
          size="small"
          className={dcn.button}
          onClick={this.toggleExpandingEditorCard}
        >
          {isExpandingEditorCard ? <FullscreenExit /> : <Fullscreen />}
        </Button>
        {showBackButton ? null : (
          <SelectTab
            filePath={filePath}
            globalEvent={this.props.globalEvent}
            localization={this.props.localization}
            className={dcn.button}
          />
        )}
      </>
    );
  }
}
