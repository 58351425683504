import { flatten } from '../../../utils/array';

const separator = '/';

/**
 * パスを ImmutableFile.name に相当する normalized file path に変換する
 * @param pathes 連結されるパス
 */
export default function normalize(...pathes: (string | undefined)[]): string {
  const segments = flatten(
    pathes.map(seg => (seg ? seg.split(separator) : []))
  );
  const normalizedSegments: string[] = [];

  for (const seg of segments) {
    switch (seg) {
      case '':
        break; // nope
      case '.':
        break; //current direcotry
      case '..':
        normalizedSegments.pop(); // above directory
        break;
      default:
        normalizedSegments.push(seg);
        break;
    }
  }

  return normalizedSegments.join(separator);
}
