import { DependencyList, useEffect } from 'react';
import { CallbackInterface, useRecoilCallback } from 'recoil';

type RecoilEffectCallback = (
  cbint: CallbackInterface
) => void | (() => void | undefined);

/**
 * Recoil へのアクセサを受け取れる React.useEffect
 * @param effect React.useEffect と同じコールバックに recoil へのアクセサを与えたもの
 * @param deps React.useEffect の deps と同じ
 */
export function useRecoilEffect(
  effect: RecoilEffectCallback,
  deps: DependencyList
) {
  const callback = useRecoilCallback(cbint => effect(cbint), deps);
  useEffect(() => callback(), deps);
}
