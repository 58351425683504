import { Entry } from 'contentful';
import * as React from 'react';

export interface Fields {
  title: string;
  url: string;
  height: number;
}

export interface IframeEmbedProps {
  entry: Entry<Fields>;
  block?: boolean;
}

export function isIframeEmbed(entry: Entry<unknown>): entry is Entry<Fields> {
  return entry.sys.contentType.sys.id === 'iframeEmbed';
}

export function IframeEmbed({ entry, block = false }: IframeEmbedProps) {
  return (
    <iframe
      src={entry.fields.url}
      height={entry.fields.height}
      width="100%"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
}
