import {
  atom,
  atomFamily,
  DefaultValue,
  GetRecoilValue,
  NodeKey,
  selector,
  selectorFamily,
  SerializableParam
} from 'recoil';

export interface AtomRewindOptions<T> {
  key: NodeKey;
  default: (options: { get: GetRecoilValue }) => Promise<T>;
  dangerouslyAllowMutability?: boolean;
}

/**
 * reset() することで options.default を再実行する Atom
 */
export function atomRewind<T>(options: AtomRewindOptions<T>) {
  // reset または set された回数
  const setCount = atom({
    key: `${options.key}__setCount`,
    default: 0
  });

  // set された場合は値を保持
  // reset された場合は reset されたことを保持
  let overrideValue: T | DefaultValue = new DefaultValue();

  return selector<T>({
    key: options.key,
    dangerouslyAllowMutability: options.dangerouslyAllowMutability,
    get: ({ get }) => {
      get(setCount); // set と reset を購読
      try {
        return overrideValue instanceof DefaultValue
          ? options.default({ get }) // reset されたので関数を再実行
          : overrideValue; // set された値をそのまま返す
      } catch (error) {
        return Promise.reject(error); // https://bit.ly/2AThnPb
      }
    },
    set: ({ set }, newValue) => {
      overrideValue = newValue;
      set(setCount, count => count + 1);
    }
  });
}

export interface AtomRewindFamilyOptions<T, P extends SerializableParam> {
  key: NodeKey;
  default: (param: P) => (options: { get: GetRecoilValue }) => Promise<T>;
  dangerouslyAllowMutability?: boolean;
}

/**
 * param を与えられる atomRewind
 */
export function atomRewindFamily<T, P extends SerializableParam>(
  options: AtomRewindFamilyOptions<T, P>
) {
  // reset または set された回数
  const setCount = atomFamily({
    key: `${options.key}__setCount`,
    default: 0
  });

  // set された場合はキーに対して値を保持
  // reset された場合はキーを削除
  const overrideValueMap = new Map<string, T>();

  return selectorFamily<T, P>({
    key: options.key,
    dangerouslyAllowMutability: options.dangerouslyAllowMutability,
    get:
      param =>
      ({ get }) => {
        const state = setCount(param);
        get(state); // set と reset を購読
        try {
          return !overrideValueMap.has(state.key)
            ? options.default(param)({ get }) // reset されたので関数を再実行
            : (overrideValueMap.get(state.key) as T); // set された値をそのまま返す
        } catch (error) {
          return Promise.reject(error); // https://bit.ly/2AThnPb
        }
      },
    set:
      param =>
      ({ set }, newValue) => {
        const state = setCount(param);
        if (newValue instanceof DefaultValue) {
          overrideValueMap.delete(state.key); // reset
        } else {
          overrideValueMap.set(state.key, newValue); // set
        }
        set(setCount(param), count => count + 1);
      }
  });
}
