import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreator } from 'typescript-fsa';

/**
 * Action Creator => Action Dispatcher
 * ただし型は一致している
 * @param actionCreator Action Creator
 */
export function useActionDispatcher<T>(
  actionCreator: ActionCreator<T>
): ActionCreator<T> {
  const dispatch = useDispatch();
  const dispatcher = useCallback((...payload: Parameters<ActionCreator<T>>) => {
    const action = actionCreator(...payload);
    dispatch(action); // Dispatch Action
    return action;
  }, []);

  return Object.assign(dispatcher, {
    type: actionCreator.type,
    match: actionCreator.match
  });
}
