import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  makeStyles,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Restore } from '@material-ui/icons';
import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { convertToWorkVersion } from '../ducks/make';
import { requestWithAuth } from '../ducks/requestWithAuth';
import { toBlob } from '../ducks/utils';
import { endpoint } from '../env';
import { useFirestore } from '../hooks/useFirestore';
import { useRecoilEffect } from '../hooks/useRecoilEffect';
import { versionAtom, workVersionIdsAtom } from '../recoil';
import { notFoundImage } from '../utils/urls';
import { linkTextClass } from '../utils/xlasses';
import { IdeLastSaved } from './IdeLastSaved';
import { unblock } from './IdeMenuBar';
import { LoadingButton } from './LoadingButton';
import { ResponsiveEmbed } from './ResponsiveEmbed';

const useStylesRollbackMenu = makeStyles(theme => ({
  root: {
    minWidth: 340,
    maxHeight: 400,
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  title: {
    pointerEvents: 'none',
    paddingLeft: theme.spacing() * 2
  },
  content: {
    width: 340,
    marginBottom: theme.spacing()
  }
}));

export interface IdeRollbackMenuProps {
  workId: string;
}

export function IdeRollbackMenu(props: IdeRollbackMenuProps) {
  const cn = useStylesRollbackMenu();

  // このステージのバージョン履歴を Subscribe する
  const firestore = useFirestore();
  useRecoilEffect(
    ({ set }) => {
      const unsubscribe = firestore()
        .collection('works')
        .doc(props.workId)
        .collection('versions')
        .orderBy('createdAt', 'desc')
        .withConverter(convertToWorkVersion)
        .onSnapshot(qs => {
          qs.docChanges().forEach(change => {
            set(versionAtom(change.doc.id), change.doc.data());
          });
          const ids = qs.docs.map(ds => ds.id);
          set(workVersionIdsAtom(props.workId), ids);
        });
      return () => unsubscribe();
    },
    [props.workId]
  );
  const versionIds = useRecoilValue(workVersionIdsAtom(props.workId));

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const [selected, setSelected] = React.useState<string>();
  const [openedDialog, setOpenedDialog] = React.useState(false);
  const handleClose = React.useCallback(() => {
    setOpenedDialog(false);
    setAnchorEl(undefined);
  }, []);
  const pendingVersion = useSelector(state => state.make.pendingVersion);
  const [waitingCommit, setWaitingCommit] = React.useState(false);
  const history = useHistory();
  const lastThumbnail = useSelector(state => state.make.thumbnails[0]);
  const handleRestore = useRecoilCallback(async () => {
    if (!selected || !props.workId) return;
    if (pendingVersion) {
      try {
        setWaitingCommit(true);
        // まだ保存されていないバージョンがあるので、先に追加する
        const data: IWorkVersion = {
          createdAt: firestore.Timestamp.now(),
          storagePath: pendingVersion.storagePath,
          fileSize: pendingVersion.fileSize
        };

        // metadata にあるサムネイルは古いかも知れないため
        // 最後に自動撮影されたサムネイルをアップロードする
        // (public になっている場合は更新されない)
        if (lastThumbnail) {
          const blob = toBlob(lastThumbnail);
          const result = await requestWithAuth(
            endpoint + '/uploadImage',
            'POST',
            blob
          );
          if (result?.url) {
            data.thumbnailUrl = result.url;
          }
        }

        await firestore()
          .collection('works')
          .doc(pendingVersion.id)
          .collection('versions')
          .withConverter(convertToWorkVersion)
          .add(data);
      } catch (error) {
        console.error(error);
        // 最後のバージョンを保存できなかったが、ロールバックは続行する
      }
    }
    handleClose();
    // Work をアンマウントしてロールバック処理だけを行う
    unblock();
    history.replace(`/rollback/${props.workId}/${selected}`);
  }, [selected, props.workId, pendingVersion, lastThumbnail]);

  const handleSelect = React.useCallback((id: string) => {
    setSelected(id);
    setOpenedDialog(true);
  }, []);

  return (
    <>
      <Tooltip
        arrow
        title="バージョンをまきもどす"
        disableHoverListener={!versionIds.length}
      >
        <IdeLastSaved
          disabled={!versionIds.length}
          onClick={e => setAnchorEl(e.currentTarget)}
        />
      </Tooltip>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        classes={{ paper: cn.root }}
        onClose={() => setAnchorEl(undefined)}
      >
        <Typography variant="subtitle1" className={cn.title}>
          過去のバージョン
        </Typography>
        {versionIds.map((id, i) => (
          <ListItem key={id} dense>
            <RollbackMenuItem id={id} now={i === 0} onClick={handleSelect} />
          </ListItem>
        ))}
      </Popover>
      <Dialog open={openedDialog} onClose={handleClose}>
        <DialogTitle>このバージョンまで、本当にまきもどしますか？</DialogTitle>
        <DialogContent className={cn.content}>
          {selected ? <RollbackMenuItem id={selected} /> : null}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            やめる
          </Button>
          <LoadingButton
            loading={waitingCommit}
            variant="contained"
            color="primary"
            startIcon={<Restore />}
            onClick={handleRestore}
          >
            まきもどす
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

interface RollbackMenuItem {
  id: string;
  now?: boolean;
  onClick?: (id: string) => void;
}

function RollbackMenuItem(props: RollbackMenuItem) {
  const { id, now, onClick } = props;
  const version = useRecoilValue(versionAtom(id));
  const rollbackId = version?.rollbackId;

  return React.useMemo(
    () =>
      version ? (
        <Grid container spacing={1} id={getId(id)}>
          <Grid item xs={6}>
            <ResponsiveEmbed verticalPerHorizontal={2 / 3}>
              <img src={version.thumbnailUrl || notFoundImage} alt="" />
            </ResponsiveEmbed>
          </Grid>
          <Grid
            item
            xs={6}
            container
            direction="column"
            justify="space-between"
            alignItems="flex-start"
          >
            <Typography variant="body1">
              {moment(version.createdAt.toDate()).format('MM/DD HH:mm')}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {Math.floor(version.fileSize / 1024)}kB
            </Typography>
            {rollbackId ? (
              <RollbackedLabel rollbackId={rollbackId} />
            ) : now ? (
              <Typography variant="body1" color="textSecondary">
                現在
              </Typography>
            ) : onClick ? (
              <Button
                variant="contained"
                color="primary"
                startIcon={<Restore />}
                onClick={() => onClick(id)}
              >
                まきもどす
              </Button>
            ) : null}
          </Grid>
        </Grid>
      ) : null,
    [version]
  );
}

interface RollbackedLabel {
  rollbackId: string;
}

function RollbackedLabel(props: RollbackedLabel) {
  const rollbackVersion = useRecoilValue(versionAtom(props.rollbackId));

  return React.useMemo(() => {
    return (
      <Typography variant="body2" color="textSecondary">
        {rollbackVersion ? (
          <span
            className={linkTextClass}
            onClick={() => {
              document.body
                .querySelector('#' + getId(props.rollbackId))
                ?.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            {moment(rollbackVersion.createdAt.toDate()).format(
              'MM/DD HH:mm まで'
            )}
          </span>
        ) : null}
        ロールバックしました
      </Typography>
    );
  }, [rollbackVersion]);
}

function getId(versionId: string) {
  return `rollback_id_${versionId}`;
}
