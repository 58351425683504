import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField
} from '@material-ui/core';
import * as React from 'react';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import { style } from 'typestyle';
import ImmutableFile from '../File/ImmutableFile';
import { Abort, Confirm } from './Buttons';

export interface IPreferenceDialogData {
  resolve: (result?: PreferenceDialogResult) => void;
  file: ImmutableFile;
}

/**
 * ファイルの設定を行うダイアログ
 */
export const preferenceDialogAtom = atom<IPreferenceDialogData | null>({
  key: 'preferenceDialogAtom',
  default: null
});

export interface PreferenceDialogResult {
  name?: string;
  type?: string;
}

const cn = {
  root: style({
    fontSize: 16
  }),
  left: style({
    textAlign: 'right'
  })
};

export function PreferenceDialog() {
  const data = useRecoilValue(preferenceDialogAtom);

  const [changed, setChanged] = React.useState(false);
  const [name, setName] = React.useState<string>();
  const [type, setType] = React.useState<string>();

  // 初期値をセットする
  React.useEffect(() => {
    if (!data) return;
    setName(data.file.name);
    setType(data.file.type);
  }, [data]);

  const onRequestClose = useRecoilCallback(
    ({ set }) => {
      data?.resolve(); // undefined を渡す
      set(preferenceDialogAtom, null);
    },
    [data]
  );

  const confirm = useRecoilCallback(
    ({ set }) => {
      if (!data) return;
      data.resolve({ name, type }); // Promise を resolve する
      set(preferenceDialogAtom, null);
    },
    [name, type, data]
  );

  return (
    <Dialog open={Boolean(data)} className={cn.root} onClose={onRequestClose}>
      <DialogTitle>File Preference</DialogTitle>
      <DialogContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={cn.left}>Name</TableCell>
              <TableCell>
                <TextField
                  id="name"
                  defaultValue={name}
                  onChange={event => {
                    setName(event.target.value);
                    setChanged(true);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={cn.left}>Type</TableCell>
              <TableCell>
                <TextField
                  id="type"
                  defaultValue={type}
                  onChange={event => {
                    setType(event.target.value);
                    setChanged(true);
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Abort onClick={onRequestClose}>Cancel</Abort>
        <Confirm disabled={!changed} onClick={confirm}>
          Save
        </Confirm>
      </DialogActions>
    </Dialog>
  );
}
