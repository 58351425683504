import _types from '../types.json';
const types = <any>_types;

const map: { [key: string]: RegExp } = {};
for (const key of Object.keys(types)) {
  if (typeof key === 'string') {
    map[key] = new RegExp(types[key], 'i');
  }
}

export default function validateType(name: string, mimeType: string) {
  if (!map[name]) {
    console.error(`${name} is not exist in validateType `);
    return false;
  }
  return map[name].test(mimeType);
}
