import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { globalEventAtom } from '../atoms';

type Callback = (event: { data: any }) => void;

export function useGlobalEvent(type: string, callback: Callback, deps: any[]) {
  const globalEvent = useRecoilValue(globalEventAtom);

  useEffect(() => {
    globalEvent.on(type, callback);
    return () => {
      globalEvent.off(type, callback);
    };
  }, deps);
}
