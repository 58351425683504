import { pathToRegexp } from 'path-to-regexp';
import { useLocation } from 'react-router-dom';
import { searchBarInfo } from '../settings/siteMap';

/**
 * 現在表示している URL にふさわしいタブの状態を取得する
 */
export function useSiteMap() {
  const location = useLocation();
  return searchBarInfo.find(item => {
    const re = pathToRegexp(item.path);
    return re.test(location.pathname);
  });
}
