import { Chip } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import * as React from 'react';
import { useSelector } from 'react-redux';

export interface IdeNoClearCheckChipProps {
  id: string;
}

/**
 * クリア未確認バッジ
 */
export function IdeNoClearCheckChip(props: IdeNoClearCheckChipProps) {
  const workData = useSelector(state => {
    return state.work.works[props.id]?.data;
  });
  const authUid = useSelector(state => state.auth.userInfo?.uid);

  const uid = workData?.uid;
  const canClear = workData?.clearChecked || (workData?.clearRate || 0) > 0;

  return uid && uid !== authUid && !canClear ? (
    <Chip
      icon={<Warning fontSize="small" style={{ paddingLeft: 6 }} />}
      label="クリア未確認"
    />
  ) : null;
}
