import {
  AppBar,
  Fab,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { ArrowBack, Search } from '@material-ui/icons';
import { compile, Key, pathToRegexp } from 'path-to-regexp';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { style } from 'typestyle';
import { useSiteMap } from '../hooks/useSiteMap';
import { ITab } from '../settings/siteMap';
import { Link } from '../utils/components';

const cn = {
  toolbar: style({
    minHeight: 48, // 上下のマージンをなくす
    backgroundColor: grey[50]
  }),
  blank: style({
    flex: 1
  }),
  icon: style({
    width: 18,
    marginRight: 12
  }),
  tabs: style({
    flexGrow: 999
  }),
  text: style({
    marginRight: 16
  })
};

type Params = {
  uid?: string;
  tab?: string;
};

export function NavigationBar() {
  const location = useLocation();
  const history = useHistory();
  const params = useParams<Params>();
  const userInfo = useSelector(state => state.auth.userInfo);
  const siteMap = useSiteMap();

  // `/users/:uid/:tab?` でルーティングされた場合にだけ使える認証
  const tabs =
    siteMap &&
    siteMap.tabs &&
    userInfo &&
    siteMap.tabs.filter(
      tab => !tab.authUserOnly || userInfo.uid === params.uid
    );

  // 現在のタブの位置を取得する
  const selected =
    tabs && tabs.find(item => pathToRegexp(item.to).test(location.pathname));

  const handleChangeTab = React.useCallback(
    (event: any, value: any) => {
      if (!siteMap || typeof value !== 'string') return;
      const keys: Key[] = [];
      const re = pathToRegexp(siteMap.path, keys);
      const params = re.exec(location.pathname);

      const data = params
        ? keys.reduce((p, c, i) => {
            return { ...p, [`${c.name}`]: params[i + 1] };
          }, {})
        : {};
      const toPath = compile(value);
      const url = toPath(data);

      history.push(url);
    },
    [siteMap, location.pathname]
  );

  if (!siteMap) {
    // TODO: アニメーション
    return null;
  }

  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar className={cn.toolbar}>
        {siteMap.backTo ? (
          <IconButton aria-label="Back" component={Link(siteMap.backTo)}>
            <ArrowBack />
          </IconButton>
        ) : null}

        {siteMap.text && (
          <Typography variant="h5" className={cn.text}>
            {siteMap.text}
          </Typography>
        )}
        {selected && tabs && (
          <Tabs
            value={selected.to}
            indicatorColor="primary"
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            className={cn.tabs}
          >
            {tabs.map((tab: ITab) => (
              <Tab key={tab.to} label={tab.text} value={tab.to} />
            ))}
          </Tabs>
        )}
      </Toolbar>
    </AppBar>
  );
}
