import CodeMirror, { Hints, Pos } from 'codemirror';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/dialog/dialog.css';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/fold/brace-fold';
// import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
// import 'codemirror/addon/scroll/simplescrollbars.css';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/search/search';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/keymap/sublime';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/css/css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/meta';
import 'codemirror/mode/yaml/yaml';
import deepEqual from 'deep-equal';
import glslMode from 'glsl-editor/glsl';
import { includes, isEqualWith, reduce } from 'lodash-es';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { style } from 'typestyle';
import { canAuthUserUseAsset } from '../../../../ducks/user';
import ImmutableFile from '../../File/ImmutableFile';
import CodeMirrorComponent from '../../utils/CodeMirrorComponent';

export interface EditorProps {
  file: any;
  codemirrorRef: (cm: CodeMirror.Editor) => void;
  extraKeys: any;
  foldOptions?: any;
  lineNumbers?: boolean;
  loadConfig: (key: string) => any;
  isPaid: boolean;
  // Injected by withFiles (for JSX)
  files: ImmutableFile[];
}

interface State {
  dropdowns: RegExpExecArray[];
  dropdownLineWidgets: CodeMirror.LineWidget[];
  dropdownConfig?: any;
}

glslMode(CodeMirror);
CodeMirror.modeInfo.push({
  name: 'glsl',
  mime: 'text/x-glsl',
  mode: 'glsl'
});

// YAML のエイリアス (.yml) (text/yaml)
CodeMirror.modeInfo.push({
  name: 'YAML',
  mimes: ['text/yaml', 'text/x-yaml'],
  mode: 'yaml',
  ext: ['yml', 'yaml'],
  alias: ['yml']
});

// segments の参照と現在の line との関係を一旦保持するマップ
const segmentsLineMap = new WeakMap();

const shallowEqual = <T extends Object>(a: T, b: T) => a === b;

const cn = {
  snippet: style({
    padding: 4,
    minWidth: 400
  }),
  paid: style({
    borderLeft: '2px solid #D9B166',
    marginLeft: -2
  }),
  disabled: style({
    pointerEvents: 'none',
    $nest: {
      '&>*': {
        opacity: 0.3
      }
    }
  }),
  primary: style({
    fontWeight: 600,
    marginRight: 16,
    display: 'inline-block',
    minWidth: 50
  }),
  secondary: style({
    color: 'rgb(100,100,100)'
  })
};

export default function EditorWrapper(
  props: Omit<EditorProps, 'files' | 'isPaid'>
) {
  let files = useSelector(state => state.file.files);
  files = files.filter(file => !file.isTrashed); // ゴミ箱にあるファイルを除外
  const isPaid = useSelector(canAuthUserUseAsset);

  return <Editor {...props} files={files} isPaid={isPaid} />;
}

class Editor extends React.Component<EditorProps, State> {
  static defaultProps = {
    codemirrorRef: () => {},
    extraKeys: {},
    lineNumbers: true,
    foldOptions: {}
  };

  state = {
    dropdowns: [],
    dropdownLineWidgets: []
  } as State;

  codemirror?: CodeMirror.Editor;

  shouldComponentUpdate(nextProps: EditorProps, nextState: State) {
    if (this.props.file !== nextProps.file) return true;
    if (!isEqualWith(this.state, nextState, shallowEqual)) return true;
    return false;
  }

  componentDidMount() {
    this.setState({
      dropdownConfig: this.props.loadConfig('dropdown')
    });
  }

  componentDidUpdate(prevProps: EditorProps) {
    if (prevProps.files !== this.props.files) {
      this.setState({
        dropdownConfig: this.props.loadConfig('dropdown')
      });
    }
  }

  handleCodemirror = (ref: CodeMirrorComponent | null) => {
    const cm = ref && ref.codeMirror;
    if (cm) {
      this.codemirror = cm;
      this.props.codemirrorRef(cm);
      // ドロップダウンウィジェット
      cm.on('changes', this.handleUpdateDropdown);
      cm.on('swapDoc', () => {
        this.clearAllWidgets();
        this.handleUpdateDropdown(cm, []);
      });
      this.handleUpdateDropdown(cm, []);
      cm.on('unfold', () => {
        this.clearAllWidgets();
        this.handleUpdateDropdown(cm, []);
      });
    }
  };

  handleValueClick = (event: MouseEvent) => {
    // Put cursor into editor
    if (this.codemirror) {
      const locate = { left: event.x, top: event.y };
      const pos = this.codemirror.coordsChar(locate);
      this.codemirror.focus();
      this.codemirror.setCursor(pos);
    }
  };

  handleUpdateDropdown = (
    cm: CodeMirror.Editor,
    batch: CodeMirror.EditorChangeLinkedList[]
  ) => {
    const origin = batch[0] && batch[0].origin; // e.g. '+input'
    const dropdowns = reduce(
      cm.getValue('\n').split('\n'),
      (prev, text, line) => {
        // Syntax: ('▼ スキン', _kきし)
        const segments = /^(.*\(['"])(▼[^'"]*)(['"],\s*)([^)]*)\)/.exec(text);
        if (segments) {
          // line は独立して保持（異なる行でも移動しただけなら問題ない）
          segmentsLineMap.set(segments, line);
          // 新しいデータを格納
          prev = prev.concat([segments]);
        }
        return prev;
      },
      [] as RegExpExecArray[]
    );
    // 中身が変わっていたら更新
    if (
      includes(['setValue', 'undo'], origin) ||
      !deepEqual(dropdowns, this.state.dropdowns)
    ) {
      // 前回の LineWidget を消去
      for (const item of this.state.dropdownLineWidgets) {
        item.clear(); // remove element
        if (item.node.parentElement) {
          item.node.parentElement.removeChild(item.node); // Node が消えないことがある. TODO: 原因を調べる
        }
      }
      // 今回の LineWidget を追加
      const dropdownLineWidgets = dropdowns.map(segments => {
        const line = segmentsLineMap.get(segments); // さっき保持した line
        return this.renderDropdown(cm, segments, line);
      });
      this.setState({
        dropdowns,
        dropdownLineWidgets
      });
    }
  };

  clearAllWidgets = () => {
    // 全ての LineWidget を消去
    for (const item of this.state.dropdownLineWidgets) {
      item.clear(); // remove element
    }
    this.setState({
      dropdowns: [],
      dropdownLineWidgets: []
    });
  };

  renderDropdown = (
    cm: CodeMirror.Editor,
    segments: string[],
    line: number
  ) => {
    const [, _prefix, _label, _right, _value] = segments;

    const label = document.createElement('span');
    label.textContent = _label;
    label.classList.add('Feeles-dropdown-label');
    const right = document.createElement('span');
    right.textContent = _right;
    right.classList.add('Feeles-dropdown-blank');
    const value = document.createElement('span');
    value.textContent = _value;
    value.classList.add('Feeles-dropdown-value');
    value.addEventListener('click', this.handleValueClick);
    const button = document.createElement('span');
    button.appendChild(label); // "▼ スキン"
    button.appendChild(right); // "', "
    button.appendChild(value); // _kきし
    button.classList.add('Feeles-dropdown-button');
    const allOfLeft = _prefix + _label + _right; // value より左の全て
    const ch = allOfLeft.length;

    const shadow = document.createElement('span');
    shadow.appendChild(button);
    shadow.classList.add('Feeles-dropdown-shadow');
    const parent = document.createElement('div');
    parent.classList.add('Feeles-widget', 'Feeles-dropdown');
    parent.appendChild(shadow);

    const pos = { line, ch: _prefix.length };
    let { left } = cm.charCoords(pos, 'local');
    left -= 40; // 実測とのズレ。 TODO: 原因を調べる
    parent.style.transform = `translate(${left}px, -1.3rem)`;
    // ウィジェット追加
    const widget = cm.addLineWidget(line, parent, {
      insertAt: 0
    });
    // クリックイベント追加
    button.addEventListener(
      'click',
      () => {
        const { dropdownConfig } = this.state;
        if (!dropdownConfig) return;
        const line = cm.getLineNumber(widget.line);
        // Open dropdown menu
        const listName = _label.substr(1).trim();
        const list = dropdownConfig[listName];
        if (list && line !== null) {
          const hint: Hints = {
            from: new Pos(line, ch),
            to: new Pos(line, ch + _value.length),
            list: list.map((item: any) => ({
              text: item.body,
              description: item.label,
              plan: item.plan,
              disabled: item.plan === 'paid' && !this.props.isPaid,
              render: renderDropdownItem
            }))
          };
          // CodeMirror.on(hint, 'pick', this.handleSaveAndRun);
          cm.showHint({
            completeSingle: false,
            hint: () => hint
          });
          cm.focus();
        }
      },
      true
    );
    return widget;
  };

  render() {
    const { file, lineNumbers } = this.props;

    const meta = CodeMirror.findModeByMIME(file.type);
    const mode = meta && meta.mode;

    return (
      <CodeMirrorComponent
        id={file.name}
        value={file.text}
        mode={mode}
        lineNumbers={lineNumbers}
        keyMap="sublime"
        foldGutter
        foldOptions={this.props.foldOptions}
        extraKeys={this.props.extraKeys}
        ref={this.handleCodemirror}
      />
    );
  }
}

function renderDropdownItem(
  element: HTMLElement,
  pos: any,
  { text, description, plan, disabled }: any
) {
  const primary = document.createElement('span');
  primary.textContent = text;
  primary.className = cn.primary;

  const secondary = document.createElement('span');
  secondary.textContent = description;
  secondary.className = cn.secondary;

  element.appendChild(primary);
  element.appendChild(secondary);
  element.classList.add(cn.snippet);
  if (plan === 'paid') {
    element.classList.add(cn.paid);
  }
  if (disabled) {
    element.classList.add(cn.disabled);
  }

  return element;
}
