import * as React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const Beginner = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M11.377 21.866L4.413 16.81A.998.998 0 0 1 4 16V4a1 1 0 0 1 1.492-.87L12 6.81l6.508-3.68A1 1 0 0 1 20 4v12c0 .32-.153.621-.413.81l-7 5.083a.999.999 0 0 1-1.21-.027zM12 8.614L6 5.22v10.286l6 4.357V8.614z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Beginner;
