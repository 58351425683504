import { Avatar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { style } from 'typestyle';
import { actions } from '../ducks/actions';
import { invalid } from '../ducks/helpers';
import theme from '../settings/theme';
import { defaultIconUrl } from './Header';
import { ProfileEditDialog } from './ProfileEditDialog';

export const cn = {
  container: style({
    maxWidth: 600
  }),
  avatar: style({
    width: 80,
    height: 80,
    fontSize: '2.5rem'
  }),
  iconButton: style({
    marginTop: -48
  }),
  fileInput: style({
    display: 'none'
  }),
  root: style({
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: theme.palette.background.paper
  }),
  textFieldInput: style({
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    width: 'calc(100% - 24px)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    $nest: {
      '&:focus': {
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
      }
    }
  }),
  textFieldRoot: style({
    padding: 0,
    $nest: {
      'label + &': {
        marginTop: theme.spacing(3)
      }
    }
  }),
  button: style({
    marginBottom: theme.spacing()
  })
};

export type Params = {
  id: string;
};

const invalidUser = invalid<IUserDocument>(new Error('User not found'));

export function Profile() {
  const { id } = useParams<Params>();

  const [edit, setEdit] = React.useState(false);
  const toggleEdit = React.useCallback(() => setEdit(!edit), [edit]);

  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.auth.userInfo);
  const user = useSelector(state => (id ? state.user.byUid[id] : invalidUser));

  React.useEffect(() => {
    dispatch(actions.user.fetchIfNeeded({ uid: id }));
  }, [id]);

  const owner = userInfo && userInfo.uid === id;

  // 名前の変更を反映する
  React.useEffect(() => {
    if (owner) {
      dispatch(actions.team.currentPage(location.pathname));
    }
  }, [user?.data?.displayName, owner]);

  if (!user || user.isProcessing) {
    return (
      <div className={cn.root}>
        <Typography variant="h5">ロード中です</Typography>
      </div>
    );
  }

  const moodMessage = user.data?.moodMessage;
  const showMoodMessage =
    moodMessage &&
    Date.now() - moodMessage.updatedAt.toMillis() < 10 * 60 * 1000; // 10 分前までなら表示

  return (
    <div className={cn.root}>
      <Grid container spacing={2} alignItems="center" className={cn.container}>
        <Grid item sm={2} xs={4}>
          <Avatar
            className={cn.avatar}
            src={user.data?.iconUrl || defaultIconUrl}
          />
        </Grid>
        <Grid item sm={5} xs={5}>
          <Typography variant="h5">{user.data?.displayName || '_'}</Typography>
          {user.data?.createdAt ? (
            <Typography
              variant="body2"
              align="left"
              color="textSecondary"
              gutterBottom
            >
              {moment(user.data.createdAt).format('YYYY年MM月に登録')}
            </Typography>
          ) : null}
          {user.data?.worksNum ? (
            <Typography
              variant="body2"
              align="left"
              color="textSecondary"
              gutterBottom
            >{`投稿数 ${user.data?.worksNum}`}</Typography>
          ) : null}
          {moodMessage && showMoodMessage ? (
            <Typography variant="body2" color="textSecondary">
              {moodMessage.status}
            </Typography>
          ) : null}
        </Grid>
        {owner ? (
          <>
            <Button
              variant="contained"
              color="primary"
              className={cn.button}
              onClick={toggleEdit}
            >
              プロフィールを編集する
            </Button>
            <ProfileEditDialog open={edit} onClose={toggleEdit} />
          </>
        ) : null}
      </Grid>
    </div>
  );
}
