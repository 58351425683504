import { combineEpics } from 'redux-observable';
import { fromEvent } from 'rxjs';
import { filter, map, throttleTime } from 'rxjs/operators';
import { endpoint } from '../env';
import { hackRpg2 } from '../settings/contents';
import { actions } from './actions';
import { asyncEpic, SKIP } from './asyncEpic';
import { reducerWithImmer } from './reducer-with-immer';
import { requestWithAuth } from './requestWithAuth';
import { StoreState } from './type';

const initialState = {
  pendingUntilAuth: '/'
};

export type State = typeof initialState;

export default reducerWithImmer(initialState)
  .case(actions.team.currentPage, (draft, payload) => {
    draft.pendingUntilAuth = payload;
  })
  .reset(actions.auth.signedOut)
  .toReducer();

export const epics = combineEpics(
  // ページが変わった時に currentPage を更新。入力を throttle する
  (action$, state$) =>
    action$.pipe(
      filter(actions.team.currentPage.match),
      throttleTime(5000, undefined, { leading: true, trailing: true }),
      filter(() => Boolean(state$.value.auth.userInfo)),
      map(() => actions.team.updateCurrentPage.started())
    ),
  // ユーザーが入力し続けている間、定期的に currentPage を更新し続ける
  (action$, state$) =>
    fromEvent(window, 'mousedown').pipe(
      throttleTime(300 * 1000),
      filter(() => Boolean(state$.value.auth.userInfo)),
      map(() => actions.team.updateCurrentPage.started())
    ),
  // 更新する
  asyncEpic(actions.team.updateCurrentPage, async (action, state) => {
    const uri = state.team.pendingUntilAuth;
    if (!uri) return SKIP;
    const payload = { uri, ...getCurrentPage(uri, state) };
    await requestWithAuth(endpoint + '/currentPage', 'PUT', payload);
  }),
  // ログインしたときに直近のページで更新する
  action$ =>
    action$.pipe(
      filter(actions.auth.signedIn.match),
      map(() => actions.team.updateCurrentPage.started())
    )
);

/**
 * 現在のページのステータステキストを取得する
 * ステータステキストはユーザーのムードメッセージとして表示される
 * プライバシーを配慮したないようにすること
 */
function getCurrentPage(
  uri: string,
  state: StoreState
): {
  title: string;
  status: string;
} {
  if (uri === '/') {
    return {
      title: 'トップページ',
      status: ''
    };
  }
  if (uri.startsWith('/works')) {
    const title = state.make.metadata.title || 'まだタイトルがありません';
    return {
      title,
      status:
        uri === hackRpg2 || uri === '/works/I4OleAc2rUoJWdWvwJF2' // 後方互換性
          ? 'チュートリアルをやっています'
          : state.make.isOwner
          ? 'プログラミングをしています'
          : '作品を観察しています'
    };
  }
  if (uri.startsWith('/map-editor')) {
    return {
      title: 'マップエディタ',
      status: '背景をデザインしています'
    };
  }
  if (uri.startsWith('/officials')) {
    return {
      title: 'RPG キット',
      status: '新しいステージを作り始めました'
    };
  }
  if (uri.startsWith('/users')) {
    const uid = uri.split('/')[2] || '';
    const name = state.user.byUid[uid]?.data?.displayName || uid;
    return {
      title: name + ' のマイページ',
      status: '作品を探しているようです'
    };
  }
  if (uri.startsWith('/preferences')) {
    return {
      title: 'せってい',
      status: '設定画面を見ています'
    };
  }
  if (uri.startsWith('/notifications')) {
    return {
      title: 'お知らせ',
      status: '公式からのお知らせを見ています'
    };
  }
  if (uri.startsWith('/contents')) {
    return {
      title: 'あそびかた',
      status: '公式コンテンツを見ています'
    };
  }
  if (uri.startsWith('/fes') || uri.startsWith('/pages/fes')) {
    return {
      title: 'フェスのページ',
      status: 'フェスについて調べています'
    };
  }
  if (uri.startsWith('/lists')) {
    return {
      title: 'みんなのステージ',
      status: '作品を探しているようです'
    };
  }
  if (uri.startsWith('/videos')) {
    const series = uri.split('/')[2] || '';
    return {
      title: series,
      status: '授業動画を観ています'
    };
  }
  return {
    title: uri || 'HackforPlay',
    status: ''
  };
}
