import * as React from 'react';

export type ScrollIntoViewProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  ScrollIntoViewOptions;

export function ScrollIntoView({
  behavior = 'smooth',
  block = 'center',
  inline = 'center',
  ...props
}: ScrollIntoViewProps) {
  // スクロールして画面に収める
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    requestAnimationFrame(() => {
      ref.current?.scrollIntoView({
        behavior,
        block,
        inline
      });
    });
  }, []);

  return <div {...props} ref={ref}></div>;
}
