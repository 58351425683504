import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Theme, withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Reply, Stop } from '@material-ui/icons';
import { deg, rotateY, translateX } from 'csx';
import * as beautify from 'js-beautify';
import * as React from 'react';
import { classes, style } from 'typestyle';
import ImmutableFile from '../../File/ImmutableFile';
import { Localization } from '../../localization';
import CardFloatingBar from '../CardFloatingBar';
import Editor from '../EditorCard/Editor';
import FixAndMessage from '../EditorCard/FixAndMessage';

export interface ShotPaneProps {
  theme: Theme;
  localization: Localization;
  loadConfig: (name: string) => any;
  file?: ImmutableFile;
  globalEvent: any;
}

interface State {
  shooting: boolean;
  height: number;
  error: Error | null;
  loading: boolean;
  canRestore: boolean;
  file: ImmutableFile;
  cardAnchorEl: HTMLElement | null;
}

const cn = {
  root: style({
    display: 'flex',
    flexDirection: 'column'
  }),
  shoot: style({
    marginRight: 9,
    marginBottom: 4,
    transform: rotateY(deg(0))
  }),
  shooting: style({
    transform: rotateY(deg(180))
  }),
  label: style({
    fontSize: '.8rem'
  }),
  error: style({
    flex: '0 1 auto',
    margin: 0,
    padding: 8,
    backgroundColor: red['50'],
    color: red['500'],
    fontFamily: 'Consolas, "Liberation Mono", Menlo, Courier, monospace',
    overflow: 'scroll'
  }),
  restore: style({
    margin: 4
  }),
  blank: style({
    flex: 1
  })
};
const getCn = (props: ShotPaneProps, state: State) => ({
  editor: style({
    position: 'relative',
    boxSizing: 'border-box',
    width: '100%',
    height: state.height,
    minHeight: 100,
    transform: translateX(state.shooting ? -500 : 0),
    opacity: state.shooting ? 0 : 1,
    transition: props.theme.transitions.create('all')
  })
});

class ShotPane extends React.PureComponent<ShotPaneProps, State> {
  state: State = {
    shooting: false,
    height: 0,
    error: null,
    loading: false,
    canRestore: false,
    file: this.props.file || shot(''),
    cardAnchorEl: null
  };

  codeMirror?: CodeMirror.Editor;

  componentDidMount() {
    if (!this.codeMirror) return;
    this.codeMirror.on('change', this.handleChange);
    this.codeMirror.on('swapDoc', this.handleChange);
    this.codeMirror.on('viewportChange', this.handleViewportChange);
    this.codeMirror.on('swapDoc', this.handleViewportChange);
    this.handleViewportChange(this.codeMirror);
    this.props.globalEvent.on('message.runCode', this.handleShot);
  }

  componentDidUpdate(prevProps: ShotPaneProps, prevState: State) {
    if (prevProps.file !== this.props.file) {
      const file = this.props.file || shot('');
      this.setState({ file });
    }

    if (!prevState.shooting && this.state.shooting) {
      // shooting アニメーションをもとにもどす
      setTimeout(() => {
        this.setState({ shooting: false });
      }, 1000);
    }
    if (prevState.height !== this.state.height) {
      setTimeout(() => {
        // 表示可能領域が変わったので、トランジション後に再描画する
        if (this.codeMirror) {
          this.codeMirror.refresh();
        }
      }, 300);
    }

    // ShotCard の中にある最も下の要素を取得する
    const cardAnchorEl = document.querySelector<HTMLElement>(
      '#ShotCard-BottomAnchor'
    );
    if (!prevState.cardAnchorEl && cardAnchorEl) {
      this.setState({ cardAnchorEl });
    }
  }

  componentWillUnmount() {
    this.props.globalEvent.off('message.runCode', this.handleShot);
  }

  handleShot = async () => {
    if (this.state.shooting) return;
    await this.shotCode();
    this.setState({ shooting: true });
  };

  handleChange = (cm: CodeMirror.Editor) => {
    const canRestore = cm.getValue() !== this.state.file.text;
    this.setState({ canRestore });
  };

  handleRestore = () => {
    if (!this.codeMirror) return;
    this.codeMirror.setValue(this.state.file.text);
  };

  async shotCode() {
    let text = this.codeMirror
      ? this.codeMirror.getValue('\n')
      : this.state.file.text;

    // コードのフォーマット
    if (this.props.loadConfig('feelesrc').formatOnSendCode || false) {
      // import .jsbeautifyrc
      let configs = this.props.loadConfig('jsbeautifyrc');
      const formatted = beautify.js(text, configs.js || {});
      if (this.codeMirror) {
        this.codeMirror.setValue(formatted);
      }
    }

    // コードをファイルにする
    const name = this.state.file.name;
    const file = shot(text, name);
    // frame に shot をおくる
    const request = {
      query: 'shot',
      value: { ...file }
    };
    this.props.globalEvent.emit('postMessage', request);
  }

  handleViewportChange = (cm: CodeMirror.Editor) => {
    const lastLine = cm.lastLine() + 1;
    let height = cm.heightAtLine(lastLine, 'local');
    // もしエディタの描画領域が広過ぎて ShotCard が画面からはみ出すなら, height を更新しない
    const { cardAnchorEl } = this.state;
    if (cardAnchorEl) {
      const { offsetParent, offsetTop } = cardAnchorEl;
      const appendedHeight = height - this.state.height;
      const containerHeight =
        offsetParent instanceof HTMLElement
          ? offsetParent.clientHeight -
            parseInt(offsetParent.style.paddingTop, 10) -
            parseInt(offsetParent.style.paddingBottom, 10)
          : 0;
      if (offsetTop + appendedHeight >= containerHeight) {
        return;
      }
    }

    this.setState({ height });
  };

  render() {
    const dcn = getCn(this.props, this.state);
    const { localization, loadConfig } = this.props;
    const { error } = this.state;

    const { shooting } = this.state;

    // TODO: Enter で実行か Shift-Enter で実行か
    const { sendCodeOnEnter } = loadConfig('feelesrc');
    const shootKey = sendCodeOnEnter ? 'Enter' : 'Ctrl-Enter';
    const extraKeys = {
      [shootKey]: this.handleShot
    };

    return (
      <>
        {error ? <pre className={cn.error}>{error.message}</pre> : null}
        {this.state.loading ? <LinearProgress /> : null}
        <CardFloatingBar>
          <span>{localization.shotCard.title}</span>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.handleRestore}
            className={cn.restore}
            disabled={!this.state.canRestore}
          >
            {localization.shotCard.restore}
          </Button>
          <div className={cn.blank} />
          <Typography className={cn.label} color="textSecondary">
            {localization.shotCard.shoot}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            disabled={this.state.shooting}
            onClick={this.handleShot}
            className={classes(cn.shoot, shooting && cn.shooting)}
            endIcon={this.state.shooting ? <Stop /> : <Reply />}
          >
            {localization.shotCard.button}
          </Button>
        </CardFloatingBar>
        <div className={dcn.editor}>
          <Editor
            file={this.state.file}
            codemirrorRef={ref => (this.codeMirror = ref)}
            extraKeys={extraKeys}
            lineNumbers={false}
            loadConfig={this.props.loadConfig}
          />
        </div>
        <FixAndMessage
          codemirror={this.codeMirror}
          localization={this.props.localization}
        />
      </>
    );
  }
}

function shot(text = '', name = '') {
  return new ImmutableFile({ type: 'text/javascript', name, text });
}

export default withTheme(ShotPane);
