import { combineEpics, StateObservable } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { Action, AsyncActionCreators } from 'typescript-fsa';
import { StoreState } from './type';

type Epic = ReturnType<typeof combineEpics>;

export const SKIP = Symbol('SKIP');
export function isResult<T>(value: T | typeof SKIP): value is T {
  return value !== SKIP;
}

export function asyncEpic<Params, Result, Error = {}>(
  asyncActionCreators: AsyncActionCreators<Params, Result, Error>,
  asyncTask: (
    action: Action<Params>,
    /**
     * このパラメータは今後なるべく使用しない
     * AsyncEpic内部で時間が経過すると、ストアの参照が古くなってしまう為
     */
    state: StoreState,
    /**
     * 最新のストアの参照にアクセスする為に、このパラメータを用いる
     */
    state$: StateObservable<StoreState>
  ) => Promise<Result | typeof SKIP>
) {
  const epic: Epic = (action$, state$) =>
    action$.pipe(
      filter(asyncActionCreators.started.match),
      mergeMap(action => {
        return from(asyncTask(action, state$.value, state$)).pipe(
          filter(isResult),
          map(result =>
            asyncActionCreators.done({
              params: action.payload,
              result
            })
          ),
          catchError(error =>
            of(
              asyncActionCreators.failed({
                params: action.payload,
                error
              })
            )
          )
        );
      })
    );
  return epic;
}
