import { useLocation } from 'react-router-dom';

const prefix = 'comment-';
const hashPrefix = '#' + prefix;

/**
 * #comment-xxxx のようにして Comment ID が与えられたとき
 * そのコメントまでスクロールする
 */
export function useHashCommentId() {
  const { hash } = useLocation();
  if (hash.startsWith(hashPrefix)) {
    return hash.slice(hashPrefix.length);
  }
}

export function getId(commentId: string) {
  return prefix + commentId;
}
