import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { classes } from 'typestyle';
import { linkTextClass } from '../utils/xlasses';

export interface IdeLastSavedProps {
  disabled: boolean;
  onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

export const IdeLastSaved = React.forwardRef<
  HTMLSpanElement,
  IdeLastSavedProps
>(function IdeLastSaved(props, ref) {
  // 最後に保存された時刻を moment.js で表示する
  const lastSaved = useSelector(state => state.make.lastSaved);
  const [label, setLabel] = React.useState('');
  React.useEffect(() => {
    // 1秒ごとに moment を再計算する
    const update = () => {
      setLabel(lastSaved ? moment(lastSaved.toMillis()).fromNow() : '');
    };
    update();
    const handle = window.setInterval(update, 1000);
    return () => {
      window.clearInterval(handle);
    };
  }, [lastSaved]);

  return (
    <span
      ref={ref}
      className={classes(!props.disabled && linkTextClass)}
      onClick={props.disabled ? undefined : props.onClick}
    >{`(最終更新:${label})`}</span>
  );
});
