import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import { Close, Done, Notifications } from '@material-ui/icons';
import { uniq } from 'lodash-es';
import * as React from 'react';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import { classes } from 'typestyle';
import { useFirestore } from '../hooks/useFirestore';
import { useMarkAsRead } from '../hooks/useMarkAsRead';
import { useRecoilEffect } from '../hooks/useRecoilEffect';
import {
  authUidAtom,
  convertToNotification,
  notificationAtom,
  notificationIdsAtom
} from '../recoil';
import firebase from '../settings/firebase';
import { analytics } from '../utils/analytics';
import { messageOf } from '../utils/error';
import { container } from '../utils/xlasses';
import { LoadingButton } from './LoadingButton';
import { LoadingPage } from './LoadingPage';
import { noticeAtom } from './NoticeManager';
import { isUnread, NotificationItem } from './NotificationItem';
import { popupAtom } from './NotificationPopover';

const firestore = firebase.firestore;

const useStyles = makeStyles(theme => ({
  root: {
    flex: '0 0 auto',
    marginTop: 32,
    marginBottom: 32
  }
}));

export function Notification() {
  const cn = useStyles();

  const markAsRead = useMarkAsRead();
  const [loading, setLoading] = React.useState(false);

  // 赤いバッジを消す
  const firestore = useFirestore();
  const uid = useRecoilValue(authUidAtom);
  React.useEffect(() => {
    if (!uid) return;
    firestore()
      .collection('users')
      .doc(uid)
      .update({ notificationOpenedAt: firestore.FieldValue.serverTimestamp() });
  }, [uid]);

  return (
    <Paper
      elevation={2}
      style={{ padding: 0 }}
      className={classes(container.large, cn.root)}
    >
      <Grid container justify="space-between">
        <Typography color="textSecondary">お知らせ</Typography>
        <Button
          startIcon={<Done />}
          disabled={loading}
          color="secondary"
          onClick={async () => {
            setLoading(true);
            await markAsRead();
            setLoading(false);
          }}
        >
          すべて既読にする
        </Button>
      </Grid>
      <React.Suspense fallback={<LoadingPage />}>
        <NotificationContent />
      </React.Suspense>
    </Paper>
  );
}

function NotificationContent() {
  const notificationIds = useRecoilValue(notificationIdsAtom);
  const markAsRead = useMarkAsRead();

  // ページを離れるときに既読にする
  React.useEffect(
    () => () => {
      markAsRead(({ type }) => type !== 'comment-work'); // コメントの通知は除く
    },
    []
  );

  return (
    <>
      {notificationIds.map(id => (
        <NotificationItem key={id} id={id} divider />
      ))}
      <Grid container justify="center">
        <MoreButton />
      </Grid>
    </>
  );
}

const useStylesNotificationCard = makeStyles(theme => ({
  root: {},
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing() / 2,
    alignItems: 'center',
    maxHeight: 128,
    overflow: 'hidden'
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing() / 2
  },
  close: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing()
  },
  dialogContent: {
    padding: 0,
    maxHeight: 400
  },
  dialogActions: {
    justifyContent: 'flex-start'
  }
}));

/**
 * 未読のアイテムの ID
 */
export const unreadIdAtom = atom<string | undefined>({
  key: 'unreadIdAtom',
  default: undefined
});

/**
 * 旧ステージをつくる画面に、最も新しい通知を１つだけ表示するためのカード
 */
export function NotificationCard() {
  const cn = useStylesNotificationCard();

  const unreadId = useRecoilValue(unreadIdAtom);
  const notificationIds = useRecoilValue(notificationIdsAtom);
  const markAsRead = useMarkAsRead();

  const [opened, setOpened] = React.useState(false);
  // 開くときと閉じるときに既読にする
  React.useEffect(() => {
    markAsRead(({ type }) => type !== 'comment-work'); // コメントの通知は除く
    if (opened) {
      analytics.openNotificationDialog();
    }
  }, [opened]);

  return (
    <>
      <Grow in={Boolean(unreadId)}>
        <div className={classes(container.large, cn.root)}>
          <div className={cn.heading}>
            <Notifications />
            お知らせ
          </div>
          <Card elevation={2} className={cn.card}>
            {unreadId ? <NotificationItem id={unreadId} /> : null}
            <Button color="secondary" onClick={() => setOpened(true)}>
              ほかのお知らせも見る
            </Button>
          </Card>
        </div>
      </Grow>
      <Dialog open={opened} maxWidth="md" onClose={() => setOpened(false)}>
        <IconButton className={cn.close} onClick={() => setOpened(false)}>
          <Close />
        </IconButton>
        <DialogTitle>お知らせ</DialogTitle>
        <DialogContent className={cn.dialogContent}>
          {notificationIds.map(id => (
            <NotificationItem id={id} divider />
          ))}
          <Grid container justify="center">
            <MoreButton />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

function MoreButton() {
  const hasMore = useRecoilValue(hasMoreAtom);
  const loading = useRecoilValue(loadingAtom);
  const fetchNext = useFetchNext();

  return (
    <LoadingButton loading={loading} disabled={!hasMore} onClick={fetchNext}>
      {hasMore ? 'もっと見る' : 'もうお知らせはありません'}
    </LoadingButton>
  );
}

export function SubscribeNotification() {
  const firestore = useFirestore();
  const uid = useRecoilValue(authUidAtom);
  const fetchNext = useFetchNext();

  useRecoilEffect(
    ({ reset, set }) => {
      if (!uid) return;
      // uid が変わったので、今まで取得したお知らせはリセットする
      let currentIds: string[] = [];
      set(notificationIdsAtom, ids => {
        currentIds = ids;
        return [];
      });
      for (const id of currentIds) {
        reset(notificationAtom(id));
      }

      // 最新のお知らせを受け取れるように、１件だけ Subscribe する
      const unsubscribe = firestore()
        .collection(`users/${uid}/notifications`)
        .orderBy('updatedAt', 'desc')
        .withConverter(convertToNotification)
        .limit(1)
        .onSnapshot({
          next: snapshot => {
            const change = snapshot.docChanges()[0];
            if (!change) return; // お知らせがない
            set(notificationAtom(change.doc.id), change.doc.data());
            if (change.type === 'added') {
              set(notificationIdsAtom, ids =>
                ids.includes(change.doc.id) ? ids : ids.concat(change.doc.id)
              );
            }
          }
        });

      // お知らせを100件取得する
      fetchNext();

      return () => {
        // ログインユーザーが変わったのでリセットする
        unsubscribe();
        reset(hasMoreAtom);
        reset(lastUpdatedAtAtom);
      };
    },
    [uid]
  );

  return null;
}

const lastUpdatedAtAtom = atom<firebase.firestore.Timestamp | undefined>({
  key: 'lastUpdatedAtAtom',
  default: undefined
});

const hasMoreAtom = atom({
  key: 'hasMoreAtom',
  default: true
});

/**
 * fetch している途中であることを表す
 */
const loadingAtom = atom({
  key: 'loadingAtom',
  default: false
});

function useFetchNext(batchSize = 100) {
  const uid = useRecoilValue(authUidAtom);

  return useRecoilCallback(
    async ({ getLoadable, set }) => {
      if (getLoadable(loadingAtom).contents) {
        return;
      }
      set(loadingAtom, true);
      try {
        let query = firestore()
          .collection(`users/${uid}/notifications`)
          .orderBy('updatedAt', 'desc')
          .withConverter(convertToNotification)
          .limit(batchSize);

        const lastUpdatedAtLoadable = getLoadable(lastUpdatedAtAtom);
        if (lastUpdatedAtLoadable.state === 'hasValue') {
          const { contents } = lastUpdatedAtLoadable;
          if (contents) {
            query = query.startAfter(contents);
          }
        }
        const qs = await query.get();
        qs.forEach(ds => {
          set(notificationAtom(ds.id), ds.data());
        });
        const added = qs.docs.map(ds => ds.id);
        set(notificationIdsAtom, curr => uniq(curr.concat(added)));
        set(hasMoreAtom, qs.size === batchSize);
        const lastItem = qs.docs[qs.docs.length - 1];
        if (lastItem) {
          set(lastUpdatedAtAtom, lastItem.get('updatedAt'));
        }
        // 未読のアイテムがまだセットされていなければセットする
        const unreadId = qs.docs.find(ds => !ds.get('readAt'))?.id;
        if (unreadId) {
          set(unreadIdAtom, curr => curr || unreadId);
        }
        // YouTube に紹介されたらポップアップ表示する
        for (const ds of qs.docs) {
          const data = ds.data();
          if (isUnread(data) && data.content.type === 'youtuber-tani') {
            set(popupAtom, data.content);
          }
        }
      } catch (error) {
        console.error(error);
        set(noticeAtom, { severity: 'error', children: messageOf(error) });
      } finally {
        set(loadingAtom, false);
      }
    },
    [uid]
  );
}
