import {
  AppBar,
  Backdrop,
  Button,
  Chip,
  Fade,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Close, Lock } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import Player from '@vimeo/player';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { actions } from '../ducks/actions';
import { getAuthUser } from '../ducks/auth';
import {
  alreadyWatchVideo,
  getUnwatched,
  getVideoId,
  isFree,
  IVideoResponse
} from '../ducks/video';
import { analytics } from '../utils/analytics';
import { Link } from '../utils/components';
import { fromNow } from '../utils/fromNow';
import { container, hover, loadAnimation } from '../utils/xlasses';
import { CircleIcon } from './CircleIcon';
import { LoadingPage } from './LoadingPage';
import { ResponsiveEmbed } from './ResponsiveEmbed';

type Params = {
  series: string;
};

const seriesList = [
  // {
  //   id: 'trial',
  //   title: 'おためし'
  // },
  {
    id: 'season_1',
    title: 'シーズン１'
  },
  {
    id: 'season_2',
    title: 'シーズン２'
  }
];

if (process.env.NODE_ENV === 'development') {
  seriesList.push({
    id: 'test',
    title: 'テスト'
  });
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 32,
    marginBottom: 32,
    overflow: 'hidden'
  },
  appBar: {
    top: 64 // 上部の AppBar に隠れないように sticky にする
  },
  appBarXs: {
    top: 56
  },
  toolbar: {
    maxWidth: 1000,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxSizing: 'border-box'
  },
  heading: {
    marginLeft: 16,
    marginRight: 32
  },
  info: {
    marginBottom: 16
  },
  scroller: {
    flex: 1,
    overflow: 'scroll'
  }
}));

export function VideoList() {
  const cn = useStyles();

  // 現在選択しているシリーズ
  const { series } = useParams<Params>();
  const history = useHistory();

  // 動画の一覧を取得
  const dispatch = useDispatch();
  const videos = useSelector(state => state.video.videos);
  const list = series ? videos[series] : undefined;
  React.useEffect(() => {
    if (series) {
      dispatch(actions.video.listIfNeeded(series));
    }
  }, [series, list]);

  // 全ての動画を視聴する権利があるかどうかを確かめる
  const initialized = useSelector(state => state.auth.initialized);
  const authUser = useSelector(getAuthUser);
  const restrictVideo = Boolean(authUser?.plans?.restrictVideo); // 授業動画を１日１本にするフラグ

  const noVideoWatched = useSelector(
    state => Object.keys(state.video.videoWatchedAt || {}).length === 0
  );
  const alreadyWatched = useSelector(alreadyWatchVideo);

  // まだ観ていない動画のうち最も古いものをオススメとする
  const recommended = useSelector(getUnwatched);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const selected = seriesList.find(item => item.id === series);

  return (
    <>
      <AppBar
        position="sticky"
        color="inherit"
        className={isXs ? cn.appBarXs : cn.appBar}
      >
        <Toolbar variant="dense" disableGutters className={cn.toolbar}>
          <CircleIcon
            src={require('../resources/youtuber-tani.jpg')}
            size={36}
          />
          <Typography variant="h6" className={cn.heading} gutterBottom>
            TANI の授業動画
          </Typography>
          <Tabs
            value={series}
            onChange={(event, value) => history.push(`/videos/${value}`)}
          >
            {seriesList.map(item => (
              <Tab key={item.id} value={item.id} label={item.title} />
            ))}
          </Tabs>
        </Toolbar>
      </AppBar>
      <div className={cn.scroller}>
        {noVideoWatched ? <Introduction /> : null}
        <Paper elevation={2} className={classNames(container.large, cn.root)}>
          <Typography variant="h5" gutterBottom>
            {selected?.title}
          </Typography>
          {alreadyWatched ? (
            <Alert
              severity="success"
              className={cn.info}
              action={
                <Button
                  component={Link('/make')}
                  variant="contained"
                  color="primary"
                >
                  ゲームをつくる
                </Button>
              }
            >
              動画チェックお疲れさまでした。それではゲームを作っていきましょう！
            </Alert>
          ) : restrictVideo ? (
            <Alert severity="info" className={cn.info}>
              １日１本まで、好きな動画をみることができます。一度みた動画はいつでも見返せます
            </Alert>
          ) : null}
          {!initialized ? (
            <>
              <Typography variant="body1" color="textSecondary">
                ユーザー情報を読み込んでいます
              </Typography>
              <LoadingPage />
            </>
          ) : list ? (
            <Grid container spacing={2}>
              {list.data.map(v => (
                <Grid
                  key={v.uri}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className={hover.growImageRoot}
                >
                  <VideoItem video={v} recommended={recommended === v} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <>
              <Typography variant="body1" color="textSecondary">
                動画一覧を読み込んでいます
              </Typography>
              <LoadingPage />
            </>
          )}
        </Paper>
      </div>
    </>
  );
}

export interface VideoItemProps {
  video: IVideoResponse['data'][number];
  recommended: boolean;
}

const useStylesVideoItem = makeStyles(theme => ({
  pointer: {
    cursor: 'pointer'
  },
  disabled: {
    filter: 'brightness(0.7)'
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -4,
    marginBottom: 4
  }
}));

export function VideoItem(props: VideoItemProps) {
  const cn = useStylesVideoItem();

  const authUser = useSelector(getAuthUser);
  const canWatchVideo = authUser?.plans?.canWatchVideo; // 授業動画が視聴可能になるフラグ
  const restrictVideo = authUser?.plans?.restrictVideo; // 授業動画を１日１本にするフラグ

  const isNeedPayment = !canWatchVideo && !isFree(props.video);

  const alreadyWatched = useSelector(alreadyWatchVideo);
  const idRef = React.useRef<string>();
  idRef.current = getVideoId(props.video.uri);
  const watchedAt = useSelector(
    state => idRef.current && state.video.videoWatchedAt[idRef.current]
  );
  const isRestricted = restrictVideo && alreadyWatched && !watchedAt; // 動画の視聴が制限されているかどうか

  const [playing, setPlaying] = React.useState(false);
  const play = React.useCallback(() => setPlaying(true), []);
  const stop = React.useCallback(() => setPlaying(false), []);

  return (
    <>
      <VideoThumbnail video={props.video} onPlay={play} />
      <Typography
        variant="body1"
        gutterBottom
        onClick={play}
        className={classNames(
          cn.pointer,
          (isRestricted || isNeedPayment) && cn.disabled
        )}
      >
        {props.video.name}
      </Typography>
      <Typography variant="body2">{props.video.description}</Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {isRestricted
          ? '今日はもう視聴できません'
          : watchedAt
          ? fromNow(watchedAt) + ' に見ました'
          : 'まだ見ていません'}
      </Typography>
      <VideoPlayer playing={playing} video={props.video} onStop={stop} />
    </>
  );
}

const useStylesIntroduction = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    marginBottom: -32,
    '& > img': {
      maxWidth: '100%',
      zIndex: 1
    }
  },
  face: {
    zIndex: 2
  },
  balloonXs: {
    marginBottom: -72
  }
}));

function Introduction() {
  const cn = useStylesIntroduction();
  const sm = useMediaQuery(`@media (max-width: ${232 + 533}px)`);

  const [time, setTime] = React.useState(0);
  React.useEffect(() => {
    const handler = window.setTimeout(() => {
      setTime(Math.min(3, time + 1));
    }, 1000);
    return () => {
      window.clearTimeout(handler);
    };
  }, [time]);

  return (
    <div
      className={classNames(container.large, cn.root)}
      style={{ padding: 0, flexDirection: sm ? 'column-reverse' : 'row' }}
    >
      <Fade in={time >= 2}>
        <img
          src={require('../resources/tani-face.png')}
          alt="TANI"
          className={classNames(cn.face)}
        />
      </Fade>
      <Fade in={time >= 3}>
        <img
          src={require('../resources/tani-balloon.png')}
          alt="ステージの作り方動画を見てね！"
          className={classNames(sm && cn.balloonXs)}
        />
      </Fade>
    </div>
  );
}

const useStylesVideoThumbnail = makeStyles(theme => ({
  videoContainer: {
    position: 'relative',
    minWidth: 200
  },
  watched: {
    position: 'absolute',
    zIndex: 1,
    right: 8,
    top: 8
  },
  chipBelow: {
    top: 40
  },
  duration: {
    position: 'absolute',
    right: 8,
    bottom: 8,
    color: 'rgb(255,255,255)',
    backgroundColor: 'rgba(0,0,0,0.8)',
    fontSize: 'small',
    padding: 4,
    borderRadius: 4,
    zIndex: 1
  },
  pointer: {
    cursor: 'pointer'
  },
  disabled: {
    filter: 'brightness(0.7)'
  },
  paidUserOnly: {
    position: 'absolute',
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: theme.transitions.create('opacity'),
    opacity: 0,
    '&:hover': {
      opacity: 1
    }
  }
}));

export interface VideoThumbnailProps {
  video: IVideoResponse['data'][number];
  recommended?: boolean;
  onPlay: () => void;
}

export function VideoThumbnail(props: VideoThumbnailProps) {
  const cn = useStylesVideoThumbnail();

  const authUser = useSelector(getAuthUser);
  const canWatchVideo = authUser?.plans?.canWatchVideo; // 授業動画が視聴可能になるフラグ
  const restrictVideo = authUser?.plans?.restrictVideo; // 授業動画を１日１本にするフラグ

  const isNeedPayment = !canWatchVideo && !isFree(props.video);
  const showFree = !canWatchVideo && isFree(props.video);

  const alreadyWatched = useSelector(alreadyWatchVideo);
  const idRef = React.useRef<string>();
  idRef.current = getVideoId(props.video.uri);
  const watchedAt = useSelector(
    state => idRef.current && state.video.videoWatchedAt[idRef.current]
  );
  const isRestricted = restrictVideo && alreadyWatched && !watchedAt; // 動画の視聴が制限されているかどうか

  const thumbnail = props.video.pictures.sizes.find(
    image => image.width >= 600
  );

  return (
    <div className={cn.videoContainer}>
      {showFree ? (
        <Chip
          label="無料公開中"
          color="secondary"
          size="small"
          className={cn.watched}
        />
      ) : null}
      {watchedAt ? (
        <Chip
          label="視聴済み"
          color="secondary"
          size="small"
          className={classNames(cn.watched, showFree && cn.chipBelow)}
        />
      ) : props.recommended ? (
        <Chip
          label="オススメ"
          color="primary"
          size="small"
          className={classNames(cn.watched, showFree && cn.chipBelow)}
        />
      ) : null}
      <span className={cn.duration}>
        {(props.video.duration / 60) >> 0}:
        {(props.video.duration % 60).toString().padStart(2, '0')}
      </span>
      <ResponsiveEmbed
        verticalPerHorizontal={9 / 16}
        className={hover.growImageParent}
      >
        <img
          src={thumbnail?.link}
          width={thumbnail?.width}
          height={thumbnail?.height}
          alt=""
          className={classNames(
            cn.pointer,
            (isRestricted || isNeedPayment) && cn.disabled
          )}
          onClick={props.onPlay}
        />
        {isNeedPayment ? (
          <Typography
            variant="subtitle2"
            className={cn.paidUserOnly}
            onClick={props.onPlay}
          >
            <Lock />
            月額会員のみ視聴可能
          </Typography>
        ) : null}
      </ResponsiveEmbed>
    </div>
  );
}

const useStylesVideoPlayer = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    '& > div': {
      position: 'relative',
      maxWidth: 1080,
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  close: {
    position: 'absolute',
    top: -48,
    right: 0,
    color: theme.palette.common.white
  }
}));

export interface VideoPlayerProps {
  video: IVideoResponse['data'][number];
  playing: boolean;
  onStop: () => void;
}

export function VideoPlayer(props: VideoPlayerProps) {
  const cn = useStylesVideoPlayer();

  const authUser = useSelector(getAuthUser);
  const canWatchVideo = authUser?.plans?.canWatchVideo; // 授業動画が視聴可能になるフラグ
  const restrictVideo = authUser?.plans?.restrictVideo; // 授業動画を１日１本にするフラグ

  const isNeedPayment = !canWatchVideo && !isFree(props.video);

  const alreadyWatched = useSelector(alreadyWatchVideo);
  const idRef = React.useRef<string>();
  idRef.current = getVideoId(props.video.uri);
  const watchedAt = useSelector(
    state => idRef.current && state.video.videoWatchedAt[idRef.current]
  );
  const isRestricted = restrictVideo && alreadyWatched && !watchedAt; // 動画の視聴が制限されているかどうか

  // 現在の Video ID (または月額プランについての説明用動画)
  const id = isNeedPayment ? '413831791' : getVideoId(props.video.uri);

  // クリックで動画を再生
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const playerRef = React.useRef<Player>();
  const dispatch = useDispatch();
  const playingRef = React.useRef(props.playing);
  playingRef.current = props.playing;
  React.useEffect(() => {
    if (!props.playing || isRestricted) {
      return;
    }
    const iframe = iframeRef.current;
    if (!iframe) return;
    // 自動再生する
    const player = new Player(iframe);
    player.on('timeupdate', function task(e) {
      if (e.percent > 0.5) {
        // 半分以上観たら視聴済みフラグを立てる
        player
          .getVideoId()
          .then(id => dispatch(actions.video.watch.started(id + '')));
        player.off('timeupdate', task);
      }
    });
    player
      .play()
      .then(() => {
        // 動画の再生を止める
        player.on('timeupdate', function task(e) {
          if (!playingRef.current) {
            player.unload(); // destroy() だと DOM ごと消してエラーになってしまう
            playerRef.current = undefined;
          }
        });
      })
      .catch(console.warn); // 自動再生. macOS/Safari が例外をスローすることがあるが、再生ボタンを押せば始まる
    player.getVideoTitle().then(title => analytics.playVideo(title)); // トラッキング
    playerRef.current = player; // unload() のために参照を保持する
  }, [props.playing, isRestricted]);

  return (
    <Backdrop
      className={cn.backdrop}
      open={props.playing}
      onClick={props.onStop}
    >
      <div className={loadAnimation.glimmering}>
        <IconButton className={cn.close}>
          <Close />
        </IconButton>
        <ResponsiveEmbed verticalPerHorizontal={9 / 16}>
          {id ? (
            <iframe
              src={`https://player.vimeo.com/video/${id}?autoplay=0&speed=1&player_id=0&app_id=167277`}
              frameBorder={0}
              allowFullScreen // for Firefox 75
              allow="autoplay; fullscreen"
              ref={iframeRef}
            ></iframe>
          ) : null}
        </ResponsiveEmbed>
      </div>
    </Backdrop>
  );
}
