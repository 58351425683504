import {
  Divider,
  Drawer,
  ListItemIcon,
  ListItemText,
  MenuItem
} from '@material-ui/core';
import { ArrowBack, CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import * as React from 'react';
import { useSelector } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { useHistory, useParams } from 'react-router-dom';
import {
  atom,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState
} from 'recoil';
import { style } from 'typestyle';
import { unblock } from '../../../components/IdeMenuBar';
import { WorkUrlParams } from '../../../components/Work';
import { useRecoilEffect } from '../../../hooks/useRecoilEffect';
import {
  cardVisibilityAtom,
  globalEventAtom,
  localizationAtom,
  muiThemeAtom
} from '../atoms';
import { SetCardVisibility } from '../Cards/defaultState';
import { EditorCard } from '../Cards/EditorCard';
import { HierarchyCard } from '../Cards/HierarchyCard';
import { MonitorCard } from '../Cards/MonitorCard';
import { ShotCard } from '../Cards/ShotCard';
import { useGlobalEvent } from '../hooks/useGlobalEvent';
import { useLoadConfig } from '../hooks/useLoadConfig';
import getCustomTheme from '../js/getCustomTheme';
import '../styles/codemirror.scss';
import { keys } from '../utils/keys';
import icons from './icons';

export const isIdeSidebarOpenedAtom = atom({
  key: 'isIdeSidebarOpenedAtom',
  default: false
});

export interface MainProps {
  setCardVisibility: SetCardVisibility;
  onMessage?: (event: any) => void;
  onThumbnailChange?: (value: string) => void;
}

const cn = {
  root: style({
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1.15
  }),
  drawer: {
    paper: style({
      position: 'absolute',
      $nest: {
        '&>*': {
          marginTop: 8
        }
      }
    })
  },
  container: style({
    flex: 1,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    overflowX: 'hidden',
    overflowY: 'scroll',
    boxSizing: 'border-box',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain'
  })
};

export function Main(props: MainProps) {
  let files = useSelector(state => state.file.files);
  // 参照透過性を保つためにフィルタを排除している。必要だったら元に戻す
  // files = files.filter(file => !file.isTrashed);
  const setMuiTheme = useSetRecoilState(muiThemeAtom);
  const localization = useRecoilValue(localizationAtom);
  const cardVisibility = useRecoilValue(cardVisibilityAtom);
  const sidebarOpened = useRecoilValue(isIdeSidebarOpenedAtom);

  const globalEvent = useRecoilValue(globalEventAtom);
  React.useEffect(
    () => () => {
      globalEvent.removeAllListeners(); // globalEvent を初期化する代わりにリスナーを削除する
    },
    []
  );

  const loadConfig = useLoadConfig();

  const takeScreenshot = React.useCallback(() => {
    const request = {
      query: 'capture',
      type: 'image/png',
      requestedBy: 'auto' // 自動的にリクエストされることを表す
    };
    return globalEvent.emitAsync('postMessage', request);
  }, [globalEvent]);

  // ファイルが変更されたらスクリーンショットを撮る
  React.useEffect(() => {
    takeScreenshot();
  }, [files]);
  // 定期的にスクリーンショットを撮る
  React.useEffect(() => {
    const id = window.setInterval(takeScreenshot, 10 * 1000);
    return () => window.clearTimeout(id);
  }, []);

  // スクリーンショットを待ち受ける
  const cacheRef = React.useRef(new Set());
  useGlobalEvent(
    'message.capture',
    event => {
      const { value } = event.data || {};
      if (!cacheRef.current.has(value)) {
        props.onThumbnailChange?.(value);
        cacheRef.current.add(value);
      }
    },
    []
  );

  // iframe からのイベントを伝える
  useGlobalEvent(
    'message.dispatchOnMessage',
    event => {
      props.onMessage?.({ ...event });
    },
    []
  );

  React.useEffect(() => {
    const feelesrc = loadConfig('feelesrc');
    setMuiTheme(getCustomTheme(feelesrc));
  }, []);

  const scrollToCard = React.useCallback((name: string) => {
    // そのカードにスクロールする
    const scrollTarget = document.getElementById(name);
    if (scrollTarget) {
      scrollTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest'
      });
    }
  }, []);

  const visibleNum = keys(cardVisibility).filter(
    key => cardVisibility[key]
  ).length;

  const shouldMonitorOnlyRef = React.useRef(false);
  const onResize = React.useCallback(() => {
    // show only MonitorCard when device's width is small
    const isSmall = window.innerWidth < 768; // 768px === Portrait iPad
    if (isSmall !== shouldMonitorOnlyRef.current && isSmall) {
      for (const key of keys(cardVisibility)) {
        if (key !== 'MonitorCard' && cardVisibility[key]) {
          props.setCardVisibility(key, false); // Close card
        }
      }
    }
    shouldMonitorOnlyRef.current = isSmall;
  }, [cardVisibility, props.setCardVisibility]);

  // visible が false から true にかわったらスクロールする
  const prevCardVisibility = React.useRef(cardVisibility);
  React.useEffect(() => {
    for (const name of keys(cardVisibility)) {
      if (!prevCardVisibility.current[name] && cardVisibility[name]) {
        scrollToCard(name);
      }
    }
    prevCardVisibility.current = cardVisibility;
  }, [cardVisibility, scrollToCard]);

  const params = useParams<WorkUrlParams>();
  const editing =
    (params[0] === 'works' && params.action === 'edit') ||
    params[0] === 'officials';
  const history = useHistory();
  const moveBack = useRecoilCallback(({ set }) => {
    set(isIdeSidebarOpenedAtom, false); // サイドバーを閉じる
    unblock(); // 警告を出さないようにする
    history.push(`/works/${params.id}`);
  }, []);

  useRecoilEffect(
    ({ set }) =>
      () => {
        set(isIdeSidebarOpenedAtom, false); // 画面遷移したときにメニューを閉じる
      },
    []
  );

  return (
    <div className={cn.root}>
      <Drawer variant="persistent" open={sidebarOpened} classes={cn.drawer}>
        <MenuItem
          disabled={params[0] !== 'works' || params.action !== 'edit'}
          onClick={moveBack}
        >
          <ListItemIcon>
            <ArrowBack />
          </ListItemIcon>
          <ListItemText>作るのをやめる</ListItemText>
        </MenuItem>
        <Divider />
        {icons.map((item: typeof icons[number], index: number) => {
          const lowerCase =
            item.name.substr(0, 1).toLowerCase() + item.name.substr(1);
          const localized = (localization as any)[lowerCase];
          const visible = cardVisibility[item.name];

          return (
            <MenuItem
              key={index}
              onClick={() => {
                props.setCardVisibility(item.name, !visible);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>
                {localized ? localized.title : item.name}
              </ListItemText>
              {visible ? (
                <CheckBox color="action" />
              ) : (
                <CheckBoxOutlineBlank color="action" />
              )}
            </MenuItem>
          );
        })}
      </Drawer>
      <div
        className={cn.container}
        style={{
          flexWrap: visibleNum <= 2 ? 'nowrap' : 'wrap'
        }}
      >
        <ReactResizeDetector handleWidth onResize={onResize} />
        <MonitorCard />
        <ShotCard setCardVisibility={props.setCardVisibility} />
        {editing ? (
          <EditorCard
            scrollToCard={scrollToCard}
            setCardVisibility={props.setCardVisibility}
          />
        ) : null}
        {editing ? <HierarchyCard /> : null}
      </div>
      <UserDefineStyle />
    </div>
  );
}

export function UserDefineStyle({}) {
  const file = useSelector(state =>
    state.file.files.find(file => file.name === 'feeles/codemirror.css')
  );
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    let style = document.createElement('style');
    if (file && file.text && ref.current) {
      style.innerHTML = file && file.text;
      ref.current.appendChild(style);
      return () => {
        ref.current && ref.current.removeChild(style);
      };
    }
  }, [file]);

  return <div ref={ref}></div>;
}
