import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import {
  Brush,
  ImportContacts,
  Keyboard,
  SentimentVerySatisfied
} from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import { Entry } from 'contentful';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { actions } from '../ducks/actions';
import { endpoint } from '../env';
import { getContentfulClient } from '../pages/getContentfulClient';
import { fromNow } from '../utils/fromNow';
import { ResponsiveEmbed } from './ResponsiveEmbed';

const categories = {
  design: {
    color: '#43A047',
    tag: 'デザインがすごい！',
    sub: '綺麗なマップなど、見せ方の表現が上手いゲーム',
    Icon: Brush
  },
  humor: {
    color: '#FBC02D',
    tag: 'ユーモアがすごい！',
    sub: '思わず笑ってしまう、奇天烈なアイデアのゲーム',
    Icon: SentimentVerySatisfied
  },
  story: {
    color: '#E53935',
    tag: 'ストーリーがすごい！',
    sub: 'ワクワク、感動するストーリーのあるゲーム',
    Icon: ImportContacts
  },
  technique: {
    color: '#1E88E5',
    tag: 'テクニックがすごい！',
    sub: '高度なプログラミング技術を使ったゲーム',
    Icon: Keyboard
  }
};

type CategoryType = keyof typeof categories;

interface StaffPicks {
  title: string;
  url: string;
  category: CategoryType;
  comment: string;
}

// カバー画像を切り返る秒数 [ms]
const transitionInterval = 6000;

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: 32,
    paddingBottom: 100,
    '& > *': {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '100%'
    },
    '& p': {
      whiteSpace: 'pre',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& img': {
      width: '100%'
    }
  },
  categories: {
    flexDirection: 'column',
    marginTop: 32,
    marginBottom: 16,
    '& svg': {
      marginRight: 16
    }
  },
  categoryItem: {
    marginBottom: 16
  }
}));

export function StaffPicks() {
  const classes = useStyles();

  // 初回マウント時に Contentful から staffPicks を取得する
  const [errors, setErrors] = React.useState<Error[]>();
  const [entries, setEntries] = React.useState<Entry<StaffPicks>[]>();
  React.useEffect(() => {
    getContentfulClient()
      .getEntries<StaffPicks>({
        content_type: 'staffPicks',
        limit: 50,
        order: '-sys.createdAt'
      })
      .then(entries => {
        if (entries.errors) {
          setErrors(entries.errors);
        } else {
          setEntries(entries.items);
        }
      })
      .catch(error => {
        setErrors([error]);
      });
  }, []);

  // 最新４件の work path だけを抜き出す
  const latestFour = React.useMemo(() => {
    return entries?.slice(0, 4).map(getWorkIdFromEntry);
  }, [entries]);

  return (
    <>
      <Cover pathes={latestFour} />
      {errors?.map(e => <Alert severity="error">{e.message}</Alert>) || null}
      <div className={classes.container}>
        {entries
          ?.slice(0, 4)
          .map(entry => <Item key={entry.sys.id} entry={entry} />) || null}
        <div className={classes.categories}>
          <Typography variant="h5" color="textSecondary" gutterBottom>
            ４つの部門
          </Typography>
          <Grid container>
            {Object.values(categories).map(cat => (
              <Grid
                item
                xs={12}
                sm={6}
                container
                alignItems="center"
                className={classes.categoryItem}
              >
                <cat.Icon fontSize="large" style={{ color: cat.color }} />
                <div>
                  <Typography variant="subtitle2" color="textSecondary">
                    {cat.tag}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {cat.sub}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
        {entries
          ?.slice(4)
          .map(entry => <Item key={entry.sys.id} entry={entry} />) || null}
      </div>
    </>
  );
}

interface CoverProps {
  ids?: string[];
  pathes?: string[];
}

const coverStyles = makeStyles(theme => ({
  cover: {
    backgroundColor: 'rgb(35, 47, 52)',
    color: '#ffffff',
    flex: 0,
    minHeight: 300,
    overflow: 'hidden',
    position: 'relative',
    marginBottom: 32,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    paddingLeft: 32
  },
  xs: {
    minHeight: 120
  },
  coverImage: {
    position: 'absolute',
    backgroundSize: 'cover',
    right: 0,
    width: '70%',
    maxWidth: 1000,
    height: '100%'
  },
  gradiation: {
    width: '100%',
    height: '100%',
    background:
      'linear-gradient(90deg, rgba(35, 47, 52, 1), rgba(35, 47, 52, 0))'
  },
  headerContainer: {
    zIndex: 1,
    position: 'relative',
    '& > img': {
      marginRight: 16
    }
  },
  detailContainer: {
    zIndex: 1,
    position: 'relative'
  },
  coverThumbnails: {
    '& img': {
      cursor: 'pointer',
      width: '100%'
    }
  }
}));

function Cover({ ids }: CoverProps) {
  const classes = coverStyles();
  const theme = useTheme();
  const enoughSize = useMediaQuery(theme.breakpoints.up('sm'));

  // ステージの情報を取得する
  const works = useSelector(state => state.work.works);

  // ４つのアイテムを順番に見せる
  const [cover, setCover] = React.useState(0);
  React.useEffect(() => {
    const handler = window.setTimeout(() => {
      setCover((cover + 1) % 4);
    }, transitionInterval);
    return () => {
      window.clearTimeout(handler);
    };
  }, [cover]);

  return (
    <div className={classNames(classes.cover, !enoughSize && classes.xs)}>
      {enoughSize ? (
        <div
          className={classes.coverImage}
          style={
            ids
              ? {
                  backgroundImage: `url(${endpoint}/works/${ids[cover]}/thumbnail)`
                }
              : {}
          }
        >
          <div className={classes.gradiation}></div>
        </div>
      ) : null}
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.headerContainer}
      >
        {enoughSize ? (
          <img src={require('../resources/staff-pick-prize-icon.png')} alt="" />
        ) : null}
        <div>
          <Typography variant="h4" color="inherit">
            このゲームがすごい！
          </Typography>
          <Typography variant="body1" color="inherit">
            ハックフォープレイのスタッフがえらんだ すごいゲーム特集！
          </Typography>
        </div>
      </Grid>
      {enoughSize ? (
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          className={classes.detailContainer}
        >
          <Grid
            item
            xs={6}
            container
            direction="row"
            spacing={1}
            className={classes.coverThumbnails}
          >
            {ids?.map((workId, i) => (
              <Grid key={i} item xs={3} lg={2}>
                <div onClick={() => setCover(i)}>
                  <ResponsiveEmbed verticalPerHorizontal={2 / 3}>
                    <img src={`${endpoint}/works/${workId}/thumbnail`} alt="" />
                  </ResponsiveEmbed>
                  <Step on={i === cover} />
                </div>
              </Grid>
            )) || null}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" align="right">
              {ids ? works[ids[cover]]?.data?.title : ''}
            </Typography>
            <Typography variant="subtitle2" align="right">
              by {ids ? works[ids[cover]]?.data?.author : ''}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
}

interface StepProps {
  on: boolean;
}

const stepStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: 4,
    backgroundColor: theme.palette.grey[500],
    '& > *': {
      position: 'absolute',
      height: '100%',
      width: '100%',
      backgroundColor: '#ffffff',
      transformOrigin: 'left'
    }
  },
  on: {
    transform: 'scaleX(1)',
    transition: theme.transitions.create('transform', {
      duration: transitionInterval,
      easing: 'linear'
    })
  },
  off: {
    transform: 'scaleX(0)',
    height: 4
  }
}));

/**
 * off -> on 時はゆっくり伸びて、 on -> off 時は即座に小さくなるバー
 */
function Step(props: StepProps) {
  const classes = stepStyles();
  // 初回のみ、トランジション初期化のために off でレンダリングする
  const [mount, setMount] = React.useState(false);
  React.useEffect(() => {
    // 直ちに切り替えるとトランジションが有効にならない (?)
    window.setImmediate(() => {
      setMount(true);
    });
  }, []);

  return (
    <div className={classes.root}>
      <div className={props.on && mount ? classes.on : classes.off}></div>
    </div>
  );
}

interface ItemProps {
  entry: Entry<StaffPicks>;
}

const itemStyles = makeStyles(theme => ({
  left: {
    // タイトルとサムネイルを合わせる
    marginTop: 8
  }
}));

function Item({ entry }: ItemProps) {
  const classes = itemStyles();

  // ステージの情報と作者の情報を取得する
  const workId = getWorkIdFromEntry(entry);
  const work = useSelector(state => state.work.works[workId]);
  const uid = work?.data?.uid;
  const user = useSelector(state => (uid ? state.user.byUid[uid] : undefined));

  // 未取得であればリクエストする
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (workId) {
      dispatch(actions.work.fetchIfNeeded(workId));
    }
  }, [workId]);
  React.useEffect(() => {
    if (uid) {
      dispatch(actions.user.fetchIfNeeded({ uid }));
    }
  }, [uid]);

  const publishedAt = work?.data?.publishedAt;
  const clearRate = work?.data?.clearRate;

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={5} sm={4} md={3} lg={2} className={classes.left}>
        <Category category={entry.fields.category} />
        <Link to={`/works/${workId}`}>
          <ResponsiveEmbed verticalPerHorizontal={2 / 3}>
            <img src={`${endpoint}/works/${workId}/thumbnail`} alt="" />
          </ResponsiveEmbed>
        </Link>
      </Grid>
      <Grid item xs={7} sm={8} md={9} lg={10}>
        <Link to={`/works/${workId}`}>
          <Typography variant="subtitle1" color="textPrimary">
            {work?.data?.title || entry.fields.title}
          </Typography>
        </Link>
        <Typography variant="body1" color="textPrimary" gutterBottom>
          {user?.data?.displayName || '_'}
          {publishedAt ? '・' + fromNow(publishedAt) + 'に公開' : ''}
          {clearRate ? `・${Math.floor(clearRate * 100)}%の人がクリア` : ''}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          スタッフコメント
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {entry.fields.comment}
        </Typography>
      </Grid>
    </Grid>
  );
}

interface CategoryProps {
  category: CategoryType;
}

const categoryStyle = makeStyles(theme => ({
  common: {
    position: 'absolute',
    color: '#ffffff',
    fontWeight: 600,
    fontSize: '1rem',
    backgroundColor: '#000000',
    fontFamily: theme.typography.fontFamily,
    padding: 4,
    zIndex: 1
  }
}));

function Category({ category }: CategoryProps) {
  const classes = categoryStyle();

  const style = React.useMemo(() => {
    return { backgroundColor: categories[category].color };
  }, [category]);

  return (
    <div style={style} className={classes.common}>
      {categories[category].tag}
    </div>
  );
}

function getWorkIdFromEntry(entry: Entry<StaffPicks>) {
  const [id] = entry.fields.url.split('/').reverse();
  return id;
}
