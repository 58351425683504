import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';

export interface ResolveProgressProps {
  size: number;
  globalEvent: any;
}

// Timeout [ms] 間 resolve がなければ、次の resolve まで hide
const Timeout = 1000;

export default class ResolveProgress extends React.PureComponent<ResolveProgressProps> {
  state = {
    visible: false
  };

  componentDidMount() {
    const { globalEvent } = this.props;
    globalEvent.on('message.resolve', this.handleResolve);
  }

  private _timer?: number;
  handleResolve = () => {
    if (!this.state.visible) {
      this.setState({ visible: true });
    }
    clearInterval(this._timer);
    this._timer = window.setTimeout(() => {
      this.setState({ visible: false });
    }, Timeout);
  };

  render() {
    if (!this.state.visible) {
      return null;
    }

    return (
      <CircularProgress
        size={this.props.size}
        thickness={Math.max(1, this.props.size / 8)}
        color="primary"
      />
    );
  }
}
