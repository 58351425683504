import { last } from 'lodash-es';
import { combineEpics } from 'redux-observable';
import firebase from '../settings/firebase';
import { actions, PaginateResult } from './actions';
import { asyncEpic, SKIP } from './asyncEpic';
import { reducerWithImmer } from './reducer-with-immer';

const firestore = firebase.firestore;

export const storeName = 'history';

export type State = {
  isFetching: boolean;
  pagination: PaginateResult<IHistory>;
  /**
   * 現在のページがロードされてからプレイしたゲームの情報
   * 新しい順に並んでいる
   */
  afterPageLoaded: IHistory[];
};

const initialState: State = {
  isFetching: false,
  pagination: {
    data: [],
    mayHasMore: true
  },
  afterPageLoaded: []
};

const pageSize = 15;

export default reducerWithImmer(initialState)
  .case(actions.history.fetchMore.started, (draft, payload) => {
    draft.isFetching = true;
  })
  .case(actions.history.fetchMore.done, (draft, payload) => {
    payload.result.data.forEach(item => draft.pagination.data.push(item));
    if (payload.result.mayHasMore !== undefined) {
      draft.pagination.mayHasMore = payload.result.mayHasMore;
    }
    if (payload.result.timestamp !== undefined) {
      draft.pagination.timestamp = payload.result.timestamp;
    }
    draft.isFetching = false;
  })
  .case(actions.history.fetchMore.failed, (draft, payload) => {
    draft.isFetching = false;
  })
  .case(actions.work.addView.done, (draft, payload) => {
    const { id } = payload.params;
    draft.afterPageLoaded.unshift({
      createdAt: firestore.Timestamp.now(),
      viewId: payload.result.id,
      workId: id
    });
  })
  .reset(actions.auth.signOut)
  .toReducer();

export const epics = combineEpics(
  // 履歴を取得する
  asyncEpic(actions.history.fetchMore, async (action, state) => {
    const uid = state.auth.userInfo?.uid;
    if (!uid) return SKIP; // type hint
    let query = firestore()
      .collection('users')
      .doc(uid)
      .collection('histories')
      .orderBy('createdAt', 'desc')
      .limit(pageSize)
      .withConverter(convertToHistory);
    if (state.history.pagination.timestamp) {
      query = query.startAfter(state.history.pagination.timestamp);
    }
    const qs = await query.get();
    const mayHasMore = qs.size === pageSize;
    const data = qs.docs.map(snapshot => snapshot.data());
    const timestamp = last(data)?.createdAt || undefined;
    return { data, mayHasMore, timestamp };
  })
);

const convertToHistory: firebase.firestore.FirestoreDataConverter<IHistory> = {
  fromFirestore: snapshot => snapshot.data() as IHistory,
  toFirestore: (data: IHistory) => data
};
