import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Theme, withTheme } from '@material-ui/core/styles';
import { ArrowDropDown } from '@material-ui/icons';
import { includes } from 'lodash-es';
import * as React from 'react';
import { style } from 'typestyle';
import { Localization } from '../../localization';

export interface DropdownProps {
  prefix?: string;
  items: string[];
  selected: string;
  onSelectChanged: (item: string) => void;
  localization: Localization;
  theme: Theme;
}

interface State {
  open: boolean;
}

const getCn = ({ theme }: DropdownProps) => ({
  button: style({
    paddingRight: theme.spacing()
  }),
  secondary: style({
    color: theme.palette.text.secondary,
    marginRight: 16
  })
});
const PaperProps = {
  style: {
    maxHeight: 200
  }
};

class Dropdown extends React.Component<DropdownProps, State> {
  static propTypes = {};

  static defaultProps = { items: [], selected: '', onSelectChanged: () => {} };

  state = {
    open: false
  };

  button = React.createRef<HTMLButtonElement>();

  handleToggle = () =>
    this.setState(state => ({
      open: !state.open
    }));

  handleSelect = (item: string) => {
    this.setState({ open: false });
    this.props.onSelectChanged(item);
  };

  render() {
    const dcn = getCn(this.props);
    const value = includes(this.props.items, this.props.selected)
      ? this.props.selected
      : this.props.localization.common.clickToSelect;

    return (
      <>
        <Button
          buttonRef={this.button}
          variant="contained"
          color="primary"
          size="small"
          className={dcn.button}
          onClick={this.handleToggle}
          endIcon={<ArrowDropDown />}
        >
          {value}
        </Button>
        <Menu
          id="lock-menu"
          anchorEl={this.button.current}
          open={this.state.open}
          onClose={this.handleToggle}
          PaperProps={PaperProps}
        >
          {this.props.items.map((item, index) => (
            <MenuItem
              key={index}
              selected={item === this.props.selected}
              onClick={() => this.handleSelect(item)}
            >
              {this.props.prefix ? (
                <span className={dcn.secondary}>{this.props.prefix}</span>
              ) : null}
              <span>{item}</span>
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

export default withTheme(Dropdown);
