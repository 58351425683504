import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import { rgba } from 'csx';
import * as React from 'react';
import { classes, style } from 'typestyle';
import { container, marginV, padding } from '../utils/xlasses';
import { Plan } from './Plan';

const cn = {
  root: style({
    marginBottom: 128
  }),
  cover: style({
    display: 'block',
    objectFit: 'cover',
    objectPosition: 'center 50%',
    width: '100%',
    height: '30vh'
  }),
  img: style({
    objectFit: 'cover',
    borderRadius: 1,
    width: '100%'
  }),
  responsiveEmbed: style({
    position: 'relative',
    width: '100%',
    paddingTop: (9 / 16) * 100 + '%',
    $nest: {
      '&>div': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        marginTop: -(9 / 16) * 100 + '%'
      },
      '& iframe': {
        width: '100%',
        height: '100%'
      }
    }
  }),
  quote: style({
    backgroundColor: rgba(0, 0, 0, 0.1).toString(),
    padding: 16,
    marginTop: 48,
    marginBottom: 16
  })
};

type LinkProps = {
  to: string;
};

const Link: React.ComponentType<LinkProps> = props => (
  <a href={props.to}>{props.children}</a>
);

type ImgProps = {
  src: string;
  alt: string;
};

const Img: React.ComponentType<ImgProps> = props => (
  <div>
    <img src={props.src} className={cn.img} alt={props.alt} />
    <Typography variant="caption" color="textSecondary">
      {props.alt}
    </Typography>
  </div>
);

type YouTubeProps = {
  src: string;
};

const YouTube: React.ComponentType<YouTubeProps> = props => (
  <div className={cn.responsiveEmbed}>
    <div>
      <iframe
        src={props.src}
        width="560"
        height="315"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  </div>
);

const Contents = () => (
  <div>
    <Typography variant="h4" gutterBottom>
      プログラミングを独学で学びたい子どもにピッタリなプラン
    </Typography>
    <Typography variant="h6" color="textSecondary" gutterBottom>
      ゲームを作りながらプログラミングを学べるので、飽きずに学べます。
    </Typography>
    <p>
      <span>例　</span>
      <Link to="https://www.hackforplay.xyz/works/aBBcAl3DmrTUU0ThHkE8">
        8連戦バトルロワイヤル！ by SEIKORO
      </Link>
    </p>
    <Img
      src="https://i.gyazo.com/4e8215d05917d17f5d2c462613e2e605.gif"
      alt=""
    />
    <Typography variant="body1" color="textSecondary">
      <b>赤色のペンギンきし</b> に対し、<b>黒色のペンギンきし</b>
      は体力が高く、またビームを撃つことができるようにプログラムされています。
      色違いのキャラクターには、それぞれ別のプログラムを設定できます。月額プラン加入すると可能になります。
    </Typography>
    <Img
      src="https://i.gyazo.com/f0fdbfc11160dec3d6030755c64fb28c.png"
      alt=""
    />
    <Divider className={marginV.large} />
    <Typography variant="h4" gutterBottom>
      独学でもゲームが作れるようになります
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Img
          src="https://i.gyazo.com/5c867ed09ae4213485a86f2787f503df.gif"
          alt=""
        />
        <Link to="https://www.hackforplay.xyz/works/J2KB4cRDlHZraRLjtdot">
          夏を取り戻せ。 by ミサ
        </Link>
      </Grid>
      <Grid item xs={6}>
        <Img
          src="https://i.gyazo.com/5ded58469cf867e910821c571f8a663b.gif"
          alt=""
        />
        <Link to="https://www.hackforplay.xyz/works/aBXE2TQ23AaXAhUjepDU">
          ETERNAL story 1 とはるのクエスト終了のお知らせ by ES
        </Link>
      </Grid>
      <Grid item xs={6}>
        <Img
          src="https://i.gyazo.com/8f2bd9861ebf35bef69d0ef8cf1746a0.gif"
          alt=""
        />
        <Link to="https://www.hackforplay.xyz/works/l4IeMELy3NbCYMo3X17W">
          RPG 第八章 by めんつゆ
        </Link>
      </Grid>
      <Grid item xs={6}>
        <Img
          src="https://i.gyazo.com/5e2eaf037bc70771f5888b3c4b48a0c4.gif"
          alt=""
        />
        <Link to="https://www.hackforplay.xyz/works/1UzRfHukl8IyINVU9U0l">
          {`スライムをたどれ{hackforplayスッタフへの挑戦状】 by アンドゥーマン２`}
        </Link>
      </Grid>
    </Grid>
    <Divider className={marginV.large} />
    <Typography variant="h4" gutterBottom>
      最初の成功体験が何よりも大切です。
    </Typography>
    <Typography variant="h6" gutterBottom>
      公式YouTubeチャンネルで紹介された子どもたちは、その後、素晴らしい才能を開花させています。チャンスは全ての子どもたちにあります。
    </Typography>
    <YouTube src="https://www.youtube.com/embed/jsgWszHEvhA" />
    <Typography variant="caption" color="textSecondary">
      ※音が出ます
    </Typography>
    <Typography variant="body1" color="textSecondary" gutterBottom>
      公式YouTubeチャンネルでは毎日１つずつ皆さんの投稿作品を紹介しています。ゲーム制作のヒントが盛り沢山なので、ぜひチャンネル登録してください。
    </Typography>
    <Divider className={marginV.large} />
    <Typography variant="h4" gutterBottom>
      月額プラン会員様限定のキャラクターたち
    </Typography>
    <Typography variant="caption" color="textSecondary" gutterBottom>
      ※通常色は無料会員でも使用可能
    </Typography>
    <Typography variant="h6" gutterBottom>
      ペンギンきし
    </Typography>
    <Grid container>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/red_penguin_knight-3fc576c1-bb0b-458e-bb2a-fd1169185cef.png')}
          alt="通常色"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/black_penguin_knight-4756e197-0645-4b1a-9adb-d12d363c486a.png')}
          alt="黒"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/blue_penguin_knight-b57fe387-bac7-4fb1-bc17-1fba53b1d8ae.png')}
          alt="青"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/green_penguin_knight-928bd62d-0c06-425e-b1cd-194204ed4bd6.png')}
          alt="緑"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/white_penguin_knight-c0770683-b12f-4555-bf2b-ab962c849275.png')}
          alt="白"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/yellow_penguin_knight-cb0162b5-cb4d-4765-8bd6-1fe007f5833d.png')}
          alt="黄"
        />
      </Grid>
    </Grid>
    <p>フィールドをうろうろする可愛い騎士</p>
    <p>
      プレイヤーを見つけると追いかけて攻撃してくるが、視界が狭いのですぐに見失ってしまう
    </p>
    <Divider className={marginV.large} />
    <Typography variant="h6" gutterBottom>
      まじょねこ
    </Typography>
    <Grid container>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/blue_witch_cat-b8385fc6-a03d-434e-99be-6bc1ce314344.png')}
          alt="通常色"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/black_witch_cat-afc0f2d5-b241-4153-824c-eb8eefeea4fe.png')}
          alt="黒"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/green_witch_cat-bca2336c-7396-4f39-be62-3bad42b5b2f8.png')}
          alt="緑"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/red_witch_cat-db464a8f-7859-4173-aa9c-77ff9671ab44.png')}
          alt="赤"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/white_witch_cat-df3d24ae-e28f-45b6-b8af-31a71d91179f.png')}
          alt="白"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/yellow_witch_cat-8ff23fd3-c467-4730-8dc7-149933f4cd0d.png')}
          alt="黄"
        />
      </Grid>
    </Grid>
    <p>フィールドをランダムにテレポートしながらプレイヤーを探す</p>
    <p>見つけると、杖をふり魔法を撃つ</p>
    <Divider className={marginV.large} />
    <Typography variant="h6" gutterBottom>
      ボススライム
    </Typography>
    <Grid container>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/blue_bossslime-402fbc89-70ac-488e-8d65-5efe4d63439f.png')}
          alt="通常色"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/black_bossslime-ac3b5961-8455-4512-8ed0-4dd40d5cffb3.png')}
          alt="黒"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/green_bossslime-21f36e8d-1010-4011-b0ac-c9cde7e09efd.png')}
          alt="緑"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/red_bossslime-37bbbb50-1995-465b-9068-950179ca9c3d.png')}
          alt="赤"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/white_bossslime-6d7508f0-69b8-4834-a0e7-e679d776d450.png')}
          alt="白"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/yellow_bossslime-e43127f2-0bc3-4532-9f36-62643eb36b36.png')}
          alt="黄"
        />
      </Grid>
    </Grid>
    <p>目の前を攻撃し続け、プレイヤーを見つけると子分のスライムを召喚する</p>
    <Divider className={marginV.large} />
    <Typography variant="h6" gutterBottom>
      ミミック
    </Typography>
    <Grid container>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/red_mimic-6961e052-4538-414c-93df-02a43fab21d7.png')}
          alt="通常色"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/black_mimic-ae37b351-3ad3-4cb2-9f69-0e1987927ae7.png')}
          alt="黒"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/blue_mimic-282794f8-3452-4f77-b505-06030b119a14.png')}
          alt="青"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/green_mimic-8194e538-7481-4e80-9504-83ac1420d54e.png')}
          alt="緑"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/white_mimic-07057584-d766-4e96-86fa-ad167be9e9ee.png')}
          alt="白"
        />
      </Grid>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/yellow_mimic-70e3c791-662c-45a7-b0bf-a19caf4e634c.png')}
          alt="黄"
        />
      </Grid>
    </Grid>
    <p>
      普段は宝箱の姿に化けているが、プレイヤーに攻撃されると姿を表し、襲いかかる
    </p>
    <Divider className={marginV.large} />
    <Typography variant="h6" gutterBottom>
      ボミィ
    </Typography>
    <Grid container>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/blue_bomy-7201e560-56a8-49a3-9337-db9babf913d2.png')}
          alt="通常色"
        />
      </Grid>

      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/black_bomy-4bc81563-17f9-44b0-888d-cdc7c0912eba.png')}
          alt="黒"
        />
      </Grid>

      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/green_bomy-b662b724-1384-48c9-852c-ac6a2275f383.png')}
          alt="緑"
        />
      </Grid>

      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/red_bomy-bce3902f-6b8b-44e7-abe6-57e3bbee95b3.png')}
          alt="赤"
        />
      </Grid>

      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/white_bomy-d3690b80-ca5c-4a33-abf5-e9d8e74b6917.png')}
          alt="白"
        />
      </Grid>

      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/yellow_bomy-1fa72af3-d6bb-46dd-b785-d4c74137248b.png')}
          alt="黄"
        />
      </Grid>
    </Grid>
    <p>つねにプレイヤーをおいかける危ないキャラクター</p>
    <p>ぶつかってしまうと爆発し大ダメージを受ける</p>
    <Divider className={marginV.large} />
    <Typography variant="h6" gutterBottom>
      ニンジャ
    </Typography>
    <Grid container>
      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/black_chameleon_ninja-6fd9e075-ef91-4671-98f0-86a6ceafe7e6.png')}
          alt="通常色"
        />
      </Grid>

      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/blue_chameleon_ninja-2d957cd9-1130-467a-adf5-8cc900b72ff9.png')}
          alt="青"
        />
      </Grid>

      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/green_chameleon_ninja-8ab6c080-69cc-410d-829f-1a2a0b6d825c.png')}
          alt="緑"
        />
      </Grid>

      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/red_chameleon_ninja-38cd34ac-4ea3-456e-861d-3bd04658303a.png')}
          alt="赤"
        />
      </Grid>

      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/white_chameleon_ninja-a0d6fabd-ccfa-41c6-94b4-bb40680f0619.png')}
          alt="白"
        />
      </Grid>

      <Grid item xs={2}>
        <Img
          src={require('../resources/promotion/yellow_chameleon_ninja-770c6e31-93c1-4c23-aea7-06179962acff.png')}
          alt="黄"
        />
      </Grid>
    </Grid>
    <p>透明になりフィールドのどこかに隠れているカメレオンのキャラクター</p>
    <p>攻撃されると姿を表し、爆弾を置いて攻撃してくる</p>
    <Typography variant="body1">ほか多数</Typography>
  </div>
);

export function Promotion() {
  return (
    <div className={cn.root}>
      <img
        src={require('../resources/promotion/_2019-03-07_12-d05adbc2-7184-4031-ac61-4fa42796ebc9.01.25.png')}
        className={cn.cover}
        alt=""
      />
      <Paper
        elevation={2}
        className={classes(padding.large, container.medium, marginV.large)}
      >
        <Contents />
      </Paper>
      <div>
        <Plan />
      </div>
    </div>
  );
}
