import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { Edit, ExpandLess, ExpandMore, GetApp } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { style } from 'typestyle';
import { useOpenAIStream } from '../../hooks/useOpenAIStream';
import { analytics } from '../../utils/analytics';

const cn = {
  container: style({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 32,
    paddingBottom: 128
  }),
  card: style({
    backgroundColor: '#fff'
  }),
  cards: style({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
    gap: 16
  }),
  result: style({
    alignSelf: 'stretch'
  }),
  paper: style({
    minWidth: '100%',
    whiteSpace: 'pre-wrap',
    padding: '8px 16px',
    lineHeight: 1.5,
    display: 'flex',
    flexDirection: 'column',
    gap: 16
  }),
  manual: style({
    width: '100%'
  }),
  manualButton: style({
    flexShrink: 0
  })
};

const cardCollections = [
  {
    label: '主人公',
    cards: [
      '男の子',
      '女の子',
      'イヌ',
      'ネコ',
      'カラス',
      'ドラキュラ',
      'ゾンビ',
      'ゴースト',
      'まほうつかい'
    ]
  },
  {
    label: '舞台',
    cards: [
      'そうげん',
      'もり',
      '冬のがけ',
      'さばく',
      'とうぎじょう',
      'あれち',
      'どうくつ',
      'まよいのどうくつ',
      'バザー',
      'いえ',
      'ダンジョン'
    ]
  }
  // ここにオブジェクトを追加すると選択肢を１つ増やせる
];

export function Story() {
  const [seed, setSeed] = useState('');
  const fetchGenerateStoryIdea = useOpenAIStream('generate_story_idea');

  const [disabled, setDisabled] = useState(false);

  const [fixedThemes, setThemes] = useState<string[]>([]);
  const [writingThemes, setWritingThemes] = useState<string[]>([]);
  const themes = writingThemes.concat(fixedThemes);

  const generateStoryIdea = () => {
    analytics.generateStoryIdea(seed);
    setDisabled(true); // 連打防止

    let buffer = '';
    const toThemes = () =>
      buffer
        .split('\n')
        .filter(s => s)
        .map(s => s.replace(/^\d\./, '').trim()) // 箇条書きの数字を削除
        .reverse();
    fetchGenerateStoryIdea({ seed }, text => {
      buffer += text;
      setWritingThemes(toThemes());
    }).finally(() => {
      setDisabled(false);
      setWritingThemes([]);
      setThemes(themes => toThemes().concat(themes));
    });
  };

  const onGenerateStoryStart = () => {
    setDisabled(true); // 連打防止
  };
  const onGenerateStoryEnd = (story: string) => {
    setDisabled(false);
  };

  return (
    <Container className={cn.container} maxWidth="md">
      <Box pt={2} alignItems="center">
        <Typography variant="h4" align="center">
          ストーリーAI
        </Typography>
        <Typography variant="body1" align="center">
          3つのカードを選んで、物語を作ってみよう！
        </Typography>
      </Box>
      <SeedInput seed={seed} onChange={setSeed} />
      <Button
        variant="contained"
        color="secondary"
        disabled={disabled}
        onClick={generateStoryIdea}
      >
        AIのアイデアをみる
      </Button>
      {themes.length > 0 && (
        <Typography variant="body2" color="textSecondary">
          これらの文章は、AIが生成したものです。
        </Typography>
      )}
      {themes.map(theme => (
        <StoryCard
          key={theme}
          disabled={disabled}
          theme={theme}
          onGenerateStoryStart={onGenerateStoryStart}
          onGenerateStoryEnd={onGenerateStoryEnd}
        />
      ))}
    </Container>
  );
}

interface SeedInputProps {
  seed: string;
  onChange: (seed: string) => void;
}

function SeedInput(props: SeedInputProps) {
  const getRandom = () => {
    const cards = cardCollections.map(collection => random(collection.cards));
    return cards;
  };
  const toString = (cards: string[]) =>
    cardCollections
      .map((collection, i) => `${collection.label}: ${cards[i]}`)
      .join('\n');
  const [selectedCards, _setSelectedCards] = useState(getRandom);
  const setCards = (cards: string[]) => {
    _setSelectedCards(cards);
    props.onChange(toString(cards));
  };
  useEffect(() => props.onChange(toString(selectedCards)), []);

  const [manual, setManual] = useState(false);

  return (
    <>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setCards(getRandom())}
        >
          ランダム
        </Button>
      </div>
      <div className={cn.cards}>
        {manual ? (
          <TextField
            variant="outlined"
            label="プロンプト"
            className={cn.manual}
            multiline
            value={props.seed}
            onChange={event => props.onChange(event.target.value.slice(0, 100))}
          />
        ) : (
          cardCollections.map((collection, index) => (
            <FormControl key={index} variant="outlined">
              <InputLabel id={`${collection.label}-select-helper-label`}>
                {collection.label}
              </InputLabel>
              <Select
                labelId={`${collection.label}-select-helper-label`}
                id={`${collection.label}-select-helper`}
                value={selectedCards[index]}
                onChange={event => {
                  const value = event.target.value + '';
                  const cards = [...selectedCards];
                  cards[index] = value;
                  setCards(cards);
                }}
                className={cn.card}
              >
                {collection.cards.map(card => (
                  <MenuItem key={card} value={card}>
                    {card}
                  </MenuItem>
                ))}
                <MenuItem key="random" value="(ランダム)">
                  (ランダム)
                </MenuItem>
              </Select>
            </FormControl>
          ))
        )}
        <Button
          onClick={() => setManual(b => !b)}
          startIcon={<Edit />}
          className={cn.manualButton}
        >
          {manual ? 'もとにもどす' : '自由に書く'}
        </Button>
      </div>
    </>
  );
}

interface StoryCardProps {
  disabled: boolean;
  theme: string;
  onGenerateStoryStart: () => void;
  onGenerateStoryEnd: (story: string) => void;
}

function StoryCard(props: StoryCardProps) {
  const { theme, disabled, onGenerateStoryEnd, onGenerateStoryStart } = props;
  const fetchGenerateStory = useOpenAIStream('generate_story');
  const [loading, setLoading] = useState(false);
  const [generated, setGenerated] = useState('');
  const [finished, setFinished] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const onClick = async () => {
    if (expanded) {
      setExpanded(false); // 開いていたのを閉じる
      return;
    }
    setExpanded(true);
    if (finished) {
      return; // すでに生成済み
    }

    onGenerateStoryStart();
    setLoading(true);
    analytics.generateStory(theme);
    fetchGenerateStory({ theme }, text => {
      setGenerated(gen => gen + text);
      setLoading(false);
    }).finally(() => {
      setLoading(false);
      setFinished(true);
      onGenerateStoryEnd(generated);
    });
  };

  return (
    <div className={cn.result}>
      <Paper className={cn.paper}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gridGap={16}
        >
          <Typography variant="h6">{theme}</Typography>
          <Button
            color="secondary"
            disabled={disabled}
            onClick={onClick}
            endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
          >
            くわしく読む
          </Button>
        </Box>
        {loading && !generated && <LinearProgress />}
        {generated && expanded && (
          <Typography variant="body1" gutterBottom>
            {generated}
          </Typography>
        )}
        {generated && expanded && finished && (
          <Button
            color="secondary"
            startIcon={<GetApp />}
            download={`${theme}.txt`}
            href={`data:text/plain;charset=utf-8,${encodeURIComponent(
              theme + '\n-\n' + generated
            )}`}
            style={{ alignSelf: 'flex-start' }}
          >
            ダウンロード
          </Button>
        )}
      </Paper>
    </div>
  );
}

function random(collection: string[]) {
  const index = Math.floor(Math.random() * collection.length);
  return collection[index];
}
