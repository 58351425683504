/* Composer of duck(action/actionCreator/reducer)s */
import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import * as asset from './asset';
import * as auth from './auth';
import * as file from './file';
import * as history from './history';
import * as make from './make';
import * as maps from './maps';
import * as notice from './notice';
import * as officialWork from './officialWork';
import * as series from './series';
import * as team from './team';
import * as user from './user';
import * as video from './video';
import * as work from './work';

export default combineReducers({
  video: video.default,
  auth: auth.default,
  notice: notice.default,
  work: work.default,
  officialWork: officialWork.default,
  make: make.default,
  series: series.default,
  user: user.default,
  maps: maps.default,
  file: file.default,
  history: history.default,
  team: team.default,
  asset: asset.default
});

export const rootEpic = combineEpics(
  video.epics,
  asset.epics,
  notice.epics,
  officialWork.epics,
  auth.epics,
  file.epics,
  history.epics,
  make.epics,
  series.epics,
  maps.epics,
  user.epics,
  team.epics,
  work.epics
);

export * from './type';
