import { Entry, Asset } from 'contentful';
import * as React from 'react';
import { style, classes } from 'typestyle';

export interface Fields {
  image: Asset;
  title: string;
}

export interface IconProps {
  entry: Entry<Fields>;
  block?: boolean;
}

export function isIcon(entry: Entry<unknown>): entry is Entry<Fields> {
  return entry.sys.contentType.sys.id === 'icon';
}

const cn = {
  block: style({
    display: 'block'
  })
};

export function Icon({ entry, block = false }: IconProps) {
  const {
    file: { url },
    title
  } = entry.fields.image.fields;
  return (
    <img
      src={url}
      alt={title}
      height={32}
      className={classes(block && cn.block)}
    />
  );
}
