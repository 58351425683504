import { Button, CircularProgress } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import * as React from 'react';
import { style } from 'typestyle';

export type LoadingButtonProps = ButtonProps & {
  loading: boolean;
};

const cn = {
  progress: style({
    marginRight: 4
  })
};

export const LoadingButton = React.forwardRef<
  HTMLButtonElement,
  LoadingButtonProps
>((props, ref) => {
  const { loading, children, ...buttonProps } = props;

  return (
    <Button
      {...buttonProps}
      disabled={loading || buttonProps.disabled}
      ref={ref}
    >
      {loading ? <CircularProgress size={22} className={cn.progress} /> : null}
      {children}
    </Button>
  );
});
