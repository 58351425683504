import IconButton from '@material-ui/core/IconButton';
import { Theme, withTheme } from '@material-ui/core/styles';
import { Computer } from '@material-ui/icons';
import * as React from 'react';
import { style } from 'typestyle';

export interface DesktopFileProps {
  theme: Theme;
  onOpen: (files: FileList) => void;
}

const cn = {
  input: style({
    display: 'none'
  })
};
const getCn = (props: DesktopFileProps) => ({
  icon: style({
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRadius: 2,
    borderTopWidth: props.theme.spacing(),
    backgroundColor: props.theme.palette.grey['500']
  })
});

class DesktopFile extends React.PureComponent<DesktopFileProps> {
  handleChange = ({
    target: { files }
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (files) {
      this.props.onOpen(files);
    }
  };

  input = React.createRef<HTMLInputElement>();

  render() {
    const dcn = getCn(this.props);

    return (
      <div>
        <input
          multiple
          type="file"
          className={cn.input}
          ref={this.input}
          onChange={this.handleChange}
        />
        <IconButton
          className={dcn.icon}
          onClick={() => this.input.current && this.input.current.click()}
        >
          <Computer />
        </IconButton>
      </div>
    );
  }
}

export default withTheme(DesktopFile);
