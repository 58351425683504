export function useOpenAIStream(action: string) {
  const fetchStream = async (
    params: object,
    callback: (text: string) => void
  ) => {
    const endpoint = process.env.REACT_APP_CLOUDRUN_ENDPOINT;
    const url = `${endpoint}/openAI?`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        action,
        ...params
      })
    });
    const reader = response.body?.getReader();
    if (!reader) return;

    let decoder = new TextDecoder();
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      if (!value) continue;
      const text = decoder.decode(value);
      callback(text);
    }
  };

  return fetchStream;
}
