import { Button } from '@material-ui/core';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import CodeMirror from 'codemirror';
import 'codemirror/mode/meta';
import * as React from 'react';
import { style } from 'typestyle';
import { actions } from '../../../ducks/file';
import ImmutableFile from '../File/ImmutableFile';
import { useActionDispatcher } from '../hooks/useActionDispatcher';
import { Localization } from '../localization';

export interface AddDialogProps extends DialogProps {
  localization: Localization;
  onClose: () => void;
}

const getSeed = (type: string) => {
  if (type === 'application/json') {
    return '{}';
  }
  return '\n'.repeat(30);
};

const cn = {
  button: style({
    marginRight: 20
  })
};

export default class AddDialog extends React.Component<AddDialogProps> {
  static defaultProps = {
    onClose: () => {}
  };

  state = {
    name: ''
  };

  handleUpdateName: TextFieldProps['onChange'] = event => {
    this.setState({ name: event.target.value });
  };

  render() {
    const { localization, ...props } = this.props;

    return (
      <Dialog {...props}>
        <DialogTitle>{localization.addDialog.title}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            value={this.state.name}
            label={localization.addDialog.fileName}
            placeholder="main.js"
            onChange={this.handleUpdateName}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={props.onClose}>
            {localization.addDialog.cancel}
          </Button>
          <AddButton name={this.state.name} onClick={props.onClose}>
            {this.props.localization.addDialog.add}
          </AddButton>
        </DialogActions>
      </Dialog>
    );
  }
}

function AddButton(props: {
  name: string;
  onClick?: () => void;
  children: React.ReactNode;
}) {
  const putFile = useActionDispatcher(actions.putFile);

  const onClick = React.useCallback(() => {
    const { mime } = CodeMirror.findModeByFileName(props.name) || {
      mime: 'text/plain'
    };

    const file = new ImmutableFile({
      name: props.name,
      type: mime,
      text: getSeed(mime)
    });
    putFile({ file, isUserAction: true });

    props.onClick && props.onClick();
  }, [props.onClick]);

  return (
    <Button
      variant="contained"
      color="primary"
      className={cn.button}
      onClick={onClick}
    >
      {props.children}
    </Button>
  );
}
