import { makeStyles } from '@material-ui/core';
import * as React from 'react';

interface TextWithURLProps {
  children: string;
}

/**
 * テキストの中に URL が含まれている場合、それを Anchor にする
 * ひとつも含まれていない場合は文字列をそのまま返す
 */
export function TextWithURL(props: TextWithURLProps) {
  return React.useMemo(() => {
    const splited = splitText(props.children);

    return (
      <>
        {splited.map((text, i) =>
          i % 2 === 0 ? (
            text
          ) : (
            <a
              href={text}
              target="_blank"
              referrerPolicy="same-origin"
              rel="noopener"
            >
              {text}
            </a>
          )
        )}
      </>
    );
  }, [props.children]);
}

/**
 * [plain, url, plain, url ...] という風に分割する
 * index が偶数のところは plain, 奇数のところは url になる
 */
function splitText(text: string) {
  const items: string[] = [];
  const reg = new RegExp(location.origin + '[!-~]*');

  for (let res = 0; res < 100; res++) {
    // 全体で100分割まで
    const result = reg.exec(text);
    if (result) {
      const plain = text.substr(0, result.index); // url が見つかった index までの文字列
      items.push(plain);
      const url = result[0];
      items.push(url);
      text = text.substr(plain.length + url.length); // 格納した文字列を消す
    } else {
      if (text) {
        items.push(text); // 残りのテキストを格納
      }
      break;
    }
  }

  return items;
}
