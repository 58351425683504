import {
  Block,
  Document,
  Inline,
  TopLevelBlock
} from '@contentful/rich-text-types';
import { Grid } from '@material-ui/core';
import * as React from 'react';

export interface GridLayoutProps {
  /**
   * documentToReactComponents で得られる Document ノード
   */
  node: Block | Inline;
  /**
   * documentToReactComponents で得られる ReactNode (の配列)
   */
  children: React.ReactNode;
}

/**
 * 空行を区切りにして、要素をグリッドレイアウトに変換する
 * ２つの空行がグリッドコンテナ、１つの空行がグリッドアイテムの区切りを表す
 * 空行とは、テキストが空のパラグラフである
 */
export function GridLayout({ node, children }: GridLayoutProps) {
  return React.useMemo(() => {
    const error = (message: string) => {
      if (process.env.NODE_ENV !== 'production') {
        throw new Error(message);
      }
      console.warn(message);
      return <>{children}</>;
    };
    if (!isDocument(node)) {
      return error('Only root document node can become GridLayout.');
    }
    if (!Array.isArray(children)) {
      return error('GridLayout needs an array of children.');
    }
    if (node.content.length !== children.length) {
      return error('Nodes length mismatch in GridLayout');
    }

    const gridContainers = Array.from(groupByEmptyLine(node.content, children));

    return <>{gridContainers.map(gridContainer)}</>;
  }, [node]);
}

function gridContainer(children: React.ReactNode[], key: number) {
  const { length } = children;

  if (length === 0) return null;
  else if (length === 1) return children[0]; // Grid にする意味がない

  return (
    <Grid container key={key} spacing={2}>
      {children.map((column, i) => (
        <Grid item key={i} xs={12} sm={Math.ceil(12 / length) as any}>
          {column}
        </Grid>
      ))}
    </Grid>
  );
}

export function isDocument(node: Block | Inline): node is Document {
  return node.nodeType === 'document';
}

/**
 * ２つの連続する空行ごとにパッケージして ReactNode の配列に変換する
 */
export function* groupByEmptyLine<T>(
  content: TopLevelBlock[],
  children: T[]
): Generator<T[][], void, void> {
  let row: T[][] = []; // グリッドコンテナに格納される列アイテム要素の配列
  for (let index = 0; index < content.length; index++) {
    let column: T[] = []; // グリッドアイテムに格納する要素の配列
    for (; index < content.length; index++) {
      // グリッドアイテムの終わりを発見したら、現在の column を確定する
      if (isEmptyLine(content[index])) {
        break;
      }
      column.push(children[index]); // 要素をグリッドアイテムに追加
    }
    // グリッドアイテムをグリッドコンテナに追加
    if (column.length > 0) {
      row.push(column);
    }
    // グリッドコンテナの終わりを発見したら、現在の row を確定する
    const next = index + 1;
    if (next >= content.length || isEmptyLine(content[next])) {
      yield row;
      // グリッドコンテナとグリッドコンテナの間に <p> を挿入する
      if (next < content.length) {
        const p = children[next];
        yield [[p]];
      }
      // 次のグリッドコンテナへ
      index++;
      row = [];
    }
  }
}

function isEmptyLine(node: TopLevelBlock) {
  return (
    node.nodeType === 'paragraph' &&
    node.content.length === 1 &&
    node.content[0].nodeType === 'text' &&
    node.content[0].value === ''
  );
}
