export interface ITab {
  text: string;
  to: string;
  authUserOnly?: true;
}

export interface ISiteMap {
  path: string;
  text: string;
  tabs?: ITab[];
  searchTo?: string;
  backTo?: string;
}

export const searchBarInfo: ISiteMap[] = [
  {
    path: '/contents/kit',
    text: 'ステージを作る'
  },
  {
    path: '/contents/:tab?',
    text: '',
    tabs: [
      {
        text: 'あそびかた',
        to: '/contents/tutorial'
      },
      {
        text: 'YouTube',
        to: '/contents/youtube'
      }
    ]
  },
  {
    path: '/lists/search/:query?',
    searchTo: '/lists/search/:query',
    text: '検索',
    backTo: '/lists'
  },
  {
    path: '/lists/:tab?',
    searchTo: '/lists/search/:query',
    text: 'みんなのステージ'
  },
  {
    path: '/users/:id/edit',
    text: 'プロフィールを編集'
  },
  {
    path: '/users/:id/:tab?/:query?',
    text: '',
    tabs: [
      {
        text: 'ステージ',
        to: '/users/:id'
      },
      {
        text: 'マップ',
        to: '/users/:id/maps',
        authUserOnly: true
      },
      {
        text: 'ゴミ箱',
        to: '/users/:id/trash',
        authUserOnly: true
      },
      {
        text: 'プラン',
        to: '/plans',
        authUserOnly: true
      },
      {
        text: 'お支払い',
        to: '/payments',
        authUserOnly: true
      },
      {
        text: 'お知らせ',
        to: '/notifications',
        authUserOnly: true
      },
      {
        text: 'せってい',
        to: '/preferences',
        authUserOnly: true
      } /*,
      {
        text: 'お気に入り',
        to: '/users/:id/likes'
      },
      {
        text: 'フォロー',
        to: '/users/:id/following'
      },
      {
        text: 'フォロワー',
        to: '/users/:id/followers'
      }*/
    ]
  },
  {
    path: '/(plans|payments|notifications|preferences)',
    text: '',
    tabs: [
      {
        text: 'ステージ',
        to: '/authUser'
      },
      {
        text: 'マップ',
        to: '/authUser/maps'
      },
      {
        text: 'ゴミ箱',
        to: '/authUser/trash'
      },
      {
        text: 'プラン',
        to: '/plans'
      },
      {
        text: 'お支払い',
        to: '/payments'
      },
      {
        text: 'お知らせ',
        to: '/notifications'
      },
      {
        text: 'せってい',
        to: '/preferences'
      }
    ]
  },
  {
    path: '/histories/:tab?',
    text: 'りれき',
    tabs: [
      {
        text: 'あそんだステージ',
        to: '/histories'
      }
    ]
  },
  {
    path: '/generative-ai/:tab?',
    text: '生成AI',
    tabs: [
      {
        text: 'ストーリー',
        to: '/generative-ai/story'
      },
      {
        text: 'ゲームアイデア',
        to: '/generative-ai/idea'
      }
    ]
  }
];

export interface IOptionalHeaderInfo {
  path: string;
  headline: string;
  caption: string;
}

export const optionalHeaderInfo: IOptionalHeaderInfo[] = [
  {
    path: '/contents/tutorial',
    headline: 'ハックフォープレイのあそびかた',
    caption:
      'まずは下の「ゲームスタート」をクリックして、あそびかたをマスターしよう'
  },
  {
    path: '/contents/youtube',
    headline: 'HackforPlay の使い方を YouTube でしらべよう',
    caption: 'YouTube を見て、きほんの使い方をマスターしよう'
  },
  {
    path: '/lists',
    headline: 'おもしろいステージをさがそう',
    caption:
      'ここにあるのはすべて子どもたちが作ったステージ。たくさん遊んで好きなステージをさがしてみよう。きみの作品のヒントになるかもよ'
  }
];
