import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { NeedSignIn } from './SignInDialog';

export function AuthUserRedirect() {
  const location = useLocation();
  const userInfo = useSelector(state => state.auth.userInfo);

  const destination = location.pathname.split('/').slice(2).join('/');

  const uid = userInfo && userInfo.uid;
  if (!uid) {
    return <NeedSignIn />;
  }

  return (
    <Redirect
      to={`/users/${uid}/${destination}${location.search}${location.hash}`}
    />
  );
}
