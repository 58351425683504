import { createClient } from 'contentful';

let client: ReturnType<typeof createClient>;
export function getContentfulClient() {
  if (!client) {
    client = createClient({
      accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN || '',
      space: process.env.REACT_APP_CONTENTFUL_SPACE || ''
    });
  }
  return client;
}
