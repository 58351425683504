import firebase from '../settings/firebase';

const auth = firebase.auth;

export type Body = Record<string, string> | Blob;

export class NotSignInError extends Error {
  constructor() {
    super('Please sign in');
    this.name = 'NotSignInError';
  }
}

export async function requestWithAuth<Result = any>(
  endpoint: string,
  method = 'GET',
  body?: Body
): Promise<Result> {
  const user = auth().currentUser;
  if (!user) throw new NotSignInError();
  const token = await user.getIdToken(true);
  const response = await fetch(endpoint, {
    method,
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: body
      ? body instanceof Blob
        ? body
        : new URLSearchParams(body)
      : undefined
  });
  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }
  const text = await response.text();
  try {
    return JSON.parse(text);
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(text);
    }
    throw new Error('Parse Error');
  }
}
