import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { style } from 'typestyle';
import { separate } from '../../File';
import ImmutableFile from '../../File/ImmutableFile';

export interface FilenameProps {
  file: ImmutableFile;
  onChange: (file: ImmutableFile, value: string) => void;
}

const cn = {
  root: style({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline'
  }),
  textField: style({
    width: 'auto',
    flex: '0 1 auto',
    height: 40
  })
};

export default class Filename extends React.PureComponent<FilenameProps> {
  state = {
    isEditing: false
  };

  input = React.createRef<HTMLInputElement>();

  componentDidMount() {
    const { file, onChange } = this.props;

    if (!this.input.current) return;
    this.input.current.onchange = event => {
      if (!event.currentTarget) return;
      let target = event.currentTarget as HTMLInputElement;
      onChange(file, target.value);
      this.setState({ isEditing: false });
    };
    this.input.current.onblur = () => {
      this.setState({ isEditing: false });
    };
  }

  touchFlag = false;
  handleDoubleTap = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();

    if (this.touchFlag) {
      this.setState({ isEditing: true });
      return;
    }
    this.touchFlag = true;
    setTimeout(() => (this.touchFlag = false), 200);
  };

  handleTextFieldTap = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  render() {
    const { file } = this.props;
    const { isEditing } = this.state;

    const { path, plain, ext, name } = separate(file.name);

    return (
      <div className={cn.root}>
        <Typography color="textSecondary">{path}</Typography>
        {isEditing ? (
          <TextField
            id={name}
            defaultValue={plain}
            ref={this.input}
            className={cn.textField}
            onClick={this.handleTextFieldTap}
          />
        ) : (
          <Typography color="textPrimary" onClick={this.handleDoubleTap}>
            {plain}
          </Typography>
        )}
        <Typography color="textSecondary" variant="caption">
          {ext}
        </Typography>
      </div>
    );
  }
}
