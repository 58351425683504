import {
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import classNames from 'classnames';
import { groupBy, mapValues, uniqBy } from 'lodash-es';
import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { actions } from '../ducks/actions';
import { fromNow } from '../utils/fromNow';
import { notFoundImage } from '../utils/urls';
import { container, loadAnimation } from '../utils/xlasses';
import { LoadingButton } from './LoadingButton';
import { ResponsiveEmbed } from './ResponsiveEmbed';
import { UserNameLink } from './UserNameLink';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing() * 2,
    marginBottom: theme.spacing() * 6
  },
  divider: {
    marginTop: theme.spacing() * 2,
    marginBottom: theme.spacing()
  },
  button: {
    marginTop: theme.spacing() * 2,
    marginBottom: theme.spacing() * 2,
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

export function History() {
  const cn = useStyles();

  const initialized = useSelector(state => state.auth.initialized);
  const pagination = useSelector(state => state.history.pagination);
  const isFetching = useSelector(state => state.history.isFetching);
  const afterPageLoaded = useSelector(state => state.history.afterPageLoaded);

  const dispatch = useDispatch();
  // 履歴を取得する　（ペジネーション）
  const fetchMoreIfNeeded = React.useCallback(() => {
    if (isFetching) return;
    dispatch(actions.history.fetchMore.started());
  }, [isFetching]);

  // 最初の履歴を取得
  React.useEffect(() => {
    if (!initialized) return; // ログイン中
    if (pagination.data.length > 0) return; // 既にデータがある
    fetchMoreIfNeeded();
  }, [initialized]);

  const now = new Date();
  const groupByDate = React.useMemo(() => {
    const all = afterPageLoaded.concat(pagination.data);
    // createdBy の日付でグルーピングする
    const grouped = groupBy(all, history => {
      const date = history.createdAt.toDate();
      if (
        date.getDate() === now.getDate() &&
        date.getMonth() === now.getMonth() &&
        date.getFullYear() === now.getFullYear()
      ) {
        return '今日';
      }
      if (date.getFullYear() === now.getFullYear()) {
        return moment(date).format('MM月DD日');
      }
      return moment(date).format('YYYY年MM月DD日');
    });
    // 同じステージのデータは省く
    return mapValues(grouped, items => uniqBy(items, item => item.workId));
  }, [pagination.data, afterPageLoaded]);

  return (
    <Paper elevation={1} className={classNames(container.large, cn.root)}>
      {Object.keys(groupByDate).map((date, groupIndex) => (
        <React.Fragment key={groupIndex}>
          {groupIndex > 0 ? <Divider className={cn.divider} /> : null}
          <Typography variant="body1" gutterBottom>
            {date}
          </Typography>
          <Grid container spacing={1}>
            {groupByDate[date].map((item, index) => (
              <Grid key={index} item xs={12} sm={4} md={3}>
                <HistoryItem key={index} workId={item.workId} />
              </Grid>
            ))}
          </Grid>
        </React.Fragment>
      ))}
      {pagination.mayHasMore ? (
        <LoadingButton
          variant="contained"
          color="primary"
          loading={isFetching}
          onClick={fetchMoreIfNeeded}
          className={cn.button}
        >
          もっとみる
        </LoadingButton>
      ) : null}
    </Paper>
  );
}

interface HistoryItemProps {
  workId: string;
}

function HistoryItem(props: HistoryItemProps) {
  const work = useSelector(state => state.work.works[props.workId]);
  const data = work?.data;

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(actions.work.fetchIfNeeded(props.workId));
  }, [props.workId]);

  const publishedAt = fromNow(data?.publishedAt);

  return (
    <div>
      <Link to={`works/${props.workId}`}>
        <ResponsiveEmbed verticalPerHorizontal={2 / 3}>
          <img
            src={data ? data.thumbnailUrl || notFoundImage : ''}
            alt={data?.title}
            className={data ? undefined : loadAnimation.glimmering}
          />
        </ResponsiveEmbed>
      </Link>
      <Link to={`works/${props.workId}`}>
        <Typography variant="body1">{data?.title || ''}</Typography>
      </Link>
      <UserNameLink uid={data?.uid} />
      {data ? (
        <Typography variant="caption">
          {publishedAt ? `${publishedAt} に公開・` : ''}
          {
            {
              public: '公開中',
              limited: '限定公開',
              private: '非公開'
            }[data.visibility]
          }
          {data.clearRate
            ? `・${Math.floor(data.clearRate * 100)}%の人がクリア`
            : ''}
        </Typography>
      ) : null}
    </div>
  );
}
