import { debounce } from 'lodash-es';
import React, { useEffect } from 'react';

interface DraggableProps {
  children: React.ReactNode;
}

export function Draggable(props: DraggableProps) {
  const chatRef = React.useRef<HTMLDivElement>(null);
  const isDragging = React.useRef(false);

  const getSize = () => {
    const rect = chatRef.current?.getBoundingClientRect();
    if (!rect) {
      return { width: 0, height: 0 };
    }
    return { width: rect.width, height: rect.height };
  };

  const getPosition = () => {
    const rect = chatRef.current?.getBoundingClientRect();
    if (!rect) return undefined;
    return { x: rect.left, y: rect.top };
  };
  const setPosition = (pos: { x: number; y: number }) => {
    chatRef.current?.style.setProperty('top', `${pos.y}px`);
    chatRef.current?.style.setProperty('left', `${pos.x}px`);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (chatRef.current) {
      isDragging.current = true;
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging.current) {
      const pos = getPosition();
      if (pos) {
        setPosition({ x: pos.x + e.movementX, y: pos.y + e.movementY });
      }
    }
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  const fitWithinWindow = () => {
    const { width, height } = getSize();
    const pos = getPosition();
    if (pos) {
      setPosition({
        x: Math.min(window.innerWidth - width, pos.x),
        y: Math.min(window.innerHeight - height, pos.y)
      });
    }
  };
  useEffect(fitWithinWindow);

  React.useEffect(() => {
    // 初期位置は画面右下 (サイズは仮)
    setPosition({
      x: window.innerWidth - 100 - 16,
      y: window.innerHeight - 100 - 16
    });

    document.addEventListener('pointermove', handleMouseMove);
    document.addEventListener('pointerup', handleMouseUp);
    const handleResize = debounce(fitWithinWindow, 1000);
    window.addEventListener('resize', handleResize);
    return () => {
      document.removeEventListener('pointermove', handleMouseMove);
      document.removeEventListener('pointerup', handleMouseUp);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      ref={chatRef}
      onPointerDown={handleMouseDown}
      style={{
        touchAction: 'none',
        position: 'fixed',
        cursor: isDragging.current ? 'grabbing' : 'grab',
        zIndex: 10000,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {props.children}
    </div>
  );
}
