// Reducer and Epics of files
import { Definition } from '@hackforplay/common/src/definition';
import { differenceBy } from 'lodash-es';
import { combineEpics } from 'redux-observable';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import actionCreatorFactory from 'typescript-fsa';
import ImmutableFile from '../ide/src/File/ImmutableFile';
import { detectLibaryVersion } from '../utils/detectLibaryVersion';
import { filterTruly } from '../utils/filterTruly';
import { idToPath } from '../utils/id';
import { commonFromCdn } from '../utils/urls';
import { actions as _actions } from './actions';
import { asyncEpic } from './asyncEpic';
import { reducerWithImmer } from './reducer-with-immer';

export type State = {
  initialized: boolean;
  /**
   * @deprecated
   * 編集中の id は Component に保持し、
   * Action の payload に id を含めるようにする
   */
  path?: string;
  files: ImmutableFile[];
  changedByUser: number;
  /**
   * ファイルに書き込まれていない変更がエディタに含まれているかどうかのフラグ
   */
  localChange: boolean;
  /**
   * @hackforplay/common のバージョン
   */
  libraryVersion?: string;
  /**
   *  @hackforplay/common に含まれるシノニムの辞書
   */
  definition?: Definition;
};

export const initialState: State = {
  initialized: false,
  files: [],
  changedByUser: 0,
  localChange: false
};

const ac = actionCreatorFactory('ide/file');

interface IFilePayload {
  file: ImmutableFile;
  isUserAction: boolean;
}

interface IFilesPayload {
  files: ImmutableFile[];
  isUserAction: boolean;
}

export const actions = {
  putFile: ac<IFilePayload>('ide/PUT_FILE'),
  putFiles: ac<IFilesPayload>('ide/PUTS_FILES'),
  deleteFiles: ac<IFilesPayload>('ide/DELETE_FILES'),
  setLocalChange: ac<boolean>('ide/SET_LOCAL_CHANGE'),
  /**
   * バージョン番号を元に CDN から定義ファイルを取得する
   */
  fetchDefinition: ac.async<string, Definition | undefined>(
    'ide/FETCH_DEFINITION'
  )
};

export const epics = combineEpics(
  // ファイルの中身を調べてライブラリのバージョンを取得、現在の値と違っていればセットする
  (action$, state$) =>
    state$.pipe(
      map(state => state.file.files),
      distinctUntilChanged(),
      map(files => files.reduce(detectLibaryVersion, undefined)),
      filterTruly,
      filter(version => version !== state$.value.file.libraryVersion),
      map(version => actions.fetchDefinition.started(version))
    ),

  // 新しいライブラリの定義ファイルを CDN から取得する
  // 古いバージョンでは定義ファイルが存在しないので 404 で失敗する。0.30 から有効
  asyncEpic(actions.fetchDefinition, async action => {
    const url = `${commonFromCdn}/${action.payload}/ja/definition.json`;
    const response = await fetch(url);
    return response.ok ? await response.json() : undefined;
  })
);

export default reducerWithImmer(initialState)
  .case(actions.putFile, (draft, payload) => {
    if (!draft.initialized) return;
    const index = draft.files.findIndex(
      file => file.name === payload.file.name
    );
    if (index > -1) {
      draft.files[index] = payload.file;
    } else {
      draft.files.push(payload.file);
    }
    if (payload.isUserAction) {
      draft.changedByUser++;
    }
  })
  .case(actions.putFiles, (draft, payload) => {
    if (!draft.initialized) return;
    for (const item of payload.files) {
      const index = draft.files.findIndex(file => file.name === item.name);
      if (index > -1) {
        draft.files[index] = item;
      } else {
        draft.files.push(item);
      }
    }
    if (payload.isUserAction) {
      draft.changedByUser++;
    }
  })
  .case(actions.deleteFiles, (draft, payload) => {
    if (!draft.initialized) return;
    draft.files = differenceBy(draft.files, payload.files, 'id');
    if (payload.isUserAction) {
      draft.changedByUser++;
    }
  })
  .case(_actions.work.fetch.done, (draft, { params, result }) => {
    // 自分のステージであれば過去のセーブ回数をロードする（他人のステージであれば 0 のまま）
    if (!params.loadAfterFetch) return;
    // これから load される work の情報をセット
    const { work, userInfo } = result;
    const savedCount = result.work?.savedCount;
    const isOwner = Boolean(userInfo && userInfo.uid === work?.uid);
    if (savedCount && isOwner) {
      draft.changedByUser = savedCount; // 保存されている savedCount をロードする
    }
  })
  .case(_actions.make.load.started, (draft, params) => {
    draft.path = idToPath('works', params.id);
  })
  .case(_actions.make.load.done, (draft, { params, result }) => {
    if (draft.path !== idToPath('works', params.id)) return;
    draft.initialized = true;
    draft.path = idToPath('works', params.id);
    draft.files = ImmutableFile.fromSeeds(result);
  })
  .case(actions.fetchDefinition.started, (draft, payload) => {
    draft.libraryVersion = payload;
  })
  .case(actions.fetchDefinition.done, (draft, payload) => {
    draft.libraryVersion = payload.params;
    draft.definition = payload.result;
  })
  .case(actions.setLocalChange, (draft, payload) => {
    draft.localChange = payload;
  })
  .reset(_actions.make.trash)
  .toReducer();
