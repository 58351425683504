import { Pos } from 'codemirror';
import * as React from 'react';

/**
 * アプリケーション内で共有したい
 * 本来なら Context を使うべきだが、実質シングルトンなのでローカル変数とする
 */
const varNamesSingleton: string[] = [];

/**
 * globals (へんすう) のブラケットの中の文字列の
 * hint (入力補完) を行う関数を得るための React Hook
 */
export function useGlobalsHint() {
  const handleRef = React.useRef(0);
  return React.useMemo(() => {
    // 協調的マルチスレッドによって UI が固まるのを防ぐ
    const hint: CodeMirror.AsyncHintFunction = (cm, callback) => {
      window.cancelIdleCallback(handleRef.current);
      handleRef.current = window.requestIdleCallback(
        () => {
          getHints(cm, varNamesSingleton.slice(), callback);
        },
        { timeout: 1000 }
      );
    };
    hint.async = true;

    return hint;
  }, []);
}

export function useSetGlobals() {
  return React.useCallback((value: string[]) => {
    varNamesSingleton.splice(0, varNamesSingleton.length, ...value);
  }, []);
}

function getHints(
  cm: CodeMirror.Editor,
  varNames: string[],
  callback: (hints: CodeMirror.Hints) => void
) {
  const cursor = cm.getCursor();
  const token = cm.getTokenAt(cursor);
  const result: CodeMirror.Hints = {
    from: cursor,
    to: cursor,
    list: []
  };

  const next = cm.getTokenAt(new Pos(cursor.line, token.end + 1));
  const to = new Pos(cursor.line, next.end);

  result.list = varNames.map<CodeMirror.Hint>(varName => ({
    text: varName,
    from: cursor,
    to
  }));

  callback(result);
}
