import { extension } from 'mime-types';
import { StateObservable } from 'redux-observable';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { Action } from 'typescript-fsa';
import { parseTimestamp } from '../utils/parseTimestamp';
import { actions } from './actions';
import { StoreState } from './type';
import { ofAction } from './typescript-fsa-redux-observable';

export function compareTimestamps<T>(map: (item: T) => TS) {
  return (a: T, b: T) => compareTimestampsImpl(map(a), map(b));
}

/**
 * タイムスタンプを比較する (ただし null がもっとも新しい)
 * @param a こちらのタイムスタンプが新しければ -1
 * @param b こちらのタイムスタンプが新しければ 1
 */
function compareTimestampsImpl(a: TS, b: TS) {
  return Math.sign(parseTimestamp(b, Infinity) - parseTimestamp(a, Infinity));
}

export const opt = <T>(source: T, given: T | undefined): T =>
  given === undefined ? source : given;

type A$ = Observable<Action<any>>;
type S$ = StateObservable<StoreState>;
/**
 * サインインユーザーに対して行う操作を記述するためのラッパー
 * @param operator userInfo に紐づいた Action Stream を処理するオペレータ
 */
export const eachUser =
  (
    operator: (
      userInfo: firebase.default.UserInfo,
      action$: A$,
      store$: S$
    ) => A$
  ) =>
  (action$: A$, store$: S$) =>
    action$.pipe(
      ofAction(actions.auth.signedIn),
      mergeMap(action =>
        operator(action.payload, action$, store$).pipe(
          takeUntil(action$.pipe(ofAction(actions.auth.signOut)))
        )
      )
    );

const isProduction = process.env.NODE_ENV === 'production';
export const productionOnly: MonoTypeOperatorFunction<any> = filter(
  () => isProduction
);

/**
 * Base64 encoded string を Blob に変換する
 */
export function toBlob(dataURL: string) {
  const [param, base64] = dataURL.split(',');
  const result = /^data:(.*);base64$/i.exec(param); // e.g. data:image/jpeg;base64
  if (!result) {
    throw new Error(`Invalid Data URL: ${param},...`);
  }
  const type = result[1];
  const ext = extension(type);
  if (!base64 || !type || !ext) {
    throw new Error(`Invalid Data URL: ${param},...`);
  }

  const binStr = atob(base64);
  const bytes = new Uint8Array(binStr.length);
  for (let i = binStr.length - 1; i >= 0; i--) {
    bytes[i] = binStr.charCodeAt(i);
  }
  return new Blob([bytes.buffer], { type });
}
