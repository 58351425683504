import { Block, Inline } from '@contentful/rich-text-types';
import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

export interface HyperLinkProps {
  node: Block | Inline;
  children: React.ReactNode;
  variant?: ButtonProps['variant'];
  color?: ButtonProps['color'];
}

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 'inherit',
    textTransform: 'inherit'
  }
}));

export function HyperLink({ node, children, ...props }: HyperLinkProps) {
  const classes = useStyles();

  const isSameOrigin = React.useMemo(() => {
    const a = document.createElement('a');
    a.href = node.data.uri;
    return a.origin === window.location.origin;
  }, [node]);

  // Same domain なら History API で移動する
  const history = useHistory();
  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      try {
        const url = new URL(node.data.uri);
        history.push(url.pathname + url.search + url.hash);
      } catch (error) {
        console.warn(error);
        location.href = node.data.uri;
      }
    },
    []
  );

  return (
    <Button
      color="primary"
      href={node.data.uri || ''}
      target={isSameOrigin ? undefined : '_blank'}
      rel={isSameOrigin ? undefined : 'noopener'}
      className={classes.root}
      onClick={isSameOrigin ? onClick : undefined}
      startIcon={isSameOrigin ? null : <OpenInNew />}
      {...props}
    >
      {children}
    </Button>
  );
}
