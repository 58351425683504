import { AppBar, Tab, Tabs, Toolbar } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { EntryCollection } from 'contentful';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { style } from 'typestyle';
import { Fields } from '../pages/Agreement';
import { getContentfulClient } from '../pages/getContentfulClient';

const cn = {
  toolbar: style({
    minHeight: 48, // 上下のマージンをなくす
    backgroundColor: grey[50]
  }),
  tabs: style({
    flexGrow: 999
  })
};

type Params = {
  tab: string;
};

export function AgreementsNavBar() {
  const { tab } = useParams<Params>();
  const [agreements, setAgreements] = React.useState<EntryCollection<Fields>>();

  React.useEffect(() => {
    getContentfulClient()
      .getEntries<Fields>({
        content_type: 'agreement',
        order: 'sys.createdAt'
      })
      .then(setAgreements, console.error);
  }, []);

  const history = useHistory();
  const handleChangeTab = React.useCallback((event: any, value: any) => {
    history.push(`./${value}`);
  }, []);

  if (!agreements) {
    // TODO: アニメーション
    return null;
  }

  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar className={cn.toolbar}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          className={cn.tabs}
        >
          {agreements.items.map(entry => (
            <Tab
              key={entry.fields.slug}
              label={entry.fields.label}
              value={entry.fields.slug}
            />
          ))}
        </Tabs>
      </Toolbar>
    </AppBar>
  );
}
