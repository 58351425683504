import { includes } from 'lodash-es';
import ImmutableFile from '../../File/ImmutableFile';

export interface IOptions {
  showInvisibles: boolean;
  showTrashes: boolean;
}

export default function search(
  file: ImmutableFile,
  query: string,
  options: null | IOptions = null
) {
  options = options || getOptions(query);

  if (options.showInvisibles === !!file.moduleName) {
    return false;
  }
  if (options.showTrashes !== file.options.isTrashed) {
    return false;
  }

  return query.split(/\s+/).every(keyword => {
    if (!keyword) {
      return true;
    }
    if (keyword[0] === ':') {
      return true;
    }

    return includes(file.name, keyword);
  });
}

const defaultOption: IOptions = {
  showInvisibles: false,
  showTrashes: false
};

export const getOptions = (query: string) =>
  query.split(/\s+/).reduce((p: IOptions, keyword) => {
    return {
      showInvisibles: p.showInvisibles || keyword[0] === '.',
      showTrashes: p.showTrashes || keyword === ':trash'
    };
  }, defaultOption);
