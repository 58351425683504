import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import Paper from '@material-ui/core/Paper';
import { style } from 'typestyle';
import { actions } from '../ducks/actions';
import { fromPaginate } from '../ducks/helpers';
import theme from '../settings/theme';
import { MapList } from './MapList';
import { TrashList } from './TrashList';
import { WorkList } from './WorkList';

const cn = {
  root: style({
    margin: theme.spacing(4)
  })
};

type Params = {
  id: string;
  tab?: string;
};

export function UserWorks() {
  const { id, tab } = useParams<Params>(); // 現在の URL に対して適切なデータを表示

  const users = useSelector(state => state.work.users[id]);
  const canFetchMore = Boolean(!users?.isFetching && users?.mayHasMore);

  const dispatch = useDispatch();
  const fetchMore = React.useCallback(() => {
    dispatch(actions.work.fetchUsers.started({ uid: id }));
  }, [id]);

  const authUser = useSelector(state => state.auth.userInfo);

  React.useEffect(fetchMore, [id, authUser]);
  return (
    <>
      {!tab ? (
        <WorkList
          id="users"
          workIds={fromPaginate(users)}
          title="ステージ"
          more
          onClickMore={fetchMore}
          canFetchMore={canFetchMore}
          showVisibility
          className={cn.root}
          getTimestamp={
            authUser?.uid === id
              ? work => work.updatedAt // 自分のステージ
              : work => work.publishedAt // 他人のステージ
          }
        />
      ) : tab === 'maps' ? (
        <MapList
          title="マップ"
          more
          moreLink={`/users/${id}/maps`}
          showVisibility
          className={cn.root}
        />
      ) : tab === 'trash' ? (
        <TrashList />
      ) : /* tab === 'likes' ? (
        <WorkList
          works={}
          title="お気に入り"
          moreLink={`/users/${id}/likes`}
        />
      ) : tab ===
      'following' ? (
        <Paper elevation={2}>フォロー</Paper>
      ) : tab === 'followers' ? (
        <Paper elevation={2}>フォロワー</Paper>
      ) :*/ null}
    </>
  );
}
