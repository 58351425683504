import { Document } from '@contentful/rich-text-types';
import { Paper } from '@material-ui/core';
import { Entry } from 'contentful';
import * as React from 'react';
import { style } from 'typestyle';
import { renderEntry } from './renderers';

export interface Fields {
  slug: string;
  label: string;
  body: Document;
}

export interface AgreementProps {
  entry: Entry<Fields>;
}

export function isAgreement(entry: Entry<unknown>): entry is Entry<Fields> {
  return entry.sys.contentType.sys.id === 'agreement';
}

const cn = {
  root: style({
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    padding: 32,
    margin: 32
  })
};

export function Agreement({ entry }: AgreementProps) {
  React.useEffect(() => {
    window.scrollTo(0, 0); // scroll to top
  }, []);

  return (
    <Paper className={cn.root} elevation={2}>
      {renderEntry(entry.fields.body)}
    </Paper>
  );
}
