import {
  Box,
  Button,
  Container,
  LinearProgress,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import { ExpandLess, ExpandMore, GetApp } from '@material-ui/icons';
import React, { useState } from 'react';
import { style } from 'typestyle';
import { useOpenAIStream } from '../../hooks/useOpenAIStream';
import { analytics } from '../../utils/analytics';

const cn = {
  container: style({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 32,
    paddingBottom: 128
  }),
  card: style({
    backgroundColor: '#fff'
  }),
  result: style({
    alignSelf: 'stretch'
  }),
  paper: style({
    minWidth: '100%',
    whiteSpace: 'pre-wrap',
    padding: '8px 16px',
    lineHeight: 1.5,
    display: 'flex',
    flexDirection: 'column',
    gap: 16
  }),
  manualButton: style({
    flexShrink: 0
  })
};

export function Idea() {
  const [theme, setTheme] = useState('宇宙');
  const fetchGenerateStoryIdea = useOpenAIStream('generate_game_idea');

  const [disabled, setDisabled] = useState(false);

  const [fixedIdeas, setIdeas] = useState<string[]>([]);
  const [writingIdeas, setWritingIdeas] = useState<string[]>([]);
  const ideas = writingIdeas.concat(fixedIdeas);

  const generateStoryIdea = () => {
    analytics.generateStoryIdea(theme);
    setDisabled(true); // 連打防止

    let buffer = '';
    const toIdeas = () =>
      buffer
        .split('\n')
        .filter(s => s)
        .map(s => s.replace(/^\d\./, '').trim()) // 箇条書きの数字を削除
        .reverse();
    fetchGenerateStoryIdea({ theme }, text => {
      buffer += text;
      setWritingIdeas(toIdeas());
    }).finally(() => {
      setDisabled(false);
      setWritingIdeas([]);
      setIdeas(ideas => toIdeas().concat(ideas));
    });
  };

  const onGenerateDetailStart = () => {
    setDisabled(true); // 連打防止
  };
  const onGenerateDetailEnd = (story: string) => {
    setDisabled(false);
  };

  return (
    <Container className={cn.container} maxWidth="md">
      <Box pt={2} alignItems="center">
        <Typography variant="h4" align="center">
          ゲームのアイデアAI
        </Typography>
        <Typography variant="body1" align="center">
          あなたの好きなゲーム、アニメ、スポーツなどを入力して、ゲームアイデアを生成！
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="baseline"
        gridGap={16}
      >
        <TextField
          variant="outlined"
          multiline
          value={theme}
          onChange={event => setTheme(event.target.value.slice(0, 100))}
          InputProps={{
            className: cn.card
          }}
          helperText="例: サッカー / 宇宙 / 鬼ごっこ"
        />
        <Button
          variant="contained"
          color="secondary"
          disabled={disabled}
          onClick={generateStoryIdea}
        >
          AIのアイデアをみる
        </Button>
      </Box>
      {ideas.length > 0 && (
        <Typography variant="body2" color="textSecondary">
          これらの文章は、AIが生成したものです。
        </Typography>
      )}
      {ideas.map(idea => (
        <DetailCard
          key={idea}
          disabled={disabled}
          idea={idea}
          onGenerateDetailStart={onGenerateDetailStart}
          onGenerateDetailEnd={onGenerateDetailEnd}
        />
      ))}
    </Container>
  );
}

interface DetailCardProps {
  disabled: boolean;
  idea: string;
  onGenerateDetailStart: () => void;
  onGenerateDetailEnd: (story: string) => void;
}

function DetailCard(props: DetailCardProps) {
  const { idea, disabled, onGenerateDetailEnd, onGenerateDetailStart } = props;
  const fetchGenerateDetail = useOpenAIStream('generate_game_detail');
  const [loading, setLoading] = useState(false);
  const [generated, setGenerated] = useState('');
  const [finished, setFinished] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const onClick = async () => {
    if (expanded) {
      setExpanded(false); // 開いていたのを閉じる
      return;
    }
    setExpanded(true);
    if (finished) {
      return; // すでに生成済み
    }

    onGenerateDetailStart();
    setLoading(true);
    analytics.generateStory(idea);
    fetchGenerateDetail({ idea }, text => {
      setGenerated(gen => gen + text);
      setLoading(false);
    }).finally(() => {
      setLoading(false);
      setFinished(true);
      onGenerateDetailEnd(generated);
    });
  };

  return (
    <div className={cn.result}>
      <Paper className={cn.paper}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gridGap={16}
        >
          <Typography variant="h6">{idea}</Typography>
          <Button
            color="secondary"
            disabled={disabled}
            onClick={onClick}
            endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
          >
            くわしく読む
          </Button>
        </Box>
        {loading && !generated && <LinearProgress />}
        {generated && expanded && (
          <Typography variant="body1" gutterBottom>
            {generated}
          </Typography>
        )}
        {generated && expanded && finished && (
          <Button
            color="secondary"
            startIcon={<GetApp />}
            download={`${idea}.txt`}
            href={`data:text/plain;charset=utf-8,${encodeURIComponent(
              idea + '\n-\n' + generated
            )}`}
            style={{ alignSelf: 'flex-start' }}
          >
            ダウンロード
          </Button>
        )}
      </Paper>
    </div>
  );
}
