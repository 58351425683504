import { useRecoilCallback, useRecoilValue } from 'recoil';
import { unreadIdAtom } from '../components/Notification';
import { isUnread } from '../components/NotificationItem';
import { useFirestore } from '../hooks/useFirestore';
import { authUidAtom, notificationAtom, notificationIdsAtom } from '../recoil';

/**
 * フィルタを通過した全ての通知を既読にする
 */
export function useMarkAsRead() {
  const firestore = useFirestore();
  const uid = useRecoilValue(authUidAtom);
  return useRecoilCallback(
    async (
      { getLoadable, set },
      filter?: (content: NotificationDocContent) => boolean
    ) => {
      const idsLoadable = getLoadable(notificationIdsAtom);
      if (idsLoadable.state !== 'hasValue') return; // type hint
      let unreadId: string | undefined = undefined;
      const ids = idsLoadable.contents.filter(id => {
        // 通知をフィルタする
        const loadable = getLoadable(notificationAtom(id));
        if (loadable.state !== 'hasValue') return false;
        if (!loadable.contents) return false;
        if (!isUnread(loadable.contents)) return false; // 既読なので除外
        if (!filter || filter(loadable.contents.content)) {
          return true;
        }
        unreadId = unreadId ?? id; // このお知らせは引き続き「お知らせ」として扱う
        return false;
      });
      if (!ids.length) return;

      const batch = firestore().batch();
      const cols = firestore()
        .collection('users')
        .doc(uid)
        .collection('notifications');
      const readAt = firestore.Timestamp.now();
      for (const id of ids) {
        batch.update(cols.doc(id), { readAt });
      }
      await batch.commit();
      for (const id of ids) {
        set(notificationAtom(id), curr =>
          !curr || !isUnread(curr)
            ? curr
            : {
                ...curr,
                readAt // 未読ならば新たな readAt をセット
              }
        );
      }
      // 未読のお知らせがあれば id をセットする, なければ undefined にする
      set(unreadIdAtom, unreadId);
    },
    [uid]
  );
}
