import { Create, DesktopWindows, FolderOpen, Reply } from '@material-ui/icons';
import * as React from 'react';
import { CardName } from '../Cards/defaultState';

const icons: {
  name: CardName;
  icon: React.ReactElement;
}[] = [
  {
    name: 'MonitorCard',
    icon: <DesktopWindows />
  },
  {
    name: 'ShotCard',
    icon: <Reply />
  },
  {
    name: 'EditorCard',
    icon: <Create />
  },
  {
    name: 'HierarchyCard',
    icon: <FolderOpen />
  }
];

export default icons;
