import * as React from 'react';
import { useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Store } from 'redux';
import { StoreState } from '../ducks';

/**
 * document.title を設定するコンポーネント
 */
export function DocumentTitleProvider() {
  const { pathname } = useLocation();
  const store = useStore<StoreState>(); // state にアクセスしたいが、re-render する必要はない

  React.useEffect(() => {
    if (pathname.startsWith('/users')) {
      return subscribeValue(store, () => {
        const uid = pathname.split('/')[2] || '';
        const displayName = store.getState().user.byUid[uid]?.data?.displayName;
        if (displayName) {
          document.title = `${displayName}のマイページ`;
        }
        return displayName !== undefined;
      });
    } else if (pathname.startsWith('/works')) {
      return subscribeValue(store, () => {
        const workId = pathname.split('/')[2] || '';
        const title = store.getState().work.works[workId]?.data?.title;
        if (title) {
          document.title = title;
        }
        return title !== undefined;
      });
    } else if (pathname.startsWith('/lists')) {
      document.title = 'みんなのステージ';
    } else if (pathname.startsWith('/make')) {
      document.title = 'ステージをつくる';
    } else if (pathname.startsWith('/videos')) {
      document.title = 'TANI の授業動画';
    } else if (pathname.startsWith('/contents')) {
      document.title = 'あそびかた';
    } else if (pathname.startsWith('/pages/fes')) {
      document.title = 'フェス';
    } else if (pathname.startsWith('/notifications')) {
      document.title = 'お知らせ';
    } else {
      document.title = 'ハックフォープレイ';
    }
  }, [pathname]);

  return null;
}

function subscribeValue(
  store: Store<StoreState>,
  /**
   * 値をセットできたら true を返す
   */
  setter: () => boolean
) {
  const success = setter();
  if (success) {
    return () => {};
  } else {
    let unsubscribe = store.subscribe(() => {
      const success = setter();
      if (success) {
        unsubscribe();
      }
    });
    return unsubscribe;
  }
}
