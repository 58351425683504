import { ButtonProps } from '@material-ui/core/Button';
import { LibraryBooks, PlayArrow, VideogameAsset } from '@material-ui/icons';
import * as React from 'react';
import * as xlasses from '../utils/xlasses';

export type ContentType = {
  type: 'youtube' | 'stage';
  title: string;
  author: null;
  description: string;
  image: string;
  url?: string;
  buttons: ButtonProps<'a'>[];
};

export const hackRpg2 =
  process.env.DEPLOY_ENV === 'production'
    ? '/works/E0TFzuJeGoO75Fp1QNU0'
    : '/works/NARLQ3eXkF7IlmL39aUD';

export const contents: Array<{ path: string; items: ContentType[] }> = [
  {
    path: '/contents/tutorial',
    items: [
      {
        type: 'stage',
        title: 'はじまりのぼうけん',
        author: null,
        description: 'コードのまほうを使って さらわれた姫を救う ぼうけんの旅',
        image: require('../resources/hack-rpg-2.jpeg'),
        url: hackRpg2,
        buttons: [
          {
            variant: 'contained',
            color: 'primary',
            className: xlasses.largeButton,
            children: 'ゲームスタート',
            href: hackRpg2,
            startIcon: <PlayArrow />
          }
        ]
      }
    ]
  },
  {
    path: '/contents/youtube',
    items: [
      {
        type: 'youtube',
        title: 'ときかたのヒント',
        author: null,
        description:
          'ゲームがクリアできない・コードの書きかえ方が分からない人は、この動画を観てね',
        image: '',
        url: 'https://player.vimeo.com/video/407945071',
        buttons: [
          {
            children: 'YouTube で見る',
            href: 'https://youtu.be/yxISRs1YG9w',
            target: '_blank'
          }
        ]
      }
    ]
  },
  {
    path: '/contents/kit',
    items: [
      {
        type: 'stage',
        title: 'RPGキット２',
        author: null,
        description: 'ハックフォープレイのステージを自分で作れるキットです',
        image: 'https://i.gyazo.com/9f65b83d52e8e7217538dee09bee365b.png',
        url: '/officials/make-rpg-2',
        buttons: [
          {
            variant: 'contained',
            color: 'primary',
            className: xlasses.largeButton,
            children: 'ステージをつくる',
            href: '/officials/make-rpg-2',
            startIcon: <VideogameAsset />
          }
        ]
      },
      {
        type: 'stage',
        title: 'RPGキットのマップを作ろう！（ベータ版）',
        author: null,
        description: 'マップをデザインしてステージで使うことができます',
        image: 'https://i.gyazo.com/bace7786ec37a46b8ccb673563f2fda9.png',
        url: '/map-editor',
        buttons: [
          {
            variant: 'contained',
            color: 'primary',
            children: 'マップをつくる',
            href: '/map-editor'
          }
        ]
      },
      {
        type: 'stage',
        title: 'よくある質問',
        author: null,
        description: 'ステージづくりに使えるテクニック集',
        image: require('../resources/faq.jpg'),
        url: 'https://www.notion.so/teramotodaiki/190dd152ace548c7a6d6ca11ac478920',
        buttons: [
          {
            variant: 'contained',
            color: 'primary',
            children: 'よくある質問をひらく',
            href: 'https://www.notion.so/teramotodaiki/190dd152ace548c7a6d6ca11ac478920'
          }
        ]
      },
      {
        type: 'stage',
        title: 'ハックフォープレイ チートシート',
        author: null,
        description: 'ハックフォープレイで使える機能の一覧',
        image: require('../resources/cheat-sheet.png'),
        url: 'https://docs.hackforplay.xyz/',
        buttons: [
          {
            variant: 'contained',
            color: 'primary',
            children: 'チートシートをひらく',
            href: 'https://docs.hackforplay.xyz/'
          }
        ]
      },
      {
        type: 'stage',
        title: '旧RPGキット（使わないで！）',
        author: null,
        description:
          '2019年3月まで使われていたキットです。RPGキット２を使ってください',
        image: 'https://i.gyazo.com/3621f6a06944377a461fc8d299a9a70f.png',
        url: '/officials/make-rpg',
        buttons: [
          {
            children: 'ステージをつくる',
            href: '/officials/make-rpg',
            startIcon: <VideogameAsset />
          },
          {
            children: '逆引きリファレンス',
            href: 'https://www.notion.so/teramotodaiki/c0726a68699842b2957446df8a2c31ef?v=d0a4fba213294d66a227ba47e34b4918',
            startIcon: <LibraryBooks />,
            target: '_blank'
          }
        ]
      }
    ]
  }
];
