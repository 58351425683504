// Inspired by https://github.com/m0a/typescript-fsa-redux-observable
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Action, ActionCreator } from 'typescript-fsa';

// ReturnType<ActionObservable<T>['pipe']> === Observable<T>
// なので、actions$ の型は ActionObservable ではなく Observable<Action<Payload>> を使う

export const ofAction =
  <Payload>(actionCreator: ActionCreator<Payload>) =>
  (actions$: Observable<Action<Payload>>) =>
    actions$.pipe(filter(actionCreator.match));

export const ofActions =
  (...actionCreators: ActionCreator<any>[]) =>
  (actions$: Observable<Action<any>>) =>
    actions$.pipe(
      filter(action =>
        actionCreators.some(actionCreator => actionCreator.match(action))
      )
    );

export const ofActionWithPayload =
  <Payload>(actionCreator: ActionCreator<Payload | null>) =>
  (actions$: Observable<Action<Payload>>) =>
    actions$.pipe(
      filter(actionCreator.match),
      filter(action => action.payload !== null)
    ) as Observable<Action<Payload>>;
