import { CircularProgress } from '@material-ui/core';
import { CircularProgressProps } from '@material-ui/core/CircularProgress';
import * as React from 'react';
import { style } from 'typestyle';

export type Props = CircularProgressProps;

const cn = {
  root: style({
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
};

export function LoadingPage(props: CircularProgressProps) {
  return (
    <div className={cn.root}>
      <CircularProgress size={60} color="secondary" {...props} />
    </div>
  );
}
