import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles
} from '@material-ui/core';
import * as React from 'react';
import { analytics } from '../utils/analytics';
import { DialogClose } from './DialogClose';
import { UserNameLink } from './UserNameLink';

export interface NotificationStagePlayMoreProps {
  title: string;
  players: string[];
}

const useStyles = makeStyles(theme => ({
  userLink: {
    marginBottom: theme.spacing() / 2
  },
  dialogContent: {
    maxHeight: 400
  },
  space: {
    marginLeft: theme.spacing() / 2
  },
  pointer: {
    cursor: 'pointer'
  }
}));

/**
 * 「他 N 人」のリンク
 */
export function NotificationStagePlayMore(
  props: NotificationStagePlayMoreProps
) {
  const cn = useStyles();

  const [opened, setOpened] = React.useState(false);
  React.useEffect(() => {
    if (opened) {
      analytics.showWhoPlay();
    }
  }, [opened]);

  return props.players.length > 1 ? (
    <>
      <span className={cn.pointer} onClick={() => setOpened(true)}>
        と他
        <b className={cn.space}>{props.players.length - 1}人</b>
      </span>
      <Dialog
        open={opened}
        fullWidth
        maxWidth="sm"
        onClose={() => setOpened(false)}
      >
        <DialogClose onClick={() => setOpened(false)} />
        <DialogTitle>「{props.title}」をさいきん あそんでくれた人</DialogTitle>
        <Divider />
        <DialogContent className={cn.dialogContent}>
          {props.players.map(uid => (
            <div key={uid} className={cn.userLink}>
              <UserNameLink uid={uid} icon />
            </div>
          ))}
        </DialogContent>
      </Dialog>
    </>
  ) : null;
}
