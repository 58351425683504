import { Editor, EditorChange, EditorChangeCancellable } from 'codemirror';

export interface IChangeResult {
  hankaku?: boolean; // 全角文字を含んでいたので, 半角に直した
  lineBreakInString?: boolean; // 文字列リテラルの中で改行しようとしたので、キャンセルした
}

const regExpOfZenkaku = /[！-～]/m; // ちょうど ASCII から 0xfee0 だけずれている Unicode
const pattersOfZenkaku = [
  // ずれ方に規則性がない [Unicode, ASCII]
  [`　`, ` `],
  [`”`, `"`],
  [`’`, `'`],
  [`、`, `,`],
  [`。`, `.`],
  [`ー`, `-`],
  [`・`, `/`],
  [`「`, `[`],
  [`」`, `]`],
  [`『`, `{`],
  [`』`, `}`],
  [`〜`, `~`]
];

/**
 * エラーを起こしそうな変更を事前に修正する. change を破壊的に変更する
 * @param change CodeMirror の 'beforeChange' に渡される change 引数
 * @returns どのような変更が行われたのかを表す結果
 */
export default function fixDangerousChange(
  cm: Editor,
  change: EditorChangeCancellable | EditorChange
): IChangeResult {
  if (!change.text) return {}; // bugfix for https://rollbar.com/HackforPlay/portal/items/1890/

  const isInput = change.origin === '+input' || change.origin === '*compose';
  const token = cm.getTokenAt(change.from);
  const isInString =
    (token.type === 'string' || token.type === 'string-2') && // 文字列リテラルまたはテンプレートリテラル
    (isInput ? token.end !== change.from.ch : token.end !== change.to.ch); // リテラルの終端記号の右側にいる場合を除く
  const input = change.text.join('\n');
  const mayCancel: EditorChangeCancellable['cancel'] = () =>
    'cancel' in change &&
    typeof change.cancel === 'function' &&
    change.cancel();
  const mayUpdate: EditorChangeCancellable['update'] = (from, to, text) =>
    'update' in change &&
    typeof change.update === 'function' &&
    change.update(from, to, text);

  if (isInString) {
    // もし文字列リテラル(テンプレートリテラルではない)の途中で改行しているならキャンセルする
    if (token.type === 'string' && isInput && change.text.length > 1) {
      mayCancel();
      return { lineBreakInString: true };
    }
  } else {
    // 全角文字を半角文字に変換する
    if (isInput) {
      let flag = false; // 変換が行われたら true になる
      const replaced = change.text.map(text => {
        const converted = replaceZenkaku(text);
        flag = flag || converted !== text;
        return converted;
      });
      if (flag) {
        mayUpdate(change.from, change.to, replaced);
        return { hankaku: true };
      }
    }
  }

  return {};
}

function replaceZenkaku(text: string) {
  if (regExpOfZenkaku.test(text)) {
    const globalRegExp = new RegExp(regExpOfZenkaku, 'g');
    text = text.replace(globalRegExp, s =>
      String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    );
  }
  for (const [before, after] of pattersOfZenkaku) {
    if (text.includes(before)) {
      text = text.split(before).join(after);
    }
  }
  return text;
}
