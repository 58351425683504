import {
  Statefull,
  has,
  invalid,
  empty,
  processing,
  initialized
} from '../ducks/helpers';

const knownCollections = [
  'works',
  'maps',
  'users',
  'series',
  'officials',
  'teams'
] as const;

/**
 * @deprecated リファクタリングの一時的なもの
 * @param path ドキュメントのパス
 */
export function pathToId(path: string) {
  const segments = path.split('/');
  if (process.env.NODE_ENV !== 'production') {
    if (
      segments.length < 2 ||
      segments.length > 3 ||
      !segments[0] ||
      !segments[1]
    ) {
      throw new Error(`Invalid path: pathToId('${path}')`);
    }
    if (!knownCollections.includes(segments[0] as any)) {
      throw new Error(`Unknown collection: pathToId('${path}')`);
    }
  }
  return segments[1];
}

/**
 * @deprecated
 * リファクタリング時のタイプガードとして
 */
export function idToPath(
  collection: typeof knownCollections[number],
  id: string
) {
  return collection + '/' + id;
}

/**
 * @deprecated
 * リファクタリング時の一時的な変換として
 */
export function toIds(
  statefull: Statefull<{ id: string }[]>
): Statefull<string[]> {
  if (statefull.data) {
    return has(statefull.data.map(item => item.id));
  }
  if (statefull.error) {
    return invalid(statefull.error);
  }
  if (statefull.isEmpty) {
    return empty();
  }
  if (statefull.isProcessing) {
    return processing();
  }
  return initialized();
}
