import { Document } from '@contentful/rich-text-types';
import { makeStyles, Paper } from '@material-ui/core';
import { Entry } from 'contentful';
import * as React from 'react';
import { renderEntry } from './renderers';

export interface Fields {
  title: string;
  body: Document;
  since: string;
  until: string;
  order?: number;
}

export interface TopicProps {
  entry: Entry<Fields>;
}

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    '& h1,& h2,& h3,& h4,& h5,& h6': {
      display: 'inline-flex',
      alignItems: 'center',
      marginTop: 0
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

export function Topic(props: TopicProps) {
  const { order, body } = props.entry.fields;
  const classes = useStyles();

  const style = React.useMemo<React.CSSProperties>(() => {
    return {
      order: order || 10000 // 設定しない場合は後ろに行く
    };
  }, [order]);

  return body ? (
    <Paper elevation={2} style={style} className={classes.root}>
      {renderEntry(body)}
    </Paper>
  ) : null;
}
