/**
 * 日付を表す値をパースしてミリ秒の Unix Epoc で返す
 * @param date あらゆるタイプの日付を表す値
 * @param fallback パースに失敗した場合の戻り値
 */
export function parseTimestamp(date?: TS, fallback = 0) {
  if (date === null || date === undefined) {
    return fallback;
  }
  if (typeof date === 'string') {
    const parsed = Date.parse(date);
    return Number.isNaN(parsed) ? fallback : parsed;
  }
  if (date instanceof Date) {
    return date.getTime();
  }
  if ('toMillis' in date && typeof date.toMillis === 'function') {
    return date.toMillis();
  }
  if (typeof date.seconds === 'number') {
    return date.seconds * 1000 + Math.floor(date.nanoseconds / 1000000);
  }

  return fallback;
}
