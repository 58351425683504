import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Theme, withTheme } from '@material-ui/core/styles';
import { DeleteForever, Search } from '@material-ui/icons';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import { style } from 'typestyle';
import { hierarchyCardFilterAtom } from '.';
import { actions } from '../../../../ducks/file';
import ImmutableFile from '../../File/ImmutableFile';
import { useActionDispatcher } from '../../hooks/useActionDispatcher';
import AutoComplete from '../../jsx/IntegrationReactSelect';
import { Localization } from '../../localization';
import DesktopFile from './DesktopFile';
import search, { getOptions } from './search';
import TrashBox from './TrashBox';

export interface SearchBarProps {
  theme: Theme;
  filter: (file: ImmutableFile) => boolean;
  setFilter: (filter: (file: ImmutableFile) => boolean) => void;
  onOpen: (files: FileList) => void;
  localization: Localization;
  // Injected by withFiles (for JSX)
  files: ImmutableFile[];
  deleteFiles: typeof actions.deleteFiles;
}

const SearchBarHeight = 40;

const cn = {
  icon: style({
    marginTop: 4
  })
};
const getCn = (props: SearchBarProps) => ({
  root: style({
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%',
    height: SearchBarHeight,
    paddingRight: 16,
    paddingLeft: props.theme.spacing(),
    zIndex: 100
  }),
  bar: style({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: SearchBarHeight,
    paddingLeft: props.theme.spacing(),
    backgroundColor: props.theme.palette.background.paper
  }),
  empty: style({
    flex: '1 0 auto',
    height: SearchBarHeight,
    marginLeft: props.theme.spacing()
  })
});

export function SearchBarWrapper(
  props: Omit<SearchBarProps, 'files' | 'deleteFiles' | 'filter' | 'setFilter'>
) {
  const files = useSelector(state => state.file.files);
  const deleteFiles = useActionDispatcher(actions.deleteFiles);
  const [filter, setFilter] = useRecoilState(hierarchyCardFilterAtom);

  return (
    <SearchBar
      {...props}
      files={files}
      deleteFiles={deleteFiles}
      filter={filter}
      setFilter={filter => setFilter(() => filter)}
    />
  );
}

class SearchBar extends React.PureComponent<SearchBarProps> {
  state = {
    showTrashes: false,
    query: ''
  };

  componentDidMount() {
    this.handleUpdate('');
  }

  handleUpdate = (value: string | { value: string }, context?: any) => {
    const query = typeof value === 'object' ? value.value : value;
    if (!query && context) {
      if (context.action !== 'input-change') {
        // react-select は value="" でイベント発火することがある
        return;
      }
    }

    const options = getOptions(query);
    this.props.setFilter(file => search(file, query, options));

    this.setState({
      query,
      showTrashes: options.showTrashes
    });
  };

  handleTrashBoxTap = () => {
    const { query, showTrashes } = this.state;

    if (!showTrashes) {
      this.handleUpdate(':trash ' + query);
    } else {
      this.handleUpdate(query.replace(/(^|\s):trash(\s|$)/g, '$1'));
    }
  };

  handleDelete = () => {
    const files = this.props.files.filter(this.props.filter);
    this.props.deleteFiles({ files, isUserAction: true });
  };

  render() {
    const dcn = getCn(this.props);
    const { onOpen, localization } = this.props;
    const { showTrashes, query } = this.state;
    const fileNames = this.props.files
      .map(f => f.moduleName)
      .filter(s => s)
      .map(s => ({
        value: s,
        label: s
      }));

    return (
      <div className={dcn.root}>
        <TrashBox showTrashes={showTrashes} onClick={this.handleTrashBoxTap} />
        <DesktopFile onOpen={onOpen} />
        <Paper elevation={3} className={dcn.bar}>
          <Search className={cn.icon} />
          <AutoComplete
            value={query}
            suggestions={fileNames}
            onChange={this.handleUpdate}
            placeholder=""
          />
        </Paper>
        {showTrashes ? (
          <Button
            variant="contained"
            color="secondary"
            className={dcn.empty}
            onClick={this.handleDelete}
            startIcon={<DeleteForever />}
          >
            {localization.hierarchyCard.emptyTrashBox}
          </Button>
        ) : null}
      </div>
    );
  }
}

export default withTheme(SearchBarWrapper);
