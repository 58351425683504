import * as React from 'react';
import { useSelector } from 'react-redux';
import ImmutableFile from '../../File/ImmutableFile';
import { useSetGlobals } from '../../hooks/useGlobalsHint';

export interface GlobalsProviderProps {
  codemirror?: CodeMirror.Editor;
}

/**
 * globals の変数名を集めるためのコンポーネント
 */
export function GlobalsProvider(props: GlobalsProviderProps) {
  const definition = useSelector(state => state.file.definition);
  const globalKeywords = React.useMemo(() => {
    getGlobalVarNamesCache = new WeakMap(); // キーワードが変われば全て再計算 => キャッシュをクリアする
    const globalKeywords = ['globals'];
    if (definition?.globals?.globals?.name) {
      globalKeywords.push(definition?.globals?.globals?.name);
    }
    return globalKeywords;
  }, [definition]);

  const files = useSelector(state => state.file.files);
  const setGlobals = useSetGlobals();
  const namesFromFiles = React.useMemo(() => {
    const varNameSet = new Set<string>();
    files
      .map(file => getGlobalVarNames(globalKeywords, file.text, file))
      .forEach(names => names?.forEach(varName => varNameSet.add(varName)));
    return varNameSet;
  }, [files, globalKeywords]);

  React.useEffect(() => {
    const onUpdate = (cm: CodeMirror.Editor) => {
      const current = getGlobalVarNames(globalKeywords, cm.getValue('\n')); // 今開いている Doc に書かれている変数名
      namesFromFiles.forEach(name => current.add(name)); // ファイルに書き込まれている変数名とマージ
      setGlobals(Array.from(current));
    };
    if (props.codemirror) {
      props.codemirror.on('changes', onUpdate);
      onUpdate(props.codemirror); // globalKeywords か namesFromFiles が変化したら直ちに再計算して設定する
    }
    return () => {
      props.codemirror?.off('changes', onUpdate);
    };
  }, [props.codemirror, globalKeywords, namesFromFiles]);

  return null;
}

let getGlobalVarNamesCache = new WeakMap<ImmutableFile, Set<string>>();
const execTimeLimit = 10; // [ms]

/**
 * 与えられたソースコードから globals['hoge'] のような変数名を取得する
 * @param globalKeyword ['globals', 'へんすう']
 * @param code ソースコード
 * @param id ImmutableFile の参照
 */
function getGlobalVarNames(
  globalKeywords: string[],
  code: string,
  cacheKey?: ImmutableFile
) {
  const cache = cacheKey ? getGlobalVarNamesCache.get(cacheKey) : undefined;
  if (cache) return cache;

  const globalVarNames = new Set<string>();

  const limit = performance.now() + execTimeLimit;
  for (const keyword of globalKeywords) {
    let lastIndex = 0;
    while (performance.now() < limit) {
      const index = code.indexOf(keyword, lastIndex);
      if (index < 0) break;

      const startIndex = index + keyword.length; // キーワードの終了位置で ['...'] の開始位置
      const endIndex = code.indexOf(']', startIndex) + 1; // ['...'] の終了位置
      if (endIndex < 0) break;

      const bracketString = code.substring(startIndex, endIndex); // "['...']"
      const result = /^\s*\[\s*(['"].*['"])\s*\]$/.exec(bracketString);
      if (result) {
        globalVarNames.add(result[1]); // "'...'"
      }
      lastIndex = startIndex;
    }
  }

  if (cacheKey) {
    getGlobalVarNamesCache.set(cacheKey, globalVarNames);
  }
  return globalVarNames;
}
