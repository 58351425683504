import { isTruly } from '../../../../utils/filterTruly';
import ImmutableFile from '../../File/ImmutableFile';
import normalize from '../../File/normalize';

interface DetectedScriptTag {
  name: string;
  code: string;
}

export function detectTags(
  html: string,
  files: ImmutableFile[]
): Promise<DetectedScriptTag>[] {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  // スクリプトタグの src 属性を取得
  const scripts = slice(doc.scripts).filter(
    node => !node.type || node.type === 'text/javascript'
  );

  return scripts.map(resolver(files)).filter(isTruly);
}

function resolver(files: ImmutableFile[]) {
  let i = 0;
  return (node: HTMLScriptElement) => {
    const src = node.getAttribute('src');
    if (src === null) {
      // <script>/* javascript code to run */</script>
      const tmp: DetectedScriptTag = {
        name: `tmp_${++i}.js`,
        code: node.textContent || ''
      };
      return Promise.resolve(tmp);
    }

    const normalized = normalize(src);
    const file = files.find(file => file.name === normalized);
    if (!file) return null; // not found

    return file.transpiled.then(transpiled => ({
      name: normalized,
      code: transpiled
    }));
  };
}

function slice<T extends Element>(collection: HTMLCollectionOf<T>) {
  const array: T[] = [];
  for (let index = 0; index < collection.length; index++) {
    const element = collection.item(index);
    element && array.push(element);
  }
  return array;
}
