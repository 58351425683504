import { grey } from '@material-ui/core/colors';
import { withTheme, WithTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { pathToRegexp } from 'path-to-regexp';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { style } from 'typestyle';
import { optionalHeaderInfo } from '../settings/siteMap';

const getCn = (props: Props) => ({
  root: style({
    textAlign: 'center',
    paddingTop: props.theme.spacing(6),
    paddingBottom: props.theme.spacing(6),
    backgroundColor: grey[300]
  })
});

export type OwnProps = {};
export type Props = OwnProps & RouteComponentProps & WithTheme;

const OptionalHeader: React.SFC<Props> = props => {
  const dcn = getCn(props);
  const { location } = props;

  // 現在表示している URL にふさわしいタブの状態を取得する
  const info = optionalHeaderInfo.find(item => {
    const re = pathToRegexp(item.path);
    return re.test(location.pathname);
  });

  if (!info) {
    // TODO: アニメーション
    return null;
  }

  return (
    <div className={dcn.root}>
      <Typography variant="h5" gutterBottom>
        {info.headline}
      </Typography>
      <Typography variant="caption">{info.caption}</Typography>
    </div>
  );
};

export default compose<React.SFC<Props>>(withTheme, withRouter)(OptionalHeader);
