import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import * as React from 'react';

export function PasswordTextField(props: TextFieldProps) {
  const [showing, setShowing] = React.useState(false);
  const toggleShowing = React.useCallback(() => {
    setShowing(!showing);
  }, [showing]);

  return (
    <TextField
      {...props}
      type={showing ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={toggleShowing}>
              {showing ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
}
