import MarkdownIt from 'markdown-it';
import { useEffect, useMemo, useState } from 'react';

const endpoint = process.env.REACT_APP_CLOUDRUN_ENDPOINT;

/** 同じcodeで同時に二度実行するのを防ぐための一時キャッシュ */
const loadingTasks = new Map<string, Promise<string>>();

export function useCodeExplanation(params: { code: string; filename: string }) {
  const codeChunks = useMemo(
    () => splitCodeByRules(params.filename, params.code),
    [] // コードが書き変わるたびに実行されるのを防ぐ
  );

  const [isLoading, setIsLoading] = useState(false);
  const [htmlChunks, setHTMLChunks] = useState<string[]>([]);
  const [isError, setIsError] = useState(false);

  const html = htmlChunks
    .map((html, i) => `<h3>${codeChunks[i]?.rule}</h3>${html || '<p>...</p>'}`)
    .join('');

  useEffect(() => {
    const { filename, code } = params;
    if (!code || !filename) return;

    setIsLoading(true);
    setIsError(false);
    setHTMLChunks(codeChunks.map(() => ''));

    const promises = codeChunks.map(({ filename, rule, code }, index) => {
      // 同じcodeで同時に二度実行するのを防ぐ
      const loadingTask =
        loadingTasks.get(code) ??
        fetch(`${endpoint}/openAI?`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            action: 'code_explanation',
            code,
            filename,
            rule
          })
        })
          .then(response => response.json())
          .then(result => {
            const md = new MarkdownIt();
            const html = md.render(result.text);
            return html;
          });
      loadingTasks.set(code, loadingTask);

      return loadingTask
        .then(html => {
          setHTMLChunks(chunks => {
            const newChunks = [...chunks];
            newChunks[index] = html;
            return newChunks;
          });
          setIsLoading(false);
        })
        .finally(() => {
          loadingTasks.delete(code);
        });
    });

    Promise.all(promises).catch(() => {
      console.error('Failed to fetch code explanation');
      setIsError(true);
    });
  }, [codeChunks]);

  return {
    isLoading,
    html,
    isError
  };
}

interface CodeChunk {
  filename: string;
  rule: string;
  code: string;
}

const ruleRegExp = /rule\.(.*)\(async function/g;

/** rule.〜とき(async function (...)) ごとにコードを分割する  */
function splitCodeByRules(filename: string, code: string) {
  const matches = [...code.matchAll(ruleRegExp)];
  const codeChunks: CodeChunk[] = [];
  for (let index = 0; index < matches.length; index++) {
    const begin = matches[index].index;
    const end = matches[index + 1]?.index ?? code.length;
    const chunk = code.slice(begin, end).trim();
    codeChunks.push({
      filename,
      rule: matches[index][1],
      code: chunk
    });
  }
  return codeChunks;
}
