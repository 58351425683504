export function flatten<T>(array: (T | T[])[]): T[] {
  const dist: T[] = [];
  for (const item of array) {
    if (Array.isArray(item)) {
      dist.push(...item);
    } else {
      dist.push(item);
    }
  }
  return dist;
}

type RecursiveArray<T> = Array<T | RecursiveArray<T>>;
export function flattenDeep<T>(array: RecursiveArray<T>): T[] {
  const dist: T[] = [];
  for (const item of array) {
    if (Array.isArray(item)) {
      dist.push(...flattenDeep(item));
    } else {
      dist.push(item);
    }
  }
  return dist;
}
