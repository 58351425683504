import { SceneMap } from '@hackforplay/next';
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { endpoint } from '../env';
import firebase from '../settings/firebase';
import { idToPath } from '../utils/id';
import { actions } from './actions';
import { jsonApi } from './api';
import { asyncEpic, SKIP } from './asyncEpic';
import * as helpers from './helpers';
import { Statefull } from './helpers';
import { reducerWithImmer } from './reducer-with-immer';
import { requestWithAuth } from './requestWithAuth';
import { StoreState } from './type';
import { ofAction } from './typescript-fsa-redux-observable';
import { eachUser, toBlob } from './utils';

const firestore = firebase.firestore;

// 最終的な Root Reducere の中で、ここで管理している State が格納される名前
export const storeName = 'maps';

export interface IMapTemplate extends IMapDocument {
  title: string;
  template: string;
}

const paginationSize = 20;

export type State = {
  /**
   * 現在編集しているマップのパス
   */
  currentEditingPath?: string;
  /**
   * 直前のセーブが初回セーブかどうか
   */
  isFirstSave: boolean;
  isUpdating: boolean;
  updateErrorMessage?: string;
  maps: KVS<Statefull<IMapDocument>>;
  /**
   * key: map id
   * value: SceneMap
   */
  sceneMaps: KVS<Statefull<SceneMap>>;
  /**
   * ログインユーザーが作成した
   * マップの ID の配列
   */
  myMapIds: string[];
  /**
   * 削除したマップの ID の配列
   */
  myDeletedMapIds: string[];
  more: {
    mayHasMore: boolean;
    isFetching: boolean;
    oldestUpdatedAt?: firebase.firestore.Timestamp;
  };
};

const initialState: State = {
  isUpdating: false,
  isFirstSave: false,
  maps: {},
  sceneMaps: {},
  myMapIds: [],
  myDeletedMapIds: [],
  more: {
    mayHasMore: true,
    isFetching: false
  }
};

// Root Reducer
export default reducerWithImmer(initialState)
  .case(actions.map.createNew.started, draft => {
    draft.isUpdating = true;
    draft.updateErrorMessage = undefined;
    draft.currentEditingPath = undefined;
  })
  .case(actions.map.createNew.done, (draft, payload) => {
    draft.isUpdating = false;
    draft.currentEditingPath = payload.result.path;
    draft.isFirstSave = true;
  })
  .case(actions.map.createNew.failed, (draft, payload) => {
    draft.isUpdating = false;
    draft.updateErrorMessage = payload.error + '';
  })
  .case(actions.map.update.started, draft => {
    draft.isUpdating = true;
    draft.updateErrorMessage = undefined;
    draft.isFirstSave = false;
  })
  .case(actions.map.update.done, (draft, payload) => {
    draft.isUpdating = false;
  })
  .case(actions.map.update.failed, (draft, payload) => {
    draft.isUpdating = false;
    draft.updateErrorMessage = payload.error + '';
  })
  .case(actions.map.delete.done, (draft, payload) => {
    const docData = draft.maps[payload.params.id]?.data;
    if (docData) {
      docData.deletedAt = payload.result.deletedAt;
      draft.myMapIds = draft.myMapIds.filter(id => id !== payload.params.id); // myMapIds から myDeletedMayIds へ
      draft.myDeletedMapIds.push(payload.params.id);
    }
  })
  .case(actions.map.restore.done, (draft, payload) => {
    const docData = draft.maps[payload.params.id]?.data;
    if (docData) {
      docData.deletedAt = null;
      draft.myMapIds.push(payload.params.id); // myDeletedMayIds から myMapIds へ
      draft.myDeletedMapIds = draft.myDeletedMapIds.filter(
        id => id !== payload.params.id
      );
    }
  })
  .case(actions.map.load.started, draft => {
    draft.currentEditingPath = undefined;
  })
  .case(actions.map.load.done, (draft, { params, result }) => {
    draft.maps[params.id] = helpers.has(result.documentData);
    draft.sceneMaps[params.id] = helpers.has(result.data);
    draft.currentEditingPath = idToPath('maps', result.id);
  })
  .case(actions.map.setData, (draft, payload) => {
    draft.sceneMaps[payload.id] = helpers.has(payload.data);
  })
  .case(actions.map.setCollection, (draft, payload) => {
    for (const snapshot of payload.docs) {
      const doc = snapshot.data();
      if (!doc) continue; // type hint
      draft.maps[snapshot.id] = helpers.from(doc); // データを格納
      delete draft.sceneMaps[snapshot.id]; // 古いマップデータを削除. TODO: 同じタブでマップエディタを開くのであれば、update のタイミングで新しいデータを格納する方が良い
      if (!doc.deletedAt && !draft.myMapIds.includes(snapshot.id)) {
        draft.myMapIds.push(snapshot.id);
      } else if (
        doc.deletedAt &&
        !draft.myDeletedMapIds.includes(snapshot.id)
      ) {
        draft.myDeletedMapIds.push(snapshot.id);
      }
    }
  })
  .case(actions.map.fetchData.started, (draft, payload) => {
    draft.sceneMaps[payload.id] = helpers.processing();
  })
  .case(actions.map.fetchData.done, (draft, payload) => {
    draft.sceneMaps[payload.params.id] = helpers.from(payload.result);
  })
  .case(actions.map.fetchMore.started, draft => {
    draft.more.isFetching = true;
  })
  .case(actions.map.fetchMore.done, (draft, payload) => {
    draft.more.mayHasMore = payload.result.size >= paginationSize;
    draft.more.isFetching = false;
    draft.more.oldestUpdatedAt = getOldestUpdatedAt(payload.result);
  })
  .case(actions.map.fetchMore.failed, draft => {
    draft.more.isFetching = false;
  })
  .case(actions.map.reset, draft => {
    draft.currentEditingPath = undefined;
    draft.isFirstSave = false;
    draft.isUpdating = false;
    draft.updateErrorMessage = undefined;
  })
  .reset(actions.auth.signOut)
  .toReducer();

export const epics = combineEpics(
  // Subscribe own documents when signed in
  eachUser((userInfo, action$) => {
    let unsubscribe = () => {};
    return new Observable<QS<IMapDocument>>(observer => {
      unsubscribe = firestore()
        .collection('maps')
        .where('uid', '==', userInfo.uid)
        .orderBy('updatedAt', 'desc')
        .limit(1)
        .withConverter(convertToMap)
        .onSnapshot(observer);
    }).pipe(
      map(snapshot => actions.map.setCollection(snapshot)),
      tap(undefined, undefined, () => unsubscribe())
    );
  }),
  // onSnapshot が成功したら, 一度 fetch more する
  action$ =>
    action$.pipe(
      ofAction(actions.map.setCollection),
      first(),
      map(() => actions.map.fetchMore.started())
    ),
  jsonApi(actions.map.fetchData, params => params.jsonUrl),

  // Fetch more
  asyncEpic(actions.map.fetchMore, async (action, state) => {
    const { oldestUpdatedAt } = state.maps.more;
    const { userInfo } = state.auth;
    if (!userInfo) return SKIP;

    const query = firestore()
      .collection('maps')
      .where('uid', '==', userInfo.uid)
      .orderBy('updatedAt', 'desc')
      .limit(paginationSize)
      .withConverter(convertToMap);
    const queryWithPaginate = oldestUpdatedAt
      ? query.startAfter(oldestUpdatedAt)
      : query;
    const result = await queryWithPaginate.get();
    return result;
  }),

  action$ =>
    action$.pipe(
      ofAction(actions.map.fetchMore.done),
      filter(action => !action.payload.result.empty),
      map(action => actions.map.setCollection(action.payload.result))
    ),

  // save new map json
  asyncEpic(actions.map.createNew, async (action, state) => {
    if (!state.auth.userInfo) return SKIP;
    const { uid } = state.auth.userInfo;

    // マップデータ JSON に書き出し
    const file = new Blob([action.payload.json], {
      type: 'application/json'
    });

    // Storage にアップロード
    const snapshot = await firebase
      .storage()
      .refFromURL(
        `gs://${
          process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_UGC || ''
        }/${uuid()}.json`
      )
      .put(file, {
        cacheControl: 'no-cache, max-age: 0'
      });

    // サムネイル
    const { url } = await requestWithAuth(
      endpoint + '/uploadImage',
      'POST',
      toBlob(action.payload.thumbnail)
    );

    return await firebase
      .firestore()
      .collection('maps')
      .add({
        uid,
        visibility: 'limited',
        jsonRef: `gs://${snapshot.metadata.bucket}/${snapshot.metadata.fullPath}`,
        jsonUrl: `https://storage.googleapis.com/${snapshot.metadata.bucket}/${snapshot.metadata.fullPath}`,
        thumbnailUrl: url,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      });
  }),

  // マップをコピーする
  asyncEpic(actions.map.copy, async (action, state) => {
    if (!state.auth.userInfo) return SKIP;
    const { uid } = state.auth.userInfo;
    const documentData = state.maps.maps[action.payload.id]?.data;
    if (!documentData) return SKIP; // have not created yet
    if (documentData.uid !== uid) return SKIP; // not mine

    // マップデータをダウンロード
    const response = await fetch(documentData.jsonUrl);
    const file = await response.blob();

    // Storage にアップロード
    const snapshot = await firebase
      .storage()
      .refFromURL(
        `gs://${
          process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_UGC || ''
        }/${uuid()}.json`
      )
      .put(file, {
        cacheControl: 'no-cache, max-age: 0'
      });

    const ref = await firestore()
      .collection('maps')
      .withConverter(convertToMap)
      .add({
        uid,
        visibility: 'limited',
        jsonRef: `gs://${snapshot.metadata.bucket}/${snapshot.metadata.fullPath}`,
        jsonUrl: `https://storage.googleapis.com/${snapshot.metadata.bucket}/${snapshot.metadata.fullPath}`,
        thumbnailUrl: documentData.thumbnailUrl || '',
        createdAt: firebase.firestore.Timestamp.now(),
        updatedAt: firebase.firestore.Timestamp.now()
      });
    return ref;
  }),

  asyncEpic(actions.map.update, async (action, state) => {
    if (!state.auth.userInfo) return SKIP;
    const { uid } = state.auth.userInfo;
    const documentData = state.maps.maps[action.payload.id]?.data;
    if (!documentData) return SKIP; // have not created yet
    if (documentData.uid !== uid) return SKIP; // not mine

    // マップデータ JSON に書き出し
    const file = new Blob([action.payload.json], { type: 'application/json' });
    // Storage にアップロード
    await firebase.storage().refFromURL(documentData.jsonRef).put(file, {
      cacheControl: 'no-cache, max-age: 0'
    });

    // サムネイル
    const { url } = await requestWithAuth(
      endpoint + '/uploadImage',
      'POST',
      toBlob(action.payload.thumbnail)
    );

    await firestore().collection('maps').doc(action.payload.id).update({
      thumbnailUrl: url,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    });

    return {};
  }),
  action$ =>
    action$.pipe(
      filter(actions.map.update.done.match),
      map(action =>
        actions.map.setData({
          id: action.payload.params.id,
          data: JSON.parse(action.payload.params.json)
        })
      )
    ),

  asyncEpic(actions.map.load, async (action, state) => {
    if (state.maps.sceneMaps[action.payload.id]) return SKIP; // 既にロード中

    let documentData = state.maps.maps[action.payload.id]?.data;
    let jsonUrl;
    if (documentData) {
      jsonUrl = documentData.jsonUrl;
    } else {
      // Firestore から取得
      const documentSnapshot = await firebase
        .firestore()
        .collection('maps')
        .doc(action.payload.id)
        .withConverter(convertToMap)
        .get();

      documentData = documentSnapshot.data();
      if (!documentData) {
        throw new Error('Not found: ' + idToPath('maps', action.payload.id));
      }
      jsonUrl = documentData.jsonUrl;
    }

    const response = await fetch(jsonUrl);
    const json = await response.text();
    return {
      id: action.payload.id,
      data: JSON.parse(json),
      documentData
    };
  }),

  asyncEpic(actions.map.delete, async (action, state) => {
    const docData = state.maps.maps[action.payload.id]?.data;
    if (!docData) return SKIP; // 取得出来ていない
    if (docData.deletedAt) return SKIP; // 削除されている

    const deletedAt = firestore.Timestamp.now();

    await firestore()
      .collection('maps')
      .doc(action.payload.id)
      .update({ deletedAt });

    return { deletedAt };
  }),

  asyncEpic(actions.map.restore, async (action, state) => {
    const docData = state.maps.maps[action.payload.id]?.data;
    if (!docData?.deletedAt) return SKIP; // 削除されていない
    await firestore()
      .collection('maps')
      .doc(action.payload.id)
      .update({ deletedAt: null });
  })
);

export function getState(store: StoreState): State {
  return store[storeName];
}

function getOldestUpdatedAt(
  querySnapshot: QS
): firebase.firestore.Timestamp | undefined {
  for (let index = querySnapshot.docs.length - 1; index >= 0; index--) {
    const snapshot = querySnapshot.docs[index];
    const updatedAt = snapshot.exists ? snapshot.data().updatedAt : undefined;
    if (updatedAt) {
      return updatedAt;
    }
  }
}

const convertToMap: firebase.firestore.FirestoreDataConverter<IMapDocument> = {
  fromFirestore: snapshot => snapshot.data() as IMapDocument,
  toFirestore: (data: IMapDocument) => data
};
