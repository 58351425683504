import { style, keyframes, media } from 'typestyle';

export const largeButton = style({
  fontSize: 26,
  paddingLeft: 20,
  paddingRight: 20,
  $nest: {
    '& svg': {
      transform: 'scale(1.8)',
      marginRight: 8
    }
  }
});

export const mediumButton = style({
  fontSize: 20,
  paddingLeft: 16,
  paddingRight: 16,
  $nest: {
    '& svg': {
      transform: 'scale(1.2)',
      marginRight: 8
    }
  }
});

export const margin = {
  large: style({
    margin: 32
  }),
  medium: style({
    margin: 16
  }),
  small: style({
    margin: 8
  })
};

export const marginV = {
  large: style({
    marginTop: 32,
    marginBottom: 32
  }),
  medium: style({
    marginTop: 16,
    marginBottom: 16
  }),
  small: style({
    marginTop: 8,
    marginBottom: 8
  })
};

export const marginR = {
  large: style({
    marginRight: 32
  }),
  medium: style({
    marginRight: 16
  }),
  small: style({
    marginRight: 8
  })
};

export const padding = {
  large: style({
    padding: 32
  }),
  medium: style({
    padding: 16
  }),
  small: style({
    padding: 8
  })
};

export const container = {
  large: style(
    {
      boxSizing: 'border-box',
      width: 1040,
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: 16
    },
    media(
      {
        maxWidth: 1040
      },
      {
        width: '100%',
        padding: 0,
        opacity: 14 // media query が違うのに 同じ className を出力している
      }
    )
  ),
  medium: style(
    {
      boxSizing: 'border-box',
      width: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: 16
    },
    media(
      {
        maxWidth: 700
      },
      {
        width: '100vw',
        padding: 0,
        opacity: 15 // media query が違うのに 同じ className を出力している
      }
    )
  )
};

export const loadAnimation = {
  glimmering: style({
    backgroundColor: 'rgb(200,200,200)',
    animationName: keyframes({
      from: { backgroundColor: 'rgb(200,200,200)' },
      to: { backgroundColor: 'rgb(128,128,128)' }
    }),
    animationDuration: '2s',
    animationDirection: 'alternate',
    animationIterationCount: 'infinite'
  })
};

export const objectFit = {
  cover: style({
    objectFit: 'cover',
    // https://github.com/bfred-it/object-fit-images/
    fontFamily: "'object-fit: cover;'"
  }),
  contain: style({
    objectFit: 'contain',
    // https://github.com/bfred-it/object-fit-images/
    fontFamily: "'object-fit: contain;'"
  })
};

// Ref: http://ianlunn.github.io/Hover/
export const hover = {
  growShadow: style({
    transitionDuration: '0.3s',
    transitionProperty: 'transform, box-shadow',
    boxShadow: '0 0 1px rgba(0, 0, 0, 0)',
    $nest: {
      '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0 10px 10px -10px rgba(0, 0, 0, 0.5)'
      }
    }
  }),
  /**
   * "&:hover img" を grow させる
   * img の上にある要素は .growImageParent をつける必要がある
   */
  growImageRoot: style({
    $nest: {
      '& img': {
        transitionDuration: '0.3s',
        transitionProperty: 'transform'
      },
      '&:hover img': {
        transform: 'scale(1.1)'
      }
    }
  }),
  growImageParent: style({
    display: 'block',
    overflow: 'hidden'
  })
};

export const linkTextClass = style({
  textDecoration: 'underline',
  cursor: 'pointer'
});
