import {
  FormControlLabel,
  makeStyles,
  Switch,
  Typography
} from '@material-ui/core';
import * as React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useFirestore } from '../hooks/useFirestore';
import { parentalControlState } from '../recoil';
import { noticeAtom } from './NoticeManager';
import { receiveCommentAtom } from './WorkInfoPanel';

export interface WorkEnableCommentProps {
  id: string;
}

const useStyles = makeStyles(theme => ({
  disabledByParent: {
    marginLeft: theme.spacing() * 4,
    color: theme.palette.text.disabled
  }
}));

export function WorkEnableComment(props: WorkEnableCommentProps) {
  const receiveComment = useRecoilValue(receiveCommentAtom(props.id));

  const firestore = useFirestore();
  const toggleReceiveComment = useRecoilCallback(
    async ({ set }) => {
      try {
        set(receiveCommentAtom(props.id), !receiveComment);
        await firestore().collection('works').doc(props.id).update({
          receiveComment: !receiveComment
        });
      } catch (error) {
        set(receiveCommentAtom(props.id), receiveComment);
        set(noticeAtom, {
          severity: 'error',
          children:
            '設定の変更に失敗しました。しばらく後もう一度試してください。'
        });
      }
    },
    [firestore, props.id, receiveComment]
  );

  // みまもり設定によってコメントの許可されていなければ
  // そもそもコメントを受け取れる設定にすることができない
  const parentalControl = useRecoilValue(parentalControlState);
  const disabledByParent =
    parentalControl?.enableReceive !== true && !receiveComment; // オン状態の時だけdisabled

  const cn = useStyles();

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={receiveComment}
            disabled={disabledByParent}
            onChange={toggleReceiveComment}
          />
        }
        disabled={disabledByParent}
        label="この作品へのコメントを受け取る"
      />
      {disabledByParent ? (
        <Typography
          variant="body2"
          className={cn.disabledByParent}
          gutterBottom
        >
          みまもり設定で制限されています
        </Typography>
      ) : null}
    </>
  );
}
