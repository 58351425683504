import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import {
  Add,
  ArrowBack,
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  ExpandLess,
  ExpandMore,
  MoreVert,
  PlaylistPlay
} from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { actions } from '../ducks/actions';
import { compareTimestamps } from '../ducks/utils';
import { useDebouncedCallback } from '../hooks/useDebouncedCallback';
import { Link } from '../utils/components';
import { EditableTextField } from './EditableTextField';
import { LoadingPage } from './LoadingPage';
import { SeriesWorkItem } from './SeriesWorkItem';

type Params = {
  id?: string;
  tab?: 'works';
};

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    position: 'relative',
    overflow: 'hidden' // カラムごとにスクロールさせる
  },
  scrollable: {
    maxHeight: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  tab: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    transform: 'translateX(0%)',
    transition: theme.transitions.create('transform')
  },
  left: {
    transform: 'translateX(-100%)'
  },
  right: {
    transform: 'translateX(100%)'
  },
  dark: {
    backgroundColor: grey[200]
  },
  light: {
    backgroundColor: grey[50]
  },
  paddingLeft: {
    paddingLeft: theme.spacing()
  }
}));

enum SeriesTab {
  SeriesList,
  SeriesWork,
  Unselected
}

export function Series() {
  const cn = useStyles();

  const params = useParams<Params>();

  // 小さくない画面で、シリーズが未選択なら、先頭のシリーズを選択する
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const headId = useSelector(state => state.series.mine.data[0]);
  const isDeleting = useSelector(state => state.series.isDeleting);
  React.useEffect(() => {
    if (!params.id && headId && !isSmall && !isDeleting) {
      history.replace(`/series/${headId}`);
    }
  }, [isSmall, params.id, headId, isDeleting]);

  const tab =
    params.id === undefined
      ? SeriesTab.SeriesList
      : params.tab !== 'works'
      ? SeriesTab.SeriesWork
      : SeriesTab.Unselected;

  return (
    <Grid container className={cn.root}>
      <Grid
        item
        xs={12}
        sm={2}
        className={classNames(
          cn.scrollable,
          cn.dark,
          isSmall && cn.tab,
          isSmall && tab !== SeriesTab.SeriesList && cn.left
        )}
      >
        <SeriesList />
      </Grid>
      <Grid
        item
        xs={12}
        sm={5}
        className={classNames(
          cn.scrollable,
          cn.light,
          isSmall && cn.tab,
          isSmall && tab === SeriesTab.SeriesList && cn.right,
          isSmall && tab === SeriesTab.Unselected && cn.left
        )}
      >
        <SeriesWorkList />
      </Grid>
      <Grid
        item
        xs={12}
        sm={5}
        className={classNames(
          cn.scrollable,
          cn.dark,
          cn.paddingLeft,
          isSmall && cn.tab,
          isSmall && tab !== SeriesTab.Unselected && cn.right
        )}
      >
        <UnselectedWorkList />
      </Grid>
    </Grid>
  );
}

const useStylesSeriesList = makeStyles(theme => ({
  listHeader: {
    padding: theme.spacing() * 2
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing()
  },
  progress: {
    marginRight: theme.spacing()
  },
  ellipsis: {
    '& > *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  }
}));

function SeriesList() {
  const cn = useStylesSeriesList();

  const params = useParams<Params>();

  const mine = useSelector(state => state.series.mine);
  const auth = useSelector(state => state.auth);

  const dispatch = useDispatch();
  React.useEffect(() => {
    // TODO: pagination
    if (auth.initialized && auth.userInfo) {
      dispatch(actions.series.listMine.started());
    }
  }, [auth]);

  // シリーズのついか
  const create = React.useCallback(() => {
    dispatch(actions.series.create.started({ title: 'あたらしいシリーズ' }));
  }, []);
  const prevCreatingRef = React.useRef(false); // isCreating が false -> true になることを検知するための変数
  const isCreating = useSelector(state => state.series.isCreating);
  const history = useHistory();
  React.useEffect(() => {
    if (prevCreatingRef.current && !isCreating) {
      // 作成が完了したら、今先頭にあるシリーズを開く
      const head = mine.data[0];
      if (head) {
        history.replace(`/series/${head}`);
      }
    }
    prevCreatingRef.current = isCreating;
  }, [isCreating]);

  return (
    <>
      <Typography variant="h6" color="textSecondary" className={cn.listHeader}>
        シリーズ一覧
      </Typography>
      <List>
        {mine.data.map(id => (
          <SeriesListItem key={id} id={id} selected={id === params.id} />
        ))}
        <ListItem button disabled={isCreating} onClick={create}>
          {isCreating ? (
            <CircularProgress size={24} className={cn.progress} />
          ) : (
            <Add className={cn.icon} />
          )}
          <ListItemText primary="シリーズをつくる" className={cn.ellipsis} />
        </ListItem>
      </List>
    </>
  );
}

interface SeriesListItemProps {
  id: string;
  selected: boolean;
}

const useStylesSeriesListItem = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing()
  },
  ellipsis: {
    '& > *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  }
}));

function SeriesListItem(props: SeriesListItemProps) {
  const cn = useStylesSeriesListItem();

  const title = useSelector(state => state.series.byId[props.id]?.data?.title);
  if (title === undefined) return null;

  return (
    <ListItem
      button
      selected={props.selected}
      component={Link(`/series/${props.id}`)}
    >
      <PlaylistPlay className={cn.icon} />
      <ListItemText primary={title} className={cn.ellipsis} />
    </ListItem>
  );
}

const useStylesSeriesWorkList = makeStyles(theme => ({
  listHeader: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    backgroundColor: grey[50],
    position: 'sticky',
    top: 0,
    zIndex: 1
  },
  backMenu: {
    minWidth: '100%'
  },
  title: {
    marginLeft: theme.spacing() * 2,
    width: 240
  },
  titleLarge: {
    marginTop: theme.spacing() * 2
  },
  blank: {
    flex: 1
  },
  info: {
    margin: theme.spacing() * 2
  },
  bottomMenu: {
    backgroundColor: grey[50],
    position: 'sticky',
    bottom: 0,
    textAlign: 'center'
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing()
  }
}));

function SeriesWorkList() {
  const cn = useStylesSeriesWorkList();

  const { id } = useParams<Params>();

  const series = useSelector(state =>
    id ? state.series.byId[id]?.data : undefined
  );

  const [selected, setSelected] = React.useState<{
    workId: string;
    anchor: HTMLElement;
  }>();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  // シリーズの削除
  const [deleteSeriesAnchor, setDeleteSeriesAnchor] =
    React.useState<HTMLElement>();
  const dispatch = useDispatch();
  const history = useHistory();
  const deleteSeries = React.useCallback(() => {
    setDeleteSeriesAnchor(undefined);
    if (id) {
      const callback = () => history.replace('/series');
      dispatch(actions.series.delete.started({ id, callback }));
    }
  }, [id]);

  const mayHasMore = useSelector(state => state.series.mine.mayHasMore);
  const isDeleting = useSelector(state => state.series.isDeleting);

  // シリーズのタイトルを変える
  const [updateTitle] = useDebouncedCallback(
    (id: string, title: string) => {
      dispatch(
        actions.series.update.started({
          id,
          data: { title }
        })
      );
    },
    [],
    1000
  );

  // 先頭へ
  const moveHead = React.useCallback(() => {
    setSelected(undefined);
    if (!series || !selected) return;
    const works = series.works.filter(id => id !== selected.workId);
    works.unshift(selected.workId);
    dispatch(actions.series.update.started({ id: series.id, data: { works } }));
  }, [series?.works, selected]);

  // 一つ上へ
  const moveUp = React.useCallback(() => {
    setSelected(undefined);
    if (!series || !selected) return;
    const index = series.works.indexOf(selected.workId);
    if (index <= 0) return;
    const works = [...series.works];
    works.splice(index - 1, 2, selected.workId, series.works[index - 1]);
    dispatch(actions.series.update.started({ id: series.id, data: { works } }));
  }, [series?.works, selected]);

  // 一つ下へ
  const moveDown = React.useCallback(() => {
    setSelected(undefined);
    if (!series || !selected) return;
    const index = series.works.indexOf(selected.workId);
    if (index < 0 || index >= series.works.length - 1) return;
    const works = [...series.works];
    works.splice(index, 2, series.works[index + 1], selected.workId);
    dispatch(actions.series.update.started({ id: series.id, data: { works } }));
  }, [series?.works, selected]);

  // 末尾へ
  const moveTail = React.useCallback(() => {
    setSelected(undefined);
    if (!series || !selected) return;
    const works = series.works.filter(id => id !== selected.workId);
    works.push(selected.workId);
    dispatch(actions.series.update.started({ id: series.id, data: { works } }));
  }, [series?.works, selected]);

  // シリーズからはずす
  const remove = React.useCallback(() => {
    setSelected(undefined);
    if (!series || !selected) return;
    const works = series.works.filter(id => id !== selected.workId);
    dispatch(actions.series.update.started({ id: series.id, data: { works } }));
  }, [series?.works, selected]);

  if (isDeleting) {
    return <LoadingPage />;
  }
  if (!id && mayHasMore === false) {
    // シリーズをひとつも作っていない
    return (
      <Alert severity="info" className={cn.info}>
        「シリーズをつくる」をクリックして、最初のシリーズをつくってみよう！
      </Alert>
    );
  }
  if (!series) {
    if (mayHasMore) {
      return <LoadingPage />;
    } else {
      return <SeriesNotFound />;
    }
  }

  return (
    <>
      <div className={cn.listHeader}>
        {isSmall ? (
          <div className={cn.backMenu}>
            <Button
              color="secondary"
              component={Link(`/series`)}
              startIcon={<ArrowBack />}
            >
              いちらんにもどる
            </Button>
          </div>
        ) : null}
        <EditableTextField
          id={series.id}
          defaultValue={series.title}
          className={classNames(cn.title, !isSmall && cn.titleLarge)}
          helperText="このシリーズのタイトル"
          onEdit={title => updateTitle(series.id, title)}
        />
        <div className={cn.blank} />
        <IconButton
          onClick={event => setDeleteSeriesAnchor(event.currentTarget)}
        >
          <MoreVert />
        </IconButton>
        <Menu
          open={Boolean(deleteSeriesAnchor)}
          anchorEl={deleteSeriesAnchor}
          onClose={() => setDeleteSeriesAnchor(undefined)}
        >
          <MenuItem disabled={series.works.length !== 0}>
            <ListItemText
              primary="シリーズを削除する"
              secondary={
                series.works.length !== 0
                  ? 'シリーズを空にする必要があります'
                  : '削除は取り消せません'
              }
              onClick={deleteSeries}
            />
          </MenuItem>
        </Menu>
      </div>
      <List>
        {series ? (
          series.works.length > 0 ? (
            series.works.map((workId, i) => (
              <SeriesWorkItem
                key={workId}
                id={workId}
                order={i + 1}
                selected={selected?.workId === workId}
                onClick={event =>
                  setSelected({
                    anchor: event.currentTarget,
                    workId
                  })
                }
              />
            ))
          ) : !isSmall ? (
            <Alert severity="info" className={cn.info}>
              みぎの一覧から、ステージをついかしましょう！
            </Alert>
          ) : null
        ) : (
          <LinearProgress />
        )}
      </List>
      {isSmall && id ? (
        <div className={cn.bottomMenu}>
          <Button
            color="secondary"
            variant="outlined"
            component={Link(`/series/${id}/works`)}
            startIcon={<Add />}
          >
            このシリーズにステージをついかする
          </Button>
        </div>
      ) : null}
      <Menu
        open={Boolean(selected)}
        anchorEl={selected?.anchor}
        onClose={() => setSelected(undefined)}
      >
        <MenuItem onClick={moveHead}>
          <ArrowUpward className={cn.icon} />
          さいしょにいどう
        </MenuItem>
        <MenuItem onClick={moveUp}>
          <ExpandLess className={cn.icon} />
          １つ上にいどう
        </MenuItem>
        <MenuItem onClick={moveDown}>
          <ExpandMore className={cn.icon} />
          １つ下にいどう
        </MenuItem>
        <MenuItem onClick={moveTail}>
          <ArrowDownward className={cn.icon} />
          さいごにいどう
        </MenuItem>
        <MenuItem onClick={remove}>
          <ArrowForward className={cn.icon} />
          シリーズからはずす
        </MenuItem>
      </Menu>
    </>
  );
}

const useStylesUnselectedWorkList = makeStyles(theme => ({
  listHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing() * 2
  },
  backMenu: {
    backgroundColor: grey[200],
    zIndex: 1,
    position: 'sticky',
    top: 0
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing()
  }
}));

function UnselectedWorkList() {
  const cn = useStylesUnselectedWorkList();

  const uid = useSelector(state => state.auth.userInfo?.uid);
  const paginatedWorks = useSelector(state => {
    return uid ? state.work.users[uid] : undefined;
  });

  // 自分のステージを全て取得する
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (uid && paginatedWorks?.mayHasMore && !paginatedWorks.isFetching) {
      dispatch(actions.work.fetchUsers.started({ uid }));
    }
  }, [uid, paginatedWorks]);

  // どのシリーズにも含まれておらず、かつ公開済みのステージだけの ID を取得
  const unselected = useSelector(state => {
    // TODO: パフォーマンス改善のために上手いことやる
    const uid = state.auth.userInfo?.uid;
    if (!uid) return [];
    const workIds = state.work.users[uid]?.data.filter(
      id => state.work.works[id]?.data?.visibility === 'public'
    );
    if (!workIds) return [];
    const set = new Set<string>(workIds);
    for (const seriesId of state.series.mine.data) {
      const series = state.series.byId[seriesId]?.data;
      if (!series) continue;
      for (const workId of series.works) {
        set.delete(workId);
      }
    }
    // 公開日順に並び替える。特に公開直後の作品は配列の末尾に来ている
    const sorted = Array.from(set).sort(
      compareTimestamps(id => state.work.works[id]?.data?.publishedAt)
    );
    return sorted;
  });

  // シリーズについかメニュー
  const [selected, setSelected] = React.useState<{
    element: HTMLElement;
    workId: string;
  }>();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { id } = useParams<Params>();
  const series = useSelector(state =>
    id ? state.series.byId[id]?.data : undefined
  );

  // シリーズについか
  const add = React.useCallback(() => {
    setSelected(undefined);
    if (!series || !selected) return;
    const works = series.works.concat(selected.workId);
    dispatch(actions.series.update.started({ id: series.id, data: { works } }));
  }, [series?.works, selected]);

  const mayHasMore = useSelector(state => state.series.mine.mayHasMore);
  const isDeleting = useSelector(state => state.series.isDeleting);

  if (!id) {
    return null; // シリーズが未選択
  }
  if (isDeleting) {
    return <LoadingPage />;
  }
  if (!series) {
    if (mayHasMore) {
      return <LoadingPage />;
    } else {
      return <SeriesNotFound />;
    }
  }

  return (
    <>
      {isSmall ? (
        <div className={cn.backMenu}>
          <Button
            color="secondary"
            component={Link(`/series/${id}`)}
            startIcon={<ArrowBack />}
          >
            {series.title}
          </Button>
        </div>
      ) : (
        <Typography
          variant="h6"
          color="textSecondary"
          className={cn.listHeader}
        >
          <ArrowBack fontSize="large" />
          このシリーズについかする
        </Typography>
      )}
      <List>
        {unselected?.map(workId => (
          <SeriesWorkItem
            key={workId}
            id={workId}
            selected={selected?.workId === workId}
            onClick={event =>
              setSelected({
                element: event.currentTarget,
                workId
              })
            }
          />
        )) || null}
        {paginatedWorks?.mayHasMore ? <LinearProgress /> : null}
      </List>
      <Menu
        open={Boolean(selected)}
        anchorEl={selected?.element}
        onClose={() => setSelected(undefined)}
      >
        <MenuItem onClick={add}>
          <ArrowBack className={cn.icon} />
          シリーズについか
        </MenuItem>
      </Menu>
    </>
  );
}

function SeriesNotFound() {
  return (
    <Typography variant="h6">
      シリーズが見つかりませんでした。再読み込みしてください
    </Typography>
  );
}
