import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  Fab,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { Mic } from '@material-ui/icons';
import * as React from 'react';
import { SpeechRecognition } from '../ide/src/utils/SpeechRecognition';

const useStylesVoiceInputDialog = makeStyles(theme => ({
  contentSpacing: {
    position: 'relative',
    '&>*': {
      marginBottom: theme.spacing() * 2
    }
  },
  result: {
    width: '25rem'
  },
  clear: {
    position: 'absolute',
    bottom: 23,
    right: 0
  }
}));

export interface VoiceInputDialogProps extends DialogProps {
  onResult: (result: string) => void;
  children: React.ReactNode;
}

export function VoiceInputDialog({
  onResult,
  children,
  ...props
}: VoiceInputDialogProps) {
  const cn = useStylesVoiceInputDialog();
  const [allowed, setAllowed] = React.useState(false);

  // 既に許可されているか？
  React.useEffect(() => {
    if (!props.open) return;
    isAudioDeviceAllowed().then(ok => {
      if (ok) {
        setAllowed(true);
      }
    });
  }, [props.open]);

  const [text, setText] = React.useState('');
  const [error, setError] = React.useState('');

  const [recognition, setRecognition] = React.useState<SpeechRecognition>();
  const handleToggle = React.useCallback(() => {
    if (recognition) {
      recognition.stop();
      setRecognition(undefined);
      return;
    }

    const next = new SpeechRecognition();
    next.continuous = false;
    next.interimResults = false;
    next.maxAlternatives = 1;
    next.onresult = event => {
      const transcript = event.results[0][0].transcript;
      setText(text => text + (text ? '\n' : '') + transcript);
    };
    next.onend = event => {
      setRecognition(undefined);
    };
    next.start();

    setRecognition(next);
  }, [recognition]);

  const handleRequest = React.useCallback(() => {
    window.navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => setAllowed(true))
      .catch(err => setError(err.message));
  }, []);

  const handleResult = React.useCallback(() => {
    onResult(text);
    setText('');
    props.onClose?.({}, 'backdropClick');
  }, [text]);

  return (
    <Dialog {...props}>
      {children}
      {!SpeechRecognition ? (
        <DialogContent>
          <Typography variant="body1">
            このブラウザでは音声入力できません。
          </Typography>
        </DialogContent>
      ) : allowed ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            alignItems="center"
            className={cn.contentSpacing}
          >
            <Fab
              size="large"
              color={recognition ? 'default' : 'primary'}
              onClick={handleToggle}
            >
              {recognition ? <CircularProgress /> : <Mic />}
            </Fab>
            <TextField
              variant="outlined"
              value={text}
              multiline
              helperText={
                text
                  ? 'つづけて入力できるよ'
                  : recognition
                  ? 'マイクに向かってしゃべってね'
                  : 'マイクのボタンを押してね'
              }
              className={cn.result}
            />
            {text ? (
              <Button
                onClick={() => setText('')}
                className={cn.clear}
                disabled={Boolean(recognition)}
              >
                クリア
              </Button>
            ) : null}
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <Typography variant="body1">
            音声入力にはマイクが必要です。
          </Typography>
          <Typography variant="body1">
            マイクの使用を「許可」してください。
          </Typography>
        </DialogContent>
      )}
      {error ? (
        <DialogContent>
          <DialogContentText>{error}</DialogContentText>
        </DialogContent>
      ) : null}
      <DialogActions>
        {allowed ? null : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleRequest}
            disabled={!SpeechRecognition}
          >
            許可
          </Button>
        )}
        {allowed ? (
          <Button
            variant="contained"
            color="primary"
            disabled={!text}
            onClick={handleResult}
          >
            このテキストをおくる
          </Button>
        ) : null}
        <Button onClick={() => props.onClose?.({}, 'backdropClick')}>
          とじる
        </Button>
      </DialogActions>
    </Dialog>
  );
}

async function isAudioDeviceAllowed() {
  try {
    const devices = await window.navigator.mediaDevices.enumerateDevices();
    const audioDevice = devices.find(
      info => info.kind === 'audioinput' && info.deviceId
    ); // deviceId が取得できる => 許可されている
    return Boolean(audioDevice);
  } catch (error) {
    console.error(error);
  }
  return false;
}
