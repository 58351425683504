import {
  Button,
  Chip,
  makeStyles,
  MenuItem,
  Popover,
  Snackbar,
  Tooltip,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { CheckCircle, Done, OpenInNew, Send } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import deepEqual from 'deep-equal';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { classes } from 'typestyle';
import { actions } from '../ducks/actions';
import { canPublish } from '../ducks/make';
import { useRemoteConfig } from '../hooks/useRemoteConfig';
import { useUpdated } from '../hooks/useUpdated';
import { clearCheckingAtom } from '../ide/src/Cards/MonitorCard';
import { parentalControlState } from '../recoil';
import { twitterIntent } from '../utils/intent';
import { EditableTextField } from './EditableTextField';
import { IdeNoClearCheckChip } from './IdeNoClearCheckChip';
import { IdeRollbackMenu } from './IdeRollbackMenu';
import { ParentalControlDialog } from './ParentalControlDialog';
import { IdePublishDialog } from './PublishDialog';
import { SignInDialog } from './SignInDialog';
import { ThumbnailDialog } from './ThumbnailDialog';
import { UserNameLink } from './UserNameLink';
import { WorkUrlParams } from './Work';

export type LocState =
  | {
      /**
       * 初回保存時のみ, path を取得出来たらリダイレクトする
       */
      redirectBecauseFirstSave?: boolean;
    }
  | undefined;

export interface IdeMenuBarProps {
  id: string;
}

const unblockerMutable = {
  current: () => {}
};
/**
 * URLの変更をブロックしない
 * （ブロックを解除する）
 */
export function unblock() {
  unblockerMutable.current();
  unblockerMutable.current = () => {};
}

const useStyles = makeStyles(theme => ({
  collapsed: {
    display: 'none'
  },
  chip: {
    marginRight: theme.spacing()
  },
  title: {
    maxHeight: '1.6em',
    maxWidth: 500,
    flexGrow: 1,
    flexShrink: 10000,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: theme.spacing()
  },
  noTitle: {
    fontStyle: 'italic'
  },
  underline: {
    '&::before': {
      // focus も hover もされていないときの underline を消去
      height: 0
    }
  },
  titleInput: {
    overflow: 'hidden',
    textOverflow: 'ellipsis' // focus を外したとき ... にする
  },
  blank: {
    flex: 1
  },
  error: {
    color: 'red'
  },
  button: {
    marginLeft: 8
  },
  menu: {
    paddingTop: 8,
    paddingBottom: 8
  },
  caption: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    wordBreak: 'keep-all'
  }
}));

/**
 * IDE 内に表示するヘッダーメニュー
 * 自分でステージを作っていて、/edit がついている場合のみ表示する
 */
export function IdeMenuBar(props: IdeMenuBarProps) {
  // ステージの公開が許可されているか
  const loadable = useRecoilValueLoadable(parentalControlState);
  const enablePublish =
    loadable.state === 'hasValue' ? loadable.contents?.enablePublish : false;

  const userInfo = useSelector(state => state.auth.userInfo);
  const uid = useSelector(state => state.make.uid);

  const makeChanged = useSelector(state => state.make.changed);
  const isUpdating = useSelector(state => state.make.isUpdating);
  const changedByUser = useSelector(state => state.file.changedByUser);
  const restrained = useSelector(state => state.make.restrained);
  const hasError = useSelector(state => state.make.error !== null);
  const metadata = useSelector(state => state.make.metadata);
  const makeCanPublish = useSelector(canPublish);
  const stopAutoSave = useSelector(state => state.make.stopAutoSave);
  const localChange = useSelector(state => state.file.localChange);
  const isOwner = useSelector(state => state.make.isOwner);
  const savedMetadata = useSelector(state => state.make.savedMetadata);
  const fileIsSaved = useSelector(
    state => state.make.savedCount === state.file.changedByUser
  );
  // metadata がサーバと同期されている
  const metadataSaved = React.useMemo(
    () => deepEqual(metadata, savedMetadata),
    [metadata, savedMetadata]
  );
  const completlySaved = fileIsSaved && metadataSaved;

  const [openSignInDialog, setOpenSignInDialog] = React.useState(false);
  const [openParentDialog, setOpenParentDialog] = React.useState(false);
  const [sentMail, setSentMail] = React.useState(false);

  // URL の変更をブロックする
  const history = useHistory<LocState>();
  const notSavedNoticeRef = React.useRef(false); // 未保存の変更があるかどうか
  // 未保存の変更があるかどうか。ページを離れる時の警告に使う
  notSavedNoticeRef.current = !isOwner
    ? false // このステージの作者ではないので、常に false
    : stopAutoSave
    ? false // 自動保存がオフになっているので、常に false
    : !completlySaved || localChange;
  React.useEffect(() => {
    const unblock = history.block(nextLocation => {
      return notSavedNoticeRef.current
        ? '【まだ保存されていません！】キャンセルをおして、保存してください'
        : 'このページを はなれますか？';
    });
    unblockerMutable.current = unblock; // 参照を外に出す
    return unblock; // block が重複しないようにする
  }, [location.pathname]);

  const dispatch = useDispatch();

  // UI
  const menuRef = React.useRef<HTMLElement>(null);
  const [menuOpened, setMenuOpened] = React.useState(false);
  const toggleMenu = React.useCallback(
    () => setMenuOpened(!menuOpened),
    [menuOpened]
  );

  // [公開する] メニュー
  const [publishMenuAnchorEl, setPublishMenuAnchorEl] =
    React.useState<HTMLButtonElement>();

  const startClearCheckAndPublish = React.useCallback(() => {
    setPublishMenuAnchorEl(undefined);
    setPublishAfterCheck(true);
    setClearChecking(true);
  }, []);

  const toggleAutoSave = React.useCallback(() => {
    dispatch(actions.make.stopAutoSave(!stopAutoSave));
    setMenuOpened(false);
  }, [stopAutoSave]);

  const unsafeKeywords = useRemoteConfig('unsafe_keywords');
  const [openPublishDialog, setOpenPublishDialog] = React.useState(false);
  const handleSetPublic = () => {
    setPublishMenuAnchorEl(undefined);

    // 不適切なキーワードが含まれている場合は公開できない
    const isIncludeUnsafeKeyword = unsafeKeywords
      .asString()
      .split(',')
      .some(
        keyword =>
          metadata.title?.includes(keyword) ||
          metadata.description?.includes(keyword)
      );
    if (isIncludeUnsafeKeyword) {
      console.error('不適切なキーワードが含まれているため公開できません');
      return;
    }

    dispatch(actions.make.metadata({ visibility: 'public' }));
    setOpenPublishDialog(true);
  };

  const handleSetLimited = React.useCallback(() => {
    dispatch(actions.make.metadata({ visibility: 'limited' }));
    setMenuOpened(false);
  }, []);

  const handleRemove = React.useCallback(() => {
    if (props.id) {
      // サーバーから削除
      dispatch(actions.work.delete.started({ id: props.id }));
      // キット一覧に飛ぶ
      unblock(); // 警告を出さない
      history.push('/authUser/trash');
    }
    setMenuOpened(false);
  }, [props.id]);

  const [thumbnailDialogOpened, setThumbnailDialogOpened] =
    React.useState(false);
  const toggleDialog = React.useCallback(() => {
    setThumbnailDialogOpened(!thumbnailDialogOpened);
    setMenuOpened(false);
  }, [thumbnailDialogOpened]);

  const [clearChecking, setClearChecking] = useRecoilState(clearCheckingAtom);
  const [publishAfterCheck, setPublishAfterCheck] = React.useState(false);
  React.useEffect(() => {
    if (metadata.clearChecked) {
      // クリアできたのでクリアチェックモードを終了する
      setClearChecking(false);
      if (publishAfterCheck) {
        // クリアチェックして公開
        handleSetPublic();
      }
    }
  }, [metadata.clearChecked]);
  React.useEffect(() => {
    if (!clearChecking) {
      // クリアチェックをやめたので自動公開を止める
      setPublishAfterCheck(false);
    }
  }, [clearChecking]);

  // 投稿を促すガイダンスを表示する
  const [guidance, setGuidance] = React.useState<
    '' | 'bar' | 'title' | 'publish' | 'published'
  >('');
  React.useEffect(() => {
    if (metadata.visibility === 'limited' && isOwner) {
      // 20, 50, 100N 回目に表示
      const show =
        changedByUser === 20 ||
        changedByUser === 50 ||
        (changedByUser > 0 && changedByUser % 100 === 0);
      setGuidance(show ? 'bar' : '');
    }
  }, [changedByUser, metadata.visibility, isOwner]);
  const handleGuideClose = React.useCallback((event: any, reason?: string) => {
    if (reason === 'clickaway') return; // 画面クリックでは閉じない
    setGuidance('');
  }, []);
  const handleGuideClick = React.useCallback(() => {
    // タイトルがついていなければタイトルをつけてもらう
    setGuidance(metadata.title ? 'publish' : 'title');
  }, [metadata.title]);
  React.useEffect(() => {
    // タイトルがついたら次は publish
    if (guidance === 'title' && metadata.title) {
      setGuidance('publish');
    }
  }, [guidance, metadata.title]);
  React.useEffect(() => {
    // 公開されたらみんなのステージへ誘導する
    if (guidance && metadata.visibility === 'public' && !isUpdating) {
      setGuidance('published');
    }
  }, [guidance, metadata.visibility, isUpdating]);
  const handleSeePublished = React.useCallback(() => {
    unblock();
    history.push('/lists/recommended');
  }, []);

  // ファイルを書き換えたらクリアチェックを未完了に戻す
  const isOwnerPreviousRef = React.useRef(isOwner); // 直前の isOwner を保持する
  useUpdated(() => {
    const isOwnerPrevious = isOwnerPreviousRef.current;
    isOwnerPreviousRef.current = isOwner;
    if (!isOwner) return; // 編集権限を持っていない
    if (!isOwnerPrevious) return; // https://bit.ly/3qlkSTk
    if (!metadata.clearChecked) return; // https://bit.ly/2FaHWS8
    if (isOwner) {
      dispatch(actions.make.metadata({ clearChecked: false }));
    }
  }, [changedByUser, isOwner]);

  // タイトルの変更を currentPage に反映させる
  React.useEffect(() => {
    dispatch(actions.team.currentPage(location.pathname));
  }, [metadata.title]);

  const params = useParams<WorkUrlParams>();

  const cn = useStyles();
  const xs = useMediaQuery('(max-width: 600)');

  return (
    <>
      {metadata.visibility === 'public' ? (
        <Chip label="公開中" className={cn.chip} />
      ) : metadata.visibility === 'private' ? (
        <Chip label="非公開" className={cn.chip} />
      ) : null}
      <IdeNoClearCheckChip id={props.id} />
      <Tooltip
        title="ここをクリックして、好きなタイトルをつけよう"
        arrow
        disableFocusListener
        open={guidance === 'title'}
      >
        <EditableTextField
          id={props.id}
          placeholder="タイトルがついていません"
          readonly={!isOwner}
          defaultValue={metadata.title}
          className={classes(cn.title, !metadata.title && cn.noTitle)}
          InputProps={{
            classes: {
              input: cn.titleInput,
              underline: cn.underline // TODO: 動作しているか試す
            }
          }}
          onEdit={title => dispatch(actions.make.metadata({ title }))}
        />
      </Tooltip>
      <UserNameLink uid={uid} icon />
      <div className={cn.blank} />
      {hasError ? (
        <span className={cn.error}>エラーがおきたようです</span>
      ) : null}
      {isOwner && metadata.visibility !== 'public' ? (
        !userInfo ? (
          <Tooltip title="ステージを公開するには、ログインしてください。">
            <Button
              variant="outlined"
              onClick={() => setOpenSignInDialog(true)}
            >
              公開する
            </Button>
          </Tooltip>
        ) : enablePublish === undefined ? (
          <Tooltip title="ステージを公開するには、みまもり設定を変更してください。">
            <Button
              variant="outlined"
              onClick={() => setOpenParentDialog(true)}
              disabled={sentMail}
              startIcon={sentMail ? <Send /> : undefined}
            >
              {sentMail ? 'メールを確認してください' : '公開する'}
            </Button>
          </Tooltip>
        ) : enablePublish ? (
          <Tooltip
            title={
              <span>
                ここをクリックして公開しよう！
                <br />
                公開したあとも直すことが出来るよ
              </span>
            }
            arrow
            open={guidance === 'publish' && makeCanPublish}
          >
            <Button
              variant="outlined"
              onClick={event => setPublishMenuAnchorEl(event.currentTarget)}
              className={cn.button}
            >
              {`公開する`}
            </Button>
          </Tooltip>
        ) : (
          <Typography variant="body2" color="textSecondary">
            公開できません
          </Typography>
        )
      ) : null}
      <Popover
        id="publish-menu"
        open={Boolean(publishMenuAnchorEl)}
        anchorEl={publishMenuAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setPublishMenuAnchorEl(undefined)}
        classes={{ paper: cn.menu }}
      >
        <MenuItem
          disabled={!makeCanPublish || metadata.clearChecked}
          onClick={startClearCheckAndPublish}
        >
          クリアチェックして公開
          {metadata.clearChecked ? <Done /> : null}
        </MenuItem>
        <MenuItem disabled={!makeCanPublish} onClick={handleSetPublic}>
          このまま公開する
        </MenuItem>
      </Popover>
      {isOwner && metadata.visibility === 'public' ? (
        <Button
          variant="outlined"
          startIcon={<CheckCircle />}
          disabled={metadata.clearChecked}
          onClick={() => setClearChecking(true)}
        >
          {clearChecking
            ? 'クリアチェック中…'
            : metadata.clearChecked
            ? 'クリアチェック済み'
            : 'クリアチェックする'}
        </Button>
      ) : null}
      <Typography
        variant="caption"
        className={classes(cn.caption, xs && cn.collapsed)}
      >
        {
          restrained && !isOwner
            ? '' // 改造が許可されていない
            : !userInfo
            ? 'ログインしていないので、保存されません'
            : !isOwner
            ? '自分のステージではないので、保存されません'
            : !makeChanged
            ? 'あたらしいステージです' // キットを開いただけの状態
            : isUpdating
            ? `通信中...` // Uploading or Downloading
            : stopAutoSave
            ? `保存されないせっていになっています`
            : completlySaved && !localChange
            ? `保存されました` // 最新の状態
            : '保存されていません' // 改造中で変更があった
        }
        <br />
        {params[0] === 'works' && isOwner ? (
          <React.Suspense fallback={null}>
            <IdeRollbackMenu workId={props.id} />
          </React.Suspense>
        ) : null}
      </Typography>
      {isOwner ? (
        <Button
          aria-owns={menuRef.current ? 'simple-menu' : undefined}
          aria-haspopup="true"
          className={cn.button}
          onClick={toggleMenu}
        >
          <span ref={menuRef}>せってい</span>
        </Button>
      ) : null}
      <Popover
        id="simple-menu"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={menuRef.current}
        open={menuOpened}
        onClose={toggleMenu}
        classes={{ paper: cn.menu }}
      >
        <MenuItem onClick={toggleDialog}>カバー画像をかえる</MenuItem>
        {metadata.visibility === 'public' ? (
          <MenuItem
            onClick={twitterIntent(
              `ステージを作りました！ ${window.location.href} #hackforplay`
            )}
          >
            Twitter でシェア
          </MenuItem>
        ) : null}
        {metadata.visibility !== 'limited' ? (
          <MenuItem disabled={isUpdating} onClick={handleSetLimited}>
            限定公開にする
          </MenuItem>
        ) : null}
        {isOwner ? (
          <MenuItem onClick={toggleAutoSave}>
            {stopAutoSave ? 'じどう保存をアリにする' : 'じどう保存をナシにする'}
          </MenuItem>
        ) : null}
        {isOwner ? <MenuItem onClick={handleRemove}>削除する</MenuItem> : null}
      </Popover>
      {isOwner ? (
        <Button
          color="primary"
          href="https://helpfeel.com/hackforplay"
          target="_blank"
          rel="noopener"
          className={cn.button}
          endIcon={<OpenInNew />}
        >
          ヒント
        </Button>
      ) : null}
      <Snackbar
        open={guidance === 'bar'}
        autoHideDuration={6000}
        onClose={handleGuideClose}
      >
        <Alert
          severity="success"
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={handleGuideClick}
            >
              そうする！
            </Button>
          }
        >
          おめでとう！このゲームのセーブ回数が {changedByUser} 回に達しました 🎉
          <br />
          そろそろ、ほかの人たちにもプレイしてもらいませんか？
        </Alert>
      </Snackbar>
      <Snackbar open={guidance === 'published'} onClose={handleGuideClose}>
        <Alert
          severity="success"
          action={
            <Button color="primary" onClick={handleSeePublished}>
              そうする！
            </Button>
          }
        >
          おめでとう！このステージがインターネットに公開されました 🎉
          <br />
          さっそく見に行ってみよう！
        </Alert>
      </Snackbar>
      <ParentalControlDialog
        open={openParentDialog}
        onClose={() => setOpenParentDialog(false)}
        onMailAddressSent={() => setSentMail(true)}
        onMailLinkSent={() => setSentMail(true)}
      />
      <SignInDialog
        open={openSignInDialog}
        onClose={() => setOpenSignInDialog(false)}
      />
      <IdePublishDialog
        id={props.id}
        open={openPublishDialog}
        onClose={(event, reason) => {
          if (reason === 'escapeKeyDown') setOpenPublishDialog(false);
        }}
      />
      <ThumbnailDialog open={thumbnailDialogOpened} onClose={toggleDialog} />
    </>
  );
}
